"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OreGroup = exports.User = exports.SurveyType = exports.Client = exports.OreType = exports.Shiploader = exports.UserRole = void 0;
/* eslint-disable */
const minimal_1 = require("protobufjs/minimal");
const baseUser = {
    id: 0,
    username: "",
    roles: 0,
};
const baseOreGroup = {
    oreTons: 0,
    oreType: "",
};
exports.UserRole = {
    USER_ROLE_USER: 0,
    USER_ROLE_SUPERVISOR: 1,
    USER_ROLE_ADMIN: 2,
    USER_ROLE_AGENT: 3,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "USER_ROLE_USER":
                return exports.UserRole.USER_ROLE_USER;
            case 1:
            case "USER_ROLE_SUPERVISOR":
                return exports.UserRole.USER_ROLE_SUPERVISOR;
            case 2:
            case "USER_ROLE_ADMIN":
                return exports.UserRole.USER_ROLE_ADMIN;
            case 3:
            case "USER_ROLE_AGENT":
                return exports.UserRole.USER_ROLE_AGENT;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.UserRole.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.UserRole.USER_ROLE_USER:
                return "USER_ROLE_USER";
            case exports.UserRole.USER_ROLE_SUPERVISOR:
                return "USER_ROLE_SUPERVISOR";
            case exports.UserRole.USER_ROLE_ADMIN:
                return "USER_ROLE_ADMIN";
            case exports.UserRole.USER_ROLE_AGENT:
                return "USER_ROLE_AGENT";
            default:
                return "UNKNOWN";
        }
    },
};
exports.Shiploader = {
    SHIPLOADER_BOTH: 0,
    SHIPLOADER_ONE: 1,
    SHIPLOADER_TWO: 2,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "SHIPLOADER_BOTH":
                return exports.Shiploader.SHIPLOADER_BOTH;
            case 1:
            case "SHIPLOADER_ONE":
                return exports.Shiploader.SHIPLOADER_ONE;
            case 2:
            case "SHIPLOADER_TWO":
                return exports.Shiploader.SHIPLOADER_TWO;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.Shiploader.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.Shiploader.SHIPLOADER_BOTH:
                return "SHIPLOADER_BOTH";
            case exports.Shiploader.SHIPLOADER_ONE:
                return "SHIPLOADER_ONE";
            case exports.Shiploader.SHIPLOADER_TWO:
                return "SHIPLOADER_TWO";
            default:
                return "UNKNOWN";
        }
    },
};
exports.OreType = {
    ORE_TYPE_UNSPECIFIED: 0,
    /** ORE_TYPE_AL -  Assmang Ore types
     */
    ORE_TYPE_AL: 1,
    ORE_TYPE_AF: 2,
    /** ORE_TYPE_LB -  Kumba Ore types
     */
    ORE_TYPE_LB: 3,
    ORE_TYPE_FB: 4,
    ORE_TYPE_DA: 5,
    ORE_TYPE_LS: 6,
    ORE_TYPE_FA: 7,
    /** ORE_TYPE_SBL -  Sedibeng Ore types
     */
    ORE_TYPE_SBL: 8,
    ORE_TYPE_SBF: 9,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "ORE_TYPE_UNSPECIFIED":
                return exports.OreType.ORE_TYPE_UNSPECIFIED;
            case 1:
            case "ORE_TYPE_AL":
                return exports.OreType.ORE_TYPE_AL;
            case 2:
            case "ORE_TYPE_AF":
                return exports.OreType.ORE_TYPE_AF;
            case 3:
            case "ORE_TYPE_LB":
                return exports.OreType.ORE_TYPE_LB;
            case 4:
            case "ORE_TYPE_FB":
                return exports.OreType.ORE_TYPE_FB;
            case 5:
            case "ORE_TYPE_DA":
                return exports.OreType.ORE_TYPE_DA;
            case 6:
            case "ORE_TYPE_LS":
                return exports.OreType.ORE_TYPE_LS;
            case 7:
            case "ORE_TYPE_FA":
                return exports.OreType.ORE_TYPE_FA;
            case 8:
            case "ORE_TYPE_SBL":
                return exports.OreType.ORE_TYPE_SBL;
            case 9:
            case "ORE_TYPE_SBF":
                return exports.OreType.ORE_TYPE_SBF;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.OreType.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.OreType.ORE_TYPE_UNSPECIFIED:
                return "ORE_TYPE_UNSPECIFIED";
            case exports.OreType.ORE_TYPE_AL:
                return "ORE_TYPE_AL";
            case exports.OreType.ORE_TYPE_AF:
                return "ORE_TYPE_AF";
            case exports.OreType.ORE_TYPE_LB:
                return "ORE_TYPE_LB";
            case exports.OreType.ORE_TYPE_FB:
                return "ORE_TYPE_FB";
            case exports.OreType.ORE_TYPE_DA:
                return "ORE_TYPE_DA";
            case exports.OreType.ORE_TYPE_LS:
                return "ORE_TYPE_LS";
            case exports.OreType.ORE_TYPE_FA:
                return "ORE_TYPE_FA";
            case exports.OreType.ORE_TYPE_SBL:
                return "ORE_TYPE_SBL";
            case exports.OreType.ORE_TYPE_SBF:
                return "ORE_TYPE_SBF";
            default:
                return "UNKNOWN";
        }
    },
};
exports.Client = {
    CLIENT_UNSPECIFIED: 0,
    CLIENT_ASSMANG: 1,
    CLIENT_KUMBA: 2,
    CLIENT_SEDIBENG: 3,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "CLIENT_UNSPECIFIED":
                return exports.Client.CLIENT_UNSPECIFIED;
            case 1:
            case "CLIENT_ASSMANG":
                return exports.Client.CLIENT_ASSMANG;
            case 2:
            case "CLIENT_KUMBA":
                return exports.Client.CLIENT_KUMBA;
            case 3:
            case "CLIENT_SEDIBENG":
                return exports.Client.CLIENT_SEDIBENG;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.Client.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.Client.CLIENT_UNSPECIFIED:
                return "CLIENT_UNSPECIFIED";
            case exports.Client.CLIENT_ASSMANG:
                return "CLIENT_ASSMANG";
            case exports.Client.CLIENT_KUMBA:
                return "CLIENT_KUMBA";
            case exports.Client.CLIENT_SEDIBENG:
                return "CLIENT_SEDIBENG";
            default:
                return "UNKNOWN";
        }
    },
};
exports.SurveyType = {
    SURVEY_TYPE_UNSPECIFIED: 0,
    SURVEY_TYPE_DRAFT_CHECK: 1,
    SURVEY_TYPE_INTERMEDIATE_SURVEY: 2,
    SURVEY_TYPE_ADD_ON_SURVEY: 3,
    SURVEY_TYPE_DRAFT_SURVEY: 4,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "SURVEY_TYPE_UNSPECIFIED":
                return exports.SurveyType.SURVEY_TYPE_UNSPECIFIED;
            case 1:
            case "SURVEY_TYPE_DRAFT_CHECK":
                return exports.SurveyType.SURVEY_TYPE_DRAFT_CHECK;
            case 2:
            case "SURVEY_TYPE_INTERMEDIATE_SURVEY":
                return exports.SurveyType.SURVEY_TYPE_INTERMEDIATE_SURVEY;
            case 3:
            case "SURVEY_TYPE_ADD_ON_SURVEY":
                return exports.SurveyType.SURVEY_TYPE_ADD_ON_SURVEY;
            case 4:
            case "SURVEY_TYPE_DRAFT_SURVEY":
                return exports.SurveyType.SURVEY_TYPE_DRAFT_SURVEY;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.SurveyType.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.SurveyType.SURVEY_TYPE_UNSPECIFIED:
                return "SURVEY_TYPE_UNSPECIFIED";
            case exports.SurveyType.SURVEY_TYPE_DRAFT_CHECK:
                return "SURVEY_TYPE_DRAFT_CHECK";
            case exports.SurveyType.SURVEY_TYPE_INTERMEDIATE_SURVEY:
                return "SURVEY_TYPE_INTERMEDIATE_SURVEY";
            case exports.SurveyType.SURVEY_TYPE_ADD_ON_SURVEY:
                return "SURVEY_TYPE_ADD_ON_SURVEY";
            case exports.SurveyType.SURVEY_TYPE_DRAFT_SURVEY:
                return "SURVEY_TYPE_DRAFT_SURVEY";
            default:
                return "UNKNOWN";
        }
    },
};
exports.User = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).int32(message.id);
        writer.uint32(18).string(message.username);
        writer.uint32(26).fork();
        for (const v of message.roles) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseUser };
        message.roles = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.roles.push(reader.int32());
                        }
                    }
                    else {
                        message.roles.push(reader.int32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseUser };
        message.roles = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = Number(object.id);
        }
        else {
            message.id = 0;
        }
        if (object.username !== undefined && object.username !== null) {
            message.username = String(object.username);
        }
        else {
            message.username = "";
        }
        if (object.roles !== undefined && object.roles !== null) {
            for (const e of object.roles) {
                message.roles.push(exports.UserRole.fromJSON(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseUser };
        message.roles = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = 0;
        }
        if (object.username !== undefined && object.username !== null) {
            message.username = object.username;
        }
        else {
            message.username = "";
        }
        if (object.roles !== undefined && object.roles !== null) {
            for (const e of object.roles) {
                message.roles.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || 0;
        obj.username = message.username || "";
        if (message.roles) {
            obj.roles = message.roles.map(e => exports.UserRole.toJSON(e));
        }
        else {
            obj.roles = [];
        }
        return obj;
    },
};
exports.OreGroup = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).int32(message.oreTons);
        writer.uint32(18).string(message.oreType);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseOreGroup };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.oreTons = reader.int32();
                    break;
                case 2:
                    message.oreType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseOreGroup };
        if (object.oreTons !== undefined && object.oreTons !== null) {
            message.oreTons = Number(object.oreTons);
        }
        else {
            message.oreTons = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseOreGroup };
        if (object.oreTons !== undefined && object.oreTons !== null) {
            message.oreTons = object.oreTons;
        }
        else {
            message.oreTons = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.oreTons = message.oreTons || 0;
        obj.oreType = message.oreType || "";
        return obj;
    },
};
