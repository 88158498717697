import * as React from 'react';
import {Box, Divider, Grid, Paper} from '@material-ui/core';
import {Field} from 'formik';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {useTitle} from 'contexts/TitleContext';
import {TextField} from 'formik-material-ui';
import {ButtonBar} from 'components/ButtonBar';
import {useHistory, useParams} from 'react-router';
import {BaseForm} from 'components/BaseForm';
import {useEngineContext} from 'contexts/EngineContext';
import * as yup from 'yup';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {StartEndDateTime} from '@deckmans/web-shared';
import {DecisionType, DecisionAnswer} from '@deckmans/domain';
import {useShiploader} from 'hooks/useShiploader';
import {Shiploader} from '@deckmans/domain';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {useVessel, useVesselActivity} from 'hooks/useVessel';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';

interface SingleLoadingActivityType {
  id: string;
  comments: string;
  endTime: string;
  startTime: string;
  reason: DecisionAnswer;
  shiploader: Shiploader;
  reasonDropdown: string;
}

const reasons = [
  {
    id: 'Not enough upfront deballast time',
    description: 'Not enough upfront deballast time',
  },
  {
    id: 'Vessel Pumps/Equipment fault',
    description: 'Vessel Pumps/Equipment fault',
  },
];

const defaultDecision = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_SINGLE_LOADING_ACTIVITY,
});

export function SingleLoadingActivity() {
  useTitle('Single loading');
  const history = useHistory();
  const {engine, eventCreator} = useEngineContext();

  const {activityId, shiploaderId} = useParams<{
    shiploaderId: string;
    activityId: string;
  }>();

  const shiploader = useShiploader(shiploaderId);

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const vessel = useVessel();
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    reason: yup.string().required('This field is required'),
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
  });

  const activity = useVesselActivity(activityId);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);
  const sd = pvEndDate !== '' ? pvEndDate : reclaimStart;
  const {startDate, endDate} = useHistoryStateDateRange(sd);
  const initialValues = React.useMemo(() => {
    if (isEditing && vessel && activity && activity.singleLoadingActivity) {
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments: activity.singleLoadingActivity.comments,
        id: activity.id,
        reason: activity.singleLoadingActivity.reason ?? defaultDecision,

        shiploader,
        reasonDropdown: reasons[0].description,
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        activity: '',
        comments: '',
        id: '',
        reason: defaultDecision,

        shiploader,
        reasonDropdown: reasons[0].description,
      };
    }
  }, [activity, isEditing, shiploader, startDate, vessel, endDate]);

  return (
    <BaseForm<SingleLoadingActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={initialValues}
      onCreateSubmit={async ({startTime, endTime, comments, reason}) => {
        const event = eventCreator.singleLoadingActivityCreated({
          comments,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          vesselId: vessel.id,
          shiploader,
        });

        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({startTime, endTime, comments, id, reason}) => {
        const event = eventCreator.singleLoadingActivityEdited({
          id,
          comments,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          vesselId: vessel.id,
          shiploader,
        });

        await engine.process(event);
        history.goBack();
      }}
    >
      {({values, submitForm}) => {
        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime />

                  <Grid item xs={6}>
                    <Duration
                      startTime={values.startTime}
                      endTime={values.endTime}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MultiLevelQuestions
                      name="reason"
                      decisionType={
                        DecisionType.DECISION_TYPE_SINGLE_LOADING_ACTIVITY
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box px={1}>
                      <Field
                        fullWidth
                        multiline
                        component={TextField}
                        name="comments"
                        label="Comments"
                        placeholder="Comments"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      saveText="save"
                      createText="create"
                      isEditing={isEditing}
                      handleSave={submitForm}
                      vessel={vessel}
                      activityId={activityId}
                      title="Delete Single Loading Activity"
                      subtitle="Are you sure you want to delete this activity?"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </BaseForm>
  );
}
