import * as React from 'react';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {CO} from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      borderColor: theme.palette.primary.main,
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    root: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      width: '100%',
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },

    toggleButtonWidth: {
      width: '100%',
    },
    flexDisplay: {
      display: 'flex',
    },
  })
);

interface Props<Val> {
  data: CO<Val>[];
  value: Val;
  handleChange: (event: React.MouseEvent<HTMLElement>, newValue: Val) => void;
  disable?: boolean;
}

export function ToggleButtons<Val = string>({
  data,
  handleChange,
  value,
  disable = false,
}: Props<Val>) {
  const classes = useStyles();
  return (
    <Box>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        className={classes.toggleButtonWidth}
      >
        {data.map(({id, description}, idx) => {
          return (
            <ToggleButton
              disabled={disable}
              key={`tb_${id}_${idx}`}
              value={description}
              classes={{selected: classes.selected, root: classes.root}}
            >
              <Typography variant="button">{description}</Typography>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
}
