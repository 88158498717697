import React from 'react';
import {useField, FieldHookConfig} from 'formik';
import {ToggleButtons} from './ToggleButtons';
import {CO} from './types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Grid} from '@material-ui/core';
// import Box from '@material-ui/core/Box';

interface Props<Val> {
  field: string | FieldHookConfig<Val>; ///aka field name
  data: CO<Val>[];
  label?: string;
  disable?: boolean;
}

export function ToggleButtonField<Val = string>({
  field,
  data,
  label,
  disable = false,
}: Props<Val>) {
  const [f, form, meta] = useField(field);
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <ToggleButtons<Val>
          disable={disable}
          data={data}
          value={f.value}
          handleChange={(_e: React.MouseEvent<HTMLElement>, newVal: Val) =>
            newVal !== null && meta.setValue(newVal)
          }
        />
      </Grid>

      {form.error && form.touched && (
        <Box pl={2}>
          <Typography variant="caption" color="error">
            {form.error}
          </Typography>
        </Box>
      )}
    </Grid>
  );
}
