import React from 'react';
import {Chip, fade, makeStyles, useTheme} from '@material-ui/core';
import WifiRoundedIcon from '@material-ui/icons/WifiRounded';
import {useNavigatorOnline} from '../../../hooks/useNavigatorOnline';

const useStyles = makeStyles((theme) => ({
  online: {
    color: theme.palette.success.main,
    backgroundColor: fade(theme.palette.success.main, 0.08),
  },
  offline: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.08),
  },
}));

export function HeaderConnected() {
  const online = useNavigatorOnline();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Chip
      className={online ? classes.online : classes.offline}
      icon={
        <WifiRoundedIcon
          style={{
            color: online
              ? theme.palette.success.main
              : theme.palette.error.main,
          }}
        />
      }
      label={online ? 'Connected' : 'Disconnected'}
    />
  );
}
