import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import {Vessel, VesselState, UserRole} from '@deckmans/domain';
import {CheckIfHatchStopped} from './ActivityButton';
import {useAuthContext} from 'contexts/AuthContext';

interface Props {
  vessel: Vessel;
  data: {
    name: string;
    onClick: () => void;
  }[];
}

export default function AddActivityMenu({data, vessel}: Props) {
  const {hasRole} = useAuthContext();
  const isVesselSubmitted = vessel.state === VesselState.VESSEL_STATE_COMPLETE;
  const roleAllowsAfterVesselSubmitted =
    hasRole(UserRole.USER_ROLE_ADMIN) || hasRole(UserRole.USER_ROLE_SUPERVISOR);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isNotFinishedLeft = CheckIfHatchStopped(vessel, 1);
  const isNotFinishedRight = CheckIfHatchStopped(vessel, 2);

  return (
    <div>
      <Fab
        onClick={handleClick}
        variant="extended"
        color="primary"
        disabled={
          hasRole(UserRole.USER_ROLE_AGENT) || isVesselSubmitted
            ? roleAllowsAfterVesselSubmitted
              ? false
              : true
            : false
        }
      >
        <Add fontSize="small" /> Add Vessel activity
      </Fab>
      {isNotFinishedLeft && isNotFinishedRight ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{horizontal: 'center', vertical: 'top'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {data.map(({name, onClick}, idx) => {
            return (
              <MenuItem
                key={'menu_item_' + idx + name}
                onClick={() => {
                  handleClose();
                  onClick();
                }}
              >
                <Typography variant="button">{name}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      ) : (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{horizontal: 'center', vertical: 'top'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <MenuItem disabled={true}>
            <Typography variant="button">Please finish hatch</Typography>
          </MenuItem>
        </Menu>
      )}
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        {data.map(({name, onClick}, idx) => {
          return (
            <MenuItem
              key={'menu_item_' + idx + name}
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              <Typography variant="button">{name}</Typography>
            </MenuItem>
          );
        })}
      </Menu> */}
    </div>
  );
}
