import React from 'react';
import {Chip, fade, makeStyles, useTheme} from '@material-ui/core';
import Update from '@material-ui/icons/Update';

const useBackgroundStyles = makeStyles((theme) => ({
  update: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.warning.main, 0.08),
  },
}));

interface Props {
  handleUpdate: () => void;
}

export function HeaderUpdate({handleUpdate}: Props) {
  const {palette} = useTheme();
  const backgroundClasses = useBackgroundStyles();

  return (
    <Chip
      className={backgroundClasses.update}
      clickable={true}
      onClick={handleUpdate}
      icon={
        <Update
          style={{
            color: palette.warning.main,
          }}
        />
      }
      label="Update"
    />
  );
}
