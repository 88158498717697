import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Typography,
  ListItem,
  List,
  ListItemText,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import {TimeBetween} from 'components/Duration';
import {useHistory} from 'react-router';
import {Shiploader} from '@deckmans/domain';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  h5: {
    fontFamily: 'Roboto Mono',
  },
  listPadding: {
    paddingTop: 0,
    paddingBottom: 0,

    border: '3px solid',
    borderColor: theme.palette.error.main,
  },
}));

interface Props {
  startDate?: Date;
  endDate?: Date;
  vesselId: string;
  shiploader: Shiploader;
  hatchGroupId: string;
  activityId: string;
}

export function UnallocatedTimeBlock(props: Props) {
  const classes = useStyles();

  const isUnallocated = moment(props.startDate).isBefore(moment(props.endDate));
  return (
    <List classes={{padding: classes.listPadding}}>
      <Divider />
      <ListItem>
        <ListItemText
          disableTypography
          primary={
            isUnallocated ? (
              <UnAllocatedTimeMenu {...props} />
            ) : (
              <Box display="flex">
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  alignItems="center"
                >
                  <Box display="flex">
                    <Typography variant="h5">TIME OVERLAP </Typography>
                  </Box>
                </Box>
              </Box>
            )
          }
        />
      </ListItem>
    </List>
  );
}

function UnAllocatedTimeMenu({
  startDate,
  endDate,
  shiploader,
  vesselId,
  hatchGroupId,
}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box display="flex" onClick={handleClick}>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            {moment(startDate).format('HH:mm')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {moment(endDate).format('HH:mm')}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          pl={2}
        >
          <Box display="flex">
            <Typography>Unallocated Time</Typography>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="overline">Total Time</Typography>
                <Typography variant="h5" className={classes.h5}>
                  <TimeBetween
                    startTime={startDate?.toUTCString()}
                    endTime={endDate?.toUTCString()}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Menu
        id="create-hatch-activity-menu"
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(
              `/activity/hatchLoadingActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            )
          }
        >
          <Typography variant="button">Loading</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              `/activity/hatchDirectLoadingActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            );
          }}
        >
          <Typography variant="button">Direct Loading</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              `/activity/hatchReclaimingActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            );
          }}
        >
          <Typography variant="button">Reclaiming</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              `/activity/hatchBreakdownActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            );
          }}
        >
          <Typography variant="button">Breakdown</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
