import React from 'react';
import {Box, Typography, makeStyles, createStyles} from '@material-ui/core';
import moment from 'moment';

interface Props {
  startTime?: string | Date;
  endTime?: string | Date;
}

const styles = makeStyles(() =>
  createStyles({
    h5: {
      fontFamily: 'Roboto Mono',
    },
  })
);

export function Duration(props: Props) {
  const classes = styles();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle1">Duration</Typography>
      <Typography variant="h5" classes={{h5: classes.h5}}>
        <TimeBetween {...props} />
      </Typography>
    </Box>
  );
}

export function TimeBetween({startTime, endTime}: Props) {
  const dur = moment.duration(moment(endTime).diff(moment(startTime)));
  const minutes = dur.asMinutes();
  const h = Math.floor(minutes / 60);
  const mod = Math.ceil(minutes % 60).toString();
  const m = mod.length === 1 ? '0' + mod : mod;
  const duration = minutes < 0 ? '-- : --' : h + ':' + m;

  return <>{startTime && endTime ? duration : '-- : --'}</>;
}
