import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import {UserRole} from '@deckmans/domain';
import {useAuthContext} from 'contexts/AuthContext';
const Transition = React.forwardRef(function Transition(
  //eslint-disable-next-line
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  handleAgree: () => void;
  title: string;
  subtitle: string;
  roleAllows: UserRole[];
  size?: IconButtonProps['size'];
}
export default function DeleteDialog({
  handleAgree,
  title = 'Delete Action',
  subtitle = 'Are you sure you want to delete this item',
  roleAllows,
  size,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const {hasRole} = useAuthContext();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (
    roleAllows
      .map((r) => {
        return hasRole(r);
      })
      .indexOf(true) === -1
  ) {
    return <></>;
  }
  return (
    <>
      <IconButton size={size} onClick={handleClickOpen}>
        <Delete />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleAgree();
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
