"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginResponse = exports.ResetPasswordResponse = exports.ResetPasswordRequest = exports.CreatePasswordRequest = exports.LoginRequest = exports.LoginServiceClientImpl = void 0;
/* eslint-disable */
const shared_1 = require("../proto/shared");
const minimal_1 = require("protobufjs/minimal");
const baseLoginRequest = {
    username: "",
    password: "",
};
const baseCreatePasswordRequest = {
    token: "",
    password: "",
};
const baseResetPasswordRequest = {
    username: "",
    auth: false,
};
const baseResetPasswordResponse = {
    success: false,
};
const baseLoginResponse = {
    token: "",
    userId: 0,
    roles: 0,
    clients: "",
    username: "",
    changePasswordToken: "",
};
class LoginServiceClientImpl {
    constructor(rpc) {
        this.rpc = rpc;
    }
    Login(request) {
        const data = exports.LoginRequest.encode(request).finish();
        const promise = this.rpc.request("login.LoginService", "Login", data);
        return promise.then(data => exports.LoginResponse.decode(new minimal_1.Reader(data)));
    }
    CreatePassword(request) {
        const data = exports.CreatePasswordRequest.encode(request).finish();
        const promise = this.rpc.request("login.LoginService", "CreatePassword", data);
        return promise.then(data => exports.LoginResponse.decode(new minimal_1.Reader(data)));
    }
    ResetPassword(request) {
        const data = exports.ResetPasswordRequest.encode(request).finish();
        const promise = this.rpc.request("login.LoginService", "ResetPassword", data);
        return promise.then(data => exports.ResetPasswordResponse.decode(new minimal_1.Reader(data)));
    }
}
exports.LoginServiceClientImpl = LoginServiceClientImpl;
exports.LoginRequest = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.username);
        writer.uint32(18).string(message.password);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseLoginRequest };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseLoginRequest };
        if (object.username !== undefined && object.username !== null) {
            message.username = String(object.username);
        }
        else {
            message.username = "";
        }
        if (object.password !== undefined && object.password !== null) {
            message.password = String(object.password);
        }
        else {
            message.password = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseLoginRequest };
        if (object.username !== undefined && object.username !== null) {
            message.username = object.username;
        }
        else {
            message.username = "";
        }
        if (object.password !== undefined && object.password !== null) {
            message.password = object.password;
        }
        else {
            message.password = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.username = message.username || "";
        obj.password = message.password || "";
        return obj;
    },
};
exports.CreatePasswordRequest = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.token);
        writer.uint32(18).string(message.password);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseCreatePasswordRequest };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseCreatePasswordRequest };
        if (object.token !== undefined && object.token !== null) {
            message.token = String(object.token);
        }
        else {
            message.token = "";
        }
        if (object.password !== undefined && object.password !== null) {
            message.password = String(object.password);
        }
        else {
            message.password = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseCreatePasswordRequest };
        if (object.token !== undefined && object.token !== null) {
            message.token = object.token;
        }
        else {
            message.token = "";
        }
        if (object.password !== undefined && object.password !== null) {
            message.password = object.password;
        }
        else {
            message.password = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.token = message.token || "";
        obj.password = message.password || "";
        return obj;
    },
};
exports.ResetPasswordRequest = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.username);
        writer.uint32(16).bool(message.auth);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseResetPasswordRequest };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.auth = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseResetPasswordRequest };
        if (object.username !== undefined && object.username !== null) {
            message.username = String(object.username);
        }
        else {
            message.username = "";
        }
        if (object.auth !== undefined && object.auth !== null) {
            message.auth = Boolean(object.auth);
        }
        else {
            message.auth = false;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseResetPasswordRequest };
        if (object.username !== undefined && object.username !== null) {
            message.username = object.username;
        }
        else {
            message.username = "";
        }
        if (object.auth !== undefined && object.auth !== null) {
            message.auth = object.auth;
        }
        else {
            message.auth = false;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.username = message.username || "";
        obj.auth = message.auth || false;
        return obj;
    },
};
exports.ResetPasswordResponse = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).bool(message.success);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseResetPasswordResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseResetPasswordResponse };
        if (object.success !== undefined && object.success !== null) {
            message.success = Boolean(object.success);
        }
        else {
            message.success = false;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseResetPasswordResponse };
        if (object.success !== undefined && object.success !== null) {
            message.success = object.success;
        }
        else {
            message.success = false;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.success = message.success || false;
        return obj;
    },
};
exports.LoginResponse = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.token);
        writer.uint32(16).int32(message.userId);
        writer.uint32(26).fork();
        for (const v of message.roles) {
            writer.int32(v);
        }
        writer.ldelim();
        for (const v of message.clients) {
            writer.uint32(34).string(v);
        }
        writer.uint32(42).string(message.username);
        writer.uint32(50).string(message.changePasswordToken);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseLoginResponse };
        message.roles = [];
        message.clients = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                case 2:
                    message.userId = reader.int32();
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.roles.push(reader.int32());
                        }
                    }
                    else {
                        message.roles.push(reader.int32());
                    }
                    break;
                case 4:
                    message.clients.push(reader.string());
                    break;
                case 5:
                    message.username = reader.string();
                    break;
                case 6:
                    message.changePasswordToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseLoginResponse };
        message.roles = [];
        message.clients = [];
        if (object.token !== undefined && object.token !== null) {
            message.token = String(object.token);
        }
        else {
            message.token = "";
        }
        if (object.userId !== undefined && object.userId !== null) {
            message.userId = Number(object.userId);
        }
        else {
            message.userId = 0;
        }
        if (object.roles !== undefined && object.roles !== null) {
            for (const e of object.roles) {
                message.roles.push(shared_1.UserRole.fromJSON(e));
            }
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(String(e));
            }
        }
        if (object.username !== undefined && object.username !== null) {
            message.username = String(object.username);
        }
        else {
            message.username = "";
        }
        if (object.changePasswordToken !== undefined && object.changePasswordToken !== null) {
            message.changePasswordToken = String(object.changePasswordToken);
        }
        else {
            message.changePasswordToken = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseLoginResponse };
        message.roles = [];
        message.clients = [];
        if (object.token !== undefined && object.token !== null) {
            message.token = object.token;
        }
        else {
            message.token = "";
        }
        if (object.userId !== undefined && object.userId !== null) {
            message.userId = object.userId;
        }
        else {
            message.userId = 0;
        }
        if (object.roles !== undefined && object.roles !== null) {
            for (const e of object.roles) {
                message.roles.push(e);
            }
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(e);
            }
        }
        if (object.username !== undefined && object.username !== null) {
            message.username = object.username;
        }
        else {
            message.username = "";
        }
        if (object.changePasswordToken !== undefined && object.changePasswordToken !== null) {
            message.changePasswordToken = object.changePasswordToken;
        }
        else {
            message.changePasswordToken = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.token = message.token || "";
        obj.userId = message.userId || 0;
        if (message.roles) {
            obj.roles = message.roles.map(e => shared_1.UserRole.toJSON(e));
        }
        else {
            obj.roles = [];
        }
        if (message.clients) {
            obj.clients = message.clients.map(e => e || "");
        }
        else {
            obj.clients = [];
        }
        obj.username = message.username || "";
        obj.changePasswordToken = message.changePasswordToken || "";
        return obj;
    },
};
