import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch6SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4492 52.4404V54.1631H6.02637L6.02637 58.6133H10.4492L10.4492 60.3428H0.496095L0.496095 58.6133H4.63867L4.63867 54.1631H0.496095L0.496095 52.4404H10.4492Z"
        fill="white"
      />
      <path
        d="M8.13184 43.9453V47.8008L10.4492 48.6074V50.4053L0.496095 46.6455L0.496095 45.0938L10.4492 41.3271V43.1318L8.13184 43.9453ZM6.73731 47.3154V44.4307L2.6084 45.873L6.73731 47.3154Z"
        fill="white"
      />
      <path
        d="M1.89063 33.0713L1.89063 36.1748H10.4492V37.8906H1.89063L1.89063 40.9668H0.496094L0.496094 33.0713H1.89063Z"
        fill="white"
      />
      <path
        d="M7.20898 23.6055C8.27083 23.7057 9.10026 24.0977 9.69727 24.7812C10.2897 25.4648 10.5859 26.374 10.5859 27.5088C10.5859 28.3018 10.3991 29.0013 10.0254 29.6074C9.64714 30.209 9.11165 30.6738 8.41895 31.002C7.72624 31.3301 6.92188 31.501 6.00586 31.5146H5.07617C4.13737 31.5146 3.31022 31.3483 2.59473 31.0156C1.87923 30.6829 1.3278 30.2067 0.94043 29.5869C0.55306 28.9626 0.359375 28.2425 0.359375 27.4268C0.359375 26.3285 0.657878 25.4443 1.25488 24.7744C1.85189 24.1045 2.69499 23.7148 3.78418 23.6055L3.78418 25.3281C3.06868 25.4102 2.55371 25.6198 2.23926 25.957C1.92025 26.2897 1.76074 26.7796 1.76074 27.4268C1.76074 28.1787 2.03646 28.7575 2.58789 29.1631C3.13477 29.5641 3.93913 29.7692 5.00098 29.7783H5.88281C6.95833 29.7783 7.77865 29.5869 8.34375 29.2041C8.90885 28.8167 9.19141 28.2516 9.19141 27.5088C9.19141 26.8298 9.03874 26.3193 8.7334 25.9775C8.42806 25.6357 7.91992 25.4193 7.20898 25.3281V23.6055Z"
        fill="white"
      />
      <path
        d="M10.4492 13.4014V15.124H6.02637V19.5742H10.4492V21.3037H0.496093L0.496093 19.5742H4.63867V15.124H0.496093L0.496093 13.4014H10.4492Z"
        fill="white"
      />
      <path
        d="M0.448241 1.46289H1.82226L1.82226 1.66797C1.83594 2.59766 2.09114 3.3405 2.58789 3.89648C3.08463 4.45247 3.78874 4.78288 4.70019 4.8877C4.13965 4.35449 3.85937 3.67318 3.85937 2.84375C3.85937 1.96419 4.16927 1.27148 4.78906 0.765625C5.40885 0.255208 6.20866 4.28292e-08 7.18848 0C8.20019 -4.42236e-08 9.02051 0.298503 9.64941 0.895508C10.2738 1.48796 10.5859 2.26725 10.5859 3.2334C10.5859 4.22689 10.2191 5.03353 9.48535 5.65332C8.75163 6.26855 7.79004 6.57617 6.60058 6.57617H6.0332C4.28776 6.57617 2.92057 6.15234 1.93164 5.30469C0.942707 4.45247 0.448241 3.22884 0.448241 1.63379L0.448241 1.46289ZM5.19922 3.24707C5.19922 3.61165 5.30176 3.94661 5.50683 4.25195C5.71191 4.55273 5.98535 4.77376 6.32715 4.91504H6.83301C7.57129 4.91504 8.15918 4.76009 8.59668 4.4502C9.02962 4.1403 9.24609 3.73926 9.24609 3.24707C9.24609 2.75488 9.06152 2.36523 8.69238 2.07812C8.32324 1.79102 7.83789 1.64746 7.23633 1.64746C6.63476 1.64746 6.14486 1.79329 5.7666 2.08496C5.38835 2.37663 5.19922 2.764 5.19922 3.24707Z"
        fill="white"
      />
    </SvgIcon>
  );
}
