import React from 'react';
import {NonHatchReclaimingActivity as NHA, Shiploader} from '@deckmans/domain';
import {ActivityListItem} from './ActivityListItem';
import {useHistory} from 'react-router';
import {useDataContext} from 'providers/DataContextProvider';

export interface NonHatchActivityProps {
  nonHatchReclaimActivity: NHA;
  vesselId: string;
  id?: string;
  startDate?: Date;
  endDate?: Date;
  shiploader: Shiploader;
}
export function NonHatchReclaimActivityComp({
  nonHatchReclaimActivity,
  vesselId,
  startDate,
  endDate,
  id,
  shiploader,
}: NonHatchActivityProps) {
  const {reason} = nonHatchReclaimActivity;
  const history = useHistory();
  const {printDecision} = useDataContext();

  const subtitle = printDecision(reason).join('/ ');

  return (
    <ActivityListItem
      color="info"
      title="Reclaiming"
      subtitle={subtitle}
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        history.push(
          `/activity/non-hatch-activity/edit/${shiploader}/${vesselId}/${id}`
        );
      }}
    />
  );
}
