import React from 'react';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import {Color} from '@material-ui/lab/Alert';
import {makeStyles, Theme} from '@material-ui/core';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {TimeBetween} from 'components/Duration';

const useStylesListComp = (color: Color) => {
  return makeStyles(({palette, spacing}: Theme) => {
    const compColor = palette[color];
    return {
      container: {
        border: '2px solid ' + compColor.main,
        borderRadius: spacing(0, 0.5, 0.5, 0),
        marginBottom: spacing(1.5),
      },
      caption: {
        color: compColor.main,
      },
      disabled: {
        color: palette.text.disabled,
      },
    };
  });
};
export interface Activity {
  color: Color; //success/info/warning/error
  title: string;
  subtitle?: string;
  startTime?: Date;
  endTime?: Date;
  editItem?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}
export function ActivityListItem({
  color,
  startTime,
  endTime,
  subtitle,
  title,
  editItem,
}: Activity) {
  const classes = useStylesListComp(color)();
  const st = moment(startTime).format('HH:mm');
  const et = moment(endTime).format('HH:mm');

  return (
    <ListItem className={classes.container} onClick={editItem}>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="subtitle1" className={classes.disabled}>
            {startTime ? st : 'UD:UD'}
          </Typography>
          <Typography variant="subtitle1" className={classes.disabled}>
            {endTime ? et : 'UD:UD'}
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.caption}>
          <Box display="flex" flexDirection="column" px={1}>
            <Typography variant="h6" color="textPrimary">
              {title}
            </Typography>
            <Typography variant="caption" color="inherit">
              {subtitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">
            <TimeBetween
              startTime={startTime?.toString()}
              endTime={endTime?.toString()}
            />
          </Typography>
          <Typography variant="overline" className={classes.disabled}>
            TotalTime
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}
