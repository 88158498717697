import React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {useFormikContext} from 'formik';
import {getHoursAndMinutes, VesselEditorType} from './VesselEditor';
import {useEngineContext} from 'contexts/EngineContext';
import {findVesselEndDate} from '@deckmans/domain/lib/util/sortVessels';
import {makeStyles} from '@material-ui/core/styles';
import {TimeBetween} from 'components/Duration';

const useStyles = makeStyles(() => ({
  textFieldLabel: {
    textTransform: 'capitalize',
  },
}));
interface Props {
  isEditing: boolean;
}

export default function TimeBetweenLastVesselEdit({isEditing}: Props) {
  const classes = useStyles();
  const {engine} = useEngineContext();
  const {values, setFieldValue, touched, errors} = useFormikContext<
    VesselEditorType
  >();

  const lVessel = React.useMemo(() => {
    return engine.state.vessels[values.previousVessel];
  }, [values.previousVessel, engine.state.vessels]);

  const lastVesselEndDate = lVessel
    ? findVesselEndDate(lVessel).toDate()
    : undefined;

  React.useEffect(() => {
    if (isEditing) {
      setFieldValue('timeBetweenVesselHours', values.timeBetweenVesselHours);
      setFieldValue(
        'timeBetweenVesselMinutes',
        values.timeBetweenVesselMinutes
      );
    }
  }, [
    setFieldValue,
    isEditing,
    values.timeBetweenVesselMinutes,
    values.timeBetweenVesselHours,
  ]);

  return (
    <Box display="flex">
      <Box pr={1} width="100%">
        <TextField
          disabled={!lVessel && !lastVesselEndDate}
          name="timeBetweenVesselHours"
          label="Hours"
          onChange={(e) => {
            setFieldValue('timeBetweenVesselHours', e.target.value);
          }}
          value={values.timeBetweenVesselHours}
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          fullWidth
          className={classes.textFieldLabel}
          error={
            touched['timeBetweenVesselHours'] &&
            !!errors['timeBetweenVesselHours']
          }
          helperText={
            touched['timeBetweenVesselHours'] &&
            !!errors['timeBetweenVesselHours']
              ? errors['timeBetweenVesselHours']
              : ''
          }
        />
      </Box>
      <Box pl={1} width="100%">
        <TextField
          disabled={!lVessel && !lastVesselEndDate}
          name="timeBetweenVesselMinutes"
          label="Minutes"
          onChange={(e) => {
            setFieldValue('timeBetweenVesselMinutes', e.target.value);
          }}
          value={values.timeBetweenVesselMinutes}
          type="number"
          InputProps={{
            inputProps: {
              max: 59,
              min: 0,
            },
          }}
          fullWidth
          className={classes.textFieldLabel}
          error={
            touched['timeBetweenVesselMinutes'] &&
            !!errors['timeBetweenVesselMinutes']
          }
          helperText={
            touched['timeBetweenVesselMinutes'] &&
            !!errors['timeBetweenVesselMinutes']
              ? errors['timeBetweenVesselMinutes']
              : ''
          }
        />
      </Box>
    </Box>
  );
}

interface TimeBetweenVesselViewProps {
  startTime?: string | Date;
}
export function TimeBetweenLastVesselView({
  startTime,
}: TimeBetweenVesselViewProps) {
  const {values, setFieldValue} = useFormikContext<VesselEditorType>();

  const hoursAndMinutes = React.useMemo(() => {
    return getHoursAndMinutes(startTime, values.date);
  }, [startTime, values.date]);

  React.useEffect(() => {
    setFieldValue('timeBetweenVesselHours', hoursAndMinutes.hours);
    setFieldValue('timeBetweenVesselMinutes', hoursAndMinutes.minutes);
  }, [setFieldValue, hoursAndMinutes.minutes, hoursAndMinutes.hours]);

  return (
    <TimeBetween startTime={startTime?.toString()} endTime={values.date} />
  );
}
