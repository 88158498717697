import React from 'react';
import {CO} from '../../components/types';
import Switch from '@material-ui/core/Switch';
import {useField, FieldHookConfig} from 'formik';
import {Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import {AddShipmentModal} from './AddShipmentModal';
import {UserRole} from '@deckmans/domain/lib/proto/shared';
import {useAlertContext} from 'contexts/AlertContex';
import {useAuthContext} from 'contexts/AuthContext';
import {Vessel} from '@deckmans/domain';
import {useDataContext} from 'providers/DataContextProvider';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  child: {
    flexBasis: 150,
    display: 'flex',
    alignItems: 'center',
  },
  chips: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

type Value = Array<{
  name: string;
  shipmentNumbers: string[];
}>;

interface Props {
  data: CO<string>[];
  field: string | FieldHookConfig<Value>;
  isEditing: boolean;
  vessel: Vessel;
}

export function VesselEditorClients({field, data, isEditing, vessel}: Props) {
  const classes = useStyles();
  const {
    master: {clients},
  } = useDataContext();
  const [f, form, meta] = useField<Value>(field);
  const [shipmentModalOpen, setShipmentModalOpen] = React.useState<string>('');
  const {alert} = useAlertContext();
  const {hasRole} = useAuthContext();

  const clientList: string[] = [];

  if (vessel) {
    const oreTypesLoaded = Object.values(vessel.activities)
      .filter((activity) => {
        return Boolean(activity.vesselHatchActivityGroup != null);
      })
      .map((activity) => activity.vesselHatchActivityGroup)
      .map((vhag) => {
        return vhag?.oreType ?? '';
      })
      .filter((oreType, index, self) => {
        return self.indexOf(oreType) === index;
      });

    clients.forEach((client) => {
      client.oreTypes.forEach((oreType) => {
        if (oreTypesLoaded.includes(oreType.id)) {
          clientList.push(client.type);
        }
      });
    });
  }
  return (
    <>
      {data.map(({description, id}, idx) => {
        const disableClientSelect =
          isEditing && clientList.includes(id.toString());

        const clientIds = f.value.map(({name}) => name);
        const valueIndex = clientIds.indexOf(id);
        const value = f.value[valueIndex];
        const shipmentNumbers = value?.shipmentNumbers ?? [];
        const isChecked = valueIndex !== -1;
        return (
          <div
            key={`switch_${f.name}_${id}_${idx}`}
            className={classes.container}
          >
            <div className={classes.child}>
              <Switch
                disabled={
                  disableClientSelect || hasRole(UserRole.USER_ROLE_AGENT)
                }
                checked={isChecked}
                onChange={() => {
                  if (isChecked) {
                    const value = f.value.filter((val) => {
                      return val.name !== id;
                    });
                    meta.setValue(value);
                  } else {
                    const value = f.value;
                    value.push({name: id, shipmentNumbers: []});
                    meta.setValue(value);
                  }
                }}
              />
              <Typography>{description}</Typography>
            </div>
            {isEditing ? (
              <>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    if (
                      (hasRole(UserRole.USER_ROLE_ADMIN) ||
                        hasRole(UserRole.USER_ROLE_SUPERVISOR)) &&
                      shipmentNumbers.length < 4
                    ) {
                      setShipmentModalOpen(description);
                    } else if (
                      !(
                        hasRole(UserRole.USER_ROLE_ADMIN) ||
                        hasRole(UserRole.USER_ROLE_SUPERVISOR)
                      )
                    ) {
                      alert(
                        'You do not have the correct access rights to add shipment numbers',
                        'error'
                      );
                    } else {
                      alert(
                        'You can not add more than 4 Shipment numbers to a client',
                        'warning'
                      );
                    }
                  }}
                  disabled={
                    valueIndex === -1 || hasRole(UserRole.USER_ROLE_AGENT)
                  }
                >
                  <Add fontSize="small" /> shipment No
                </Button>
                <AddShipmentModal
                  shipmentNumbers={shipmentNumbers}
                  open={shipmentModalOpen === description}
                  setOpen={setShipmentModalOpen}
                  handleAddShipmentNumber={(shipmentNumber: string) => {
                    const v = f.value;
                    let sn = shipmentNumbers;
                    const snIndex = sn.indexOf(shipmentNumber);
                    if (snIndex === -1) {
                      sn.push(shipmentNumber);
                    } else {
                      sn = sn.filter((item) => {
                        return item !== shipmentNumber;
                      });
                    }
                    v[valueIndex] = {name: id, shipmentNumbers: sn};
                    meta.setValue(v);
                    setShipmentModalOpen('');
                  }}
                />
                <div className={classes.chips}>
                  {value?.shipmentNumbers.map((shipmentNumber, idx) => {
                    return (
                      <Chip
                        key={`chip_${shipmentNumbers}_id_${idx}`}
                        label={shipmentNumber}
                        variant="outlined"
                        disabled={
                          !(
                            hasRole(UserRole.USER_ROLE_ADMIN) ||
                            hasRole(UserRole.USER_ROLE_SUPERVISOR)
                          )
                        }
                        onDelete={() => {
                          const v = f.value;
                          let sn = v[valueIndex].shipmentNumbers;
                          sn = sn.filter((item) => {
                            return item !== shipmentNumber;
                          });

                          v[valueIndex] = {name: id, shipmentNumbers: sn};
                          meta.setValue(v);
                        }}
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      })}
      {form.error && form.touched && (
        <Typography variant="caption" color="error">
          {form.error}
        </Typography>
      )}
    </>
  );
}
