"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsSafariDesktop = void 0;
const detect_browser_1 = require("detect-browser");
const browser = detect_browser_1.detect();
function useIsSafariDesktop() {
    var _a;
    return (_a = ((browser === null || browser === void 0 ? void 0 : browser.name) === 'safari' && browser.os === 'Mac OS')) !== null && _a !== void 0 ? _a : false;
}
exports.useIsSafariDesktop = useIsSafariDesktop;
