"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleLoadingActivity = exports.StopVesselActivity = exports.SurveyActivity = exports.HatchAwaitingShiploaderActivity = exports.HatchBreakdownActivity = exports.HatchReclaimingActivity = exports.HatchLoadingActivity = exports.HatchDirectLoadingActivity = exports.VesselHatchActivity = exports.VesselActivity = exports.NonHatchTnpaActivity = exports.NonHatchAwaitingShiploaderActivity = exports.NonHatchBreakdownActivity = exports.NonHatchReclaimingActivity = exports.NonHatchShiftingActivity = exports.VesselHatchActivityGroup_ActivitiesEntry = exports.VesselHatchActivityGroup_Completed = exports.VesselHatchActivityGroup = exports.Vessel_ActivitiesEntry = exports.Vessel_Client = exports.Vessel = exports.State_VesselsEntry = exports.State = exports.ActivityType = exports.VesselState = void 0;
/* eslint-disable */
const shared_1 = require("../proto/shared");
const master_1 = require("../proto/master");
const timestamp_1 = require("../google/protobuf/timestamp");
const minimal_1 = require("protobufjs/minimal");
const baseState = {
    eventId: "",
};
const baseState_VesselsEntry = {
    key: "",
};
const baseVessel = {
    id: "",
    name: "",
    berth: "",
    comments: "",
    state: 0,
    delay: "",
    previousVesselId: "",
    delayReason2: "",
    secondComments: "",
    timeBetweenVesselHours: 0,
    timeBetweenVesselMinutes: 0,
};
const baseVessel_Client = {
    name: "",
    shipmentNumbers: "",
};
const baseVessel_ActivitiesEntry = {
    key: "",
};
const baseVesselHatchActivityGroup = {
    id: "",
    client: "",
    hatchNumber: 0,
    oreType: "",
};
const baseVesselHatchActivityGroup_Completed = {
    hatchTons: 0,
    hatchRate: 0,
    directLoadingTons: 0,
    stockpileTons: 0,
    directLoadingRate: 0,
    stockpileRate: 0,
};
const baseVesselHatchActivityGroup_ActivitiesEntry = {
    key: "",
};
const baseNonHatchShiftingActivity = {
    comments: "",
    reason: "",
};
const baseNonHatchReclaimingActivity = {
    comments: "",
    client: "",
};
const baseNonHatchBreakdownActivity = {
    equipmentName: "",
    comments: "",
    client: "",
    oreType: "",
};
const baseNonHatchAwaitingShiploaderActivity = {
    comments: "",
};
const baseNonHatchTnpaActivity = {
    comments: "",
    reason: "",
};
const baseVesselActivity = {
    id: "",
    shiploader: 0,
};
const baseVesselHatchActivity = {
    id: "",
};
const baseHatchDirectLoadingActivity = {
    tippler: "",
    train: "",
    route: "",
};
const baseHatchLoadingActivity = {
    stockpile: "",
    route: "",
};
const baseHatchReclaimingActivity = {
    comments: "",
    client: "",
};
const baseHatchBreakdownActivity = {
    equipmentName: "",
    comments: "",
};
const baseHatchAwaitingShiploaderActivity = {
    comments: "",
};
const baseSurveyActivity = {
    surveyType: 0,
    comments: "",
};
const baseStopVesselActivity = {
    comments: "",
    vslKmPerHour: 0,
    ccrKmPerHour: 0,
    portControlKmPerHour: 0,
    swellMeters: 0,
    vesselName: "",
};
const baseSingleLoadingActivity = {
    comments: "",
};
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function toTimestamp(date) {
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
exports.VesselState = {
    VESSEL_STATE_IN_PROGRESS: 0,
    VESSEL_STATE_COMPLETE: 1,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "VESSEL_STATE_IN_PROGRESS":
                return exports.VesselState.VESSEL_STATE_IN_PROGRESS;
            case 1:
            case "VESSEL_STATE_COMPLETE":
                return exports.VesselState.VESSEL_STATE_COMPLETE;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.VesselState.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.VesselState.VESSEL_STATE_IN_PROGRESS:
                return "VESSEL_STATE_IN_PROGRESS";
            case exports.VesselState.VESSEL_STATE_COMPLETE:
                return "VESSEL_STATE_COMPLETE";
            default:
                return "UNKNOWN";
        }
    },
};
exports.ActivityType = {
    NONE: 0,
    HATCH_GROUP: 1,
    HATCH_DIRECT_LOADING: 2,
    HATCH_LOADING: 3,
    HATCH_RECLAIMING: 4,
    HATCH_BREAKDOWN: 5,
    SURVEY: 6,
    NON_HATCH: 7,
    SINGLE_LOADING: 8,
    STOP_VESSEL: 9,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "NONE":
                return exports.ActivityType.NONE;
            case 1:
            case "HATCH_GROUP":
                return exports.ActivityType.HATCH_GROUP;
            case 2:
            case "HATCH_DIRECT_LOADING":
                return exports.ActivityType.HATCH_DIRECT_LOADING;
            case 3:
            case "HATCH_LOADING":
                return exports.ActivityType.HATCH_LOADING;
            case 4:
            case "HATCH_RECLAIMING":
                return exports.ActivityType.HATCH_RECLAIMING;
            case 5:
            case "HATCH_BREAKDOWN":
                return exports.ActivityType.HATCH_BREAKDOWN;
            case 6:
            case "SURVEY":
                return exports.ActivityType.SURVEY;
            case 7:
            case "NON_HATCH":
                return exports.ActivityType.NON_HATCH;
            case 8:
            case "SINGLE_LOADING":
                return exports.ActivityType.SINGLE_LOADING;
            case 9:
            case "STOP_VESSEL":
                return exports.ActivityType.STOP_VESSEL;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.ActivityType.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.ActivityType.NONE:
                return "NONE";
            case exports.ActivityType.HATCH_GROUP:
                return "HATCH_GROUP";
            case exports.ActivityType.HATCH_DIRECT_LOADING:
                return "HATCH_DIRECT_LOADING";
            case exports.ActivityType.HATCH_LOADING:
                return "HATCH_LOADING";
            case exports.ActivityType.HATCH_RECLAIMING:
                return "HATCH_RECLAIMING";
            case exports.ActivityType.HATCH_BREAKDOWN:
                return "HATCH_BREAKDOWN";
            case exports.ActivityType.SURVEY:
                return "SURVEY";
            case exports.ActivityType.NON_HATCH:
                return "NON_HATCH";
            case exports.ActivityType.SINGLE_LOADING:
                return "SINGLE_LOADING";
            case exports.ActivityType.STOP_VESSEL:
                return "STOP_VESSEL";
            default:
                return "UNKNOWN";
        }
    },
};
exports.State = {
    encode(message, writer = minimal_1.Writer.create()) {
        Object.entries(message.vessels).forEach(([key, value]) => {
            exports.State_VesselsEntry.encode({ key: key, value }, writer.uint32(10).fork()).ldelim();
        });
        writer.uint32(18).string(message.eventId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseState };
        message.vessels = {};
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = exports.State_VesselsEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.vessels[entry1.key] = entry1.value;
                    }
                    break;
                case 2:
                    message.eventId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseState };
        message.vessels = {};
        if (object.vessels !== undefined && object.vessels !== null) {
            Object.entries(object.vessels).forEach(([key, value]) => {
                message.vessels[key] = exports.Vessel.fromJSON(value);
            });
        }
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = String(object.eventId);
        }
        else {
            message.eventId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseState };
        message.vessels = {};
        if (object.vessels !== undefined && object.vessels !== null) {
            Object.entries(object.vessels).forEach(([key, value]) => {
                if (value !== undefined) {
                    message.vessels[key] = exports.Vessel.fromPartial(value);
                }
            });
        }
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = object.eventId;
        }
        else {
            message.eventId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.vessels = message.vessels || undefined;
        obj.eventId = message.eventId || "";
        return obj;
    },
};
exports.State_VesselsEntry = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.key);
        if (message.value !== undefined && message.value !== undefined) {
            exports.Vessel.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseState_VesselsEntry };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = exports.Vessel.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseState_VesselsEntry };
        if (object.key !== undefined && object.key !== null) {
            message.key = String(object.key);
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = exports.Vessel.fromJSON(object.value);
        }
        else {
            message.value = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseState_VesselsEntry };
        if (object.key !== undefined && object.key !== null) {
            message.key = object.key;
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = exports.Vessel.fromPartial(object.value);
        }
        else {
            message.value = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.key = message.key || "";
        obj.value = message.value ? exports.Vessel.toJSON(message.value) : undefined;
        return obj;
    },
};
exports.Vessel = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.name);
        writer.uint32(26).string(message.berth);
        writer.uint32(34).string(message.comments);
        writer.uint32(40).int32(message.state);
        if (message.reclaimStart !== undefined && message.reclaimStart !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.reclaimStart), writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.clients) {
            exports.Vessel_Client.encode(v, writer.uint32(58).fork()).ldelim();
        }
        Object.entries(message.activities).forEach(([key, value]) => {
            exports.Vessel_ActivitiesEntry.encode({ key: key, value }, writer.uint32(66).fork()).ldelim();
        });
        writer.uint32(74).string(message.delay);
        if (message.createdBy !== undefined && message.createdBy !== undefined) {
            shared_1.User.encode(message.createdBy, writer.uint32(82).fork()).ldelim();
        }
        writer.uint32(90).string(message.previousVesselId);
        writer.uint32(98).string(message.delayReason2);
        writer.uint32(106).string(message.secondComments);
        writer.uint32(112).int32(message.timeBetweenVesselHours);
        writer.uint32(120).int32(message.timeBetweenVesselMinutes);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVessel };
        message.clients = [];
        message.activities = {};
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.berth = reader.string();
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.state = reader.int32();
                    break;
                case 6:
                    message.reclaimStart = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.clients.push(exports.Vessel_Client.decode(reader, reader.uint32()));
                    break;
                case 8:
                    const entry8 = exports.Vessel_ActivitiesEntry.decode(reader, reader.uint32());
                    if (entry8.value !== undefined) {
                        message.activities[entry8.key] = entry8.value;
                    }
                    break;
                case 9:
                    message.delay = reader.string();
                    break;
                case 10:
                    message.createdBy = shared_1.User.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.previousVesselId = reader.string();
                    break;
                case 12:
                    message.delayReason2 = reader.string();
                    break;
                case 13:
                    message.secondComments = reader.string();
                    break;
                case 14:
                    message.timeBetweenVesselHours = reader.int32();
                    break;
                case 15:
                    message.timeBetweenVesselMinutes = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVessel };
        message.clients = [];
        message.activities = {};
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.berth !== undefined && object.berth !== null) {
            message.berth = String(object.berth);
        }
        else {
            message.berth = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.state !== undefined && object.state !== null) {
            message.state = exports.VesselState.fromJSON(object.state);
        }
        else {
            message.state = 0;
        }
        if (object.reclaimStart !== undefined && object.reclaimStart !== null) {
            message.reclaimStart = fromJsonTimestamp(object.reclaimStart);
        }
        else {
            message.reclaimStart = undefined;
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.Vessel_Client.fromJSON(e));
            }
        }
        if (object.activities !== undefined && object.activities !== null) {
            Object.entries(object.activities).forEach(([key, value]) => {
                message.activities[key] = exports.VesselActivity.fromJSON(value);
            });
        }
        if (object.delay !== undefined && object.delay !== null) {
            message.delay = String(object.delay);
        }
        else {
            message.delay = "";
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromJSON(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.previousVesselId !== undefined && object.previousVesselId !== null) {
            message.previousVesselId = String(object.previousVesselId);
        }
        else {
            message.previousVesselId = "";
        }
        if (object.delayReason2 !== undefined && object.delayReason2 !== null) {
            message.delayReason2 = String(object.delayReason2);
        }
        else {
            message.delayReason2 = "";
        }
        if (object.secondComments !== undefined && object.secondComments !== null) {
            message.secondComments = String(object.secondComments);
        }
        else {
            message.secondComments = "";
        }
        if (object.timeBetweenVesselHours !== undefined && object.timeBetweenVesselHours !== null) {
            message.timeBetweenVesselHours = Number(object.timeBetweenVesselHours);
        }
        else {
            message.timeBetweenVesselHours = 0;
        }
        if (object.timeBetweenVesselMinutes !== undefined && object.timeBetweenVesselMinutes !== null) {
            message.timeBetweenVesselMinutes = Number(object.timeBetweenVesselMinutes);
        }
        else {
            message.timeBetweenVesselMinutes = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVessel };
        message.clients = [];
        message.activities = {};
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.berth !== undefined && object.berth !== null) {
            message.berth = object.berth;
        }
        else {
            message.berth = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.state !== undefined && object.state !== null) {
            message.state = object.state;
        }
        else {
            message.state = 0;
        }
        if (object.reclaimStart !== undefined && object.reclaimStart !== null) {
            message.reclaimStart = object.reclaimStart;
        }
        else {
            message.reclaimStart = undefined;
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.Vessel_Client.fromPartial(e));
            }
        }
        if (object.activities !== undefined && object.activities !== null) {
            Object.entries(object.activities).forEach(([key, value]) => {
                if (value !== undefined) {
                    message.activities[key] = exports.VesselActivity.fromPartial(value);
                }
            });
        }
        if (object.delay !== undefined && object.delay !== null) {
            message.delay = object.delay;
        }
        else {
            message.delay = "";
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromPartial(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.previousVesselId !== undefined && object.previousVesselId !== null) {
            message.previousVesselId = object.previousVesselId;
        }
        else {
            message.previousVesselId = "";
        }
        if (object.delayReason2 !== undefined && object.delayReason2 !== null) {
            message.delayReason2 = object.delayReason2;
        }
        else {
            message.delayReason2 = "";
        }
        if (object.secondComments !== undefined && object.secondComments !== null) {
            message.secondComments = object.secondComments;
        }
        else {
            message.secondComments = "";
        }
        if (object.timeBetweenVesselHours !== undefined && object.timeBetweenVesselHours !== null) {
            message.timeBetweenVesselHours = object.timeBetweenVesselHours;
        }
        else {
            message.timeBetweenVesselHours = 0;
        }
        if (object.timeBetweenVesselMinutes !== undefined && object.timeBetweenVesselMinutes !== null) {
            message.timeBetweenVesselMinutes = object.timeBetweenVesselMinutes;
        }
        else {
            message.timeBetweenVesselMinutes = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.name = message.name || "";
        obj.berth = message.berth || "";
        obj.comments = message.comments || "";
        obj.state = exports.VesselState.toJSON(message.state);
        obj.reclaimStart = message.reclaimStart !== undefined ? message.reclaimStart.toISOString() : null;
        if (message.clients) {
            obj.clients = message.clients.map(e => e ? exports.Vessel_Client.toJSON(e) : undefined);
        }
        else {
            obj.clients = [];
        }
        obj.activities = message.activities || undefined;
        obj.delay = message.delay || "";
        obj.createdBy = message.createdBy ? shared_1.User.toJSON(message.createdBy) : undefined;
        obj.previousVesselId = message.previousVesselId || "";
        obj.delayReason2 = message.delayReason2 || "";
        obj.secondComments = message.secondComments || "";
        obj.timeBetweenVesselHours = message.timeBetweenVesselHours || 0;
        obj.timeBetweenVesselMinutes = message.timeBetweenVesselMinutes || 0;
        return obj;
    },
};
exports.Vessel_Client = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.name);
        for (const v of message.shipmentNumbers) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVessel_Client };
        message.shipmentNumbers = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.shipmentNumbers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVessel_Client };
        message.shipmentNumbers = [];
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.shipmentNumbers !== undefined && object.shipmentNumbers !== null) {
            for (const e of object.shipmentNumbers) {
                message.shipmentNumbers.push(String(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVessel_Client };
        message.shipmentNumbers = [];
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.shipmentNumbers !== undefined && object.shipmentNumbers !== null) {
            for (const e of object.shipmentNumbers) {
                message.shipmentNumbers.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.name = message.name || "";
        if (message.shipmentNumbers) {
            obj.shipmentNumbers = message.shipmentNumbers.map(e => e || "");
        }
        else {
            obj.shipmentNumbers = [];
        }
        return obj;
    },
};
exports.Vessel_ActivitiesEntry = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.key);
        if (message.value !== undefined && message.value !== undefined) {
            exports.VesselActivity.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVessel_ActivitiesEntry };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = exports.VesselActivity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVessel_ActivitiesEntry };
        if (object.key !== undefined && object.key !== null) {
            message.key = String(object.key);
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = exports.VesselActivity.fromJSON(object.value);
        }
        else {
            message.value = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVessel_ActivitiesEntry };
        if (object.key !== undefined && object.key !== null) {
            message.key = object.key;
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = exports.VesselActivity.fromPartial(object.value);
        }
        else {
            message.value = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.key = message.key || "";
        obj.value = message.value ? exports.VesselActivity.toJSON(message.value) : undefined;
        return obj;
    },
};
exports.VesselHatchActivityGroup = {
    encode(message, writer = minimal_1.Writer.create()) {
        Object.entries(message.activities).forEach(([key, value]) => {
            exports.VesselHatchActivityGroup_ActivitiesEntry.encode({ key: key, value }, writer.uint32(10).fork()).ldelim();
        });
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.client);
        writer.uint32(32).int32(message.hatchNumber);
        writer.uint32(42).string(message.oreType);
        if (message.completed !== undefined && message.completed !== undefined) {
            exports.VesselHatchActivityGroup_Completed.encode(message.completed, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselHatchActivityGroup };
        message.activities = {};
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = exports.VesselHatchActivityGroup_ActivitiesEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.activities[entry1.key] = entry1.value;
                    }
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.client = reader.string();
                    break;
                case 4:
                    message.hatchNumber = reader.int32();
                    break;
                case 5:
                    message.oreType = reader.string();
                    break;
                case 6:
                    message.completed = exports.VesselHatchActivityGroup_Completed.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselHatchActivityGroup };
        message.activities = {};
        if (object.activities !== undefined && object.activities !== null) {
            Object.entries(object.activities).forEach(([key, value]) => {
                message.activities[key] = exports.VesselHatchActivity.fromJSON(value);
            });
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.hatchNumber !== undefined && object.hatchNumber !== null) {
            message.hatchNumber = Number(object.hatchNumber);
        }
        else {
            message.hatchNumber = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        if (object.completed !== undefined && object.completed !== null) {
            message.completed = exports.VesselHatchActivityGroup_Completed.fromJSON(object.completed);
        }
        else {
            message.completed = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselHatchActivityGroup };
        message.activities = {};
        if (object.activities !== undefined && object.activities !== null) {
            Object.entries(object.activities).forEach(([key, value]) => {
                if (value !== undefined) {
                    message.activities[key] = exports.VesselHatchActivity.fromPartial(value);
                }
            });
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.hatchNumber !== undefined && object.hatchNumber !== null) {
            message.hatchNumber = object.hatchNumber;
        }
        else {
            message.hatchNumber = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        if (object.completed !== undefined && object.completed !== null) {
            message.completed = exports.VesselHatchActivityGroup_Completed.fromPartial(object.completed);
        }
        else {
            message.completed = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.activities = message.activities || undefined;
        obj.id = message.id || "";
        obj.client = message.client || "";
        obj.hatchNumber = message.hatchNumber || 0;
        obj.oreType = message.oreType || "";
        obj.completed = message.completed ? exports.VesselHatchActivityGroup_Completed.toJSON(message.completed) : undefined;
        return obj;
    },
};
exports.VesselHatchActivityGroup_Completed = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).int32(message.hatchTons);
        writer.uint32(16).int32(message.hatchRate);
        writer.uint32(24).int32(message.directLoadingTons);
        writer.uint32(32).int32(message.stockpileTons);
        writer.uint32(45).float(message.directLoadingRate);
        writer.uint32(53).float(message.stockpileRate);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselHatchActivityGroup_Completed };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hatchTons = reader.int32();
                    break;
                case 2:
                    message.hatchRate = reader.int32();
                    break;
                case 3:
                    message.directLoadingTons = reader.int32();
                    break;
                case 4:
                    message.stockpileTons = reader.int32();
                    break;
                case 5:
                    message.directLoadingRate = reader.float();
                    break;
                case 6:
                    message.stockpileRate = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselHatchActivityGroup_Completed };
        if (object.hatchTons !== undefined && object.hatchTons !== null) {
            message.hatchTons = Number(object.hatchTons);
        }
        else {
            message.hatchTons = 0;
        }
        if (object.hatchRate !== undefined && object.hatchRate !== null) {
            message.hatchRate = Number(object.hatchRate);
        }
        else {
            message.hatchRate = 0;
        }
        if (object.directLoadingTons !== undefined && object.directLoadingTons !== null) {
            message.directLoadingTons = Number(object.directLoadingTons);
        }
        else {
            message.directLoadingTons = 0;
        }
        if (object.stockpileTons !== undefined && object.stockpileTons !== null) {
            message.stockpileTons = Number(object.stockpileTons);
        }
        else {
            message.stockpileTons = 0;
        }
        if (object.directLoadingRate !== undefined && object.directLoadingRate !== null) {
            message.directLoadingRate = Number(object.directLoadingRate);
        }
        else {
            message.directLoadingRate = 0;
        }
        if (object.stockpileRate !== undefined && object.stockpileRate !== null) {
            message.stockpileRate = Number(object.stockpileRate);
        }
        else {
            message.stockpileRate = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselHatchActivityGroup_Completed };
        if (object.hatchTons !== undefined && object.hatchTons !== null) {
            message.hatchTons = object.hatchTons;
        }
        else {
            message.hatchTons = 0;
        }
        if (object.hatchRate !== undefined && object.hatchRate !== null) {
            message.hatchRate = object.hatchRate;
        }
        else {
            message.hatchRate = 0;
        }
        if (object.directLoadingTons !== undefined && object.directLoadingTons !== null) {
            message.directLoadingTons = object.directLoadingTons;
        }
        else {
            message.directLoadingTons = 0;
        }
        if (object.stockpileTons !== undefined && object.stockpileTons !== null) {
            message.stockpileTons = object.stockpileTons;
        }
        else {
            message.stockpileTons = 0;
        }
        if (object.directLoadingRate !== undefined && object.directLoadingRate !== null) {
            message.directLoadingRate = object.directLoadingRate;
        }
        else {
            message.directLoadingRate = 0;
        }
        if (object.stockpileRate !== undefined && object.stockpileRate !== null) {
            message.stockpileRate = object.stockpileRate;
        }
        else {
            message.stockpileRate = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.hatchTons = message.hatchTons || 0;
        obj.hatchRate = message.hatchRate || 0;
        obj.directLoadingTons = message.directLoadingTons || 0;
        obj.stockpileTons = message.stockpileTons || 0;
        obj.directLoadingRate = message.directLoadingRate || 0;
        obj.stockpileRate = message.stockpileRate || 0;
        return obj;
    },
};
exports.VesselHatchActivityGroup_ActivitiesEntry = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.key);
        if (message.value !== undefined && message.value !== undefined) {
            exports.VesselHatchActivity.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselHatchActivityGroup_ActivitiesEntry };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = exports.VesselHatchActivity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselHatchActivityGroup_ActivitiesEntry };
        if (object.key !== undefined && object.key !== null) {
            message.key = String(object.key);
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = exports.VesselHatchActivity.fromJSON(object.value);
        }
        else {
            message.value = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselHatchActivityGroup_ActivitiesEntry };
        if (object.key !== undefined && object.key !== null) {
            message.key = object.key;
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = exports.VesselHatchActivity.fromPartial(object.value);
        }
        else {
            message.value = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.key = message.key || "";
        obj.value = message.value ? exports.VesselHatchActivity.toJSON(message.value) : undefined;
        return obj;
    },
};
exports.NonHatchShiftingActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.comments);
        writer.uint32(18).string(message.reason);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchShiftingActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.comments = reader.string();
                    break;
                case 2:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchShiftingActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchShiftingActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.comments = message.comments || "";
        obj.reason = message.reason || "";
        return obj;
    },
};
exports.NonHatchReclaimingActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(10).fork()).ldelim();
        }
        writer.uint32(18).string(message.comments);
        writer.uint32(26).string(message.client);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchReclaimingActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.comments = reader.string();
                    break;
                case 3:
                    message.client = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchReclaimingActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchReclaimingActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        return obj;
    },
};
exports.NonHatchBreakdownActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(10).fork()).ldelim();
        }
        writer.uint32(18).string(message.equipmentName);
        writer.uint32(26).string(message.comments);
        writer.uint32(34).string(message.client);
        writer.uint32(42).string(message.oreType);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchBreakdownActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.equipmentName = reader.string();
                    break;
                case 3:
                    message.comments = reader.string();
                    break;
                case 4:
                    message.client = reader.string();
                    break;
                case 5:
                    message.oreType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchBreakdownActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = String(object.equipmentName);
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchBreakdownActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = object.equipmentName;
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.equipmentName = message.equipmentName || "";
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        obj.oreType = message.oreType || "";
        return obj;
    },
};
exports.NonHatchAwaitingShiploaderActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchAwaitingShiploaderActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.comments = reader.string();
                    break;
                case 2:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchAwaitingShiploaderActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchAwaitingShiploaderActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        return obj;
    },
};
exports.NonHatchTnpaActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.comments);
        writer.uint32(18).string(message.reason);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchTnpaActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.comments = reader.string();
                    break;
                case 2:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchTnpaActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchTnpaActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.comments = message.comments || "";
        obj.reason = message.reason || "";
        return obj;
    },
};
exports.VesselActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(32).int32(message.shiploader);
        if (message.createdBy !== undefined && message.createdBy !== undefined) {
            shared_1.User.encode(message.createdBy, writer.uint32(42).fork()).ldelim();
        }
        if (message.vesselHatchActivityGroup !== undefined && message.vesselHatchActivityGroup !== undefined) {
            exports.VesselHatchActivityGroup.encode(message.vesselHatchActivityGroup, writer.uint32(90).fork()).ldelim();
        }
        if (message.surveyActivity !== undefined && message.surveyActivity !== undefined) {
            exports.SurveyActivity.encode(message.surveyActivity, writer.uint32(98).fork()).ldelim();
        }
        if (message.stopVesselActivity !== undefined && message.stopVesselActivity !== undefined) {
            exports.StopVesselActivity.encode(message.stopVesselActivity, writer.uint32(106).fork()).ldelim();
        }
        if (message.singleLoadingActivity !== undefined && message.singleLoadingActivity !== undefined) {
            exports.SingleLoadingActivity.encode(message.singleLoadingActivity, writer.uint32(114).fork()).ldelim();
        }
        if (message.nonHatchShiftingActivity !== undefined && message.nonHatchShiftingActivity !== undefined) {
            exports.NonHatchShiftingActivity.encode(message.nonHatchShiftingActivity, writer.uint32(122).fork()).ldelim();
        }
        if (message.nonHatchReclaimingActivity !== undefined && message.nonHatchReclaimingActivity !== undefined) {
            exports.NonHatchReclaimingActivity.encode(message.nonHatchReclaimingActivity, writer.uint32(130).fork()).ldelim();
        }
        if (message.nonHatchBreakdownActivity !== undefined && message.nonHatchBreakdownActivity !== undefined) {
            exports.NonHatchBreakdownActivity.encode(message.nonHatchBreakdownActivity, writer.uint32(138).fork()).ldelim();
        }
        if (message.nonHatchAwaitingShiploaderActivity !== undefined && message.nonHatchAwaitingShiploaderActivity !== undefined) {
            exports.NonHatchAwaitingShiploaderActivity.encode(message.nonHatchAwaitingShiploaderActivity, writer.uint32(146).fork()).ldelim();
        }
        if (message.nonHatchTnpaActivity !== undefined && message.nonHatchTnpaActivity !== undefined) {
            exports.NonHatchTnpaActivity.encode(message.nonHatchTnpaActivity, writer.uint32(154).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.shiploader = reader.int32();
                    break;
                case 5:
                    message.createdBy = shared_1.User.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.vesselHatchActivityGroup = exports.VesselHatchActivityGroup.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.surveyActivity = exports.SurveyActivity.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.stopVesselActivity = exports.StopVesselActivity.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.singleLoadingActivity = exports.SingleLoadingActivity.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.nonHatchShiftingActivity = exports.NonHatchShiftingActivity.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.nonHatchReclaimingActivity = exports.NonHatchReclaimingActivity.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.nonHatchBreakdownActivity = exports.NonHatchBreakdownActivity.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.nonHatchAwaitingShiploaderActivity = exports.NonHatchAwaitingShiploaderActivity.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.nonHatchTnpaActivity = exports.NonHatchTnpaActivity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselActivity };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromJSON(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.vesselHatchActivityGroup !== undefined && object.vesselHatchActivityGroup !== null) {
            message.vesselHatchActivityGroup = exports.VesselHatchActivityGroup.fromJSON(object.vesselHatchActivityGroup);
        }
        else {
            message.vesselHatchActivityGroup = undefined;
        }
        if (object.surveyActivity !== undefined && object.surveyActivity !== null) {
            message.surveyActivity = exports.SurveyActivity.fromJSON(object.surveyActivity);
        }
        else {
            message.surveyActivity = undefined;
        }
        if (object.stopVesselActivity !== undefined && object.stopVesselActivity !== null) {
            message.stopVesselActivity = exports.StopVesselActivity.fromJSON(object.stopVesselActivity);
        }
        else {
            message.stopVesselActivity = undefined;
        }
        if (object.singleLoadingActivity !== undefined && object.singleLoadingActivity !== null) {
            message.singleLoadingActivity = exports.SingleLoadingActivity.fromJSON(object.singleLoadingActivity);
        }
        else {
            message.singleLoadingActivity = undefined;
        }
        if (object.nonHatchShiftingActivity !== undefined && object.nonHatchShiftingActivity !== null) {
            message.nonHatchShiftingActivity = exports.NonHatchShiftingActivity.fromJSON(object.nonHatchShiftingActivity);
        }
        else {
            message.nonHatchShiftingActivity = undefined;
        }
        if (object.nonHatchReclaimingActivity !== undefined && object.nonHatchReclaimingActivity !== null) {
            message.nonHatchReclaimingActivity = exports.NonHatchReclaimingActivity.fromJSON(object.nonHatchReclaimingActivity);
        }
        else {
            message.nonHatchReclaimingActivity = undefined;
        }
        if (object.nonHatchBreakdownActivity !== undefined && object.nonHatchBreakdownActivity !== null) {
            message.nonHatchBreakdownActivity = exports.NonHatchBreakdownActivity.fromJSON(object.nonHatchBreakdownActivity);
        }
        else {
            message.nonHatchBreakdownActivity = undefined;
        }
        if (object.nonHatchAwaitingShiploaderActivity !== undefined && object.nonHatchAwaitingShiploaderActivity !== null) {
            message.nonHatchAwaitingShiploaderActivity = exports.NonHatchAwaitingShiploaderActivity.fromJSON(object.nonHatchAwaitingShiploaderActivity);
        }
        else {
            message.nonHatchAwaitingShiploaderActivity = undefined;
        }
        if (object.nonHatchTnpaActivity !== undefined && object.nonHatchTnpaActivity !== null) {
            message.nonHatchTnpaActivity = exports.NonHatchTnpaActivity.fromJSON(object.nonHatchTnpaActivity);
        }
        else {
            message.nonHatchTnpaActivity = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselActivity };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromPartial(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.vesselHatchActivityGroup !== undefined && object.vesselHatchActivityGroup !== null) {
            message.vesselHatchActivityGroup = exports.VesselHatchActivityGroup.fromPartial(object.vesselHatchActivityGroup);
        }
        else {
            message.vesselHatchActivityGroup = undefined;
        }
        if (object.surveyActivity !== undefined && object.surveyActivity !== null) {
            message.surveyActivity = exports.SurveyActivity.fromPartial(object.surveyActivity);
        }
        else {
            message.surveyActivity = undefined;
        }
        if (object.stopVesselActivity !== undefined && object.stopVesselActivity !== null) {
            message.stopVesselActivity = exports.StopVesselActivity.fromPartial(object.stopVesselActivity);
        }
        else {
            message.stopVesselActivity = undefined;
        }
        if (object.singleLoadingActivity !== undefined && object.singleLoadingActivity !== null) {
            message.singleLoadingActivity = exports.SingleLoadingActivity.fromPartial(object.singleLoadingActivity);
        }
        else {
            message.singleLoadingActivity = undefined;
        }
        if (object.nonHatchShiftingActivity !== undefined && object.nonHatchShiftingActivity !== null) {
            message.nonHatchShiftingActivity = exports.NonHatchShiftingActivity.fromPartial(object.nonHatchShiftingActivity);
        }
        else {
            message.nonHatchShiftingActivity = undefined;
        }
        if (object.nonHatchReclaimingActivity !== undefined && object.nonHatchReclaimingActivity !== null) {
            message.nonHatchReclaimingActivity = exports.NonHatchReclaimingActivity.fromPartial(object.nonHatchReclaimingActivity);
        }
        else {
            message.nonHatchReclaimingActivity = undefined;
        }
        if (object.nonHatchBreakdownActivity !== undefined && object.nonHatchBreakdownActivity !== null) {
            message.nonHatchBreakdownActivity = exports.NonHatchBreakdownActivity.fromPartial(object.nonHatchBreakdownActivity);
        }
        else {
            message.nonHatchBreakdownActivity = undefined;
        }
        if (object.nonHatchAwaitingShiploaderActivity !== undefined && object.nonHatchAwaitingShiploaderActivity !== null) {
            message.nonHatchAwaitingShiploaderActivity = exports.NonHatchAwaitingShiploaderActivity.fromPartial(object.nonHatchAwaitingShiploaderActivity);
        }
        else {
            message.nonHatchAwaitingShiploaderActivity = undefined;
        }
        if (object.nonHatchTnpaActivity !== undefined && object.nonHatchTnpaActivity !== null) {
            message.nonHatchTnpaActivity = exports.NonHatchTnpaActivity.fromPartial(object.nonHatchTnpaActivity);
        }
        else {
            message.nonHatchTnpaActivity = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.createdBy = message.createdBy ? shared_1.User.toJSON(message.createdBy) : undefined;
        obj.vesselHatchActivityGroup = message.vesselHatchActivityGroup ? exports.VesselHatchActivityGroup.toJSON(message.vesselHatchActivityGroup) : undefined;
        obj.surveyActivity = message.surveyActivity ? exports.SurveyActivity.toJSON(message.surveyActivity) : undefined;
        obj.stopVesselActivity = message.stopVesselActivity ? exports.StopVesselActivity.toJSON(message.stopVesselActivity) : undefined;
        obj.singleLoadingActivity = message.singleLoadingActivity ? exports.SingleLoadingActivity.toJSON(message.singleLoadingActivity) : undefined;
        obj.nonHatchShiftingActivity = message.nonHatchShiftingActivity ? exports.NonHatchShiftingActivity.toJSON(message.nonHatchShiftingActivity) : undefined;
        obj.nonHatchReclaimingActivity = message.nonHatchReclaimingActivity ? exports.NonHatchReclaimingActivity.toJSON(message.nonHatchReclaimingActivity) : undefined;
        obj.nonHatchBreakdownActivity = message.nonHatchBreakdownActivity ? exports.NonHatchBreakdownActivity.toJSON(message.nonHatchBreakdownActivity) : undefined;
        obj.nonHatchAwaitingShiploaderActivity = message.nonHatchAwaitingShiploaderActivity ? exports.NonHatchAwaitingShiploaderActivity.toJSON(message.nonHatchAwaitingShiploaderActivity) : undefined;
        obj.nonHatchTnpaActivity = message.nonHatchTnpaActivity ? exports.NonHatchTnpaActivity.toJSON(message.nonHatchTnpaActivity) : undefined;
        return obj;
    },
};
exports.VesselHatchActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.createdBy !== undefined && message.createdBy !== undefined) {
            shared_1.User.encode(message.createdBy, writer.uint32(34).fork()).ldelim();
        }
        if (message.hatchDirectLoadingActivity !== undefined && message.hatchDirectLoadingActivity !== undefined) {
            exports.HatchDirectLoadingActivity.encode(message.hatchDirectLoadingActivity, writer.uint32(82).fork()).ldelim();
        }
        if (message.hatchLoadingActivity !== undefined && message.hatchLoadingActivity !== undefined) {
            exports.HatchLoadingActivity.encode(message.hatchLoadingActivity, writer.uint32(90).fork()).ldelim();
        }
        if (message.hatchReclaimingActivity !== undefined && message.hatchReclaimingActivity !== undefined) {
            exports.HatchReclaimingActivity.encode(message.hatchReclaimingActivity, writer.uint32(98).fork()).ldelim();
        }
        if (message.hatchBreakdownActivity !== undefined && message.hatchBreakdownActivity !== undefined) {
            exports.HatchBreakdownActivity.encode(message.hatchBreakdownActivity, writer.uint32(106).fork()).ldelim();
        }
        if (message.hatchAwaitingShiploaderActivity !== undefined && message.hatchAwaitingShiploaderActivity !== undefined) {
            exports.HatchAwaitingShiploaderActivity.encode(message.hatchAwaitingShiploaderActivity, writer.uint32(114).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselHatchActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.createdBy = shared_1.User.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.hatchDirectLoadingActivity = exports.HatchDirectLoadingActivity.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.hatchLoadingActivity = exports.HatchLoadingActivity.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.hatchReclaimingActivity = exports.HatchReclaimingActivity.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.hatchBreakdownActivity = exports.HatchBreakdownActivity.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.hatchAwaitingShiploaderActivity = exports.HatchAwaitingShiploaderActivity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselHatchActivity };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromJSON(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.hatchDirectLoadingActivity !== undefined && object.hatchDirectLoadingActivity !== null) {
            message.hatchDirectLoadingActivity = exports.HatchDirectLoadingActivity.fromJSON(object.hatchDirectLoadingActivity);
        }
        else {
            message.hatchDirectLoadingActivity = undefined;
        }
        if (object.hatchLoadingActivity !== undefined && object.hatchLoadingActivity !== null) {
            message.hatchLoadingActivity = exports.HatchLoadingActivity.fromJSON(object.hatchLoadingActivity);
        }
        else {
            message.hatchLoadingActivity = undefined;
        }
        if (object.hatchReclaimingActivity !== undefined && object.hatchReclaimingActivity !== null) {
            message.hatchReclaimingActivity = exports.HatchReclaimingActivity.fromJSON(object.hatchReclaimingActivity);
        }
        else {
            message.hatchReclaimingActivity = undefined;
        }
        if (object.hatchBreakdownActivity !== undefined && object.hatchBreakdownActivity !== null) {
            message.hatchBreakdownActivity = exports.HatchBreakdownActivity.fromJSON(object.hatchBreakdownActivity);
        }
        else {
            message.hatchBreakdownActivity = undefined;
        }
        if (object.hatchAwaitingShiploaderActivity !== undefined && object.hatchAwaitingShiploaderActivity !== null) {
            message.hatchAwaitingShiploaderActivity = exports.HatchAwaitingShiploaderActivity.fromJSON(object.hatchAwaitingShiploaderActivity);
        }
        else {
            message.hatchAwaitingShiploaderActivity = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselHatchActivity };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromPartial(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.hatchDirectLoadingActivity !== undefined && object.hatchDirectLoadingActivity !== null) {
            message.hatchDirectLoadingActivity = exports.HatchDirectLoadingActivity.fromPartial(object.hatchDirectLoadingActivity);
        }
        else {
            message.hatchDirectLoadingActivity = undefined;
        }
        if (object.hatchLoadingActivity !== undefined && object.hatchLoadingActivity !== null) {
            message.hatchLoadingActivity = exports.HatchLoadingActivity.fromPartial(object.hatchLoadingActivity);
        }
        else {
            message.hatchLoadingActivity = undefined;
        }
        if (object.hatchReclaimingActivity !== undefined && object.hatchReclaimingActivity !== null) {
            message.hatchReclaimingActivity = exports.HatchReclaimingActivity.fromPartial(object.hatchReclaimingActivity);
        }
        else {
            message.hatchReclaimingActivity = undefined;
        }
        if (object.hatchBreakdownActivity !== undefined && object.hatchBreakdownActivity !== null) {
            message.hatchBreakdownActivity = exports.HatchBreakdownActivity.fromPartial(object.hatchBreakdownActivity);
        }
        else {
            message.hatchBreakdownActivity = undefined;
        }
        if (object.hatchAwaitingShiploaderActivity !== undefined && object.hatchAwaitingShiploaderActivity !== null) {
            message.hatchAwaitingShiploaderActivity = exports.HatchAwaitingShiploaderActivity.fromPartial(object.hatchAwaitingShiploaderActivity);
        }
        else {
            message.hatchAwaitingShiploaderActivity = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.createdBy = message.createdBy ? shared_1.User.toJSON(message.createdBy) : undefined;
        obj.hatchDirectLoadingActivity = message.hatchDirectLoadingActivity ? exports.HatchDirectLoadingActivity.toJSON(message.hatchDirectLoadingActivity) : undefined;
        obj.hatchLoadingActivity = message.hatchLoadingActivity ? exports.HatchLoadingActivity.toJSON(message.hatchLoadingActivity) : undefined;
        obj.hatchReclaimingActivity = message.hatchReclaimingActivity ? exports.HatchReclaimingActivity.toJSON(message.hatchReclaimingActivity) : undefined;
        obj.hatchBreakdownActivity = message.hatchBreakdownActivity ? exports.HatchBreakdownActivity.toJSON(message.hatchBreakdownActivity) : undefined;
        obj.hatchAwaitingShiploaderActivity = message.hatchAwaitingShiploaderActivity ? exports.HatchAwaitingShiploaderActivity.toJSON(message.hatchAwaitingShiploaderActivity) : undefined;
        return obj;
    },
};
exports.HatchDirectLoadingActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.tippler);
        writer.uint32(18).string(message.train);
        writer.uint32(26).string(message.route);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchDirectLoadingActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tippler = reader.string();
                    break;
                case 2:
                    message.train = reader.string();
                    break;
                case 3:
                    message.route = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchDirectLoadingActivity };
        if (object.tippler !== undefined && object.tippler !== null) {
            message.tippler = String(object.tippler);
        }
        else {
            message.tippler = "";
        }
        if (object.train !== undefined && object.train !== null) {
            message.train = String(object.train);
        }
        else {
            message.train = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = String(object.route);
        }
        else {
            message.route = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchDirectLoadingActivity };
        if (object.tippler !== undefined && object.tippler !== null) {
            message.tippler = object.tippler;
        }
        else {
            message.tippler = "";
        }
        if (object.train !== undefined && object.train !== null) {
            message.train = object.train;
        }
        else {
            message.train = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = object.route;
        }
        else {
            message.route = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.tippler = message.tippler || "";
        obj.train = message.train || "";
        obj.route = message.route || "";
        return obj;
    },
};
exports.HatchLoadingActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.stockpile);
        writer.uint32(18).string(message.route);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchLoadingActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.stockpile = reader.string();
                    break;
                case 2:
                    message.route = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchLoadingActivity };
        if (object.stockpile !== undefined && object.stockpile !== null) {
            message.stockpile = String(object.stockpile);
        }
        else {
            message.stockpile = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = String(object.route);
        }
        else {
            message.route = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchLoadingActivity };
        if (object.stockpile !== undefined && object.stockpile !== null) {
            message.stockpile = object.stockpile;
        }
        else {
            message.stockpile = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = object.route;
        }
        else {
            message.route = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.stockpile = message.stockpile || "";
        obj.route = message.route || "";
        return obj;
    },
};
exports.HatchReclaimingActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(10).fork()).ldelim();
        }
        writer.uint32(18).string(message.comments);
        writer.uint32(26).string(message.client);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchReclaimingActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.comments = reader.string();
                    break;
                case 3:
                    message.client = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchReclaimingActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchReclaimingActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        return obj;
    },
};
exports.HatchBreakdownActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(10).fork()).ldelim();
        }
        writer.uint32(18).string(message.equipmentName);
        writer.uint32(26).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchBreakdownActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.equipmentName = reader.string();
                    break;
                case 3:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchBreakdownActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = String(object.equipmentName);
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchBreakdownActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = object.equipmentName;
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.equipmentName = message.equipmentName || "";
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.HatchAwaitingShiploaderActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchAwaitingShiploaderActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.comments = reader.string();
                    break;
                case 2:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchAwaitingShiploaderActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchAwaitingShiploaderActivity };
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        return obj;
    },
};
exports.SurveyActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).int32(message.surveyType);
        for (const v of message.oreGroup) {
            shared_1.OreGroup.encode(v, writer.uint32(18).fork()).ldelim();
        }
        writer.uint32(26).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseSurveyActivity };
        message.oreGroup = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.surveyType = reader.int32();
                    break;
                case 2:
                    message.oreGroup.push(shared_1.OreGroup.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseSurveyActivity };
        message.oreGroup = [];
        if (object.surveyType !== undefined && object.surveyType !== null) {
            message.surveyType = shared_1.SurveyType.fromJSON(object.surveyType);
        }
        else {
            message.surveyType = 0;
        }
        if (object.oreGroup !== undefined && object.oreGroup !== null) {
            for (const e of object.oreGroup) {
                message.oreGroup.push(shared_1.OreGroup.fromJSON(e));
            }
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseSurveyActivity };
        message.oreGroup = [];
        if (object.surveyType !== undefined && object.surveyType !== null) {
            message.surveyType = object.surveyType;
        }
        else {
            message.surveyType = 0;
        }
        if (object.oreGroup !== undefined && object.oreGroup !== null) {
            for (const e of object.oreGroup) {
                message.oreGroup.push(shared_1.OreGroup.fromPartial(e));
            }
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.surveyType = shared_1.SurveyType.toJSON(message.surveyType);
        if (message.oreGroup) {
            obj.oreGroup = message.oreGroup.map(e => e ? shared_1.OreGroup.toJSON(e) : undefined);
        }
        else {
            obj.oreGroup = [];
        }
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.StopVesselActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(10).fork()).ldelim();
        }
        writer.uint32(18).string(message.comments);
        writer.uint32(24).int32(message.vslKmPerHour);
        writer.uint32(32).int32(message.ccrKmPerHour);
        writer.uint32(40).int32(message.portControlKmPerHour);
        writer.uint32(48).int32(message.swellMeters);
        writer.uint32(58).string(message.vesselName);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopVesselActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.comments = reader.string();
                    break;
                case 3:
                    message.vslKmPerHour = reader.int32();
                    break;
                case 4:
                    message.ccrKmPerHour = reader.int32();
                    break;
                case 5:
                    message.portControlKmPerHour = reader.int32();
                    break;
                case 6:
                    message.swellMeters = reader.int32();
                    break;
                case 7:
                    message.vesselName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopVesselActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vslKmPerHour !== undefined && object.vslKmPerHour !== null) {
            message.vslKmPerHour = Number(object.vslKmPerHour);
        }
        else {
            message.vslKmPerHour = 0;
        }
        if (object.ccrKmPerHour !== undefined && object.ccrKmPerHour !== null) {
            message.ccrKmPerHour = Number(object.ccrKmPerHour);
        }
        else {
            message.ccrKmPerHour = 0;
        }
        if (object.portControlKmPerHour !== undefined && object.portControlKmPerHour !== null) {
            message.portControlKmPerHour = Number(object.portControlKmPerHour);
        }
        else {
            message.portControlKmPerHour = 0;
        }
        if (object.swellMeters !== undefined && object.swellMeters !== null) {
            message.swellMeters = Number(object.swellMeters);
        }
        else {
            message.swellMeters = 0;
        }
        if (object.vesselName !== undefined && object.vesselName !== null) {
            message.vesselName = String(object.vesselName);
        }
        else {
            message.vesselName = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopVesselActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vslKmPerHour !== undefined && object.vslKmPerHour !== null) {
            message.vslKmPerHour = object.vslKmPerHour;
        }
        else {
            message.vslKmPerHour = 0;
        }
        if (object.ccrKmPerHour !== undefined && object.ccrKmPerHour !== null) {
            message.ccrKmPerHour = object.ccrKmPerHour;
        }
        else {
            message.ccrKmPerHour = 0;
        }
        if (object.portControlKmPerHour !== undefined && object.portControlKmPerHour !== null) {
            message.portControlKmPerHour = object.portControlKmPerHour;
        }
        else {
            message.portControlKmPerHour = 0;
        }
        if (object.swellMeters !== undefined && object.swellMeters !== null) {
            message.swellMeters = object.swellMeters;
        }
        else {
            message.swellMeters = 0;
        }
        if (object.vesselName !== undefined && object.vesselName !== null) {
            message.vesselName = object.vesselName;
        }
        else {
            message.vesselName = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.vslKmPerHour = message.vslKmPerHour || 0;
        obj.ccrKmPerHour = message.ccrKmPerHour || 0;
        obj.portControlKmPerHour = message.portControlKmPerHour || 0;
        obj.swellMeters = message.swellMeters || 0;
        obj.vesselName = message.vesselName || "";
        return obj;
    },
};
exports.SingleLoadingActivity = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(10).fork()).ldelim();
        }
        writer.uint32(18).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseSingleLoadingActivity };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseSingleLoadingActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseSingleLoadingActivity };
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        return obj;
    },
};
