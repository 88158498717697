import React from 'react';
import {VesselActivity, Vessel} from '@deckmans/domain';
import {HatchActivityComp} from './HatchActivityComp';
import {NonHatchShiftingActivityComp} from './NonHatchShiftingActivity';
import {NonHatchReclaimActivityComp} from './NonHatchReclaimActivity';
import {SingleLoadingActivityComp} from './SingleLoadActivityComp';
import {StopVesselActivityComp} from './StopVesselActivityComp';
import {Cell} from 'components/Table';
import {SurveyActivityComp} from './SurveyActivityComp';
import {UnallocatedTimeComp} from './UnallocatedTimeComp';
import {Shiploader} from '@deckmans/domain';
import {NonHatchBreakdownActivityComp} from './NonHatchBreakdownActivity';
import {NonHatchAwaitingShiploaderActivityComp} from './NonHatchAwaitingShiploaderActivity';
import {NonHatchTnpaActivityComp} from './NonHatchTnpaActivity';
import moment from 'moment';
import {
  ExtendedVesselActivity,
  calculateExtendedActivities,
  getExtendedUnallocatedTime,
} from 'helpers/unAllocatedTimeHelper';
import {
  findVesselActivityEndDate,
  SortVesselActivities,
} from '@deckmans/domain/lib/util/sortVesselActivities';
interface RenderCellProps {
  extendedVesselActivity: ExtendedVesselActivity;
  vesselId: string;
}
function RenderCell({extendedVesselActivity, vesselId}: RenderCellProps) {
  const {
    vesselHatchActivityGroup,
    nonHatchTnpaActivity,
    nonHatchAwaitingShiploaderActivity,
    nonHatchBreakdownActivity,
    nonHatchReclaimingActivity,
    nonHatchShiftingActivity,
    singleLoadingActivity,
    stopVesselActivity,
    surveyActivity,
    unAllocatedTime,
    startDate,
    endDate,
    shiploader,
    id,
  } = extendedVesselActivity;
  if (vesselHatchActivityGroup) {
    //Shiploader specific
    return (
      <HatchActivityComp
        vesselId={vesselId}
        vesselActivity={extendedVesselActivity}
      />
    );
  }
  if (nonHatchShiftingActivity) {
    //This is shiploader specific
    return (
      <NonHatchShiftingActivityComp
        id={id}
        shiploader={shiploader}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        nonHatchShiftingActivity={nonHatchShiftingActivity}
      />
    );
  }
  if (nonHatchReclaimingActivity) {
    //This is shiploader specific
    return (
      <NonHatchReclaimActivityComp
        id={id}
        shiploader={shiploader}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        nonHatchReclaimActivity={nonHatchReclaimingActivity}
      />
    );
  }
  if (nonHatchBreakdownActivity) {
    //This is shiploader specific
    return (
      <NonHatchBreakdownActivityComp
        id={id}
        shiploader={shiploader}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        nonHatchBreakdownActivity={nonHatchBreakdownActivity}
      />
    );
  }
  if (nonHatchAwaitingShiploaderActivity) {
    //This is shiploader specific
    return (
      <NonHatchAwaitingShiploaderActivityComp
        id={id}
        shiploader={shiploader}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        nonHatchAwaitingShiploaderActivity={nonHatchAwaitingShiploaderActivity}
      />
    );
  }
  if (nonHatchTnpaActivity) {
    //This is shiploader specific
    return (
      <NonHatchTnpaActivityComp
        id={id}
        shiploader={shiploader}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        nonHatchTnpaActivity={nonHatchTnpaActivity}
      />
    );
  }
  if (singleLoadingActivity) {
    //This is shiploader specific
    return (
      <SingleLoadingActivityComp
        id={id}
        shiploader={shiploader}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        singleLoadingActivity={singleLoadingActivity}
      />
    );
  }
  if (stopVesselActivity) {
    //This is not shiploader specific
    return (
      <StopVesselActivityComp
        id={id}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        stopVesselActivity={stopVesselActivity}
      />
    );
  }
  if (surveyActivity) {
    //This is not shiploader specific
    return (
      <SurveyActivityComp
        id={id}
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        surveyActivity={surveyActivity}
      />
    );
  }
  if (unAllocatedTime) {
    //This is not shiploader specific
    return (
      <UnallocatedTimeComp
        startDate={startDate}
        endDate={endDate}
        vesselId={vesselId}
        shiploader={shiploader}
      />
    );
  }
  //no defined type found
  return <></>;
}

interface Props {
  vessel: Vessel;
}

export function populateCells(
  vesselActivities: VesselActivity[],
  vesselReclaimStart: Date
) {
  const cells: ExtendedVesselActivity[][] = [];
  let cellItemLeft: VesselActivity[] = [];
  let cellItemRight: VesselActivity[] = [];

  const cellItemLeftCopy: VesselActivity[] = [];
  const cellItemRightCopy: VesselActivity[] = [];

  vesselActivities.forEach((vesselActivity, idx) => {
    const {
      vesselHatchActivityGroup,
      nonHatchShiftingActivity,
      nonHatchReclaimingActivity,
      nonHatchBreakdownActivity,
      nonHatchAwaitingShiploaderActivity,
      nonHatchTnpaActivity,
      singleLoadingActivity,
      stopVesselActivity,
      surveyActivity,
      shiploader,
    } = vesselActivity;

    const Item = vesselActivity;
    if (vesselHatchActivityGroup) {
      //Shiploader specific
      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }
    if (nonHatchShiftingActivity) {
      //This is shiploader specific

      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }
    if (nonHatchReclaimingActivity) {
      //This is shiploader specific

      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }
    if (nonHatchBreakdownActivity) {
      //This is shiploader specific

      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }
    if (nonHatchAwaitingShiploaderActivity) {
      //This is shiploader specific

      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }
    if (nonHatchTnpaActivity) {
      //This is shiploader specific

      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }
    if (singleLoadingActivity) {
      //This is shiploader specific

      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
        cellItemLeftCopy.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
        cellItemRightCopy.push(Item);
      }
    }

    if (stopVesselActivity || surveyActivity) {
      //This is not shiploader specific

      const CrossItem = [vesselActivity];
      cellItemRightCopy.push(vesselActivity);
      cellItemLeftCopy.push(vesselActivity);
      const cil = calculateExtendedActivities(
        cellItemLeft,
        vesselReclaimStart,
        false
      );
      const cir = calculateExtendedActivities(
        cellItemRight,
        vesselReclaimStart,
        false
      );

      //This case added the two empty arrays there is validation
      // in place to make sure you don't add a new act if the old one is not working
      if (cil.length === 0 && cir.length === 0) {
        cells.push(CrossItem, CrossItem);
      } else {
        //TODO add unallocated time if it exists
        const lastRightCrossItemType =
          cir.length === 0 && cellItemRightCopy.length > 0;
        const lastLeftCrossItemType =
          cil.length === 0 && cellItemLeftCopy.length > 0;

        const lastLeftEndDate =
          cil.length > 0
            ? findVesselActivityEndDate(cil[cil.length - 1])
            : lastLeftCrossItemType
            ? cellItemLeftCopy.length > 1
              ? findVesselActivityEndDate(
                  cellItemLeftCopy[cellItemLeftCopy.length - 2]
                )
              : moment(vesselReclaimStart)
            : null;

        const lastRightEndDate =
          cir.length > 0
            ? findVesselActivityEndDate(cir[cir.length - 1])
            : lastRightCrossItemType
            ? cellItemRightCopy.length > 1
              ? findVesselActivityEndDate(
                  cellItemRightCopy[cellItemRightCopy.length - 2]
                )
              : moment(vesselReclaimStart)
            : null;
        const crossVesselStartDate = vesselActivity.startDate;

        const crossVesselIsAfterLeft = moment(crossVesselStartDate).isAfter(
          moment(lastLeftEndDate)
        );
        const crossVesselIsAfterRight = moment(crossVesselStartDate).isAfter(
          moment(lastRightEndDate)
        );

        const crossVesselIsBeforeLeft = moment(crossVesselStartDate).isBefore(
          moment(lastLeftEndDate)
        );
        const crossVesselIsBeforeRight = moment(crossVesselStartDate).isBefore(
          moment(lastRightEndDate)
        );
        const crossVesselIsSameRight = moment(crossVesselStartDate).isSame(
          moment(lastRightEndDate),
          'minute'
        );
        const crossVesselIsSameLeft = moment(crossVesselStartDate).isSame(
          moment(lastLeftEndDate),
          'minute'
        );

        const leftUnallocated = {
          id: '',
          shiploader: Shiploader.SHIPLOADER_ONE,
          startDate: lastLeftEndDate?.toDate(),
          endDate: crossVesselStartDate ?? new Date(),
          unAllocatedTime: {},
        };

        const RightUnallocated = {
          id: '',
          shiploader: Shiploader.SHIPLOADER_TWO,
          startDate: lastRightEndDate?.toDate(),
          endDate: crossVesselStartDate ?? new Date(),
          unAllocatedTime: {},
        };

        if (crossVesselIsSameLeft) {
          if (crossVesselIsSameRight) {
            cells.push(cil, cir, CrossItem, CrossItem);
          } else if (crossVesselIsAfterRight || crossVesselIsBeforeRight) {
            // cells.push(cil, cir, [], [RightUnallocated], CrossItem, CrossItem);
            cir.push(RightUnallocated);
            cells.push(cil, cir, CrossItem, CrossItem);
          } else if (
            !crossVesselIsAfterRight &&
            !crossVesselIsBeforeRight &&
            !crossVesselIsSameRight
          ) {
            cells.push(cil, cir, CrossItem, CrossItem);
          }
        } else if (crossVesselIsSameRight) {
          if (crossVesselIsAfterLeft || crossVesselIsBeforeLeft) {
            cil.push(leftUnallocated);
            // cells.push(cil, cir, [leftUnallocated], [], CrossItem, CrossItem);
            cells.push(cil, cir, CrossItem, CrossItem);
          } else if (
            !crossVesselIsAfterLeft &&
            !crossVesselIsBeforeLeft &&
            !crossVesselIsSameLeft
          ) {
            cells.push(cil, cir, CrossItem, CrossItem);
          }
        } else if (crossVesselIsAfterRight || crossVesselIsBeforeRight) {
          if (crossVesselIsAfterLeft || crossVesselIsBeforeLeft) {
            cells.push(
              cil,
              cir,
              [leftUnallocated],
              [RightUnallocated],
              CrossItem,
              CrossItem
            );
          }
        }
      }

      //TODO add un allocate times before clearing array
      cellItemRight = [];
      cellItemLeft = [];
    }

    if (idx + 1 === vesselActivities.length) {
      const sameLengthLeft = cellItemLeft.length === cellItemLeftCopy.length;
      const sameLengthRight = cellItemRight.length === cellItemRightCopy.length;

      const cil = calculateExtendedActivities(
        cellItemLeft,
        vesselReclaimStart,
        sameLengthLeft
      );
      const cir = calculateExtendedActivities(
        cellItemRight,
        vesselReclaimStart,
        sameLengthRight
      );

      //This case added the two empty arrays there is validation
      // in place to make sure you don't add a new act if the old one is not working
      if (cil.length === 0 && cir.length === 0) {
        // dont push empty arrays
      } else {
        cells.push(cil, cir);
      }

      cellItemRight = [];
      cellItemLeft = [];
    }
  });
  return cells;
}

export function CellManager({vessel}: Props) {
  const vesselActivities = SortVesselActivities(vessel.activities);

  const cells = populateCells(
    vesselActivities,
    vessel.reclaimStart ?? new Date()
  );

  //gets cells with unallocated time if there are cross vessel activities
  const cellsWithUnallocatedTime = getExtendedUnallocatedTime(
    cells,
    vessel.reclaimStart ?? new Date()
  );

  return (
    <>
      {cellsWithUnallocatedTime.map((act, idx) => {
        return (
          <Cell key={`Cell_${idx}`}>
            {act.map((subAct, subActIdx) => (
              <RenderCell
                key={`Cell_Item_${idx}_${subActIdx}`}
                extendedVesselActivity={subAct}
                vesselId={vessel.id}
              />
            ))}
          </Cell>
        );
      })}
    </>
  );
}
