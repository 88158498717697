"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.process = exports.processEvent = exports.updateGroupActivityDateRange = exports.getVessel = void 0;
const date_fns_1 = require("date-fns");
const model_1 = require("../proto/model");
const event_1 = require("../proto/event");
class ErrorEvent extends Error {
    constructor(m) {
        super(m);
        Object.setPrototypeOf(this, ErrorEvent.prototype);
    }
}
function getVessel(state, vesselId) {
    const vessel = state.vessels[vesselId];
    if (!vessel) {
        throw new ErrorEvent('Vessel not found');
    }
    else {
        return vessel;
    }
}
exports.getVessel = getVessel;
function getVesselActivity(vessel, activityId) {
    const activities = vessel.activities[activityId];
    if (!activities) {
        throw new ErrorEvent(`Activities on vessel ${vessel.name} not found`);
    }
    else {
        return activities;
    }
}
function checkVesselId(state, vesselId) {
    if (!!state.vessels[vesselId]) {
        throw new Error(`Vessel with ID: ${vesselId} already exists`);
    }
}
function updateGroupActivityDateRange(groupActivity) {
    if (groupActivity.vesselHatchActivityGroup) {
        const group = groupActivity.vesselHatchActivityGroup;
        let earliestStartDate;
        let latestEndDate;
        for (const activity of Object.values(group.activities)) {
            if (activity.startDate) {
                if (!earliestStartDate) {
                    earliestStartDate = activity.startDate;
                }
                else if (date_fns_1.isBefore(activity.startDate, earliestStartDate)) {
                    earliestStartDate = activity.startDate;
                }
            }
            if (activity.endDate) {
                if (!latestEndDate) {
                    latestEndDate = activity.endDate;
                }
                else if (date_fns_1.isAfter(activity.endDate, latestEndDate)) {
                    latestEndDate = activity.endDate;
                }
            }
        }
        groupActivity.startDate = earliestStartDate;
        groupActivity.endDate = latestEndDate;
    }
    else {
        throw new Error(`Expected Vessel Hatch Activity Group. Got: ${groupActivity}`);
    }
}
exports.updateGroupActivityDateRange = updateGroupActivityDateRange;
function processHatchGroupSubActivity(state, event) {
    const vessel = getVessel(state, event.aggregateId);
    let activity;
    switch (event.type) {
        case event_1.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED: {
            if (event.hatchDirectLoadingActivityCreated) {
                const payload = event.hatchDirectLoadingActivityCreated;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchDirectLoadingActivity: model_1.HatchDirectLoadingActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED: {
            if (event.hatchDirectLoadingActivityEdited) {
                const payload = event.hatchDirectLoadingActivityEdited;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchDirectLoadingActivity: model_1.HatchDirectLoadingActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED: {
            if (event.hatchLoadingActivityCreated) {
                const payload = event.hatchLoadingActivityCreated;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchLoadingActivity: model_1.HatchLoadingActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED: {
            if (event.hatchLoadingActivityEdited) {
                const payload = event.hatchLoadingActivityEdited;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchLoadingActivity: model_1.HatchLoadingActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED: {
            if (event.hatchReclaimingActivityCreated) {
                const payload = event.hatchReclaimingActivityCreated;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchReclaimingActivity: model_1.HatchReclaimingActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED: {
            if (event.hatchReclaimingActivityEdited) {
                const payload = event.hatchReclaimingActivityEdited;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchReclaimingActivity: model_1.HatchReclaimingActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED: {
            if (event.hatchBreakdownActivityCreated) {
                const payload = event.hatchBreakdownActivityCreated;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchBreakdownActivity: model_1.HatchBreakdownActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED: {
            if (event.hatchBreakdownActivityEdited) {
                const payload = event.hatchBreakdownActivityEdited;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchBreakdownActivity: model_1.HatchBreakdownActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED: {
            if (event.hatchAwaitingShiploaderActivityCreated) {
                const payload = event.hatchAwaitingShiploaderActivityCreated;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchAwaitingShiploaderActivity: model_1.HatchAwaitingShiploaderActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED: {
            if (event.hatchAwaitingShiploaderActivityEdited) {
                const payload = event.hatchAwaitingShiploaderActivityEdited;
                activity = getVesselActivity(vessel, payload.groupActivityId);
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.activities[payload.id] = model_1.VesselHatchActivity.fromPartial({
                        id: payload.id,
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        createdBy: event.createdBy,
                        hatchAwaitingShiploaderActivity: model_1.HatchAwaitingShiploaderActivity.fromPartial(payload),
                    });
                }
            }
            break;
        }
        default:
            throw new Error(`Unexpected Event Type: ${event.type}`);
    }
    if (!activity || !activity.vesselHatchActivityGroup) {
        throw new Error(`Group Activity not populated: ${event}`);
    }
    return activity;
}
function processEvent(state, event) {
    var _a;
    switch (event.type) {
        case event_1.EventType.EVENT_TYPE_VESSEL_CREATED: {
            if (event.vesselCreated) {
                checkVesselId(state, event.vesselCreated.id);
                state.vessels[event.vesselCreated.id] = model_1.Vessel.fromPartial({
                    ...event.vesselCreated,
                    // Initialize activites
                    activities: {},
                    createdBy: event.createdBy,
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_VESSEL_DELETED: {
            if (event.vesselDeleted) {
                if (getVessel(state, event.vesselDeleted.id)) {
                    delete state.vessels[event.vesselDeleted.id];
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_VESSEL_EDITED: {
            if (event.vesselEdited) {
                const payload = event.vesselEdited;
                const vessel = state.vessels[event.vesselEdited.id];
                state.vessels[event.vesselEdited.id] = {
                    ...model_1.Vessel.fromPartial(payload),
                    // Preserve activities
                    activities: vessel.activities,
                    createdBy: event.createdBy,
                };
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_VESSEL: {
            if (event.stopVessel) {
                const payload = event.stopVessel;
                const vessel = state.vessels[event.stopVessel.vesselId];
                state.vessels[event.stopVessel.vesselId] = model_1.Vessel.fromPartial({
                    ...vessel,
                    ...payload,
                    id: vessel.id,
                    state: model_1.VesselState.VESSEL_STATE_COMPLETE,
                    createdBy: event.createdBy,
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED: {
            if (event.hatchActivityGroupCreated) {
                const vessel = getVessel(state, event.hatchActivityGroupCreated.vesselId);
                vessel.activities[event.hatchActivityGroupCreated.id] = model_1.VesselActivity.fromPartial({
                    id: event.hatchActivityGroupCreated.id,
                    shiploader: event.hatchActivityGroupCreated.shiploader,
                    vesselHatchActivityGroup: model_1.VesselHatchActivityGroup.fromPartial(event.hatchActivityGroupCreated),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED: {
            if (event.hatchActivityGroupEdited) {
                const vessel = getVessel(state, event.hatchActivityGroupEdited.vesselId);
                vessel.activities[event.hatchActivityGroupEdited.id] = model_1.VesselActivity.fromPartial({
                    endDate: vessel.activities[event.hatchActivityGroupEdited.id].endDate,
                    shiploader: event.hatchActivityGroupEdited.shiploader,
                    startDate: vessel.activities[event.hatchActivityGroupEdited.id].startDate,
                    vesselHatchActivityGroup: {
                        ...vessel.activities[event.hatchActivityGroupEdited.id]
                            .vesselHatchActivityGroup,
                        ...model_1.VesselHatchActivityGroup.fromPartial(event.hatchActivityGroupEdited),
                        activities: (_a = vessel.activities[event.hatchActivityGroupEdited.id]
                            .vesselHatchActivityGroup) === null || _a === void 0 ? void 0 : _a.activities,
                    },
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED: {
            if (event.hatchActivityGroupDeleted) {
                const vessel = getVessel(state, event.hatchActivityGroupDeleted.vesselId);
                delete vessel.activities[event.hatchActivityGroupDeleted.id];
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_HATCH_CREATED: {
            if (event.stopHatchCreated) {
                const vessel = getVessel(state, event.stopHatchCreated.vesselId);
                const payload = event.stopHatchCreated;
                const activity = vessel.activities[event.stopHatchCreated.groupActivityId];
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.completed = model_1.VesselHatchActivityGroup_Completed.fromPartial(payload);
                }
                else {
                    throw new Error(`${event.stopHatchCreated.groupActivityId} is not a group activity`);
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_HATCH_EDITED: {
            if (event.stopHatchEdited) {
                const vessel = getVessel(state, event.stopHatchEdited.vesselId);
                const payload = event.stopHatchEdited;
                const activity = vessel.activities[event.stopHatchEdited.groupActivityId];
                if (activity.vesselHatchActivityGroup) {
                    activity.vesselHatchActivityGroup.completed = model_1.VesselHatchActivityGroup_Completed.fromPartial(payload);
                }
                else {
                    throw new Error(`${event.stopHatchEdited.groupActivityId} is not a group activity`);
                }
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_HATCH_DELETED: {
            if (event.stopHatchDeleted) {
                const vessel = getVessel(state, event.stopHatchDeleted.vesselId);
                const activity = vessel.activities[event.stopHatchDeleted.groupActivityId];
                if (activity.vesselHatchActivityGroup) {
                    delete activity.vesselHatchActivityGroup.completed;
                }
                else {
                    throw new Error(`${event.stopHatchDeleted.groupActivityId} is not a group activity`);
                }
            }
            break;
        }
        // All Hatch group sub activities
        case event_1.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED:
        case event_1.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED:
        case event_1.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED:
        case event_1.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED:
        case event_1.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED:
        case event_1.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED:
        case event_1.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED:
        case event_1.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED:
        case event_1.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED:
        case event_1.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED:
            const activity = processHatchGroupSubActivity(state, event);
            updateGroupActivityDateRange(activity);
            break;
        case event_1.EventType.EVENT_TYPE_SURVEY_ACTIVITY_CREATED: {
            if (event.surveyActivityCreated) {
                const payload = event.surveyActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    surveyActivity: model_1.SurveyActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_SURVEY_ACTIVITY_EDITED: {
            if (event.surveyActivityEdited) {
                const payload = event.surveyActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    surveyActivity: model_1.SurveyActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED: {
            if (event.stopVesselActivityCreated) {
                const payload = event.stopVesselActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    stopVesselActivity: model_1.StopVesselActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED: {
            if (event.stopVesselActivityEdited) {
                const payload = event.stopVesselActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    stopVesselActivity: model_1.StopVesselActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_DELETED: {
            if (event.stopVesselActivityDeleted) {
                const payload = event.stopVesselActivityDeleted;
                const vessel = getVessel(state, payload.vesselId);
                delete vessel.activities[payload.id];
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED: {
            if (event.nonHatchShiftingActivityCreated) {
                const payload = event.nonHatchShiftingActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchShiftingActivity: model_1.NonHatchShiftingActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED: {
            if (event.nonHatchShiftingActivityEdited) {
                const payload = event.nonHatchShiftingActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchShiftingActivity: model_1.NonHatchShiftingActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED: {
            if (event.nonHatchReclaimingActivityCreated) {
                const payload = event.nonHatchReclaimingActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchReclaimingActivity: model_1.NonHatchReclaimingActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED: {
            if (event.nonHatchReclaimingActivityEdited) {
                const payload = event.nonHatchReclaimingActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchReclaimingActivity: model_1.NonHatchReclaimingActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED: {
            if (event.nonHatchBreakdownActivityCreated) {
                const payload = event.nonHatchBreakdownActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchBreakdownActivity: model_1.NonHatchBreakdownActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED: {
            if (event.nonHatchBreakdownActivityEdited) {
                const payload = event.nonHatchBreakdownActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchBreakdownActivity: model_1.NonHatchBreakdownActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED: {
            if (event.nonHatchAwaitingShiploaderActivityCreated) {
                const payload = event.nonHatchAwaitingShiploaderActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchAwaitingShiploaderActivity: model_1.NonHatchAwaitingShiploaderActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED: {
            if (event.nonHatchAwaitingShiploaderActivityEdited) {
                const payload = event.nonHatchAwaitingShiploaderActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchAwaitingShiploaderActivity: model_1.NonHatchAwaitingShiploaderActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED: {
            if (event.nonHatchTnpaActivityCreated) {
                const payload = event.nonHatchTnpaActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchTnpaActivity: model_1.NonHatchTnpaActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED: {
            if (event.nonHatchTnpaActivityEdited) {
                const payload = event.nonHatchTnpaActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    nonHatchTnpaActivity: model_1.NonHatchTnpaActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED: {
            if (event.singleLoadingActivityCreated) {
                const payload = event.singleLoadingActivityCreated;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    singleLoadingActivity: model_1.SingleLoadingActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED: {
            if (event.singleLoadingActivityEdited) {
                const payload = event.singleLoadingActivityEdited;
                const vessel = getVessel(state, payload.vesselId);
                vessel.activities[payload.id] = model_1.VesselActivity.fromPartial({
                    id: payload.id,
                    shiploader: payload.shiploader,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                    createdBy: event.createdBy,
                    singleLoadingActivity: model_1.SingleLoadingActivity.fromPartial(payload),
                });
            }
            break;
        }
        case event_1.EventType.EVENT_TYPE_ACTIVITY_DELETED: {
            if (event.activityDeleted) {
                const vessel = getVessel(state, event.activityDeleted.vesselId);
                if (vessel.activities[event.activityDeleted.id]) {
                    delete vessel.activities[event.activityDeleted.id];
                    return;
                }
                else {
                    const activityWithGroup = Object.values(vessel.activities)
                        .filter((a) => a.vesselHatchActivityGroup)
                        .map((a) => a);
                    for (const activity of activityWithGroup) {
                        const group = activity.vesselHatchActivityGroup;
                        if (group && group.activities[event.activityDeleted.id]) {
                            delete group.activities[event.activityDeleted.id];
                            updateGroupActivityDateRange(activity);
                            return;
                        }
                    }
                }
            }
            // TODO: failure case here. activity not found
            return;
        }
        default: {
            // TODO: change to expect-error so that if we ever miss a type we
            // will not get an error which is an error...
            throw new Error(`Unexpected Event Type: ${event.type}`);
        }
    }
}
exports.processEvent = processEvent;
function process(state, event) {
    processEvent(state, event);
    // Update latest event id
    state.eventId = event.eventId;
}
exports.process = process;
