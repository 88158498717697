import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import {HatchButton} from './HatchButton';
import {makeStyles, Theme} from '@material-ui/core';
import {HatchSummary} from './HatchSummary';
import {HatchActivityList} from './HatchActivityList';
import {useHistory} from 'react-router';
import {ExtendedVesselActivity} from 'helpers/unAllocatedTimeHelper';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  hatch: {
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },

  listPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    paddingBottom: theme.spacing(1),
  },
}));

export interface HatchActivity {
  vesselActivity: ExtendedVesselActivity;
  vesselId: string;
}
export function HatchActivityComp({vesselActivity, vesselId}: HatchActivity) {
  const classes = useStyles();
  const history = useHistory();
  if (!vesselActivity.vesselHatchActivityGroup) {
    return <></>;
  }
  const {id, hatchNumber} = vesselActivity.vesselHatchActivityGroup;

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={2}>
        <HatchButton
          hatch={hatchNumber}
          onClick={() => history.push(`/hatch/view/${vesselId}/${id}`)}
        />
      </Grid>
      <Grid item xs={10}>
        <List classes={{padding: classes.listPadding}}>
          <HatchActivityList
            vesselActivity={vesselActivity}
            vesselId={vesselId}
          />
          <HatchSummary
            vesselHatchGroup={vesselActivity.vesselHatchActivityGroup}
            vesselId={vesselId}
          />
        </List>
      </Grid>
    </Grid>
  );
}
