import * as React from 'react';
import {Box, Typography} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import styles from './GroupCircleSelectStyles';
import {CO} from './types';

interface Props<Val> {
  data: CO<Val>[];
  value: Val;
  handleChange: (event: React.MouseEvent<HTMLElement>, newValue: Val) => void;
}

export function GroupCircleSelect<Val = string>({
  data,
  value,
  handleChange,
}: Props<Val>) {
  const classes = styles();
  return (
    <Box>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        className={classes.hatchToggleButtonGroup}
      >
        {data.map(({id, description}, idx) => {
          return (
            <ToggleButton
              key={`toggle_button_${id}_${idx}`}
              value={description}
              //TODO: find class to target
              style={{borderRadius: '100px', width: '56px', height: '56px'}}
              classes={{
                root: classes.root,
              }}
            >
              <Typography variant="h6">{description}</Typography>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
}
