import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch8SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2227 52.2969V54.0195H5.79981V58.4697H10.2227V60.1992H0.269534L0.269534 58.4697H4.41211V54.0195H0.269533L0.269533 52.2969H10.2227Z"
        fill="white"
      />
      <path
        d="M7.90528 43.8018V47.6572L10.2227 48.4639V50.2617L0.269533 46.502L0.269533 44.9502L10.2227 41.1836V42.9883L7.90528 43.8018ZM6.51074 47.1719V44.2871L2.38184 45.7295L6.51074 47.1719Z"
        fill="white"
      />
      <path
        d="M1.66406 32.9277L1.66406 36.0312H10.2227V37.7471H1.66406L1.66406 40.8232H0.269533L0.269533 32.9277H1.66406Z"
        fill="white"
      />
      <path
        d="M6.98242 23.4619C8.04427 23.5622 8.8737 23.9541 9.4707 24.6377C10.0632 25.3213 10.3594 26.2305 10.3594 27.3652C10.3594 28.1582 10.1725 28.8577 9.79883 29.4639C9.42057 30.0654 8.88509 30.5303 8.19238 30.8584C7.49968 31.1865 6.69531 31.3574 5.7793 31.3711H4.84961C3.91081 31.3711 3.08366 31.2048 2.36817 30.8721C1.65267 30.5394 1.10124 30.0632 0.713868 29.4434C0.326499 28.819 0.132814 28.099 0.132814 27.2832C0.132814 26.1849 0.431316 25.3008 1.02832 24.6309C1.62533 23.9609 2.46843 23.5713 3.55762 23.4619V25.1846C2.84212 25.2666 2.32715 25.4762 2.0127 25.8135C1.69369 26.1462 1.53418 26.6361 1.53418 27.2832C1.53418 28.0352 1.8099 28.6139 2.36133 29.0195C2.9082 29.4206 3.71257 29.6257 4.77442 29.6348H5.65625C6.73177 29.6348 7.55208 29.4434 8.11719 29.0605C8.68229 28.6732 8.96484 28.1081 8.96484 27.3652C8.96484 26.6862 8.81218 26.1758 8.50684 25.834C8.2015 25.4922 7.69336 25.2757 6.98242 25.1846V23.4619Z"
        fill="white"
      />
      <path
        d="M10.2227 13.2578L10.2227 14.9805H5.79981V19.4307H10.2227V21.1602H0.269532L0.269532 19.4307H4.41211V14.9805H0.269532L0.269532 13.2578H10.2227Z"
        fill="white"
      />
      <path
        d="M2.89453 0.21875C3.38216 0.21875 3.81738 0.344076 4.2002 0.594727C4.57845 0.845378 4.87695 1.18717 5.0957 1.62012C5.33724 1.10059 5.66764 0.701823 6.08691 0.423828C6.50619 0.141276 6.98014 2.31078e-08 7.50879 0C8.38379 -3.82475e-08 9.07878 0.296224 9.59375 0.888672C10.1042 1.48112 10.3594 2.26953 10.3594 3.25391C10.3594 4.24284 10.1019 5.03581 9.58691 5.63281C9.07194 6.22526 8.37923 6.52148 7.50879 6.52148C6.97559 6.52148 6.49707 6.38021 6.07324 6.09766C5.64941 5.8151 5.32357 5.41862 5.0957 4.9082C4.87695 5.33659 4.57845 5.67611 4.2002 5.92676C3.81738 6.17285 3.38216 6.2959 2.89453 6.2959C2.04688 6.2959 1.37467 6.02246 0.87793 5.47559C0.381185 4.92871 0.132813 4.19043 0.132812 3.26074C0.132812 2.3265 0.381185 1.58594 0.87793 1.03906C1.37467 0.492188 2.04687 0.21875 2.89453 0.21875ZM7.42676 1.66113C6.93457 1.66113 6.53809 1.80924 6.2373 2.10547C5.93197 2.39714 5.7793 2.78451 5.7793 3.26758C5.7793 3.75065 5.92969 4.13802 6.23047 4.42969C6.53125 4.7168 6.93001 4.86035 7.42676 4.86035C7.91439 4.86035 8.30404 4.71908 8.5957 4.43652C8.88737 4.14941 9.0332 3.75521 9.0332 3.25391C9.0332 2.7526 8.89193 2.36296 8.60938 2.08496C8.32682 1.80241 7.93262 1.66113 7.42676 1.66113ZM2.95605 1.87305C2.52311 1.87305 2.16764 1.99837 1.88965 2.24902C1.6071 2.49967 1.46582 2.83691 1.46582 3.26074C1.46582 3.68457 1.60026 4.01953 1.86914 4.26563C2.13346 4.51172 2.49577 4.63477 2.95605 4.63477C3.41178 4.63477 3.77409 4.51172 4.04297 4.26562C4.31185 4.01497 4.44629 3.67773 4.44629 3.25391C4.44629 2.83008 4.31185 2.49512 4.04297 2.24902C3.77409 1.99837 3.41178 1.87305 2.95605 1.87305Z"
        fill="white"
      />
    </SvgIcon>
  );
}
