import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import {Row, VesselOverviewDetails} from './VesselOverview';
import {getBreakdownCount} from '@deckmans/domain/lib/util/sortVessels';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useVessel} from 'hooks/useVessel';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));
export default function VesselOverviewSummary() {
  const vessel = useVessel();

  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table aria-label="spanning table" size="small">
        <TableHead></TableHead>
        <TableBody>
          <VesselOverviewDetails />
          <Row
            child1="Breakdowns"
            child2={`${getBreakdownCount(vessel)} breakdowns`}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
