import React from 'react';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import {Color} from '@material-ui/lab/Alert';
import {makeStyles, Theme} from '@material-ui/core';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import WavesRoundedIcon from '@material-ui/icons/WavesRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import {TimeBetween} from 'components/Duration';
import {UserRole} from '@deckmans/domain';
import {useAuthContext} from 'contexts/AuthContext';

const useStylesListComp = (color: Color) => {
  return makeStyles(({palette, spacing}: Theme) => {
    const compColor = palette[color];
    return {
      container: {
        border: '2px solid ' + compColor.main,
        borderRadius: spacing(0, 0.5, 0.5, 0),
        marginBottom: spacing(1.5),
      },
      caption: {
        color: compColor.main,
      },
      disabled: {
        color: palette.text.disabled,
      },
      smallBox: {
        backgroundColor: compColor.main,
        borderTopLeftRadius: '4px',
        height: '50%',
        width: '80%',
        right: 0,
        bottom: 0,
        color: 'white',
      },
    };
  });
};

type ActivityListType = 'stopVessel' | 'unallocatedTime';

export interface Activity {
  color: Color; //success/info/warning/error
  title: string;
  type: ActivityListType;
  startTime?: Date;
  endTime?: Date;
  editItem?: () => void;
}
export function ActivityListItemWithBox({
  color,
  startTime,
  endTime,
  type,
  title,
  editItem,
}: Activity) {
  const {hasRole} = useAuthContext();

  const classes = useStylesListComp(color)();
  const st = moment(startTime).format('HH:mm');
  const et = moment(endTime).format('HH:mm');

  return (
    <ListItem
      className={classes.container}
      onClick={hasRole(UserRole.USER_ROLE_AGENT) ? () => {} : editItem}
    >
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="subtitle1" className={classes.disabled}>
            {startTime ? st : 'UD:UD'}
          </Typography>
          <Typography variant="subtitle1" className={classes.disabled}>
            {endTime ? et : 'UD:UD'}
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.caption}>
          <Box display="flex" flexDirection="column" px={1}>
            <Typography variant="h6" color="textPrimary">
              {title}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              pl={1}
              position="absolute"
              className={classes.smallBox}
            >
              {type === 'stopVessel' && <WavesRoundedIcon />}
              {type === 'unallocatedTime' && <ErrorRoundedIcon />}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">
            <TimeBetween
              startTime={startTime?.toString()}
              endTime={endTime?.toString()}
            />
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}
