import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.action.selected,
      border: 0,
      height: '56px',
      width: '56px',
      '@media': {
        '&:hover': {
          backgroundColor: theme.palette.action.selected,
        },
      },
      //borderRadius: '100px',
      '&.Mui-selected': {
        borderRadius: '100px',
        backgroundColor: theme.palette.info.main,
        color: 'white',
        border: 0,
        '&:hover': {
          backgroundColor: theme.palette.info.main,
        },
      },
    },

    hatchToggleButtonGroup: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    hatchToggleButton: {
      borderRadius: '100px',
      height: '56px',
      width: '56px',
    },
  })
);
