import * as React from 'react';
import {Formik, Field} from 'formik';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Box,
} from '@material-ui/core';
import {TextField} from 'formik-material-ui';
import {useEngineContext} from 'contexts/EngineContext';
import {
  VesselHatchActivityGroup,
  VesselState,
  UserRole,
} from '@deckmans/domain';
import {
  sortVesselHatchActivities,
  findVesselHatchGroupDurations,
  findVesselHatchGroupStockpileDuration,
  findVesselHatchGroupDirectLoadingDuration,
} from '@deckmans/domain/lib/util/sortVesselHatchActivities';
import {useAuthContext} from 'contexts/AuthContext';
import * as yup from 'yup';

interface Props {
  open: boolean;
  setOpen: () => void;
  vesselHatchGroup: VesselHatchActivityGroup;
  vesselId: string;
}

function calculateRate(totalTons: number, duration: number) {
  return totalTons / duration;
}

export function HatchSummaryEditDialog({
  vesselHatchGroup,
  open,
  setOpen,
  vesselId,
}: Props) {
  const {id, oreType, completed} = vesselHatchGroup;

  const {engine, eventCreator} = useEngineContext();
  const {hasRole} = useAuthContext();
  const vesselState = engine.state.vessels[vesselId].state;
  const canEditAfterSubmit =
    vesselState === VesselState.VESSEL_STATE_COMPLETE &&
    (hasRole(UserRole.USER_ROLE_ADMIN) ||
      hasRole(UserRole.USER_ROLE_SUPERVISOR));

  const canEditBeforeSubmit =
    vesselState === VesselState.VESSEL_STATE_IN_PROGRESS &&
    (hasRole(UserRole.USER_ROLE_ADMIN) ||
      hasRole(UserRole.USER_ROLE_SUPERVISOR) ||
      hasRole(UserRole.USER_ROLE_USER));

  const activities = sortVesselHatchActivities(vesselHatchGroup);

  const duration = findVesselHatchGroupDurations(activities);

  const stockpileDuration = findVesselHatchGroupStockpileDuration(activities);

  const directLoadingDuration = findVesselHatchGroupDirectLoadingDuration(
    activities
  );

  const containsLoading = activities.some((act) => {
    return act.hatchLoadingActivity;
  });

  const containsDirectLoading = activities.some((act) => {
    return act.hatchDirectLoadingActivity;
  });

  const validationSchema = yup.object().shape({
    tons:
      containsLoading && containsDirectLoading
        ? yup
            .number()
            .typeError('Tons must be a number')
            .moreThan(0, 'Tons cannot be 0')
            .moreThan(
              yup.ref('directLoadingTons'),
              'Total tons must be more than direct loading tons'
            )
            .required('Please enter tons loaded')
        : yup
            .number()
            .typeError('Tons must be a number')
            .moreThan(0, 'Tons cannot be 0')
            .required('Please enter tons loaded'),
    directLoadingTons:
      containsDirectLoading && containsLoading
        ? yup
            .number()
            .typeError('Direct loading tons must be a number')
            .moreThan(0, 'Direct loading tons cannot be 0')
            .required('Please enter direct loading tons')
        : yup.number(),
  });

  return (
    <Formik
      initialValues={{
        tons: completed?.hatchTons ?? 0,
        directLoadingTons: completed?.directLoadingTons ?? 0,
      }}
      validationSchema={validationSchema}
      onSubmit={async ({tons, directLoadingTons}) => {
        const hatchRate = calculateRate(tons, duration / 60);
        let directLoadingRate = 0;

        if (containsDirectLoading) {
          directLoadingRate = calculateRate(
            containsDirectLoading && containsLoading ? directLoadingTons : tons,
            directLoadingDuration / 60
          );
        }

        let stockpileRate = 0;
        if (containsLoading) {
          stockpileRate = calculateRate(
            containsDirectLoading && containsLoading
              ? tons - directLoadingTons
              : tons,
            stockpileDuration / 60
          );
        }

        const event = eventCreator.stopHatchEdited({
          groupActivityId: id,
          id: id,
          oreType: oreType,
          hatchRate,
          hatchTons: tons,
          vesselId: vesselId,
          directLoadingTons:
            containsDirectLoading && containsLoading
              ? directLoadingTons
              : containsLoading
              ? 0
              : tons,
          directLoadingRate,
          stockpileRate,
          stockpileTons:
            containsDirectLoading && containsLoading
              ? tons - directLoadingTons
              : containsDirectLoading
              ? 0
              : tons,
        });
        await engine.process(event);
        setOpen();
      }}
    >
      {({submitForm, values}) => {
        return (
          <Dialog
            open={open}
            onClose={() => {
              setOpen();
            }}
            fullWidth
          >
            <DialogTitle disableTypography>
              <Typography variant="h6">Tons loaded (Tons)</Typography>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Field
                  component={TextField}
                  name="tons"
                  label="Total tons"
                  placeholder="Total tons"
                  fullWidth
                  onFocus={(event: React.FormEvent<HTMLInputElement>) => {
                    event.currentTarget.setSelectionRange(
                      0,
                      event.currentTarget.size
                    );
                  }}
                />
              </Box>
              {containsDirectLoading && containsLoading && (
                <Box pt={3}>
                  <Field
                    fullWidth
                    component={TextField}
                    name="directLoadingTons"
                    label="Direct loading tons"
                    placeholder="Direct loading tons"
                    onFocus={(event: React.FormEvent<HTMLInputElement>) => {
                      event.currentTarget.setSelectionRange(
                        0,
                        event.currentTarget.size
                      );
                    }}
                  />
                </Box>
              )}

              {values.directLoadingTons > 0 && (
                <Box display="flex" justifyContent="space-between" pt={3}>
                  <Typography variant="subtitle1">Direct loading</Typography>
                  <Typography variant="subtitle1">
                    {values.directLoadingTons}
                  </Typography>
                </Box>
              )}
              {values.tons !== 0 && containsLoading && (
                <Box display="flex" justifyContent="space-between" pt={3}>
                  <Typography variant="subtitle1">Stockpile loading</Typography>
                  <Typography variant="subtitle1">
                    {values.directLoadingTons > 0
                      ? values.tons - values.directLoadingTons
                      : values.tons}
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container spacing={1}>
                {canEditAfterSubmit && (
                  <Grid item xs={6}>
                    <Button fullWidth color="primary" onClick={submitForm}>
                      save & finish
                    </Button>
                  </Grid>
                )}
                {canEditBeforeSubmit && (
                  <Grid item xs={6}>
                    <Button fullWidth color="primary" onClick={submitForm}>
                      save & finish
                    </Button>
                  </Grid>
                )}
                <Grid
                  item
                  xs={canEditBeforeSubmit ? 6 : canEditAfterSubmit ? 6 : 12}
                >
                  <Button
                    variant="text"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      setOpen();
                    }}
                  >
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
}
