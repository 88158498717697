import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch3SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9961 52.208V53.9307H6.57324V58.3809H10.9961V60.1104H1.04297L1.04297 58.3809H5.18555V53.9307H1.04297L1.04297 52.208H10.9961Z"
        fill="white"
      />
      <path
        d="M8.67871 43.7129V47.5684L10.9961 48.375V50.1729L1.04297 46.4131L1.04297 44.8613L10.9961 41.0947V42.8994L8.67871 43.7129ZM7.28418 47.083V44.1982L3.15528 45.6406L7.28418 47.083Z"
        fill="white"
      />
      <path
        d="M2.4375 32.8389L2.4375 35.9424H10.9961L10.9961 37.6582H2.4375L2.4375 40.7344H1.04297L1.04297 32.8389H2.4375Z"
        fill="white"
      />
      <path
        d="M7.75586 23.373C8.81771 23.4733 9.64714 23.8652 10.2441 24.5488C10.8366 25.2324 11.1328 26.1416 11.1328 27.2764C11.1328 28.0693 10.946 28.7689 10.5723 29.375C10.194 29.9766 9.65853 30.4414 8.96582 30.7695C8.27311 31.0977 7.46875 31.2686 6.55274 31.2822H5.62305C4.68425 31.2822 3.8571 31.1159 3.1416 30.7832C2.42611 30.4505 1.87468 29.9743 1.48731 29.3545C1.09994 28.7301 0.906251 28.0101 0.906251 27.1943C0.906251 26.096 1.20475 25.2119 1.80176 24.542C2.39876 23.8721 3.24186 23.4824 4.33106 23.373V25.0957C3.61556 25.1777 3.10059 25.3874 2.78613 25.7246C2.46712 26.0573 2.30762 26.5472 2.30762 27.1943C2.30762 27.9463 2.58333 28.5251 3.13477 28.9307C3.68164 29.3317 4.486 29.5368 5.54785 29.5459H6.42969C7.50521 29.5459 8.32552 29.3545 8.89063 28.9717C9.45573 28.5843 9.73828 28.0192 9.73828 27.2764C9.73828 26.5973 9.58561 26.0869 9.28027 25.7451C8.97494 25.4033 8.4668 25.1868 7.75586 25.0957V23.373Z"
        fill="white"
      />
      <path
        d="M10.9961 13.1689L10.9961 14.8916H6.57324V19.3418H10.9961V21.0713H1.04297L1.04297 19.3418H5.18555V14.8916H1.04297L1.04297 13.1689H10.9961Z"
        fill="white"
      />
      <path
        d="M5.26074 4.47754V3.46582C5.25619 2.9554 5.12402 2.55208 4.86426 2.25586C4.60449 1.95508 4.22852 1.80469 3.73633 1.80469C3.26237 1.80469 2.89551 1.93001 2.63574 2.18066C2.37142 2.42676 2.23926 2.80501 2.23926 3.31543C2.23926 3.76204 2.36914 4.12891 2.62891 4.41602C2.88411 4.70313 3.21908 4.84668 3.63379 4.84668L3.63379 6.50781C3.12337 6.50781 2.65853 6.37337 2.23926 6.10449C1.81999 5.83106 1.49414 5.4528 1.26172 4.96973C1.02474 4.4821 0.90625 3.9375 0.90625 3.33594C0.90625 2.34245 1.1569 1.56315 1.6582 0.998047C2.15495 0.428386 2.84766 0.143555 3.73633 0.143555C4.18294 0.143555 4.60449 0.28711 5.00098 0.574219C5.3929 0.856771 5.68913 1.22363 5.88965 1.6748C6.0765 1.12793 6.37044 0.713216 6.77148 0.430664C7.17253 0.143555 7.65104 2.43031e-08 8.20703 0C9.10026 -3.90443e-08 9.8112 0.307617 10.3398 0.922851C10.8685 1.53353 11.1328 2.33789 11.1328 3.33594C11.1328 4.29297 10.8776 5.07682 10.3672 5.6875C9.85677 6.29818 9.17773 6.60352 8.33008 6.60352V4.94238C8.76758 4.94238 9.12305 4.79655 9.39648 4.50488C9.66992 4.20866 9.80664 3.81217 9.80664 3.31543C9.80664 2.80046 9.66992 2.39486 9.39648 2.09863C9.12305 1.80241 8.72656 1.6543 8.20703 1.6543C7.68294 1.6543 7.27962 1.80924 6.99707 2.11914C6.71452 2.42904 6.57324 2.88932 6.57324 3.5V4.47754H5.26074Z"
        fill="white"
      />
    </SvgIcon>
  );
}
