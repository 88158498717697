import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthContextProvider} from 'contexts/AuthContext';
import {Routes} from './Routes';
import {ThemeController} from '@deckmans/web-shared';
import {DataContextProvider} from 'providers/DataContextProvider';
import {HeaderRouter} from 'modules/Header/Header';
import {TitleContextController} from 'contexts/TitleContext';
import {SyncContextController} from 'contexts/SyncContext';
import {EngineContextProvider} from 'contexts/EngineContext';
import {IndexedDbProvider} from 'contexts/IndexedDbContext';
import {ServiceWorkerProvider} from './contexts/ServiceWorkerContext';
import {DebugBar} from './modules/DebugBar/DebugBar';
import {AlertContextProvider} from 'contexts/AlertContex';
import {FullPageError} from 'components/FullPageError';
import {SearchContextController} from 'contexts/SearchContext';

interface EBProps {
  children: JSX.Element;
}

function ErrorBoundary({children}: EBProps) {
  let child: JSX.Element | null = null;
  try {
    child = children;
  } catch (error) {
    child = <FullPageError error={error} />;
  }
  return child;
}

export function App() {
  return (
    <ErrorBoundary>
      <Router>
        <ThemeController>
          <AlertContextProvider>
            <IndexedDbProvider>
              <AuthContextProvider>
                <SearchContextController>
                  <EngineContextProvider>
                    <DataContextProvider>
                      <TitleContextController>
                        <SyncContextController>
                          <ServiceWorkerProvider>
                            <HeaderRouter />
                            <Routes />
                            <DebugBar />
                          </ServiceWorkerProvider>
                        </SyncContextController>
                      </TitleContextController>
                    </DataContextProvider>
                  </EngineContextProvider>
                </SearchContextController>
              </AuthContextProvider>
            </IndexedDbProvider>
          </AlertContextProvider>
        </ThemeController>
      </Router>
    </ErrorBoundary>
  );
}
