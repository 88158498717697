import * as React from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import {useTitle} from 'contexts/TitleContext';
import {SingleSelectField} from 'components/SingleSelectField';
import {useHistory, useParams} from 'react-router';
import {GroupCircleSelectField} from 'components/GroupCircleSelectField';
import {BaseForm} from 'components/BaseForm';
import {useEngineContext} from 'contexts/EngineContext';
import Paper from '@material-ui/core/Paper';
import * as yup from 'yup';
import {Shiploader} from '@deckmans/domain/lib/proto/shared';
import {hatches} from '@deckmans/domain/lib/util/enumData';
import {useShiploader} from '../../hooks/useShiploader';
import {useVessel} from 'hooks/useVessel';
import {useDataContext} from 'providers/DataContextProvider';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    toggleButtonWidth: {
      width: '100%',
    },
    flexDisplay: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      borderTop: '1px solid',
      borderTopColor: theme.palette.divider,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
  })
);
interface HatchType {
  id: string;
  hatch: string | null;
  client: string; //Native select workaround
  oreType: string; //Native select workaround
  shiploader: Shiploader;
}
export function HatchActivityEditor() {
  const {engine, eventCreator} = useEngineContext();
  const {
    master: {clients},
  } = useDataContext();

  const {vesselId, hatchId, shiploaderId} = useParams<{
    vesselId: string;
    shiploaderId: string;
    hatchId?: string;
  }>();
  const vessel = useVessel();
  const classes = styles();
  const history = useHistory();
  const isEditing = Boolean(vesselId && hatchId);
  useTitle(isEditing ? 'Edit Hatch Activity' : 'New Hatch Activity');
  const shiploader = useShiploader(shiploaderId);

  const activity = React.useMemo(() => {
    if (hatchId) {
      return vessel.activities[hatchId].vesselHatchActivityGroup;
    }
  }, [hatchId, vessel.activities]);
  return (
    <Paper elevation={8}>
      <BaseForm<HatchType>
        isEditing={isEditing}
        validationSchema={yup.object().shape({
          hatch: yup.string().nullable().required('Please select a hatch'),
          oreType: yup.mixed<string>().required('This field is required'),
          client: yup.mixed<string>().required('This field is required'),
        })}
        initialValues={
          isEditing
            ? {
                id: hatchId ?? '',
                hatch: activity?.hatchNumber.toString() ?? null,
                client: activity?.client.toString() ?? '',
                oreType: activity?.oreType.toString() ?? '',
                shiploader,
              }
            : {
                id: hatchId ?? '',
                hatch: null,
                client: '',
                oreType: '',
                shiploader,
              }
        }
        onCreateSubmit={async ({hatch, oreType, client, shiploader}) => {
          //create event
          const event = eventCreator.hatchActivityGroupedCreated({
            vesselId: vessel.id,
            hatchNumber: hatch ? parseInt(hatch) : 0, //will never be 0 look at yup
            oreType: oreType,
            client: client,
            shiploader,
          });
          await engine.process(event);
          history.replace(
            `/hatch/view/${vesselId}/${event.hatchActivityGroupCreated?.id}`
          );
        }}
        onEditSubmit={async ({id, client, hatch, oreType, shiploader}) => {
          //edit event
          const event = eventCreator.hatchActivityGroupEdited({
            id,
            vesselId: vessel.id,
            oreType: oreType,
            client: client,
            hatchNumber: hatch ? parseInt(hatch) : 0,
            shiploader,
          });
          await engine.process(event);
          history.replace(
            `/hatch/view/${vesselId}/${event.hatchActivityGroupEdited?.id}`
          );
        }}
      >
        {({submitForm, values, setFieldValue}) => {
          return (
            <Box py={4} px={2} mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Select Hatch</Typography>
                </Grid>
                <Grid item xs={12}>
                  <GroupCircleSelectField
                    data={hatches.map((hatch) => ({
                      id: hatch.id,
                      description: hatch.description,
                    }))}
                    field="hatch"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SingleSelectField
                    fullWidth
                    addNone
                    field="client"
                    label="Client"
                    onChangeCapture={() => {
                      setFieldValue('oreType', '');
                    }}
                    placeholder="Client name"
                    data={vessel.clients.map(({name}) => {
                      const client = name;
                      if (!client) {
                        return {
                          id: '',
                          description: '',
                        };
                      } else {
                        return {
                          id: client,
                          description: client,
                        };
                      }
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SingleSelectField
                    fullWidth
                    field="oreType"
                    label="Ore type"
                    placeholder="Ore type"
                    addNone
                    data={clients
                      .filter(({type}) => {
                        return `${type}` === values.client;
                      })
                      .map((b) => {
                        return b.oreTypes;
                      })[0]
                      ?.map(({id, description}) => ({
                        id: id.toString(),
                        description,
                      }))}
                  />
                </Grid>
              </Grid>
              <Box display="flex" my={4} py={4} className={classes.divider}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={() => submitForm()}
                >
                  {isEditing ? 'Update' : 'Save & Start Loading'}
                </Button>
                <Button
                  fullWidth
                  variant="text"
                  color="secondary"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          );
        }}
      </BaseForm>
    </Paper>
  );
}
