"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uuidV4 = void 0;
// https://github.com/tc39/proposal-uuid
// https://stackoverflow.com/a/2117523/614371
function uuidV4() {
    // eslint-disable-next-line
    // @ts-ignore @typescript-eslint/ban-ts-comment
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
}
exports.uuidV4 = uuidV4;
