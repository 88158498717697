"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StopHatchDeletedEvent = exports.StopHatchEditedEvent = exports.StopHatchCreatedEvent = exports.SingleLoadingActivityEditedEvent = exports.SingleLoadingActivityCreatedEvent = exports.NonHatchTnpaActivityEditedEvent = exports.NonHatchTnpaActivityCreatedEvent = exports.NonHatchAwaitingShiploaderActivityEditedEvent = exports.NonHatchAwaitingShiploaderActivityCreatedEvent = exports.NonHatchBreakdownActivityEditedEvent = exports.NonHatchBreakdownActivityCreatedEvent = exports.NonHatchReclaimingActivityEditedEvent = exports.NonHatchReclaimingActivityCreatedEvent = exports.NonHatchShiftingActivityEditedEvent = exports.NonHatchShiftingActivityCreatedEvent = exports.StopVesselActivityDeletedEvent = exports.StopVesselActivityEditedEvent = exports.StopVesselActivityCreatedEvent = exports.SurveyActivityEditedEvent = exports.SurveyActivityCreatedEvent = exports.HatchAwaitingShiploaderActivityEditedEvent = exports.HatchAwaitingShiploaderActivityCreatedEvent = exports.HatchBreakdownActivityEditedEvent = exports.HatchBreakdownActivityCreatedEvent = exports.HatchReclaimingActivityEditedEvent = exports.HatchReclaimingActivityCreatedEvent = exports.HatchLoadingActivityEditedEvent = exports.HatchLoadingActivityCreatedEvent = exports.HatchDirectLoadingActivityEditedEvent = exports.HatchDirectLoadingActivityCreatedEvent = exports.HatchActivityGroupDeletedEvent = exports.HatchActivityGroupEditedEvent = exports.HatchActivityGroupCreatedEvent = exports.ActivityDeletedEvent = exports.StopVesselEvent = exports.VesselDeletedEvent = exports.VesselEditedEvent_Client = exports.VesselEditedEvent = exports.VesselCreatedEvent_Client = exports.VesselCreatedEvent = exports.Event = exports.EventType = void 0;
/* eslint-disable */
const shared_1 = require("../proto/shared");
const model_1 = require("../proto/model");
const master_1 = require("../proto/master");
const timestamp_1 = require("../google/protobuf/timestamp");
const minimal_1 = require("protobufjs/minimal");
const baseEvent = {
    eventId: "",
    type: 0,
    aggregateId: "",
};
const baseVesselCreatedEvent = {
    id: "",
    name: "",
    berth: "",
    comments: "",
    state: 0,
    delay: "",
    previousVesselId: "",
    delayReason2: "",
    secondComments: "",
    timeBetweenVesselHours: 0,
    timeBetweenVesselMinutes: 0,
};
const baseVesselCreatedEvent_Client = {
    name: "",
    shipmentNumbers: "",
};
const baseVesselEditedEvent = {
    id: "",
    name: "",
    berth: "",
    comments: "",
    state: 0,
    delay: "",
    previousVesselId: "",
    delayReason2: "",
    secondComments: "",
    timeBetweenVesselHours: 0,
    timeBetweenVesselMinutes: 0,
};
const baseVesselEditedEvent_Client = {
    name: "",
    shipmentNumbers: "",
};
const baseVesselDeletedEvent = {
    id: "",
};
const baseStopVesselEvent = {
    id: "",
    vesselId: "",
};
const baseActivityDeletedEvent = {
    id: "",
    vesselId: "",
};
const baseHatchActivityGroupCreatedEvent = {
    id: "",
    vesselId: "",
    hatchNumber: 0,
    shiploader: 0,
    client: "",
    oreType: "",
};
const baseHatchActivityGroupEditedEvent = {
    id: "",
    vesselId: "",
    hatchNumber: 0,
    shiploader: 0,
    client: "",
    oreType: "",
};
const baseHatchActivityGroupDeletedEvent = {
    id: "",
    vesselId: "",
};
const baseHatchDirectLoadingActivityCreatedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    tippler: "",
    train: "",
    route: "",
};
const baseHatchDirectLoadingActivityEditedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    tippler: "",
    train: "",
    route: "",
};
const baseHatchLoadingActivityCreatedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    stockpile: "",
    route: "",
    comments: "",
};
const baseHatchLoadingActivityEditedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    stockpile: "",
    route: "",
    comments: "",
};
const baseHatchReclaimingActivityCreatedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    comments: "",
    client: "",
};
const baseHatchReclaimingActivityEditedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    comments: "",
    client: "",
};
const baseHatchBreakdownActivityCreatedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    equipmentName: "",
    comments: "",
};
const baseHatchBreakdownActivityEditedEvent = {
    groupActivityId: "",
    id: "",
    vesselId: "",
    equipmentName: "",
    comments: "",
};
const baseHatchAwaitingShiploaderActivityCreatedEvent = {
    groupActivityId: "",
    id: "",
    comments: "",
    vesselId: "",
};
const baseHatchAwaitingShiploaderActivityEditedEvent = {
    groupActivityId: "",
    id: "",
    comments: "",
    vesselId: "",
};
const baseSurveyActivityCreatedEvent = {
    id: "",
    vesselId: "",
    surveyType: 0,
    comments: "",
};
const baseSurveyActivityEditedEvent = {
    id: "",
    vesselId: "",
    surveyType: 0,
    comments: "",
};
const baseStopVesselActivityCreatedEvent = {
    id: "",
    vesselId: "",
    vslKmPerHour: 0,
    ccrKmPerHour: 0,
    portControlKmPerHour: 0,
    swellMeters: 0,
    vesselName: "",
    comments: "",
};
const baseStopVesselActivityEditedEvent = {
    id: "",
    vesselId: "",
    vslKmPerHour: 0,
    ccrKmPerHour: 0,
    portControlKmPerHour: 0,
    swellMeters: 0,
    vesselName: "",
    comments: "",
};
const baseStopVesselActivityDeletedEvent = {
    id: "",
    vesselId: "",
};
const baseNonHatchShiftingActivityCreatedEvent = {
    id: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
    reason: "",
};
const baseNonHatchShiftingActivityEditedEvent = {
    id: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
    reason: "",
};
const baseNonHatchReclaimingActivityCreatedEvent = {
    id: "",
    comments: "",
    client: "",
    vesselId: "",
    shiploader: 0,
};
const baseNonHatchReclaimingActivityEditedEvent = {
    id: "",
    comments: "",
    client: "",
    vesselId: "",
    shiploader: 0,
};
const baseNonHatchBreakdownActivityCreatedEvent = {
    id: "",
    equipmentName: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
    client: "",
    oreType: "",
};
const baseNonHatchBreakdownActivityEditedEvent = {
    id: "",
    equipmentName: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
    client: "",
    oreType: "",
};
const baseNonHatchAwaitingShiploaderActivityCreatedEvent = {
    id: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
};
const baseNonHatchAwaitingShiploaderActivityEditedEvent = {
    id: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
};
const baseNonHatchTnpaActivityCreatedEvent = {
    id: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
    reason: "",
};
const baseNonHatchTnpaActivityEditedEvent = {
    id: "",
    comments: "",
    vesselId: "",
    shiploader: 0,
    reason: "",
};
const baseSingleLoadingActivityCreatedEvent = {
    id: "",
    vesselId: "",
    shiploader: 0,
    comments: "",
};
const baseSingleLoadingActivityEditedEvent = {
    id: "",
    vesselId: "",
    shiploader: 0,
    comments: "",
};
const baseStopHatchCreatedEvent = {
    id: "",
    groupActivityId: "",
    vesselId: "",
    hatchTons: 0,
    hatchRate: 0,
    oreType: "",
    directLoadingTons: 0,
    stockpileTons: 0,
    directLoadingRate: 0,
    stockpileRate: 0,
};
const baseStopHatchEditedEvent = {
    id: "",
    groupActivityId: "",
    vesselId: "",
    hatchTons: 0,
    hatchRate: 0,
    oreType: "",
    directLoadingTons: 0,
    stockpileTons: 0,
    directLoadingRate: 0,
    stockpileRate: 0,
};
const baseStopHatchDeletedEvent = {
    id: "",
    groupActivityId: "",
    vesselId: "",
};
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function toTimestamp(date) {
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
exports.EventType = {
    EVENT_TYPE_UNSPECIFIED: 0,
    EVENT_TYPE_VESSEL_CREATED: 1,
    EVENT_TYPE_VESSEL_EDITED: 2,
    EVENT_TYPE_VESSEL_DELETED: 3,
    EVENT_TYPE_STOP_VESSEL: 4,
    EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED: 5,
    EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED: 6,
    EVENT_TYPE_STOP_VESSEL_ACTIVITY_DELETED: 7,
    EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED: 8,
    EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED: 9,
    EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED: 10,
    EVENT_TYPE_STOP_HATCH_CREATED: 11,
    EVENT_TYPE_STOP_HATCH_EDITED: 12,
    EVENT_TYPE_STOP_HATCH_DELETED: 13,
    EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED: 14,
    EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED: 15,
    EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED: 16,
    EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED: 17,
    EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED: 18,
    EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED: 19,
    EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED: 20,
    EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED: 21,
    EVENT_TYPE_SURVEY_ACTIVITY_CREATED: 22,
    EVENT_TYPE_SURVEY_ACTIVITY_EDITED: 23,
    EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED: 24,
    EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED: 25,
    EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED: 26,
    EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED: 27,
    EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED: 28,
    EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED: 29,
    EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED: 30,
    EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED: 31,
    EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED: 32,
    EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED: 33,
    EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED: 34,
    EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED: 35,
    EVENT_TYPE_ACTIVITY_DELETED: 36,
    EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED: 37,
    EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED: 38,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "EVENT_TYPE_UNSPECIFIED":
                return exports.EventType.EVENT_TYPE_UNSPECIFIED;
            case 1:
            case "EVENT_TYPE_VESSEL_CREATED":
                return exports.EventType.EVENT_TYPE_VESSEL_CREATED;
            case 2:
            case "EVENT_TYPE_VESSEL_EDITED":
                return exports.EventType.EVENT_TYPE_VESSEL_EDITED;
            case 3:
            case "EVENT_TYPE_VESSEL_DELETED":
                return exports.EventType.EVENT_TYPE_VESSEL_DELETED;
            case 4:
            case "EVENT_TYPE_STOP_VESSEL":
                return exports.EventType.EVENT_TYPE_STOP_VESSEL;
            case 5:
            case "EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED;
            case 6:
            case "EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED;
            case 7:
            case "EVENT_TYPE_STOP_VESSEL_ACTIVITY_DELETED":
                return exports.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_DELETED;
            case 8:
            case "EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED":
                return exports.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED;
            case 9:
            case "EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED":
                return exports.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED;
            case 10:
            case "EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED":
                return exports.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED;
            case 11:
            case "EVENT_TYPE_STOP_HATCH_CREATED":
                return exports.EventType.EVENT_TYPE_STOP_HATCH_CREATED;
            case 12:
            case "EVENT_TYPE_STOP_HATCH_EDITED":
                return exports.EventType.EVENT_TYPE_STOP_HATCH_EDITED;
            case 13:
            case "EVENT_TYPE_STOP_HATCH_DELETED":
                return exports.EventType.EVENT_TYPE_STOP_HATCH_DELETED;
            case 14:
            case "EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED;
            case 15:
            case "EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED;
            case 16:
            case "EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED;
            case 17:
            case "EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED;
            case 18:
            case "EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED;
            case 19:
            case "EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED;
            case 20:
            case "EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED;
            case 21:
            case "EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED;
            case 22:
            case "EVENT_TYPE_SURVEY_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_SURVEY_ACTIVITY_CREATED;
            case 23:
            case "EVENT_TYPE_SURVEY_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_SURVEY_ACTIVITY_EDITED;
            case 24:
            case "EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED;
            case 25:
            case "EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED;
            case 26:
            case "EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED;
            case 27:
            case "EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED;
            case 28:
            case "EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED;
            case 29:
            case "EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED;
            case 30:
            case "EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED;
            case 31:
            case "EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED;
            case 32:
            case "EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED;
            case 33:
            case "EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED;
            case 34:
            case "EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED;
            case 35:
            case "EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED;
            case 36:
            case "EVENT_TYPE_ACTIVITY_DELETED":
                return exports.EventType.EVENT_TYPE_ACTIVITY_DELETED;
            case 37:
            case "EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED":
                return exports.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED;
            case 38:
            case "EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED":
                return exports.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.EventType.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.EventType.EVENT_TYPE_UNSPECIFIED:
                return "EVENT_TYPE_UNSPECIFIED";
            case exports.EventType.EVENT_TYPE_VESSEL_CREATED:
                return "EVENT_TYPE_VESSEL_CREATED";
            case exports.EventType.EVENT_TYPE_VESSEL_EDITED:
                return "EVENT_TYPE_VESSEL_EDITED";
            case exports.EventType.EVENT_TYPE_VESSEL_DELETED:
                return "EVENT_TYPE_VESSEL_DELETED";
            case exports.EventType.EVENT_TYPE_STOP_VESSEL:
                return "EVENT_TYPE_STOP_VESSEL";
            case exports.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED:
                return "EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED:
                return "EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_DELETED:
                return "EVENT_TYPE_STOP_VESSEL_ACTIVITY_DELETED";
            case exports.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED:
                return "EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED";
            case exports.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED:
                return "EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED";
            case exports.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED:
                return "EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED";
            case exports.EventType.EVENT_TYPE_STOP_HATCH_CREATED:
                return "EVENT_TYPE_STOP_HATCH_CREATED";
            case exports.EventType.EVENT_TYPE_STOP_HATCH_EDITED:
                return "EVENT_TYPE_STOP_HATCH_EDITED";
            case exports.EventType.EVENT_TYPE_STOP_HATCH_DELETED:
                return "EVENT_TYPE_STOP_HATCH_DELETED";
            case exports.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED:
                return "EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED:
                return "EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED:
                return "EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED:
                return "EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED:
                return "EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED:
                return "EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED:
                return "EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED:
                return "EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_SURVEY_ACTIVITY_CREATED:
                return "EVENT_TYPE_SURVEY_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_SURVEY_ACTIVITY_EDITED:
                return "EVENT_TYPE_SURVEY_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED:
                return "EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED:
                return "EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED:
                return "EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED:
                return "EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED:
                return "EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED:
                return "EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED:
                return "EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED:
                return "EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED:
                return "EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED:
                return "EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED:
                return "EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED:
                return "EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED";
            case exports.EventType.EVENT_TYPE_ACTIVITY_DELETED:
                return "EVENT_TYPE_ACTIVITY_DELETED";
            case exports.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED:
                return "EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED";
            case exports.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED:
                return "EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED";
            default:
                return "UNKNOWN";
        }
    },
};
exports.Event = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.eventId);
        writer.uint32(16).int32(message.type);
        writer.uint32(26).string(message.aggregateId);
        if (message.createdAt !== undefined && message.createdAt !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
        }
        if (message.createdBy !== undefined && message.createdBy !== undefined) {
            shared_1.User.encode(message.createdBy, writer.uint32(42).fork()).ldelim();
        }
        if (message.vesselCreated !== undefined && message.vesselCreated !== undefined) {
            exports.VesselCreatedEvent.encode(message.vesselCreated, writer.uint32(50).fork()).ldelim();
        }
        if (message.vesselEdited !== undefined && message.vesselEdited !== undefined) {
            exports.VesselEditedEvent.encode(message.vesselEdited, writer.uint32(58).fork()).ldelim();
        }
        if (message.vesselDeleted !== undefined && message.vesselDeleted !== undefined) {
            exports.VesselDeletedEvent.encode(message.vesselDeleted, writer.uint32(66).fork()).ldelim();
        }
        if (message.stopVessel !== undefined && message.stopVessel !== undefined) {
            exports.StopVesselEvent.encode(message.stopVessel, writer.uint32(74).fork()).ldelim();
        }
        if (message.activityDeleted !== undefined && message.activityDeleted !== undefined) {
            exports.ActivityDeletedEvent.encode(message.activityDeleted, writer.uint32(82).fork()).ldelim();
        }
        if (message.hatchActivityGroupCreated !== undefined && message.hatchActivityGroupCreated !== undefined) {
            exports.HatchActivityGroupCreatedEvent.encode(message.hatchActivityGroupCreated, writer.uint32(90).fork()).ldelim();
        }
        if (message.hatchActivityGroupEdited !== undefined && message.hatchActivityGroupEdited !== undefined) {
            exports.HatchActivityGroupEditedEvent.encode(message.hatchActivityGroupEdited, writer.uint32(98).fork()).ldelim();
        }
        if (message.hatchActivityGroupDeleted !== undefined && message.hatchActivityGroupDeleted !== undefined) {
            exports.HatchActivityGroupDeletedEvent.encode(message.hatchActivityGroupDeleted, writer.uint32(106).fork()).ldelim();
        }
        if (message.hatchDirectLoadingActivityCreated !== undefined && message.hatchDirectLoadingActivityCreated !== undefined) {
            exports.HatchDirectLoadingActivityCreatedEvent.encode(message.hatchDirectLoadingActivityCreated, writer.uint32(114).fork()).ldelim();
        }
        if (message.hatchDirectLoadingActivityEdited !== undefined && message.hatchDirectLoadingActivityEdited !== undefined) {
            exports.HatchDirectLoadingActivityEditedEvent.encode(message.hatchDirectLoadingActivityEdited, writer.uint32(122).fork()).ldelim();
        }
        if (message.hatchLoadingActivityCreated !== undefined && message.hatchLoadingActivityCreated !== undefined) {
            exports.HatchLoadingActivityCreatedEvent.encode(message.hatchLoadingActivityCreated, writer.uint32(130).fork()).ldelim();
        }
        if (message.hatchLoadingActivityEdited !== undefined && message.hatchLoadingActivityEdited !== undefined) {
            exports.HatchLoadingActivityEditedEvent.encode(message.hatchLoadingActivityEdited, writer.uint32(138).fork()).ldelim();
        }
        if (message.hatchReclaimingActivityCreated !== undefined && message.hatchReclaimingActivityCreated !== undefined) {
            exports.HatchReclaimingActivityCreatedEvent.encode(message.hatchReclaimingActivityCreated, writer.uint32(146).fork()).ldelim();
        }
        if (message.hatchReclaimingActivityEdited !== undefined && message.hatchReclaimingActivityEdited !== undefined) {
            exports.HatchReclaimingActivityEditedEvent.encode(message.hatchReclaimingActivityEdited, writer.uint32(154).fork()).ldelim();
        }
        if (message.hatchBreakdownActivityCreated !== undefined && message.hatchBreakdownActivityCreated !== undefined) {
            exports.HatchBreakdownActivityCreatedEvent.encode(message.hatchBreakdownActivityCreated, writer.uint32(162).fork()).ldelim();
        }
        if (message.hatchBreakdownActivityEdited !== undefined && message.hatchBreakdownActivityEdited !== undefined) {
            exports.HatchBreakdownActivityEditedEvent.encode(message.hatchBreakdownActivityEdited, writer.uint32(170).fork()).ldelim();
        }
        if (message.surveyActivityCreated !== undefined && message.surveyActivityCreated !== undefined) {
            exports.SurveyActivityCreatedEvent.encode(message.surveyActivityCreated, writer.uint32(178).fork()).ldelim();
        }
        if (message.stopVesselActivityCreated !== undefined && message.stopVesselActivityCreated !== undefined) {
            exports.StopVesselActivityCreatedEvent.encode(message.stopVesselActivityCreated, writer.uint32(186).fork()).ldelim();
        }
        if (message.stopVesselActivityEdited !== undefined && message.stopVesselActivityEdited !== undefined) {
            exports.StopVesselActivityEditedEvent.encode(message.stopVesselActivityEdited, writer.uint32(194).fork()).ldelim();
        }
        if (message.stopVesselActivityDeleted !== undefined && message.stopVesselActivityDeleted !== undefined) {
            exports.StopVesselActivityDeletedEvent.encode(message.stopVesselActivityDeleted, writer.uint32(202).fork()).ldelim();
        }
        if (message.surveyActivityEdited !== undefined && message.surveyActivityEdited !== undefined) {
            exports.SurveyActivityEditedEvent.encode(message.surveyActivityEdited, writer.uint32(210).fork()).ldelim();
        }
        if (message.nonHatchShiftingActivityCreated !== undefined && message.nonHatchShiftingActivityCreated !== undefined) {
            exports.NonHatchShiftingActivityCreatedEvent.encode(message.nonHatchShiftingActivityCreated, writer.uint32(226).fork()).ldelim();
        }
        if (message.nonHatchShiftingActivityEdited !== undefined && message.nonHatchShiftingActivityEdited !== undefined) {
            exports.NonHatchShiftingActivityEditedEvent.encode(message.nonHatchShiftingActivityEdited, writer.uint32(234).fork()).ldelim();
        }
        if (message.nonHatchReclaimingActivityCreated !== undefined && message.nonHatchReclaimingActivityCreated !== undefined) {
            exports.NonHatchReclaimingActivityCreatedEvent.encode(message.nonHatchReclaimingActivityCreated, writer.uint32(242).fork()).ldelim();
        }
        if (message.nonHatchReclaimingActivityEdited !== undefined && message.nonHatchReclaimingActivityEdited !== undefined) {
            exports.NonHatchReclaimingActivityEditedEvent.encode(message.nonHatchReclaimingActivityEdited, writer.uint32(250).fork()).ldelim();
        }
        if (message.nonHatchBreakdownActivityCreated !== undefined && message.nonHatchBreakdownActivityCreated !== undefined) {
            exports.NonHatchBreakdownActivityCreatedEvent.encode(message.nonHatchBreakdownActivityCreated, writer.uint32(258).fork()).ldelim();
        }
        if (message.nonHatchBreakdownActivityEdited !== undefined && message.nonHatchBreakdownActivityEdited !== undefined) {
            exports.NonHatchBreakdownActivityEditedEvent.encode(message.nonHatchBreakdownActivityEdited, writer.uint32(266).fork()).ldelim();
        }
        if (message.nonHatchAwaitingShiploaderActivityCreated !== undefined && message.nonHatchAwaitingShiploaderActivityCreated !== undefined) {
            exports.NonHatchAwaitingShiploaderActivityCreatedEvent.encode(message.nonHatchAwaitingShiploaderActivityCreated, writer.uint32(274).fork()).ldelim();
        }
        if (message.nonHatchAwaitingShiploaderActivityEdited !== undefined && message.nonHatchAwaitingShiploaderActivityEdited !== undefined) {
            exports.NonHatchAwaitingShiploaderActivityEditedEvent.encode(message.nonHatchAwaitingShiploaderActivityEdited, writer.uint32(282).fork()).ldelim();
        }
        if (message.nonHatchTnpaActivityCreated !== undefined && message.nonHatchTnpaActivityCreated !== undefined) {
            exports.NonHatchTnpaActivityCreatedEvent.encode(message.nonHatchTnpaActivityCreated, writer.uint32(290).fork()).ldelim();
        }
        if (message.nonHatchTnpaActivityEdited !== undefined && message.nonHatchTnpaActivityEdited !== undefined) {
            exports.NonHatchTnpaActivityEditedEvent.encode(message.nonHatchTnpaActivityEdited, writer.uint32(298).fork()).ldelim();
        }
        if (message.singleLoadingActivityCreated !== undefined && message.singleLoadingActivityCreated !== undefined) {
            exports.SingleLoadingActivityCreatedEvent.encode(message.singleLoadingActivityCreated, writer.uint32(306).fork()).ldelim();
        }
        if (message.singleLoadingActivityEdited !== undefined && message.singleLoadingActivityEdited !== undefined) {
            exports.SingleLoadingActivityEditedEvent.encode(message.singleLoadingActivityEdited, writer.uint32(314).fork()).ldelim();
        }
        if (message.stopHatchCreated !== undefined && message.stopHatchCreated !== undefined) {
            exports.StopHatchCreatedEvent.encode(message.stopHatchCreated, writer.uint32(322).fork()).ldelim();
        }
        if (message.stopHatchEdited !== undefined && message.stopHatchEdited !== undefined) {
            exports.StopHatchEditedEvent.encode(message.stopHatchEdited, writer.uint32(330).fork()).ldelim();
        }
        if (message.stopHatchDeleted !== undefined && message.stopHatchDeleted !== undefined) {
            exports.StopHatchDeletedEvent.encode(message.stopHatchDeleted, writer.uint32(338).fork()).ldelim();
        }
        if (message.hatchAwaitingShiploaderActivityCreated !== undefined && message.hatchAwaitingShiploaderActivityCreated !== undefined) {
            exports.HatchAwaitingShiploaderActivityCreatedEvent.encode(message.hatchAwaitingShiploaderActivityCreated, writer.uint32(346).fork()).ldelim();
        }
        if (message.hatchAwaitingShiploaderActivityEdited !== undefined && message.hatchAwaitingShiploaderActivityEdited !== undefined) {
            exports.HatchAwaitingShiploaderActivityEditedEvent.encode(message.hatchAwaitingShiploaderActivityEdited, writer.uint32(354).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventId = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.aggregateId = reader.string();
                    break;
                case 4:
                    message.createdAt = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.createdBy = shared_1.User.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.vesselCreated = exports.VesselCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.vesselEdited = exports.VesselEditedEvent.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.vesselDeleted = exports.VesselDeletedEvent.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.stopVessel = exports.StopVesselEvent.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.activityDeleted = exports.ActivityDeletedEvent.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.hatchActivityGroupCreated = exports.HatchActivityGroupCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.hatchActivityGroupEdited = exports.HatchActivityGroupEditedEvent.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.hatchActivityGroupDeleted = exports.HatchActivityGroupDeletedEvent.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.hatchDirectLoadingActivityCreated = exports.HatchDirectLoadingActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.hatchDirectLoadingActivityEdited = exports.HatchDirectLoadingActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.hatchLoadingActivityCreated = exports.HatchLoadingActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.hatchLoadingActivityEdited = exports.HatchLoadingActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.hatchReclaimingActivityCreated = exports.HatchReclaimingActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.hatchReclaimingActivityEdited = exports.HatchReclaimingActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.hatchBreakdownActivityCreated = exports.HatchBreakdownActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.hatchBreakdownActivityEdited = exports.HatchBreakdownActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.surveyActivityCreated = exports.SurveyActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.stopVesselActivityCreated = exports.StopVesselActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.stopVesselActivityEdited = exports.StopVesselActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.stopVesselActivityDeleted = exports.StopVesselActivityDeletedEvent.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.surveyActivityEdited = exports.SurveyActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.nonHatchShiftingActivityCreated = exports.NonHatchShiftingActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.nonHatchShiftingActivityEdited = exports.NonHatchShiftingActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.nonHatchReclaimingActivityCreated = exports.NonHatchReclaimingActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.nonHatchReclaimingActivityEdited = exports.NonHatchReclaimingActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.nonHatchBreakdownActivityCreated = exports.NonHatchBreakdownActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.nonHatchBreakdownActivityEdited = exports.NonHatchBreakdownActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.nonHatchAwaitingShiploaderActivityCreated = exports.NonHatchAwaitingShiploaderActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.nonHatchAwaitingShiploaderActivityEdited = exports.NonHatchAwaitingShiploaderActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.nonHatchTnpaActivityCreated = exports.NonHatchTnpaActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.nonHatchTnpaActivityEdited = exports.NonHatchTnpaActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.singleLoadingActivityCreated = exports.SingleLoadingActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.singleLoadingActivityEdited = exports.SingleLoadingActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.stopHatchCreated = exports.StopHatchCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.stopHatchEdited = exports.StopHatchEditedEvent.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.stopHatchDeleted = exports.StopHatchDeletedEvent.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.hatchAwaitingShiploaderActivityCreated = exports.HatchAwaitingShiploaderActivityCreatedEvent.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.hatchAwaitingShiploaderActivityEdited = exports.HatchAwaitingShiploaderActivityEditedEvent.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseEvent };
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = String(object.eventId);
        }
        else {
            message.eventId = "";
        }
        if (object.type !== undefined && object.type !== null) {
            message.type = exports.EventType.fromJSON(object.type);
        }
        else {
            message.type = 0;
        }
        if (object.aggregateId !== undefined && object.aggregateId !== null) {
            message.aggregateId = String(object.aggregateId);
        }
        else {
            message.aggregateId = "";
        }
        if (object.createdAt !== undefined && object.createdAt !== null) {
            message.createdAt = fromJsonTimestamp(object.createdAt);
        }
        else {
            message.createdAt = undefined;
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromJSON(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.vesselCreated !== undefined && object.vesselCreated !== null) {
            message.vesselCreated = exports.VesselCreatedEvent.fromJSON(object.vesselCreated);
        }
        else {
            message.vesselCreated = undefined;
        }
        if (object.vesselEdited !== undefined && object.vesselEdited !== null) {
            message.vesselEdited = exports.VesselEditedEvent.fromJSON(object.vesselEdited);
        }
        else {
            message.vesselEdited = undefined;
        }
        if (object.vesselDeleted !== undefined && object.vesselDeleted !== null) {
            message.vesselDeleted = exports.VesselDeletedEvent.fromJSON(object.vesselDeleted);
        }
        else {
            message.vesselDeleted = undefined;
        }
        if (object.stopVessel !== undefined && object.stopVessel !== null) {
            message.stopVessel = exports.StopVesselEvent.fromJSON(object.stopVessel);
        }
        else {
            message.stopVessel = undefined;
        }
        if (object.activityDeleted !== undefined && object.activityDeleted !== null) {
            message.activityDeleted = exports.ActivityDeletedEvent.fromJSON(object.activityDeleted);
        }
        else {
            message.activityDeleted = undefined;
        }
        if (object.hatchActivityGroupCreated !== undefined && object.hatchActivityGroupCreated !== null) {
            message.hatchActivityGroupCreated = exports.HatchActivityGroupCreatedEvent.fromJSON(object.hatchActivityGroupCreated);
        }
        else {
            message.hatchActivityGroupCreated = undefined;
        }
        if (object.hatchActivityGroupEdited !== undefined && object.hatchActivityGroupEdited !== null) {
            message.hatchActivityGroupEdited = exports.HatchActivityGroupEditedEvent.fromJSON(object.hatchActivityGroupEdited);
        }
        else {
            message.hatchActivityGroupEdited = undefined;
        }
        if (object.hatchActivityGroupDeleted !== undefined && object.hatchActivityGroupDeleted !== null) {
            message.hatchActivityGroupDeleted = exports.HatchActivityGroupDeletedEvent.fromJSON(object.hatchActivityGroupDeleted);
        }
        else {
            message.hatchActivityGroupDeleted = undefined;
        }
        if (object.hatchDirectLoadingActivityCreated !== undefined && object.hatchDirectLoadingActivityCreated !== null) {
            message.hatchDirectLoadingActivityCreated = exports.HatchDirectLoadingActivityCreatedEvent.fromJSON(object.hatchDirectLoadingActivityCreated);
        }
        else {
            message.hatchDirectLoadingActivityCreated = undefined;
        }
        if (object.hatchDirectLoadingActivityEdited !== undefined && object.hatchDirectLoadingActivityEdited !== null) {
            message.hatchDirectLoadingActivityEdited = exports.HatchDirectLoadingActivityEditedEvent.fromJSON(object.hatchDirectLoadingActivityEdited);
        }
        else {
            message.hatchDirectLoadingActivityEdited = undefined;
        }
        if (object.hatchLoadingActivityCreated !== undefined && object.hatchLoadingActivityCreated !== null) {
            message.hatchLoadingActivityCreated = exports.HatchLoadingActivityCreatedEvent.fromJSON(object.hatchLoadingActivityCreated);
        }
        else {
            message.hatchLoadingActivityCreated = undefined;
        }
        if (object.hatchLoadingActivityEdited !== undefined && object.hatchLoadingActivityEdited !== null) {
            message.hatchLoadingActivityEdited = exports.HatchLoadingActivityEditedEvent.fromJSON(object.hatchLoadingActivityEdited);
        }
        else {
            message.hatchLoadingActivityEdited = undefined;
        }
        if (object.hatchReclaimingActivityCreated !== undefined && object.hatchReclaimingActivityCreated !== null) {
            message.hatchReclaimingActivityCreated = exports.HatchReclaimingActivityCreatedEvent.fromJSON(object.hatchReclaimingActivityCreated);
        }
        else {
            message.hatchReclaimingActivityCreated = undefined;
        }
        if (object.hatchReclaimingActivityEdited !== undefined && object.hatchReclaimingActivityEdited !== null) {
            message.hatchReclaimingActivityEdited = exports.HatchReclaimingActivityEditedEvent.fromJSON(object.hatchReclaimingActivityEdited);
        }
        else {
            message.hatchReclaimingActivityEdited = undefined;
        }
        if (object.hatchBreakdownActivityCreated !== undefined && object.hatchBreakdownActivityCreated !== null) {
            message.hatchBreakdownActivityCreated = exports.HatchBreakdownActivityCreatedEvent.fromJSON(object.hatchBreakdownActivityCreated);
        }
        else {
            message.hatchBreakdownActivityCreated = undefined;
        }
        if (object.hatchBreakdownActivityEdited !== undefined && object.hatchBreakdownActivityEdited !== null) {
            message.hatchBreakdownActivityEdited = exports.HatchBreakdownActivityEditedEvent.fromJSON(object.hatchBreakdownActivityEdited);
        }
        else {
            message.hatchBreakdownActivityEdited = undefined;
        }
        if (object.surveyActivityCreated !== undefined && object.surveyActivityCreated !== null) {
            message.surveyActivityCreated = exports.SurveyActivityCreatedEvent.fromJSON(object.surveyActivityCreated);
        }
        else {
            message.surveyActivityCreated = undefined;
        }
        if (object.stopVesselActivityCreated !== undefined && object.stopVesselActivityCreated !== null) {
            message.stopVesselActivityCreated = exports.StopVesselActivityCreatedEvent.fromJSON(object.stopVesselActivityCreated);
        }
        else {
            message.stopVesselActivityCreated = undefined;
        }
        if (object.stopVesselActivityEdited !== undefined && object.stopVesselActivityEdited !== null) {
            message.stopVesselActivityEdited = exports.StopVesselActivityEditedEvent.fromJSON(object.stopVesselActivityEdited);
        }
        else {
            message.stopVesselActivityEdited = undefined;
        }
        if (object.stopVesselActivityDeleted !== undefined && object.stopVesselActivityDeleted !== null) {
            message.stopVesselActivityDeleted = exports.StopVesselActivityDeletedEvent.fromJSON(object.stopVesselActivityDeleted);
        }
        else {
            message.stopVesselActivityDeleted = undefined;
        }
        if (object.surveyActivityEdited !== undefined && object.surveyActivityEdited !== null) {
            message.surveyActivityEdited = exports.SurveyActivityEditedEvent.fromJSON(object.surveyActivityEdited);
        }
        else {
            message.surveyActivityEdited = undefined;
        }
        if (object.nonHatchShiftingActivityCreated !== undefined && object.nonHatchShiftingActivityCreated !== null) {
            message.nonHatchShiftingActivityCreated = exports.NonHatchShiftingActivityCreatedEvent.fromJSON(object.nonHatchShiftingActivityCreated);
        }
        else {
            message.nonHatchShiftingActivityCreated = undefined;
        }
        if (object.nonHatchShiftingActivityEdited !== undefined && object.nonHatchShiftingActivityEdited !== null) {
            message.nonHatchShiftingActivityEdited = exports.NonHatchShiftingActivityEditedEvent.fromJSON(object.nonHatchShiftingActivityEdited);
        }
        else {
            message.nonHatchShiftingActivityEdited = undefined;
        }
        if (object.nonHatchReclaimingActivityCreated !== undefined && object.nonHatchReclaimingActivityCreated !== null) {
            message.nonHatchReclaimingActivityCreated = exports.NonHatchReclaimingActivityCreatedEvent.fromJSON(object.nonHatchReclaimingActivityCreated);
        }
        else {
            message.nonHatchReclaimingActivityCreated = undefined;
        }
        if (object.nonHatchReclaimingActivityEdited !== undefined && object.nonHatchReclaimingActivityEdited !== null) {
            message.nonHatchReclaimingActivityEdited = exports.NonHatchReclaimingActivityEditedEvent.fromJSON(object.nonHatchReclaimingActivityEdited);
        }
        else {
            message.nonHatchReclaimingActivityEdited = undefined;
        }
        if (object.nonHatchBreakdownActivityCreated !== undefined && object.nonHatchBreakdownActivityCreated !== null) {
            message.nonHatchBreakdownActivityCreated = exports.NonHatchBreakdownActivityCreatedEvent.fromJSON(object.nonHatchBreakdownActivityCreated);
        }
        else {
            message.nonHatchBreakdownActivityCreated = undefined;
        }
        if (object.nonHatchBreakdownActivityEdited !== undefined && object.nonHatchBreakdownActivityEdited !== null) {
            message.nonHatchBreakdownActivityEdited = exports.NonHatchBreakdownActivityEditedEvent.fromJSON(object.nonHatchBreakdownActivityEdited);
        }
        else {
            message.nonHatchBreakdownActivityEdited = undefined;
        }
        if (object.nonHatchAwaitingShiploaderActivityCreated !== undefined && object.nonHatchAwaitingShiploaderActivityCreated !== null) {
            message.nonHatchAwaitingShiploaderActivityCreated = exports.NonHatchAwaitingShiploaderActivityCreatedEvent.fromJSON(object.nonHatchAwaitingShiploaderActivityCreated);
        }
        else {
            message.nonHatchAwaitingShiploaderActivityCreated = undefined;
        }
        if (object.nonHatchAwaitingShiploaderActivityEdited !== undefined && object.nonHatchAwaitingShiploaderActivityEdited !== null) {
            message.nonHatchAwaitingShiploaderActivityEdited = exports.NonHatchAwaitingShiploaderActivityEditedEvent.fromJSON(object.nonHatchAwaitingShiploaderActivityEdited);
        }
        else {
            message.nonHatchAwaitingShiploaderActivityEdited = undefined;
        }
        if (object.nonHatchTnpaActivityCreated !== undefined && object.nonHatchTnpaActivityCreated !== null) {
            message.nonHatchTnpaActivityCreated = exports.NonHatchTnpaActivityCreatedEvent.fromJSON(object.nonHatchTnpaActivityCreated);
        }
        else {
            message.nonHatchTnpaActivityCreated = undefined;
        }
        if (object.nonHatchTnpaActivityEdited !== undefined && object.nonHatchTnpaActivityEdited !== null) {
            message.nonHatchTnpaActivityEdited = exports.NonHatchTnpaActivityEditedEvent.fromJSON(object.nonHatchTnpaActivityEdited);
        }
        else {
            message.nonHatchTnpaActivityEdited = undefined;
        }
        if (object.singleLoadingActivityCreated !== undefined && object.singleLoadingActivityCreated !== null) {
            message.singleLoadingActivityCreated = exports.SingleLoadingActivityCreatedEvent.fromJSON(object.singleLoadingActivityCreated);
        }
        else {
            message.singleLoadingActivityCreated = undefined;
        }
        if (object.singleLoadingActivityEdited !== undefined && object.singleLoadingActivityEdited !== null) {
            message.singleLoadingActivityEdited = exports.SingleLoadingActivityEditedEvent.fromJSON(object.singleLoadingActivityEdited);
        }
        else {
            message.singleLoadingActivityEdited = undefined;
        }
        if (object.stopHatchCreated !== undefined && object.stopHatchCreated !== null) {
            message.stopHatchCreated = exports.StopHatchCreatedEvent.fromJSON(object.stopHatchCreated);
        }
        else {
            message.stopHatchCreated = undefined;
        }
        if (object.stopHatchEdited !== undefined && object.stopHatchEdited !== null) {
            message.stopHatchEdited = exports.StopHatchEditedEvent.fromJSON(object.stopHatchEdited);
        }
        else {
            message.stopHatchEdited = undefined;
        }
        if (object.stopHatchDeleted !== undefined && object.stopHatchDeleted !== null) {
            message.stopHatchDeleted = exports.StopHatchDeletedEvent.fromJSON(object.stopHatchDeleted);
        }
        else {
            message.stopHatchDeleted = undefined;
        }
        if (object.hatchAwaitingShiploaderActivityCreated !== undefined && object.hatchAwaitingShiploaderActivityCreated !== null) {
            message.hatchAwaitingShiploaderActivityCreated = exports.HatchAwaitingShiploaderActivityCreatedEvent.fromJSON(object.hatchAwaitingShiploaderActivityCreated);
        }
        else {
            message.hatchAwaitingShiploaderActivityCreated = undefined;
        }
        if (object.hatchAwaitingShiploaderActivityEdited !== undefined && object.hatchAwaitingShiploaderActivityEdited !== null) {
            message.hatchAwaitingShiploaderActivityEdited = exports.HatchAwaitingShiploaderActivityEditedEvent.fromJSON(object.hatchAwaitingShiploaderActivityEdited);
        }
        else {
            message.hatchAwaitingShiploaderActivityEdited = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseEvent };
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = object.eventId;
        }
        else {
            message.eventId = "";
        }
        if (object.type !== undefined && object.type !== null) {
            message.type = object.type;
        }
        else {
            message.type = 0;
        }
        if (object.aggregateId !== undefined && object.aggregateId !== null) {
            message.aggregateId = object.aggregateId;
        }
        else {
            message.aggregateId = "";
        }
        if (object.createdAt !== undefined && object.createdAt !== null) {
            message.createdAt = object.createdAt;
        }
        else {
            message.createdAt = undefined;
        }
        if (object.createdBy !== undefined && object.createdBy !== null) {
            message.createdBy = shared_1.User.fromPartial(object.createdBy);
        }
        else {
            message.createdBy = undefined;
        }
        if (object.vesselCreated !== undefined && object.vesselCreated !== null) {
            message.vesselCreated = exports.VesselCreatedEvent.fromPartial(object.vesselCreated);
        }
        else {
            message.vesselCreated = undefined;
        }
        if (object.vesselEdited !== undefined && object.vesselEdited !== null) {
            message.vesselEdited = exports.VesselEditedEvent.fromPartial(object.vesselEdited);
        }
        else {
            message.vesselEdited = undefined;
        }
        if (object.vesselDeleted !== undefined && object.vesselDeleted !== null) {
            message.vesselDeleted = exports.VesselDeletedEvent.fromPartial(object.vesselDeleted);
        }
        else {
            message.vesselDeleted = undefined;
        }
        if (object.stopVessel !== undefined && object.stopVessel !== null) {
            message.stopVessel = exports.StopVesselEvent.fromPartial(object.stopVessel);
        }
        else {
            message.stopVessel = undefined;
        }
        if (object.activityDeleted !== undefined && object.activityDeleted !== null) {
            message.activityDeleted = exports.ActivityDeletedEvent.fromPartial(object.activityDeleted);
        }
        else {
            message.activityDeleted = undefined;
        }
        if (object.hatchActivityGroupCreated !== undefined && object.hatchActivityGroupCreated !== null) {
            message.hatchActivityGroupCreated = exports.HatchActivityGroupCreatedEvent.fromPartial(object.hatchActivityGroupCreated);
        }
        else {
            message.hatchActivityGroupCreated = undefined;
        }
        if (object.hatchActivityGroupEdited !== undefined && object.hatchActivityGroupEdited !== null) {
            message.hatchActivityGroupEdited = exports.HatchActivityGroupEditedEvent.fromPartial(object.hatchActivityGroupEdited);
        }
        else {
            message.hatchActivityGroupEdited = undefined;
        }
        if (object.hatchActivityGroupDeleted !== undefined && object.hatchActivityGroupDeleted !== null) {
            message.hatchActivityGroupDeleted = exports.HatchActivityGroupDeletedEvent.fromPartial(object.hatchActivityGroupDeleted);
        }
        else {
            message.hatchActivityGroupDeleted = undefined;
        }
        if (object.hatchDirectLoadingActivityCreated !== undefined && object.hatchDirectLoadingActivityCreated !== null) {
            message.hatchDirectLoadingActivityCreated = exports.HatchDirectLoadingActivityCreatedEvent.fromPartial(object.hatchDirectLoadingActivityCreated);
        }
        else {
            message.hatchDirectLoadingActivityCreated = undefined;
        }
        if (object.hatchDirectLoadingActivityEdited !== undefined && object.hatchDirectLoadingActivityEdited !== null) {
            message.hatchDirectLoadingActivityEdited = exports.HatchDirectLoadingActivityEditedEvent.fromPartial(object.hatchDirectLoadingActivityEdited);
        }
        else {
            message.hatchDirectLoadingActivityEdited = undefined;
        }
        if (object.hatchLoadingActivityCreated !== undefined && object.hatchLoadingActivityCreated !== null) {
            message.hatchLoadingActivityCreated = exports.HatchLoadingActivityCreatedEvent.fromPartial(object.hatchLoadingActivityCreated);
        }
        else {
            message.hatchLoadingActivityCreated = undefined;
        }
        if (object.hatchLoadingActivityEdited !== undefined && object.hatchLoadingActivityEdited !== null) {
            message.hatchLoadingActivityEdited = exports.HatchLoadingActivityEditedEvent.fromPartial(object.hatchLoadingActivityEdited);
        }
        else {
            message.hatchLoadingActivityEdited = undefined;
        }
        if (object.hatchReclaimingActivityCreated !== undefined && object.hatchReclaimingActivityCreated !== null) {
            message.hatchReclaimingActivityCreated = exports.HatchReclaimingActivityCreatedEvent.fromPartial(object.hatchReclaimingActivityCreated);
        }
        else {
            message.hatchReclaimingActivityCreated = undefined;
        }
        if (object.hatchReclaimingActivityEdited !== undefined && object.hatchReclaimingActivityEdited !== null) {
            message.hatchReclaimingActivityEdited = exports.HatchReclaimingActivityEditedEvent.fromPartial(object.hatchReclaimingActivityEdited);
        }
        else {
            message.hatchReclaimingActivityEdited = undefined;
        }
        if (object.hatchBreakdownActivityCreated !== undefined && object.hatchBreakdownActivityCreated !== null) {
            message.hatchBreakdownActivityCreated = exports.HatchBreakdownActivityCreatedEvent.fromPartial(object.hatchBreakdownActivityCreated);
        }
        else {
            message.hatchBreakdownActivityCreated = undefined;
        }
        if (object.hatchBreakdownActivityEdited !== undefined && object.hatchBreakdownActivityEdited !== null) {
            message.hatchBreakdownActivityEdited = exports.HatchBreakdownActivityEditedEvent.fromPartial(object.hatchBreakdownActivityEdited);
        }
        else {
            message.hatchBreakdownActivityEdited = undefined;
        }
        if (object.surveyActivityCreated !== undefined && object.surveyActivityCreated !== null) {
            message.surveyActivityCreated = exports.SurveyActivityCreatedEvent.fromPartial(object.surveyActivityCreated);
        }
        else {
            message.surveyActivityCreated = undefined;
        }
        if (object.stopVesselActivityCreated !== undefined && object.stopVesselActivityCreated !== null) {
            message.stopVesselActivityCreated = exports.StopVesselActivityCreatedEvent.fromPartial(object.stopVesselActivityCreated);
        }
        else {
            message.stopVesselActivityCreated = undefined;
        }
        if (object.stopVesselActivityEdited !== undefined && object.stopVesselActivityEdited !== null) {
            message.stopVesselActivityEdited = exports.StopVesselActivityEditedEvent.fromPartial(object.stopVesselActivityEdited);
        }
        else {
            message.stopVesselActivityEdited = undefined;
        }
        if (object.stopVesselActivityDeleted !== undefined && object.stopVesselActivityDeleted !== null) {
            message.stopVesselActivityDeleted = exports.StopVesselActivityDeletedEvent.fromPartial(object.stopVesselActivityDeleted);
        }
        else {
            message.stopVesselActivityDeleted = undefined;
        }
        if (object.surveyActivityEdited !== undefined && object.surveyActivityEdited !== null) {
            message.surveyActivityEdited = exports.SurveyActivityEditedEvent.fromPartial(object.surveyActivityEdited);
        }
        else {
            message.surveyActivityEdited = undefined;
        }
        if (object.nonHatchShiftingActivityCreated !== undefined && object.nonHatchShiftingActivityCreated !== null) {
            message.nonHatchShiftingActivityCreated = exports.NonHatchShiftingActivityCreatedEvent.fromPartial(object.nonHatchShiftingActivityCreated);
        }
        else {
            message.nonHatchShiftingActivityCreated = undefined;
        }
        if (object.nonHatchShiftingActivityEdited !== undefined && object.nonHatchShiftingActivityEdited !== null) {
            message.nonHatchShiftingActivityEdited = exports.NonHatchShiftingActivityEditedEvent.fromPartial(object.nonHatchShiftingActivityEdited);
        }
        else {
            message.nonHatchShiftingActivityEdited = undefined;
        }
        if (object.nonHatchReclaimingActivityCreated !== undefined && object.nonHatchReclaimingActivityCreated !== null) {
            message.nonHatchReclaimingActivityCreated = exports.NonHatchReclaimingActivityCreatedEvent.fromPartial(object.nonHatchReclaimingActivityCreated);
        }
        else {
            message.nonHatchReclaimingActivityCreated = undefined;
        }
        if (object.nonHatchReclaimingActivityEdited !== undefined && object.nonHatchReclaimingActivityEdited !== null) {
            message.nonHatchReclaimingActivityEdited = exports.NonHatchReclaimingActivityEditedEvent.fromPartial(object.nonHatchReclaimingActivityEdited);
        }
        else {
            message.nonHatchReclaimingActivityEdited = undefined;
        }
        if (object.nonHatchBreakdownActivityCreated !== undefined && object.nonHatchBreakdownActivityCreated !== null) {
            message.nonHatchBreakdownActivityCreated = exports.NonHatchBreakdownActivityCreatedEvent.fromPartial(object.nonHatchBreakdownActivityCreated);
        }
        else {
            message.nonHatchBreakdownActivityCreated = undefined;
        }
        if (object.nonHatchBreakdownActivityEdited !== undefined && object.nonHatchBreakdownActivityEdited !== null) {
            message.nonHatchBreakdownActivityEdited = exports.NonHatchBreakdownActivityEditedEvent.fromPartial(object.nonHatchBreakdownActivityEdited);
        }
        else {
            message.nonHatchBreakdownActivityEdited = undefined;
        }
        if (object.nonHatchAwaitingShiploaderActivityCreated !== undefined && object.nonHatchAwaitingShiploaderActivityCreated !== null) {
            message.nonHatchAwaitingShiploaderActivityCreated = exports.NonHatchAwaitingShiploaderActivityCreatedEvent.fromPartial(object.nonHatchAwaitingShiploaderActivityCreated);
        }
        else {
            message.nonHatchAwaitingShiploaderActivityCreated = undefined;
        }
        if (object.nonHatchAwaitingShiploaderActivityEdited !== undefined && object.nonHatchAwaitingShiploaderActivityEdited !== null) {
            message.nonHatchAwaitingShiploaderActivityEdited = exports.NonHatchAwaitingShiploaderActivityEditedEvent.fromPartial(object.nonHatchAwaitingShiploaderActivityEdited);
        }
        else {
            message.nonHatchAwaitingShiploaderActivityEdited = undefined;
        }
        if (object.nonHatchTnpaActivityCreated !== undefined && object.nonHatchTnpaActivityCreated !== null) {
            message.nonHatchTnpaActivityCreated = exports.NonHatchTnpaActivityCreatedEvent.fromPartial(object.nonHatchTnpaActivityCreated);
        }
        else {
            message.nonHatchTnpaActivityCreated = undefined;
        }
        if (object.nonHatchTnpaActivityEdited !== undefined && object.nonHatchTnpaActivityEdited !== null) {
            message.nonHatchTnpaActivityEdited = exports.NonHatchTnpaActivityEditedEvent.fromPartial(object.nonHatchTnpaActivityEdited);
        }
        else {
            message.nonHatchTnpaActivityEdited = undefined;
        }
        if (object.singleLoadingActivityCreated !== undefined && object.singleLoadingActivityCreated !== null) {
            message.singleLoadingActivityCreated = exports.SingleLoadingActivityCreatedEvent.fromPartial(object.singleLoadingActivityCreated);
        }
        else {
            message.singleLoadingActivityCreated = undefined;
        }
        if (object.singleLoadingActivityEdited !== undefined && object.singleLoadingActivityEdited !== null) {
            message.singleLoadingActivityEdited = exports.SingleLoadingActivityEditedEvent.fromPartial(object.singleLoadingActivityEdited);
        }
        else {
            message.singleLoadingActivityEdited = undefined;
        }
        if (object.stopHatchCreated !== undefined && object.stopHatchCreated !== null) {
            message.stopHatchCreated = exports.StopHatchCreatedEvent.fromPartial(object.stopHatchCreated);
        }
        else {
            message.stopHatchCreated = undefined;
        }
        if (object.stopHatchEdited !== undefined && object.stopHatchEdited !== null) {
            message.stopHatchEdited = exports.StopHatchEditedEvent.fromPartial(object.stopHatchEdited);
        }
        else {
            message.stopHatchEdited = undefined;
        }
        if (object.stopHatchDeleted !== undefined && object.stopHatchDeleted !== null) {
            message.stopHatchDeleted = exports.StopHatchDeletedEvent.fromPartial(object.stopHatchDeleted);
        }
        else {
            message.stopHatchDeleted = undefined;
        }
        if (object.hatchAwaitingShiploaderActivityCreated !== undefined && object.hatchAwaitingShiploaderActivityCreated !== null) {
            message.hatchAwaitingShiploaderActivityCreated = exports.HatchAwaitingShiploaderActivityCreatedEvent.fromPartial(object.hatchAwaitingShiploaderActivityCreated);
        }
        else {
            message.hatchAwaitingShiploaderActivityCreated = undefined;
        }
        if (object.hatchAwaitingShiploaderActivityEdited !== undefined && object.hatchAwaitingShiploaderActivityEdited !== null) {
            message.hatchAwaitingShiploaderActivityEdited = exports.HatchAwaitingShiploaderActivityEditedEvent.fromPartial(object.hatchAwaitingShiploaderActivityEdited);
        }
        else {
            message.hatchAwaitingShiploaderActivityEdited = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.eventId = message.eventId || "";
        obj.type = exports.EventType.toJSON(message.type);
        obj.aggregateId = message.aggregateId || "";
        obj.createdAt = message.createdAt !== undefined ? message.createdAt.toISOString() : null;
        obj.createdBy = message.createdBy ? shared_1.User.toJSON(message.createdBy) : undefined;
        obj.vesselCreated = message.vesselCreated ? exports.VesselCreatedEvent.toJSON(message.vesselCreated) : undefined;
        obj.vesselEdited = message.vesselEdited ? exports.VesselEditedEvent.toJSON(message.vesselEdited) : undefined;
        obj.vesselDeleted = message.vesselDeleted ? exports.VesselDeletedEvent.toJSON(message.vesselDeleted) : undefined;
        obj.stopVessel = message.stopVessel ? exports.StopVesselEvent.toJSON(message.stopVessel) : undefined;
        obj.activityDeleted = message.activityDeleted ? exports.ActivityDeletedEvent.toJSON(message.activityDeleted) : undefined;
        obj.hatchActivityGroupCreated = message.hatchActivityGroupCreated ? exports.HatchActivityGroupCreatedEvent.toJSON(message.hatchActivityGroupCreated) : undefined;
        obj.hatchActivityGroupEdited = message.hatchActivityGroupEdited ? exports.HatchActivityGroupEditedEvent.toJSON(message.hatchActivityGroupEdited) : undefined;
        obj.hatchActivityGroupDeleted = message.hatchActivityGroupDeleted ? exports.HatchActivityGroupDeletedEvent.toJSON(message.hatchActivityGroupDeleted) : undefined;
        obj.hatchDirectLoadingActivityCreated = message.hatchDirectLoadingActivityCreated ? exports.HatchDirectLoadingActivityCreatedEvent.toJSON(message.hatchDirectLoadingActivityCreated) : undefined;
        obj.hatchDirectLoadingActivityEdited = message.hatchDirectLoadingActivityEdited ? exports.HatchDirectLoadingActivityEditedEvent.toJSON(message.hatchDirectLoadingActivityEdited) : undefined;
        obj.hatchLoadingActivityCreated = message.hatchLoadingActivityCreated ? exports.HatchLoadingActivityCreatedEvent.toJSON(message.hatchLoadingActivityCreated) : undefined;
        obj.hatchLoadingActivityEdited = message.hatchLoadingActivityEdited ? exports.HatchLoadingActivityEditedEvent.toJSON(message.hatchLoadingActivityEdited) : undefined;
        obj.hatchReclaimingActivityCreated = message.hatchReclaimingActivityCreated ? exports.HatchReclaimingActivityCreatedEvent.toJSON(message.hatchReclaimingActivityCreated) : undefined;
        obj.hatchReclaimingActivityEdited = message.hatchReclaimingActivityEdited ? exports.HatchReclaimingActivityEditedEvent.toJSON(message.hatchReclaimingActivityEdited) : undefined;
        obj.hatchBreakdownActivityCreated = message.hatchBreakdownActivityCreated ? exports.HatchBreakdownActivityCreatedEvent.toJSON(message.hatchBreakdownActivityCreated) : undefined;
        obj.hatchBreakdownActivityEdited = message.hatchBreakdownActivityEdited ? exports.HatchBreakdownActivityEditedEvent.toJSON(message.hatchBreakdownActivityEdited) : undefined;
        obj.surveyActivityCreated = message.surveyActivityCreated ? exports.SurveyActivityCreatedEvent.toJSON(message.surveyActivityCreated) : undefined;
        obj.stopVesselActivityCreated = message.stopVesselActivityCreated ? exports.StopVesselActivityCreatedEvent.toJSON(message.stopVesselActivityCreated) : undefined;
        obj.stopVesselActivityEdited = message.stopVesselActivityEdited ? exports.StopVesselActivityEditedEvent.toJSON(message.stopVesselActivityEdited) : undefined;
        obj.stopVesselActivityDeleted = message.stopVesselActivityDeleted ? exports.StopVesselActivityDeletedEvent.toJSON(message.stopVesselActivityDeleted) : undefined;
        obj.surveyActivityEdited = message.surveyActivityEdited ? exports.SurveyActivityEditedEvent.toJSON(message.surveyActivityEdited) : undefined;
        obj.nonHatchShiftingActivityCreated = message.nonHatchShiftingActivityCreated ? exports.NonHatchShiftingActivityCreatedEvent.toJSON(message.nonHatchShiftingActivityCreated) : undefined;
        obj.nonHatchShiftingActivityEdited = message.nonHatchShiftingActivityEdited ? exports.NonHatchShiftingActivityEditedEvent.toJSON(message.nonHatchShiftingActivityEdited) : undefined;
        obj.nonHatchReclaimingActivityCreated = message.nonHatchReclaimingActivityCreated ? exports.NonHatchReclaimingActivityCreatedEvent.toJSON(message.nonHatchReclaimingActivityCreated) : undefined;
        obj.nonHatchReclaimingActivityEdited = message.nonHatchReclaimingActivityEdited ? exports.NonHatchReclaimingActivityEditedEvent.toJSON(message.nonHatchReclaimingActivityEdited) : undefined;
        obj.nonHatchBreakdownActivityCreated = message.nonHatchBreakdownActivityCreated ? exports.NonHatchBreakdownActivityCreatedEvent.toJSON(message.nonHatchBreakdownActivityCreated) : undefined;
        obj.nonHatchBreakdownActivityEdited = message.nonHatchBreakdownActivityEdited ? exports.NonHatchBreakdownActivityEditedEvent.toJSON(message.nonHatchBreakdownActivityEdited) : undefined;
        obj.nonHatchAwaitingShiploaderActivityCreated = message.nonHatchAwaitingShiploaderActivityCreated ? exports.NonHatchAwaitingShiploaderActivityCreatedEvent.toJSON(message.nonHatchAwaitingShiploaderActivityCreated) : undefined;
        obj.nonHatchAwaitingShiploaderActivityEdited = message.nonHatchAwaitingShiploaderActivityEdited ? exports.NonHatchAwaitingShiploaderActivityEditedEvent.toJSON(message.nonHatchAwaitingShiploaderActivityEdited) : undefined;
        obj.nonHatchTnpaActivityCreated = message.nonHatchTnpaActivityCreated ? exports.NonHatchTnpaActivityCreatedEvent.toJSON(message.nonHatchTnpaActivityCreated) : undefined;
        obj.nonHatchTnpaActivityEdited = message.nonHatchTnpaActivityEdited ? exports.NonHatchTnpaActivityEditedEvent.toJSON(message.nonHatchTnpaActivityEdited) : undefined;
        obj.singleLoadingActivityCreated = message.singleLoadingActivityCreated ? exports.SingleLoadingActivityCreatedEvent.toJSON(message.singleLoadingActivityCreated) : undefined;
        obj.singleLoadingActivityEdited = message.singleLoadingActivityEdited ? exports.SingleLoadingActivityEditedEvent.toJSON(message.singleLoadingActivityEdited) : undefined;
        obj.stopHatchCreated = message.stopHatchCreated ? exports.StopHatchCreatedEvent.toJSON(message.stopHatchCreated) : undefined;
        obj.stopHatchEdited = message.stopHatchEdited ? exports.StopHatchEditedEvent.toJSON(message.stopHatchEdited) : undefined;
        obj.stopHatchDeleted = message.stopHatchDeleted ? exports.StopHatchDeletedEvent.toJSON(message.stopHatchDeleted) : undefined;
        obj.hatchAwaitingShiploaderActivityCreated = message.hatchAwaitingShiploaderActivityCreated ? exports.HatchAwaitingShiploaderActivityCreatedEvent.toJSON(message.hatchAwaitingShiploaderActivityCreated) : undefined;
        obj.hatchAwaitingShiploaderActivityEdited = message.hatchAwaitingShiploaderActivityEdited ? exports.HatchAwaitingShiploaderActivityEditedEvent.toJSON(message.hatchAwaitingShiploaderActivityEdited) : undefined;
        return obj;
    },
};
exports.VesselCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.name);
        writer.uint32(26).string(message.berth);
        writer.uint32(34).string(message.comments);
        writer.uint32(40).int32(message.state);
        if (message.reclaimStart !== undefined && message.reclaimStart !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.reclaimStart), writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.clients) {
            exports.VesselCreatedEvent_Client.encode(v, writer.uint32(58).fork()).ldelim();
        }
        writer.uint32(66).string(message.delay);
        writer.uint32(74).string(message.previousVesselId);
        writer.uint32(82).string(message.delayReason2);
        writer.uint32(90).string(message.secondComments);
        writer.uint32(96).int32(message.timeBetweenVesselHours);
        writer.uint32(104).int32(message.timeBetweenVesselMinutes);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselCreatedEvent };
        message.clients = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.berth = reader.string();
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.state = reader.int32();
                    break;
                case 6:
                    message.reclaimStart = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.clients.push(exports.VesselCreatedEvent_Client.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.delay = reader.string();
                    break;
                case 9:
                    message.previousVesselId = reader.string();
                    break;
                case 10:
                    message.delayReason2 = reader.string();
                    break;
                case 11:
                    message.secondComments = reader.string();
                    break;
                case 12:
                    message.timeBetweenVesselHours = reader.int32();
                    break;
                case 13:
                    message.timeBetweenVesselMinutes = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselCreatedEvent };
        message.clients = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.berth !== undefined && object.berth !== null) {
            message.berth = String(object.berth);
        }
        else {
            message.berth = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.state !== undefined && object.state !== null) {
            message.state = model_1.VesselState.fromJSON(object.state);
        }
        else {
            message.state = 0;
        }
        if (object.reclaimStart !== undefined && object.reclaimStart !== null) {
            message.reclaimStart = fromJsonTimestamp(object.reclaimStart);
        }
        else {
            message.reclaimStart = undefined;
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.VesselCreatedEvent_Client.fromJSON(e));
            }
        }
        if (object.delay !== undefined && object.delay !== null) {
            message.delay = String(object.delay);
        }
        else {
            message.delay = "";
        }
        if (object.previousVesselId !== undefined && object.previousVesselId !== null) {
            message.previousVesselId = String(object.previousVesselId);
        }
        else {
            message.previousVesselId = "";
        }
        if (object.delayReason2 !== undefined && object.delayReason2 !== null) {
            message.delayReason2 = String(object.delayReason2);
        }
        else {
            message.delayReason2 = "";
        }
        if (object.secondComments !== undefined && object.secondComments !== null) {
            message.secondComments = String(object.secondComments);
        }
        else {
            message.secondComments = "";
        }
        if (object.timeBetweenVesselHours !== undefined && object.timeBetweenVesselHours !== null) {
            message.timeBetweenVesselHours = Number(object.timeBetweenVesselHours);
        }
        else {
            message.timeBetweenVesselHours = 0;
        }
        if (object.timeBetweenVesselMinutes !== undefined && object.timeBetweenVesselMinutes !== null) {
            message.timeBetweenVesselMinutes = Number(object.timeBetweenVesselMinutes);
        }
        else {
            message.timeBetweenVesselMinutes = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselCreatedEvent };
        message.clients = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.berth !== undefined && object.berth !== null) {
            message.berth = object.berth;
        }
        else {
            message.berth = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.state !== undefined && object.state !== null) {
            message.state = object.state;
        }
        else {
            message.state = 0;
        }
        if (object.reclaimStart !== undefined && object.reclaimStart !== null) {
            message.reclaimStart = object.reclaimStart;
        }
        else {
            message.reclaimStart = undefined;
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.VesselCreatedEvent_Client.fromPartial(e));
            }
        }
        if (object.delay !== undefined && object.delay !== null) {
            message.delay = object.delay;
        }
        else {
            message.delay = "";
        }
        if (object.previousVesselId !== undefined && object.previousVesselId !== null) {
            message.previousVesselId = object.previousVesselId;
        }
        else {
            message.previousVesselId = "";
        }
        if (object.delayReason2 !== undefined && object.delayReason2 !== null) {
            message.delayReason2 = object.delayReason2;
        }
        else {
            message.delayReason2 = "";
        }
        if (object.secondComments !== undefined && object.secondComments !== null) {
            message.secondComments = object.secondComments;
        }
        else {
            message.secondComments = "";
        }
        if (object.timeBetweenVesselHours !== undefined && object.timeBetweenVesselHours !== null) {
            message.timeBetweenVesselHours = object.timeBetweenVesselHours;
        }
        else {
            message.timeBetweenVesselHours = 0;
        }
        if (object.timeBetweenVesselMinutes !== undefined && object.timeBetweenVesselMinutes !== null) {
            message.timeBetweenVesselMinutes = object.timeBetweenVesselMinutes;
        }
        else {
            message.timeBetweenVesselMinutes = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.name = message.name || "";
        obj.berth = message.berth || "";
        obj.comments = message.comments || "";
        obj.state = model_1.VesselState.toJSON(message.state);
        obj.reclaimStart = message.reclaimStart !== undefined ? message.reclaimStart.toISOString() : null;
        if (message.clients) {
            obj.clients = message.clients.map(e => e ? exports.VesselCreatedEvent_Client.toJSON(e) : undefined);
        }
        else {
            obj.clients = [];
        }
        obj.delay = message.delay || "";
        obj.previousVesselId = message.previousVesselId || "";
        obj.delayReason2 = message.delayReason2 || "";
        obj.secondComments = message.secondComments || "";
        obj.timeBetweenVesselHours = message.timeBetweenVesselHours || 0;
        obj.timeBetweenVesselMinutes = message.timeBetweenVesselMinutes || 0;
        return obj;
    },
};
exports.VesselCreatedEvent_Client = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.name);
        for (const v of message.shipmentNumbers) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselCreatedEvent_Client };
        message.shipmentNumbers = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.shipmentNumbers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselCreatedEvent_Client };
        message.shipmentNumbers = [];
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.shipmentNumbers !== undefined && object.shipmentNumbers !== null) {
            for (const e of object.shipmentNumbers) {
                message.shipmentNumbers.push(String(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselCreatedEvent_Client };
        message.shipmentNumbers = [];
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.shipmentNumbers !== undefined && object.shipmentNumbers !== null) {
            for (const e of object.shipmentNumbers) {
                message.shipmentNumbers.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.name = message.name || "";
        if (message.shipmentNumbers) {
            obj.shipmentNumbers = message.shipmentNumbers.map(e => e || "");
        }
        else {
            obj.shipmentNumbers = [];
        }
        return obj;
    },
};
exports.VesselEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.name);
        writer.uint32(26).string(message.berth);
        writer.uint32(34).string(message.comments);
        writer.uint32(40).int32(message.state);
        if (message.reclaimStart !== undefined && message.reclaimStart !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.reclaimStart), writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.clients) {
            exports.VesselEditedEvent_Client.encode(v, writer.uint32(58).fork()).ldelim();
        }
        writer.uint32(66).string(message.delay);
        writer.uint32(74).string(message.previousVesselId);
        writer.uint32(82).string(message.delayReason2);
        writer.uint32(90).string(message.secondComments);
        writer.uint32(96).int32(message.timeBetweenVesselHours);
        writer.uint32(104).int32(message.timeBetweenVesselMinutes);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselEditedEvent };
        message.clients = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.berth = reader.string();
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.state = reader.int32();
                    break;
                case 6:
                    message.reclaimStart = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.clients.push(exports.VesselEditedEvent_Client.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.delay = reader.string();
                    break;
                case 9:
                    message.previousVesselId = reader.string();
                    break;
                case 10:
                    message.delayReason2 = reader.string();
                    break;
                case 11:
                    message.secondComments = reader.string();
                    break;
                case 12:
                    message.timeBetweenVesselHours = reader.int32();
                    break;
                case 13:
                    message.timeBetweenVesselMinutes = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselEditedEvent };
        message.clients = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.berth !== undefined && object.berth !== null) {
            message.berth = String(object.berth);
        }
        else {
            message.berth = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.state !== undefined && object.state !== null) {
            message.state = model_1.VesselState.fromJSON(object.state);
        }
        else {
            message.state = 0;
        }
        if (object.reclaimStart !== undefined && object.reclaimStart !== null) {
            message.reclaimStart = fromJsonTimestamp(object.reclaimStart);
        }
        else {
            message.reclaimStart = undefined;
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.VesselEditedEvent_Client.fromJSON(e));
            }
        }
        if (object.delay !== undefined && object.delay !== null) {
            message.delay = String(object.delay);
        }
        else {
            message.delay = "";
        }
        if (object.previousVesselId !== undefined && object.previousVesselId !== null) {
            message.previousVesselId = String(object.previousVesselId);
        }
        else {
            message.previousVesselId = "";
        }
        if (object.delayReason2 !== undefined && object.delayReason2 !== null) {
            message.delayReason2 = String(object.delayReason2);
        }
        else {
            message.delayReason2 = "";
        }
        if (object.secondComments !== undefined && object.secondComments !== null) {
            message.secondComments = String(object.secondComments);
        }
        else {
            message.secondComments = "";
        }
        if (object.timeBetweenVesselHours !== undefined && object.timeBetweenVesselHours !== null) {
            message.timeBetweenVesselHours = Number(object.timeBetweenVesselHours);
        }
        else {
            message.timeBetweenVesselHours = 0;
        }
        if (object.timeBetweenVesselMinutes !== undefined && object.timeBetweenVesselMinutes !== null) {
            message.timeBetweenVesselMinutes = Number(object.timeBetweenVesselMinutes);
        }
        else {
            message.timeBetweenVesselMinutes = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselEditedEvent };
        message.clients = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.berth !== undefined && object.berth !== null) {
            message.berth = object.berth;
        }
        else {
            message.berth = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.state !== undefined && object.state !== null) {
            message.state = object.state;
        }
        else {
            message.state = 0;
        }
        if (object.reclaimStart !== undefined && object.reclaimStart !== null) {
            message.reclaimStart = object.reclaimStart;
        }
        else {
            message.reclaimStart = undefined;
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.VesselEditedEvent_Client.fromPartial(e));
            }
        }
        if (object.delay !== undefined && object.delay !== null) {
            message.delay = object.delay;
        }
        else {
            message.delay = "";
        }
        if (object.previousVesselId !== undefined && object.previousVesselId !== null) {
            message.previousVesselId = object.previousVesselId;
        }
        else {
            message.previousVesselId = "";
        }
        if (object.delayReason2 !== undefined && object.delayReason2 !== null) {
            message.delayReason2 = object.delayReason2;
        }
        else {
            message.delayReason2 = "";
        }
        if (object.secondComments !== undefined && object.secondComments !== null) {
            message.secondComments = object.secondComments;
        }
        else {
            message.secondComments = "";
        }
        if (object.timeBetweenVesselHours !== undefined && object.timeBetweenVesselHours !== null) {
            message.timeBetweenVesselHours = object.timeBetweenVesselHours;
        }
        else {
            message.timeBetweenVesselHours = 0;
        }
        if (object.timeBetweenVesselMinutes !== undefined && object.timeBetweenVesselMinutes !== null) {
            message.timeBetweenVesselMinutes = object.timeBetweenVesselMinutes;
        }
        else {
            message.timeBetweenVesselMinutes = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.name = message.name || "";
        obj.berth = message.berth || "";
        obj.comments = message.comments || "";
        obj.state = model_1.VesselState.toJSON(message.state);
        obj.reclaimStart = message.reclaimStart !== undefined ? message.reclaimStart.toISOString() : null;
        if (message.clients) {
            obj.clients = message.clients.map(e => e ? exports.VesselEditedEvent_Client.toJSON(e) : undefined);
        }
        else {
            obj.clients = [];
        }
        obj.delay = message.delay || "";
        obj.previousVesselId = message.previousVesselId || "";
        obj.delayReason2 = message.delayReason2 || "";
        obj.secondComments = message.secondComments || "";
        obj.timeBetweenVesselHours = message.timeBetweenVesselHours || 0;
        obj.timeBetweenVesselMinutes = message.timeBetweenVesselMinutes || 0;
        return obj;
    },
};
exports.VesselEditedEvent_Client = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.name);
        for (const v of message.shipmentNumbers) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselEditedEvent_Client };
        message.shipmentNumbers = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.shipmentNumbers.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselEditedEvent_Client };
        message.shipmentNumbers = [];
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.shipmentNumbers !== undefined && object.shipmentNumbers !== null) {
            for (const e of object.shipmentNumbers) {
                message.shipmentNumbers.push(String(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselEditedEvent_Client };
        message.shipmentNumbers = [];
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.shipmentNumbers !== undefined && object.shipmentNumbers !== null) {
            for (const e of object.shipmentNumbers) {
                message.shipmentNumbers.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.name = message.name || "";
        if (message.shipmentNumbers) {
            obj.shipmentNumbers = message.shipmentNumbers.map(e => e || "");
        }
        else {
            obj.shipmentNumbers = [];
        }
        return obj;
    },
};
exports.VesselDeletedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseVesselDeletedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseVesselDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseVesselDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        return obj;
    },
};
exports.StopVesselEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopVesselEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopVesselEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopVesselEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
exports.ActivityDeletedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseActivityDeletedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseActivityDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseActivityDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
exports.HatchActivityGroupCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        writer.uint32(24).int32(message.hatchNumber);
        writer.uint32(32).int32(message.shiploader);
        writer.uint32(42).string(message.client);
        writer.uint32(50).string(message.oreType);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchActivityGroupCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.hatchNumber = reader.int32();
                    break;
                case 4:
                    message.shiploader = reader.int32();
                    break;
                case 5:
                    message.client = reader.string();
                    break;
                case 6:
                    message.oreType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchActivityGroupCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchNumber !== undefined && object.hatchNumber !== null) {
            message.hatchNumber = Number(object.hatchNumber);
        }
        else {
            message.hatchNumber = 0;
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchActivityGroupCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchNumber !== undefined && object.hatchNumber !== null) {
            message.hatchNumber = object.hatchNumber;
        }
        else {
            message.hatchNumber = 0;
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.hatchNumber = message.hatchNumber || 0;
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.client = message.client || "";
        obj.oreType = message.oreType || "";
        return obj;
    },
};
exports.HatchActivityGroupEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        writer.uint32(24).int32(message.hatchNumber);
        writer.uint32(32).int32(message.shiploader);
        writer.uint32(42).string(message.client);
        writer.uint32(50).string(message.oreType);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchActivityGroupEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.hatchNumber = reader.int32();
                    break;
                case 4:
                    message.shiploader = reader.int32();
                    break;
                case 5:
                    message.client = reader.string();
                    break;
                case 6:
                    message.oreType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchActivityGroupEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchNumber !== undefined && object.hatchNumber !== null) {
            message.hatchNumber = Number(object.hatchNumber);
        }
        else {
            message.hatchNumber = 0;
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchActivityGroupEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchNumber !== undefined && object.hatchNumber !== null) {
            message.hatchNumber = object.hatchNumber;
        }
        else {
            message.hatchNumber = 0;
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.hatchNumber = message.hatchNumber || 0;
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.client = message.client || "";
        obj.oreType = message.oreType || "";
        return obj;
    },
};
exports.HatchActivityGroupDeletedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchActivityGroupDeletedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchActivityGroupDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchActivityGroupDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
exports.HatchDirectLoadingActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.tippler);
        writer.uint32(58).string(message.train);
        writer.uint32(66).string(message.route);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchDirectLoadingActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.tippler = reader.string();
                    break;
                case 7:
                    message.train = reader.string();
                    break;
                case 8:
                    message.route = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchDirectLoadingActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.tippler !== undefined && object.tippler !== null) {
            message.tippler = String(object.tippler);
        }
        else {
            message.tippler = "";
        }
        if (object.train !== undefined && object.train !== null) {
            message.train = String(object.train);
        }
        else {
            message.train = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = String(object.route);
        }
        else {
            message.route = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchDirectLoadingActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.tippler !== undefined && object.tippler !== null) {
            message.tippler = object.tippler;
        }
        else {
            message.tippler = "";
        }
        if (object.train !== undefined && object.train !== null) {
            message.train = object.train;
        }
        else {
            message.train = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = object.route;
        }
        else {
            message.route = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.tippler = message.tippler || "";
        obj.train = message.train || "";
        obj.route = message.route || "";
        return obj;
    },
};
exports.HatchDirectLoadingActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.tippler);
        writer.uint32(58).string(message.train);
        writer.uint32(66).string(message.route);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchDirectLoadingActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.tippler = reader.string();
                    break;
                case 7:
                    message.train = reader.string();
                    break;
                case 8:
                    message.route = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchDirectLoadingActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.tippler !== undefined && object.tippler !== null) {
            message.tippler = String(object.tippler);
        }
        else {
            message.tippler = "";
        }
        if (object.train !== undefined && object.train !== null) {
            message.train = String(object.train);
        }
        else {
            message.train = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = String(object.route);
        }
        else {
            message.route = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchDirectLoadingActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.tippler !== undefined && object.tippler !== null) {
            message.tippler = object.tippler;
        }
        else {
            message.tippler = "";
        }
        if (object.train !== undefined && object.train !== null) {
            message.train = object.train;
        }
        else {
            message.train = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = object.route;
        }
        else {
            message.route = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.tippler = message.tippler || "";
        obj.train = message.train || "";
        obj.route = message.route || "";
        return obj;
    },
};
exports.HatchLoadingActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.stockpile);
        writer.uint32(58).string(message.route);
        writer.uint32(66).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchLoadingActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.stockpile = reader.string();
                    break;
                case 7:
                    message.route = reader.string();
                    break;
                case 8:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchLoadingActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.stockpile !== undefined && object.stockpile !== null) {
            message.stockpile = String(object.stockpile);
        }
        else {
            message.stockpile = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = String(object.route);
        }
        else {
            message.route = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchLoadingActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.stockpile !== undefined && object.stockpile !== null) {
            message.stockpile = object.stockpile;
        }
        else {
            message.stockpile = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = object.route;
        }
        else {
            message.route = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.stockpile = message.stockpile || "";
        obj.route = message.route || "";
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.HatchLoadingActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.stockpile);
        writer.uint32(58).string(message.route);
        writer.uint32(66).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchLoadingActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.stockpile = reader.string();
                    break;
                case 7:
                    message.route = reader.string();
                    break;
                case 8:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchLoadingActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.stockpile !== undefined && object.stockpile !== null) {
            message.stockpile = String(object.stockpile);
        }
        else {
            message.stockpile = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = String(object.route);
        }
        else {
            message.route = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchLoadingActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.stockpile !== undefined && object.stockpile !== null) {
            message.stockpile = object.stockpile;
        }
        else {
            message.stockpile = "";
        }
        if (object.route !== undefined && object.route !== null) {
            message.route = object.route;
        }
        else {
            message.route = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.stockpile = message.stockpile || "";
        obj.route = message.route || "";
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.HatchReclaimingActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.comments);
        writer.uint32(66).string(message.client);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchReclaimingActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                case 8:
                    message.client = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchReclaimingActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchReclaimingActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        return obj;
    },
};
exports.HatchReclaimingActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.comments);
        writer.uint32(66).string(message.client);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchReclaimingActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                case 8:
                    message.client = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchReclaimingActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchReclaimingActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        return obj;
    },
};
exports.HatchBreakdownActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.equipmentName);
        writer.uint32(58).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchBreakdownActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.equipmentName = reader.string();
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                case 8:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchBreakdownActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = String(object.equipmentName);
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchBreakdownActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = object.equipmentName;
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.equipmentName = message.equipmentName || "";
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        return obj;
    },
};
exports.HatchBreakdownActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.equipmentName);
        writer.uint32(58).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchBreakdownActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.equipmentName = reader.string();
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                case 8:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchBreakdownActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = String(object.equipmentName);
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchBreakdownActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = object.equipmentName;
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.equipmentName = message.equipmentName || "";
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        return obj;
    },
};
exports.HatchAwaitingShiploaderActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(42).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchAwaitingShiploaderActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.comments = reader.string();
                    break;
                case 6:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchAwaitingShiploaderActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchAwaitingShiploaderActivityCreatedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
exports.HatchAwaitingShiploaderActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.groupActivityId);
        writer.uint32(18).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(42).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseHatchAwaitingShiploaderActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.groupActivityId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.comments = reader.string();
                    break;
                case 6:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseHatchAwaitingShiploaderActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseHatchAwaitingShiploaderActivityEditedEvent };
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.groupActivityId = message.groupActivityId || "";
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
exports.SurveyActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(40).int32(message.surveyType);
        for (const v of message.oreGroup) {
            shared_1.OreGroup.encode(v, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseSurveyActivityCreatedEvent };
        message.oreGroup = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.surveyType = reader.int32();
                    break;
                case 6:
                    message.oreGroup.push(shared_1.OreGroup.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseSurveyActivityCreatedEvent };
        message.oreGroup = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.surveyType !== undefined && object.surveyType !== null) {
            message.surveyType = shared_1.SurveyType.fromJSON(object.surveyType);
        }
        else {
            message.surveyType = 0;
        }
        if (object.oreGroup !== undefined && object.oreGroup !== null) {
            for (const e of object.oreGroup) {
                message.oreGroup.push(shared_1.OreGroup.fromJSON(e));
            }
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseSurveyActivityCreatedEvent };
        message.oreGroup = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.surveyType !== undefined && object.surveyType !== null) {
            message.surveyType = object.surveyType;
        }
        else {
            message.surveyType = 0;
        }
        if (object.oreGroup !== undefined && object.oreGroup !== null) {
            for (const e of object.oreGroup) {
                message.oreGroup.push(shared_1.OreGroup.fromPartial(e));
            }
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.surveyType = shared_1.SurveyType.toJSON(message.surveyType);
        if (message.oreGroup) {
            obj.oreGroup = message.oreGroup.map(e => e ? shared_1.OreGroup.toJSON(e) : undefined);
        }
        else {
            obj.oreGroup = [];
        }
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.SurveyActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(40).int32(message.surveyType);
        for (const v of message.oreGroup) {
            shared_1.OreGroup.encode(v, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseSurveyActivityEditedEvent };
        message.oreGroup = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.surveyType = reader.int32();
                    break;
                case 6:
                    message.oreGroup.push(shared_1.OreGroup.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseSurveyActivityEditedEvent };
        message.oreGroup = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.surveyType !== undefined && object.surveyType !== null) {
            message.surveyType = shared_1.SurveyType.fromJSON(object.surveyType);
        }
        else {
            message.surveyType = 0;
        }
        if (object.oreGroup !== undefined && object.oreGroup !== null) {
            for (const e of object.oreGroup) {
                message.oreGroup.push(shared_1.OreGroup.fromJSON(e));
            }
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseSurveyActivityEditedEvent };
        message.oreGroup = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.surveyType !== undefined && object.surveyType !== null) {
            message.surveyType = object.surveyType;
        }
        else {
            message.surveyType = 0;
        }
        if (object.oreGroup !== undefined && object.oreGroup !== null) {
            for (const e of object.oreGroup) {
                message.oreGroup.push(shared_1.OreGroup.fromPartial(e));
            }
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.surveyType = shared_1.SurveyType.toJSON(message.surveyType);
        if (message.oreGroup) {
            obj.oreGroup = message.oreGroup.map(e => e ? shared_1.OreGroup.toJSON(e) : undefined);
        }
        else {
            obj.oreGroup = [];
        }
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.StopVesselActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(40).int32(message.vslKmPerHour);
        writer.uint32(48).int32(message.ccrKmPerHour);
        writer.uint32(56).int32(message.portControlKmPerHour);
        writer.uint32(64).int32(message.swellMeters);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(74).fork()).ldelim();
        }
        writer.uint32(82).string(message.vesselName);
        writer.uint32(90).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopVesselActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.vslKmPerHour = reader.int32();
                    break;
                case 6:
                    message.ccrKmPerHour = reader.int32();
                    break;
                case 7:
                    message.portControlKmPerHour = reader.int32();
                    break;
                case 8:
                    message.swellMeters = reader.int32();
                    break;
                case 9:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.vesselName = reader.string();
                    break;
                case 11:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopVesselActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.vslKmPerHour !== undefined && object.vslKmPerHour !== null) {
            message.vslKmPerHour = Number(object.vslKmPerHour);
        }
        else {
            message.vslKmPerHour = 0;
        }
        if (object.ccrKmPerHour !== undefined && object.ccrKmPerHour !== null) {
            message.ccrKmPerHour = Number(object.ccrKmPerHour);
        }
        else {
            message.ccrKmPerHour = 0;
        }
        if (object.portControlKmPerHour !== undefined && object.portControlKmPerHour !== null) {
            message.portControlKmPerHour = Number(object.portControlKmPerHour);
        }
        else {
            message.portControlKmPerHour = 0;
        }
        if (object.swellMeters !== undefined && object.swellMeters !== null) {
            message.swellMeters = Number(object.swellMeters);
        }
        else {
            message.swellMeters = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselName !== undefined && object.vesselName !== null) {
            message.vesselName = String(object.vesselName);
        }
        else {
            message.vesselName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopVesselActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.vslKmPerHour !== undefined && object.vslKmPerHour !== null) {
            message.vslKmPerHour = object.vslKmPerHour;
        }
        else {
            message.vslKmPerHour = 0;
        }
        if (object.ccrKmPerHour !== undefined && object.ccrKmPerHour !== null) {
            message.ccrKmPerHour = object.ccrKmPerHour;
        }
        else {
            message.ccrKmPerHour = 0;
        }
        if (object.portControlKmPerHour !== undefined && object.portControlKmPerHour !== null) {
            message.portControlKmPerHour = object.portControlKmPerHour;
        }
        else {
            message.portControlKmPerHour = 0;
        }
        if (object.swellMeters !== undefined && object.swellMeters !== null) {
            message.swellMeters = object.swellMeters;
        }
        else {
            message.swellMeters = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselName !== undefined && object.vesselName !== null) {
            message.vesselName = object.vesselName;
        }
        else {
            message.vesselName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.vslKmPerHour = message.vslKmPerHour || 0;
        obj.ccrKmPerHour = message.ccrKmPerHour || 0;
        obj.portControlKmPerHour = message.portControlKmPerHour || 0;
        obj.swellMeters = message.swellMeters || 0;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.vesselName = message.vesselName || "";
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.StopVesselActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(40).int32(message.vslKmPerHour);
        writer.uint32(48).int32(message.ccrKmPerHour);
        writer.uint32(56).int32(message.portControlKmPerHour);
        writer.uint32(64).int32(message.swellMeters);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(74).fork()).ldelim();
        }
        writer.uint32(82).string(message.vesselName);
        writer.uint32(90).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopVesselActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.vslKmPerHour = reader.int32();
                    break;
                case 6:
                    message.ccrKmPerHour = reader.int32();
                    break;
                case 7:
                    message.portControlKmPerHour = reader.int32();
                    break;
                case 8:
                    message.swellMeters = reader.int32();
                    break;
                case 9:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.vesselName = reader.string();
                    break;
                case 11:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopVesselActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.vslKmPerHour !== undefined && object.vslKmPerHour !== null) {
            message.vslKmPerHour = Number(object.vslKmPerHour);
        }
        else {
            message.vslKmPerHour = 0;
        }
        if (object.ccrKmPerHour !== undefined && object.ccrKmPerHour !== null) {
            message.ccrKmPerHour = Number(object.ccrKmPerHour);
        }
        else {
            message.ccrKmPerHour = 0;
        }
        if (object.portControlKmPerHour !== undefined && object.portControlKmPerHour !== null) {
            message.portControlKmPerHour = Number(object.portControlKmPerHour);
        }
        else {
            message.portControlKmPerHour = 0;
        }
        if (object.swellMeters !== undefined && object.swellMeters !== null) {
            message.swellMeters = Number(object.swellMeters);
        }
        else {
            message.swellMeters = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselName !== undefined && object.vesselName !== null) {
            message.vesselName = String(object.vesselName);
        }
        else {
            message.vesselName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopVesselActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.vslKmPerHour !== undefined && object.vslKmPerHour !== null) {
            message.vslKmPerHour = object.vslKmPerHour;
        }
        else {
            message.vslKmPerHour = 0;
        }
        if (object.ccrKmPerHour !== undefined && object.ccrKmPerHour !== null) {
            message.ccrKmPerHour = object.ccrKmPerHour;
        }
        else {
            message.ccrKmPerHour = 0;
        }
        if (object.portControlKmPerHour !== undefined && object.portControlKmPerHour !== null) {
            message.portControlKmPerHour = object.portControlKmPerHour;
        }
        else {
            message.portControlKmPerHour = 0;
        }
        if (object.swellMeters !== undefined && object.swellMeters !== null) {
            message.swellMeters = object.swellMeters;
        }
        else {
            message.swellMeters = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselName !== undefined && object.vesselName !== null) {
            message.vesselName = object.vesselName;
        }
        else {
            message.vesselName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.vslKmPerHour = message.vslKmPerHour || 0;
        obj.ccrKmPerHour = message.ccrKmPerHour || 0;
        obj.portControlKmPerHour = message.portControlKmPerHour || 0;
        obj.swellMeters = message.swellMeters || 0;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.vesselName = message.vesselName || "";
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.StopVesselActivityDeletedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopVesselActivityDeletedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopVesselActivityDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopVesselActivityDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
exports.NonHatchShiftingActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(34).string(message.comments);
        writer.uint32(42).string(message.vesselId);
        writer.uint32(48).int32(message.shiploader);
        writer.uint32(58).string(message.reason);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchShiftingActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.vesselId = reader.string();
                    break;
                case 6:
                    message.shiploader = reader.int32();
                    break;
                case 7:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchShiftingActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchShiftingActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.reason = message.reason || "";
        return obj;
    },
};
exports.NonHatchShiftingActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(34).string(message.comments);
        writer.uint32(42).string(message.vesselId);
        writer.uint32(48).int32(message.shiploader);
        writer.uint32(58).string(message.reason);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchShiftingActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.vesselId = reader.string();
                    break;
                case 6:
                    message.shiploader = reader.int32();
                    break;
                case 7:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchShiftingActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchShiftingActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.reason = message.reason || "";
        return obj;
    },
};
exports.NonHatchReclaimingActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(42).string(message.comments);
        writer.uint32(50).string(message.client);
        writer.uint32(58).string(message.vesselId);
        writer.uint32(64).int32(message.shiploader);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchReclaimingActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.comments = reader.string();
                    break;
                case 6:
                    message.client = reader.string();
                    break;
                case 7:
                    message.vesselId = reader.string();
                    break;
                case 8:
                    message.shiploader = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchReclaimingActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchReclaimingActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        return obj;
    },
};
exports.NonHatchReclaimingActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(42).string(message.comments);
        writer.uint32(50).string(message.client);
        writer.uint32(58).string(message.vesselId);
        writer.uint32(64).int32(message.shiploader);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchReclaimingActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.comments = reader.string();
                    break;
                case 6:
                    message.client = reader.string();
                    break;
                case 7:
                    message.vesselId = reader.string();
                    break;
                case 8:
                    message.shiploader = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchReclaimingActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchReclaimingActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        obj.client = message.client || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        return obj;
    },
};
exports.NonHatchBreakdownActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(42).string(message.equipmentName);
        writer.uint32(50).string(message.comments);
        writer.uint32(58).string(message.vesselId);
        writer.uint32(64).int32(message.shiploader);
        writer.uint32(74).string(message.client);
        writer.uint32(82).string(message.oreType);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchBreakdownActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.equipmentName = reader.string();
                    break;
                case 6:
                    message.comments = reader.string();
                    break;
                case 7:
                    message.vesselId = reader.string();
                    break;
                case 8:
                    message.shiploader = reader.int32();
                    break;
                case 9:
                    message.client = reader.string();
                    break;
                case 10:
                    message.oreType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchBreakdownActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = String(object.equipmentName);
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchBreakdownActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = object.equipmentName;
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.equipmentName = message.equipmentName || "";
        obj.comments = message.comments || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.client = message.client || "";
        obj.oreType = message.oreType || "";
        return obj;
    },
};
exports.NonHatchBreakdownActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(42).string(message.equipmentName);
        writer.uint32(50).string(message.comments);
        writer.uint32(58).string(message.vesselId);
        writer.uint32(64).int32(message.shiploader);
        writer.uint32(74).string(message.client);
        writer.uint32(82).string(message.oreType);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchBreakdownActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.equipmentName = reader.string();
                    break;
                case 6:
                    message.comments = reader.string();
                    break;
                case 7:
                    message.vesselId = reader.string();
                    break;
                case 8:
                    message.shiploader = reader.int32();
                    break;
                case 9:
                    message.client = reader.string();
                    break;
                case 10:
                    message.oreType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchBreakdownActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = String(object.equipmentName);
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = String(object.client);
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchBreakdownActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.equipmentName !== undefined && object.equipmentName !== null) {
            message.equipmentName = object.equipmentName;
        }
        else {
            message.equipmentName = "";
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.client !== undefined && object.client !== null) {
            message.client = object.client;
        }
        else {
            message.client = "";
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.equipmentName = message.equipmentName || "";
        obj.comments = message.comments || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.client = message.client || "";
        obj.oreType = message.oreType || "";
        return obj;
    },
};
exports.NonHatchAwaitingShiploaderActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(34).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.vesselId);
        writer.uint32(56).int32(message.shiploader);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchAwaitingShiploaderActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.vesselId = reader.string();
                    break;
                case 7:
                    message.shiploader = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchAwaitingShiploaderActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchAwaitingShiploaderActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        return obj;
    },
};
exports.NonHatchAwaitingShiploaderActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(34).string(message.comments);
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(42).fork()).ldelim();
        }
        writer.uint32(50).string(message.vesselId);
        writer.uint32(56).int32(message.shiploader);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchAwaitingShiploaderActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.vesselId = reader.string();
                    break;
                case 7:
                    message.shiploader = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchAwaitingShiploaderActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchAwaitingShiploaderActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        return obj;
    },
};
exports.NonHatchTnpaActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(34).string(message.comments);
        writer.uint32(42).string(message.vesselId);
        writer.uint32(48).int32(message.shiploader);
        writer.uint32(58).string(message.reason);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchTnpaActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.vesselId = reader.string();
                    break;
                case 6:
                    message.shiploader = reader.int32();
                    break;
                case 7:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchTnpaActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchTnpaActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.reason = message.reason || "";
        return obj;
    },
};
exports.NonHatchTnpaActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
        }
        writer.uint32(34).string(message.comments);
        writer.uint32(42).string(message.vesselId);
        writer.uint32(48).int32(message.shiploader);
        writer.uint32(58).string(message.reason);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseNonHatchTnpaActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.comments = reader.string();
                    break;
                case 5:
                    message.vesselId = reader.string();
                    break;
                case 6:
                    message.shiploader = reader.int32();
                    break;
                case 7:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseNonHatchTnpaActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseNonHatchTnpaActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.comments = message.comments || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.reason = message.reason || "";
        return obj;
    },
};
exports.SingleLoadingActivityCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        writer.uint32(24).int32(message.shiploader);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseSingleLoadingActivityCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.shiploader = reader.int32();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseSingleLoadingActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseSingleLoadingActivityCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.SingleLoadingActivityEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.vesselId);
        writer.uint32(24).int32(message.shiploader);
        if (message.startDate !== undefined && message.startDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
        }
        if (message.endDate !== undefined && message.endDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
        }
        if (message.reason !== undefined && message.reason !== undefined) {
            master_1.DecisionAnswer.encode(message.reason, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(58).string(message.comments);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseSingleLoadingActivityEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.vesselId = reader.string();
                    break;
                case 3:
                    message.shiploader = reader.int32();
                    break;
                case 4:
                    message.startDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.endDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.reason = master_1.DecisionAnswer.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseSingleLoadingActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = shared_1.Shiploader.fromJSON(object.shiploader);
        }
        else {
            message.shiploader = 0;
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = fromJsonTimestamp(object.startDate);
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = fromJsonTimestamp(object.endDate);
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromJSON(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = String(object.comments);
        }
        else {
            message.comments = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseSingleLoadingActivityEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.shiploader !== undefined && object.shiploader !== null) {
            message.shiploader = object.shiploader;
        }
        else {
            message.shiploader = 0;
        }
        if (object.startDate !== undefined && object.startDate !== null) {
            message.startDate = object.startDate;
        }
        else {
            message.startDate = undefined;
        }
        if (object.endDate !== undefined && object.endDate !== null) {
            message.endDate = object.endDate;
        }
        else {
            message.endDate = undefined;
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = master_1.DecisionAnswer.fromPartial(object.reason);
        }
        else {
            message.reason = undefined;
        }
        if (object.comments !== undefined && object.comments !== null) {
            message.comments = object.comments;
        }
        else {
            message.comments = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.vesselId = message.vesselId || "";
        obj.shiploader = shared_1.Shiploader.toJSON(message.shiploader);
        obj.startDate = message.startDate !== undefined ? message.startDate.toISOString() : null;
        obj.endDate = message.endDate !== undefined ? message.endDate.toISOString() : null;
        obj.reason = message.reason ? master_1.DecisionAnswer.toJSON(message.reason) : undefined;
        obj.comments = message.comments || "";
        return obj;
    },
};
exports.StopHatchCreatedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.groupActivityId);
        writer.uint32(26).string(message.vesselId);
        writer.uint32(32).int32(message.hatchTons);
        writer.uint32(45).float(message.hatchRate);
        writer.uint32(58).string(message.oreType);
        writer.uint32(64).int32(message.directLoadingTons);
        writer.uint32(72).int32(message.stockpileTons);
        writer.uint32(85).float(message.directLoadingRate);
        writer.uint32(93).float(message.stockpileRate);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopHatchCreatedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.groupActivityId = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.hatchTons = reader.int32();
                    break;
                case 5:
                    message.hatchRate = reader.float();
                    break;
                case 7:
                    message.oreType = reader.string();
                    break;
                case 8:
                    message.directLoadingTons = reader.int32();
                    break;
                case 9:
                    message.stockpileTons = reader.int32();
                    break;
                case 10:
                    message.directLoadingRate = reader.float();
                    break;
                case 11:
                    message.stockpileRate = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopHatchCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchTons !== undefined && object.hatchTons !== null) {
            message.hatchTons = Number(object.hatchTons);
        }
        else {
            message.hatchTons = 0;
        }
        if (object.hatchRate !== undefined && object.hatchRate !== null) {
            message.hatchRate = Number(object.hatchRate);
        }
        else {
            message.hatchRate = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        if (object.directLoadingTons !== undefined && object.directLoadingTons !== null) {
            message.directLoadingTons = Number(object.directLoadingTons);
        }
        else {
            message.directLoadingTons = 0;
        }
        if (object.stockpileTons !== undefined && object.stockpileTons !== null) {
            message.stockpileTons = Number(object.stockpileTons);
        }
        else {
            message.stockpileTons = 0;
        }
        if (object.directLoadingRate !== undefined && object.directLoadingRate !== null) {
            message.directLoadingRate = Number(object.directLoadingRate);
        }
        else {
            message.directLoadingRate = 0;
        }
        if (object.stockpileRate !== undefined && object.stockpileRate !== null) {
            message.stockpileRate = Number(object.stockpileRate);
        }
        else {
            message.stockpileRate = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopHatchCreatedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchTons !== undefined && object.hatchTons !== null) {
            message.hatchTons = object.hatchTons;
        }
        else {
            message.hatchTons = 0;
        }
        if (object.hatchRate !== undefined && object.hatchRate !== null) {
            message.hatchRate = object.hatchRate;
        }
        else {
            message.hatchRate = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        if (object.directLoadingTons !== undefined && object.directLoadingTons !== null) {
            message.directLoadingTons = object.directLoadingTons;
        }
        else {
            message.directLoadingTons = 0;
        }
        if (object.stockpileTons !== undefined && object.stockpileTons !== null) {
            message.stockpileTons = object.stockpileTons;
        }
        else {
            message.stockpileTons = 0;
        }
        if (object.directLoadingRate !== undefined && object.directLoadingRate !== null) {
            message.directLoadingRate = object.directLoadingRate;
        }
        else {
            message.directLoadingRate = 0;
        }
        if (object.stockpileRate !== undefined && object.stockpileRate !== null) {
            message.stockpileRate = object.stockpileRate;
        }
        else {
            message.stockpileRate = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.groupActivityId = message.groupActivityId || "";
        obj.vesselId = message.vesselId || "";
        obj.hatchTons = message.hatchTons || 0;
        obj.hatchRate = message.hatchRate || 0;
        obj.oreType = message.oreType || "";
        obj.directLoadingTons = message.directLoadingTons || 0;
        obj.stockpileTons = message.stockpileTons || 0;
        obj.directLoadingRate = message.directLoadingRate || 0;
        obj.stockpileRate = message.stockpileRate || 0;
        return obj;
    },
};
exports.StopHatchEditedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.groupActivityId);
        writer.uint32(26).string(message.vesselId);
        writer.uint32(32).int32(message.hatchTons);
        writer.uint32(45).float(message.hatchRate);
        writer.uint32(58).string(message.oreType);
        writer.uint32(64).int32(message.directLoadingTons);
        writer.uint32(72).int32(message.stockpileTons);
        writer.uint32(85).float(message.directLoadingRate);
        writer.uint32(93).float(message.stockpileRate);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopHatchEditedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.groupActivityId = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                case 4:
                    message.hatchTons = reader.int32();
                    break;
                case 5:
                    message.hatchRate = reader.float();
                    break;
                case 7:
                    message.oreType = reader.string();
                    break;
                case 8:
                    message.directLoadingTons = reader.int32();
                    break;
                case 9:
                    message.stockpileTons = reader.int32();
                    break;
                case 10:
                    message.directLoadingRate = reader.float();
                    break;
                case 11:
                    message.stockpileRate = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopHatchEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchTons !== undefined && object.hatchTons !== null) {
            message.hatchTons = Number(object.hatchTons);
        }
        else {
            message.hatchTons = 0;
        }
        if (object.hatchRate !== undefined && object.hatchRate !== null) {
            message.hatchRate = Number(object.hatchRate);
        }
        else {
            message.hatchRate = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = String(object.oreType);
        }
        else {
            message.oreType = "";
        }
        if (object.directLoadingTons !== undefined && object.directLoadingTons !== null) {
            message.directLoadingTons = Number(object.directLoadingTons);
        }
        else {
            message.directLoadingTons = 0;
        }
        if (object.stockpileTons !== undefined && object.stockpileTons !== null) {
            message.stockpileTons = Number(object.stockpileTons);
        }
        else {
            message.stockpileTons = 0;
        }
        if (object.directLoadingRate !== undefined && object.directLoadingRate !== null) {
            message.directLoadingRate = Number(object.directLoadingRate);
        }
        else {
            message.directLoadingRate = 0;
        }
        if (object.stockpileRate !== undefined && object.stockpileRate !== null) {
            message.stockpileRate = Number(object.stockpileRate);
        }
        else {
            message.stockpileRate = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopHatchEditedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        if (object.hatchTons !== undefined && object.hatchTons !== null) {
            message.hatchTons = object.hatchTons;
        }
        else {
            message.hatchTons = 0;
        }
        if (object.hatchRate !== undefined && object.hatchRate !== null) {
            message.hatchRate = object.hatchRate;
        }
        else {
            message.hatchRate = 0;
        }
        if (object.oreType !== undefined && object.oreType !== null) {
            message.oreType = object.oreType;
        }
        else {
            message.oreType = "";
        }
        if (object.directLoadingTons !== undefined && object.directLoadingTons !== null) {
            message.directLoadingTons = object.directLoadingTons;
        }
        else {
            message.directLoadingTons = 0;
        }
        if (object.stockpileTons !== undefined && object.stockpileTons !== null) {
            message.stockpileTons = object.stockpileTons;
        }
        else {
            message.stockpileTons = 0;
        }
        if (object.directLoadingRate !== undefined && object.directLoadingRate !== null) {
            message.directLoadingRate = object.directLoadingRate;
        }
        else {
            message.directLoadingRate = 0;
        }
        if (object.stockpileRate !== undefined && object.stockpileRate !== null) {
            message.stockpileRate = object.stockpileRate;
        }
        else {
            message.stockpileRate = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.groupActivityId = message.groupActivityId || "";
        obj.vesselId = message.vesselId || "";
        obj.hatchTons = message.hatchTons || 0;
        obj.hatchRate = message.hatchRate || 0;
        obj.oreType = message.oreType || "";
        obj.directLoadingTons = message.directLoadingTons || 0;
        obj.stockpileTons = message.stockpileTons || 0;
        obj.directLoadingRate = message.directLoadingRate || 0;
        obj.stockpileRate = message.stockpileRate || 0;
        return obj;
    },
};
exports.StopHatchDeletedEvent = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.groupActivityId);
        writer.uint32(26).string(message.vesselId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseStopHatchDeletedEvent };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.groupActivityId = reader.string();
                    break;
                case 3:
                    message.vesselId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseStopHatchDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = String(object.groupActivityId);
        }
        else {
            message.groupActivityId = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = String(object.vesselId);
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseStopHatchDeletedEvent };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.groupActivityId !== undefined && object.groupActivityId !== null) {
            message.groupActivityId = object.groupActivityId;
        }
        else {
            message.groupActivityId = "";
        }
        if (object.vesselId !== undefined && object.vesselId !== null) {
            message.vesselId = object.vesselId;
        }
        else {
            message.vesselId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.groupActivityId = message.groupActivityId || "";
        obj.vesselId = message.vesselId || "";
        return obj;
    },
};
