import React from 'react';
import {NonHatchTnpaActivity as NHA, Shiploader} from '@deckmans/domain';
import {ActivityListItem} from './ActivityListItem';
import {useHistory} from 'react-router';

export interface NonHatchActivityProps {
  nonHatchTnpaActivity: NHA;
  vesselId: string;
  id?: string;
  shiploader: Shiploader;
  startDate?: Date;
  endDate?: Date;
}
export function NonHatchTnpaActivityComp({
  nonHatchTnpaActivity,
  vesselId,
  startDate,
  endDate,
  shiploader,
  id,
}: NonHatchActivityProps) {
  const {reason} = nonHatchTnpaActivity;

  const history = useHistory();

  return (
    <ActivityListItem
      color="info"
      title="TNPA"
      subtitle={reason}
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        history.push(
          `/activity/non-hatch-activity/edit/${shiploader}/${vesselId}/${id}`
        );
      }}
    />
  );
}
