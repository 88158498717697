"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MasterRequest = exports.Master = exports.ClientOreTypes = exports.ClientNameNoOreTypes = exports.ClientName = exports.EquipmentType = exports.DecisionAnswer = exports.Decision = exports.Option = exports.DecisionType = exports.MasterServiceClientImpl = void 0;
/* eslint-disable */
const minimal_1 = require("protobufjs/minimal");
const baseOption = {
    id: "",
    name: "",
    key: "",
    freeText: false,
    label: "",
    sequence: 0,
};
const baseDecision = {
    type: 0,
};
const baseDecisionAnswer = {
    type: 0,
    decisionKeys: "",
};
const baseEquipmentType = {
    type: "",
    names: "",
};
const baseClientName = {
    type: "",
};
const baseClientNameNoOreTypes = {
    id: "",
    description: "",
};
const baseClientOreTypes = {
    id: "",
    description: "",
};
const baseMaster = {
    routes: "",
    tipplers: "",
    stockpiles: "",
    trains: "",
    seed: "",
    vesselNames: "",
};
const baseMasterRequest = {};
class MasterServiceClientImpl {
    constructor(rpc) {
        this.rpc = rpc;
    }
    GetMaster(request) {
        const data = exports.MasterRequest.encode(request).finish();
        const promise = this.rpc.request("master.MasterService", "GetMaster", data);
        return promise.then(data => exports.Master.decode(new minimal_1.Reader(data)));
    }
}
exports.MasterServiceClientImpl = MasterServiceClientImpl;
exports.DecisionType = {
    DECISION_TYPE_UNSPECIFIED: 0,
    DECISION_TYPE_BREAKDOWN_ACTIVITY: 1,
    DECISION_TYPE_RECLAIM_ACTIVITY: 2,
    DECISION_TYPE_SINGLE_LOADING_ACTIVITY: 3,
    DECISION_TYPE_STOP_VESSEL_ACTIVITY: 4,
    DECISION_TYPE_VESSEL_DELAY_OPTIONS: 5,
    DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY: 6,
    UNRECOGNIZED: -1,
    fromJSON(object) {
        switch (object) {
            case 0:
            case "DECISION_TYPE_UNSPECIFIED":
                return exports.DecisionType.DECISION_TYPE_UNSPECIFIED;
            case 1:
            case "DECISION_TYPE_BREAKDOWN_ACTIVITY":
                return exports.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY;
            case 2:
            case "DECISION_TYPE_RECLAIM_ACTIVITY":
                return exports.DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY;
            case 3:
            case "DECISION_TYPE_SINGLE_LOADING_ACTIVITY":
                return exports.DecisionType.DECISION_TYPE_SINGLE_LOADING_ACTIVITY;
            case 4:
            case "DECISION_TYPE_STOP_VESSEL_ACTIVITY":
                return exports.DecisionType.DECISION_TYPE_STOP_VESSEL_ACTIVITY;
            case 5:
            case "DECISION_TYPE_VESSEL_DELAY_OPTIONS":
                return exports.DecisionType.DECISION_TYPE_VESSEL_DELAY_OPTIONS;
            case 6:
            case "DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY":
                return exports.DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY;
            case -1:
            case "UNRECOGNIZED":
            default:
                return exports.DecisionType.UNRECOGNIZED;
        }
    },
    toJSON(object) {
        switch (object) {
            case exports.DecisionType.DECISION_TYPE_UNSPECIFIED:
                return "DECISION_TYPE_UNSPECIFIED";
            case exports.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY:
                return "DECISION_TYPE_BREAKDOWN_ACTIVITY";
            case exports.DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY:
                return "DECISION_TYPE_RECLAIM_ACTIVITY";
            case exports.DecisionType.DECISION_TYPE_SINGLE_LOADING_ACTIVITY:
                return "DECISION_TYPE_SINGLE_LOADING_ACTIVITY";
            case exports.DecisionType.DECISION_TYPE_STOP_VESSEL_ACTIVITY:
                return "DECISION_TYPE_STOP_VESSEL_ACTIVITY";
            case exports.DecisionType.DECISION_TYPE_VESSEL_DELAY_OPTIONS:
                return "DECISION_TYPE_VESSEL_DELAY_OPTIONS";
            case exports.DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY:
                return "DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY";
            default:
                return "UNKNOWN";
        }
    },
};
exports.Option = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.name);
        writer.uint32(26).string(message.key);
        writer.uint32(32).bool(message.freeText);
        writer.uint32(42).string(message.label);
        for (const v of message.options) {
            exports.Option.encode(v, writer.uint32(50).fork()).ldelim();
        }
        writer.uint32(56).int32(message.sequence);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseOption };
        message.options = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.freeText = reader.bool();
                    break;
                case 5:
                    message.label = reader.string();
                    break;
                case 6:
                    message.options.push(exports.Option.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.sequence = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseOption };
        message.options = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.key !== undefined && object.key !== null) {
            message.key = String(object.key);
        }
        else {
            message.key = "";
        }
        if (object.freeText !== undefined && object.freeText !== null) {
            message.freeText = Boolean(object.freeText);
        }
        else {
            message.freeText = false;
        }
        if (object.label !== undefined && object.label !== null) {
            message.label = String(object.label);
        }
        else {
            message.label = "";
        }
        if (object.options !== undefined && object.options !== null) {
            for (const e of object.options) {
                message.options.push(exports.Option.fromJSON(e));
            }
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = Number(object.sequence);
        }
        else {
            message.sequence = 0;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseOption };
        message.options = [];
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.key !== undefined && object.key !== null) {
            message.key = object.key;
        }
        else {
            message.key = "";
        }
        if (object.freeText !== undefined && object.freeText !== null) {
            message.freeText = object.freeText;
        }
        else {
            message.freeText = false;
        }
        if (object.label !== undefined && object.label !== null) {
            message.label = object.label;
        }
        else {
            message.label = "";
        }
        if (object.options !== undefined && object.options !== null) {
            for (const e of object.options) {
                message.options.push(exports.Option.fromPartial(e));
            }
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = object.sequence;
        }
        else {
            message.sequence = 0;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.name = message.name || "";
        obj.key = message.key || "";
        obj.freeText = message.freeText || false;
        obj.label = message.label || "";
        if (message.options) {
            obj.options = message.options.map(e => e ? exports.Option.toJSON(e) : undefined);
        }
        else {
            obj.options = [];
        }
        obj.sequence = message.sequence || 0;
        return obj;
    },
};
exports.Decision = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).int32(message.type);
        for (const v of message.options) {
            exports.Option.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseDecision };
        message.options = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.options.push(exports.Option.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseDecision };
        message.options = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = exports.DecisionType.fromJSON(object.type);
        }
        else {
            message.type = 0;
        }
        if (object.options !== undefined && object.options !== null) {
            for (const e of object.options) {
                message.options.push(exports.Option.fromJSON(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseDecision };
        message.options = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = object.type;
        }
        else {
            message.type = 0;
        }
        if (object.options !== undefined && object.options !== null) {
            for (const e of object.options) {
                message.options.push(exports.Option.fromPartial(e));
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.type = exports.DecisionType.toJSON(message.type);
        if (message.options) {
            obj.options = message.options.map(e => e ? exports.Option.toJSON(e) : undefined);
        }
        else {
            obj.options = [];
        }
        return obj;
    },
};
exports.DecisionAnswer = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).int32(message.type);
        for (const v of message.decisionKeys) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseDecisionAnswer };
        message.decisionKeys = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.decisionKeys.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseDecisionAnswer };
        message.decisionKeys = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = exports.DecisionType.fromJSON(object.type);
        }
        else {
            message.type = 0;
        }
        if (object.decisionKeys !== undefined && object.decisionKeys !== null) {
            for (const e of object.decisionKeys) {
                message.decisionKeys.push(String(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseDecisionAnswer };
        message.decisionKeys = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = object.type;
        }
        else {
            message.type = 0;
        }
        if (object.decisionKeys !== undefined && object.decisionKeys !== null) {
            for (const e of object.decisionKeys) {
                message.decisionKeys.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.type = exports.DecisionType.toJSON(message.type);
        if (message.decisionKeys) {
            obj.decisionKeys = message.decisionKeys.map(e => e || "");
        }
        else {
            obj.decisionKeys = [];
        }
        return obj;
    },
};
exports.EquipmentType = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.type);
        for (const v of message.names) {
            writer.uint32(18).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseEquipmentType };
        message.names = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.names.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseEquipmentType };
        message.names = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = String(object.type);
        }
        else {
            message.type = "";
        }
        if (object.names !== undefined && object.names !== null) {
            for (const e of object.names) {
                message.names.push(String(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseEquipmentType };
        message.names = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = object.type;
        }
        else {
            message.type = "";
        }
        if (object.names !== undefined && object.names !== null) {
            for (const e of object.names) {
                message.names.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.type = message.type || "";
        if (message.names) {
            obj.names = message.names.map(e => e || "");
        }
        else {
            obj.names = [];
        }
        return obj;
    },
};
exports.ClientName = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.type);
        for (const v of message.oreTypes) {
            exports.ClientOreTypes.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseClientName };
        message.oreTypes = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.oreTypes.push(exports.ClientOreTypes.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseClientName };
        message.oreTypes = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = String(object.type);
        }
        else {
            message.type = "";
        }
        if (object.oreTypes !== undefined && object.oreTypes !== null) {
            for (const e of object.oreTypes) {
                message.oreTypes.push(exports.ClientOreTypes.fromJSON(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseClientName };
        message.oreTypes = [];
        if (object.type !== undefined && object.type !== null) {
            message.type = object.type;
        }
        else {
            message.type = "";
        }
        if (object.oreTypes !== undefined && object.oreTypes !== null) {
            for (const e of object.oreTypes) {
                message.oreTypes.push(exports.ClientOreTypes.fromPartial(e));
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.type = message.type || "";
        if (message.oreTypes) {
            obj.oreTypes = message.oreTypes.map(e => e ? exports.ClientOreTypes.toJSON(e) : undefined);
        }
        else {
            obj.oreTypes = [];
        }
        return obj;
    },
};
exports.ClientNameNoOreTypes = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.description);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseClientNameNoOreTypes };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseClientNameNoOreTypes };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.description !== undefined && object.description !== null) {
            message.description = String(object.description);
        }
        else {
            message.description = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseClientNameNoOreTypes };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.description !== undefined && object.description !== null) {
            message.description = object.description;
        }
        else {
            message.description = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.description = message.description || "";
        return obj;
    },
};
exports.ClientOreTypes = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.description);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseClientOreTypes };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseClientOreTypes };
        if (object.id !== undefined && object.id !== null) {
            message.id = String(object.id);
        }
        else {
            message.id = "";
        }
        if (object.description !== undefined && object.description !== null) {
            message.description = String(object.description);
        }
        else {
            message.description = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseClientOreTypes };
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = "";
        }
        if (object.description !== undefined && object.description !== null) {
            message.description = object.description;
        }
        else {
            message.description = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.id = message.id || "";
        obj.description = message.description || "";
        return obj;
    },
};
exports.Master = {
    encode(message, writer = minimal_1.Writer.create()) {
        for (const v of message.routes) {
            writer.uint32(10).string(v);
        }
        for (const v of message.tipplers) {
            writer.uint32(18).string(v);
        }
        for (const v of message.decisions) {
            exports.Decision.encode(v, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.equipmentTypes) {
            exports.EquipmentType.encode(v, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.stockpiles) {
            writer.uint32(42).string(v);
        }
        for (const v of message.trains) {
            writer.uint32(50).string(v);
        }
        for (const v of message.clients) {
            exports.ClientName.encode(v, writer.uint32(58).fork()).ldelim();
        }
        for (const v of message.oreTypes) {
            exports.ClientOreTypes.encode(v, writer.uint32(66).fork()).ldelim();
        }
        for (const v of message.clientNoOreType) {
            exports.ClientNameNoOreTypes.encode(v, writer.uint32(74).fork()).ldelim();
        }
        writer.uint32(82).string(message.seed);
        for (const v of message.vesselNames) {
            writer.uint32(90).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMaster };
        message.routes = [];
        message.tipplers = [];
        message.decisions = [];
        message.equipmentTypes = [];
        message.stockpiles = [];
        message.trains = [];
        message.clients = [];
        message.oreTypes = [];
        message.clientNoOreType = [];
        message.vesselNames = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.routes.push(reader.string());
                    break;
                case 2:
                    message.tipplers.push(reader.string());
                    break;
                case 3:
                    message.decisions.push(exports.Decision.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.equipmentTypes.push(exports.EquipmentType.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.stockpiles.push(reader.string());
                    break;
                case 6:
                    message.trains.push(reader.string());
                    break;
                case 7:
                    message.clients.push(exports.ClientName.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.oreTypes.push(exports.ClientOreTypes.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.clientNoOreType.push(exports.ClientNameNoOreTypes.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.seed = reader.string();
                    break;
                case 11:
                    message.vesselNames.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseMaster };
        message.routes = [];
        message.tipplers = [];
        message.decisions = [];
        message.equipmentTypes = [];
        message.stockpiles = [];
        message.trains = [];
        message.clients = [];
        message.oreTypes = [];
        message.clientNoOreType = [];
        message.vesselNames = [];
        if (object.routes !== undefined && object.routes !== null) {
            for (const e of object.routes) {
                message.routes.push(String(e));
            }
        }
        if (object.tipplers !== undefined && object.tipplers !== null) {
            for (const e of object.tipplers) {
                message.tipplers.push(String(e));
            }
        }
        if (object.decisions !== undefined && object.decisions !== null) {
            for (const e of object.decisions) {
                message.decisions.push(exports.Decision.fromJSON(e));
            }
        }
        if (object.equipmentTypes !== undefined && object.equipmentTypes !== null) {
            for (const e of object.equipmentTypes) {
                message.equipmentTypes.push(exports.EquipmentType.fromJSON(e));
            }
        }
        if (object.stockpiles !== undefined && object.stockpiles !== null) {
            for (const e of object.stockpiles) {
                message.stockpiles.push(String(e));
            }
        }
        if (object.trains !== undefined && object.trains !== null) {
            for (const e of object.trains) {
                message.trains.push(String(e));
            }
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.ClientName.fromJSON(e));
            }
        }
        if (object.oreTypes !== undefined && object.oreTypes !== null) {
            for (const e of object.oreTypes) {
                message.oreTypes.push(exports.ClientOreTypes.fromJSON(e));
            }
        }
        if (object.clientNoOreType !== undefined && object.clientNoOreType !== null) {
            for (const e of object.clientNoOreType) {
                message.clientNoOreType.push(exports.ClientNameNoOreTypes.fromJSON(e));
            }
        }
        if (object.seed !== undefined && object.seed !== null) {
            message.seed = String(object.seed);
        }
        else {
            message.seed = "";
        }
        if (object.vesselNames !== undefined && object.vesselNames !== null) {
            for (const e of object.vesselNames) {
                message.vesselNames.push(String(e));
            }
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseMaster };
        message.routes = [];
        message.tipplers = [];
        message.decisions = [];
        message.equipmentTypes = [];
        message.stockpiles = [];
        message.trains = [];
        message.clients = [];
        message.oreTypes = [];
        message.clientNoOreType = [];
        message.vesselNames = [];
        if (object.routes !== undefined && object.routes !== null) {
            for (const e of object.routes) {
                message.routes.push(e);
            }
        }
        if (object.tipplers !== undefined && object.tipplers !== null) {
            for (const e of object.tipplers) {
                message.tipplers.push(e);
            }
        }
        if (object.decisions !== undefined && object.decisions !== null) {
            for (const e of object.decisions) {
                message.decisions.push(exports.Decision.fromPartial(e));
            }
        }
        if (object.equipmentTypes !== undefined && object.equipmentTypes !== null) {
            for (const e of object.equipmentTypes) {
                message.equipmentTypes.push(exports.EquipmentType.fromPartial(e));
            }
        }
        if (object.stockpiles !== undefined && object.stockpiles !== null) {
            for (const e of object.stockpiles) {
                message.stockpiles.push(e);
            }
        }
        if (object.trains !== undefined && object.trains !== null) {
            for (const e of object.trains) {
                message.trains.push(e);
            }
        }
        if (object.clients !== undefined && object.clients !== null) {
            for (const e of object.clients) {
                message.clients.push(exports.ClientName.fromPartial(e));
            }
        }
        if (object.oreTypes !== undefined && object.oreTypes !== null) {
            for (const e of object.oreTypes) {
                message.oreTypes.push(exports.ClientOreTypes.fromPartial(e));
            }
        }
        if (object.clientNoOreType !== undefined && object.clientNoOreType !== null) {
            for (const e of object.clientNoOreType) {
                message.clientNoOreType.push(exports.ClientNameNoOreTypes.fromPartial(e));
            }
        }
        if (object.seed !== undefined && object.seed !== null) {
            message.seed = object.seed;
        }
        else {
            message.seed = "";
        }
        if (object.vesselNames !== undefined && object.vesselNames !== null) {
            for (const e of object.vesselNames) {
                message.vesselNames.push(e);
            }
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        if (message.routes) {
            obj.routes = message.routes.map(e => e || "");
        }
        else {
            obj.routes = [];
        }
        if (message.tipplers) {
            obj.tipplers = message.tipplers.map(e => e || "");
        }
        else {
            obj.tipplers = [];
        }
        if (message.decisions) {
            obj.decisions = message.decisions.map(e => e ? exports.Decision.toJSON(e) : undefined);
        }
        else {
            obj.decisions = [];
        }
        if (message.equipmentTypes) {
            obj.equipmentTypes = message.equipmentTypes.map(e => e ? exports.EquipmentType.toJSON(e) : undefined);
        }
        else {
            obj.equipmentTypes = [];
        }
        if (message.stockpiles) {
            obj.stockpiles = message.stockpiles.map(e => e || "");
        }
        else {
            obj.stockpiles = [];
        }
        if (message.trains) {
            obj.trains = message.trains.map(e => e || "");
        }
        else {
            obj.trains = [];
        }
        if (message.clients) {
            obj.clients = message.clients.map(e => e ? exports.ClientName.toJSON(e) : undefined);
        }
        else {
            obj.clients = [];
        }
        if (message.oreTypes) {
            obj.oreTypes = message.oreTypes.map(e => e ? exports.ClientOreTypes.toJSON(e) : undefined);
        }
        else {
            obj.oreTypes = [];
        }
        if (message.clientNoOreType) {
            obj.clientNoOreType = message.clientNoOreType.map(e => e ? exports.ClientNameNoOreTypes.toJSON(e) : undefined);
        }
        else {
            obj.clientNoOreType = [];
        }
        obj.seed = message.seed || "";
        if (message.vesselNames) {
            obj.vesselNames = message.vesselNames.map(e => e || "");
        }
        else {
            obj.vesselNames = [];
        }
        return obj;
    },
};
exports.MasterRequest = {
    encode(_, writer = minimal_1.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMasterRequest };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        const message = { ...baseMasterRequest };
        return message;
    },
    fromPartial(_) {
        const message = { ...baseMasterRequest };
        return message;
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
};
