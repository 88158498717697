import * as React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import {useEngineContext} from 'contexts/EngineContext';
import {LogbookChip} from './components/LogbookChip';
import {useHistory} from 'react-router';
import {VesselState} from '@deckmans/domain/lib/proto/model';
import moment from 'moment';
import {FinishVesselModal} from 'modules/VesselView/components/FinishVesselModal';
import {useTitle} from 'contexts/TitleContext';
import {DeleteVesselButton} from './components/DeleteVesselButton';
import {useAuthContext} from 'contexts/AuthContext';
import {useAlertContext} from 'contexts/AlertContex';
import {checkIfUnallocatedTime} from 'helpers/unAllocatedTimeHelper';
import {UserRole} from '@deckmans/domain';
import {useVessels} from 'hooks/useVessel';
import IconButton from '@material-ui/core/IconButton';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {useSearchContext} from 'contexts/SearchContext';
import {CheckIfHatchStopped} from '../VesselView/components/ActivityButton';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
  },
  addIcon: {
    paddingRight: theme.spacing(1),
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  vn: {
    paddingLeft: theme.spacing(1),
  },
}));

export function Logbook() {
  useTitle('Deckmans');
  const history = useHistory();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState('');
  const {engine, eventCreator, synching} = useEngineContext();
  const {hasRole, auth} = useAuthContext();
  const {alert} = useAlertContext();
  const {setEndDate} = useSearchContext();

  React.useEffect(() => {
    setEndDate(moment().add(2, 'weeks').toDate());
  }, [setEndDate]);
  const clientNames = auth.authenticated ? auth.clients : [];

  // const vessels = useVessels()
  //   .map((item) => ({
  //     ...item,
  //     clients: item.clients.filter((child) => clientNames.includes(child.name)),
  //   }))
  //   .filter((item) => item.clients.length > 0);

  const vessels = useVessels().filter((item) =>
    item.clients.every((element) => {
      return hasRole(UserRole.USER_ROLE_AGENT)
        ? clientNames.indexOf(element.name) !== -1
        : item;
    })
  );

  const loggedInUserInProgressVessels = useVessels(true).filter((v) => {
    return v.state === VesselState.VESSEL_STATE_IN_PROGRESS;
  });

  return (
    <div className={classes.container}>
      <Box pb={2}>
        <Fab
          disabled={hasRole(UserRole.USER_ROLE_AGENT)}
          variant="extended"
          size="large"
          color="primary"
          onClick={() => {
            if (
              loggedInUserInProgressVessels.length > 1 &&
              !hasRole(UserRole.USER_ROLE_ADMIN)
            ) {
              alert(
                'Your user Role does not permit you to have more than two active vessel at a time',
                'warning'
              );
            } else {
              history.push('/vessel/new');
            }
          }}
        >
          <DirectionsBoatIcon className={classes.addIcon} /> Add new vessel
        </Fab>
      </Box>
      {synching ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Paper className={classes.paper} elevation={8}>
          <Box pl={1} pt={1}>
            <Typography variant="h4" color="primary">
              Vessel Logbook
            </Typography>
          </Box>
          <List>
            {vessels.reverse().map((vessel) => {
              const {id, reclaimStart, state, name} = vessel;

              const unallocatedTime = checkIfUnallocatedTime(vessel);
              const vesselActivities = Object.values(vessel.activities).map(
                (activity) => {
                  return activity;
                }
              );
              const hatchCompleted =
                CheckIfHatchStopped(vessel, 1) &&
                CheckIfHatchStopped(vessel, 2);

              return (
                <React.Fragment key={id}>
                  <ListItem onClick={() => history.push('/vessel/view/' + id)}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Box pb={1}>
                          <Typography
                            variant="button"
                            color="textSecondary"
                            gutterBottom
                          >
                            {moment(reclaimStart).format('DD MMMM YYYY HH:mm')}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box display="flex">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push(`/vesselOverview/${id}`);
                            }}
                          >
                            <EventNoteIcon />
                          </IconButton>
                          <LogbookChip
                            color={
                              state !== VesselState.VESSEL_STATE_COMPLETE
                                ? 'secondary'
                                : 'primary'
                            }
                            label={
                              state === VesselState.VESSEL_STATE_COMPLETE
                                ? 'Complete'
                                : 'In Progress'
                            }
                          />
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            className={classes.vn}
                          >
                            {name}
                          </Typography>
                          <Box p={1} />
                        </Box>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Button
                        size="small"
                        variant="text"
                        onClick={() => history.push(`/vessel/edit/${id}`)}
                      >
                        {hasRole(UserRole.USER_ROLE_SUPERVISOR) ||
                        hasRole(UserRole.USER_ROLE_ADMIN)
                          ? 'edit'
                          : 'view'}
                      </Button>

                      <Button
                        disabled={
                          state === VesselState.VESSEL_STATE_COMPLETE ||
                          unallocatedTime ||
                          vesselActivities.length === 0 ||
                          !hatchCompleted ||
                          hasRole(UserRole.USER_ROLE_AGENT)
                        }
                        size="small"
                        onClick={() => setModalOpen(id)}
                      >
                        submit
                      </Button>
                      <DeleteVesselButton vessel={vessel} />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <FinishVesselModal
                    open={modalOpen === id}
                    setOpen={(open) => {
                      if (open) {
                        setModalOpen(id);
                      } else {
                        setModalOpen('');
                      }
                    }}
                    handleAccept={async () => {
                      //TODO this will change
                      //this is just for demo purpose
                      const event = eventCreator.vesselEdited({
                        ...vessel,
                        reclaimStart: vessel.reclaimStart ?? new Date(),
                        id,
                        state: VesselState.VESSEL_STATE_COMPLETE,
                      });
                      await engine.process(event);
                      setModalOpen('');
                    }}
                  />
                </React.Fragment>
              );
            })}
          </List>
        </Paper>
      )}
    </div>
  );
}
