"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapReason = exports.print = void 0;
const master_1 = require("../proto/master");
function print(answer, decisions) {
    if (!answer) {
        return ['Unknown'];
    }
    const decision = decisions.find((d) => d.type === answer.type);
    if (!decision) {
        // Could not find decision, render keys
        return answer.decisionKeys;
    }
    const result = [];
    let options = decision.options;
    outer: for (const key of answer.decisionKeys) {
        for (const opt of options) {
            if (opt.key === key) {
                options = opt.options;
                result.push(opt.name);
                if (!options || options.length === 0) {
                    break outer;
                }
            }
        }
    }
    return result;
}
exports.print = print;
function getLabels(answer, decisions) {
    if (!answer) {
        return ['Unknown'];
    }
    const decision = decisions.find((d) => d.type === answer.type);
    if (!decision) {
        // Could not find decision, render keys
        return answer.decisionKeys;
    }
    const result = [];
    let options = decision.options;
    outer: for (const key of answer.decisionKeys) {
        for (const opt of options) {
            if (opt.key === key) {
                options = opt.options;
                result.push(opt.label);
                if (!options || options.length === 0) {
                    break outer;
                }
            }
        }
    }
    return result;
}
function mapReason(answer, decisions, activity = '') {
    const titles = print(answer, decisions);
    for (let i = 0; i < 5; i++) {
        if (titles.length <= 4) {
            titles.push('');
        }
    }
    const returnObject = {
        activity,
        reason1: '',
        reason2: '',
        reason3: '',
        reason4: '',
        equipmentType: '',
    };
    if (!activity) {
        returnObject.activity = titles[0];
        returnObject.reason1 = titles[1];
        returnObject.reason2 = titles[2];
        returnObject.reason3 = titles[3];
        returnObject.reason4 = titles[4];
    }
    else {
        returnObject.activity = activity;
        returnObject.reason1 = titles[0];
        returnObject.reason2 = titles[1];
        returnObject.reason3 = titles[2];
        returnObject.reason4 = titles[3];
    }
    if ((answer === null || answer === void 0 ? void 0 : answer.type) === master_1.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY) {
        const labels = getLabels(answer, decisions);
        const equipmentTypeIndex = labels.indexOf('Equipment Type');
        if (equipmentTypeIndex !== -1) {
            returnObject.equipmentType = titles[equipmentTypeIndex];
        }
        if (returnObject.equipmentType === '') {
            returnObject.equipmentType = titles[0];
        }
    }
    return returnObject;
}
exports.mapReason = mapReason;
