import React from 'react';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {useField, FieldHookConfig} from 'formik';
import {CO} from './types';

export type SelectType = string | number | undefined;
interface Props {
  field: string | FieldHookConfig<string>;
  data?: CO<SelectType>[]; //TODO narrow types
  addNone?: boolean;
  onChangeAdditional?: (
    value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  //
}
//eslint-disable-next-line
export function SingleSelectField({
  addNone = false,
  onChangeAdditional,
  ...props
}: Props & TextFieldProps) {
  const [{onChange, ...field}, meta, helper] = useField(props.field);

  return (
    <TextField
      {...props}
      {...field}
      SelectProps={{
        ...props.SelectProps,
        native: true,
      }}
      onChange={(e) => {
        helper.setValue(e.target.value);
        onChangeAdditional && onChangeAdditional(e);
      }}
      error={Boolean(meta.touched && meta.error)}
      select
      helperText={meta.touched && meta.error ? meta.error : ''}
    >
      {/* //TODO handle add this if asked for */}
      {addNone && (
        <option
          key={'None_key_0_' + field.name}
          value={typeof field.value === 'string' ? '' : -1}
        >
          None
        </option>
      )}
      {props.data?.map(({id, description}, idx) => {
        return (
          <option key={`${description}_${idx}`} value={id as SelectType}>
            {description}
          </option>
        );
      })}
    </TextField>
  );
}
