"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleContextController = exports.useTitle = exports.useTitleContext = exports.TitleContext = void 0;
const React = __importStar(require("react"));
exports.TitleContext = React.createContext({
    title: 'Deckmans',
    //eslint-disable-next-line
    setTitle: () => { },
});
exports.useTitleContext = () => React.useContext(exports.TitleContext);
function useTitle(newTitle) {
    const { setTitle } = exports.useTitleContext();
    React.useEffect(() => {
        setTitle(newTitle);
    }, [newTitle, setTitle]);
}
exports.useTitle = useTitle;
function TitleContextController({ children }) {
    const [title, setTitle] = React.useState('Deckmans');
    const value = React.useMemo(() => ({
        title,
        setTitle,
    }), [title, setTitle]);
    return (React.createElement(exports.TitleContext.Provider, { value: value }, children));
}
exports.TitleContextController = TitleContextController;
