import * as React from 'react';
import {Box, Divider, Grid, Paper} from '@material-ui/core';
import {Field} from 'formik';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {TextField} from 'formik-material-ui';
import {ButtonBar} from 'components/ButtonBar';
import {useHistory, useParams} from 'react-router';
import {BaseForm} from 'components/BaseForm';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {StartEndDateTime} from '@deckmans/web-shared';
import * as yup from 'yup';
import {DecisionAnswer, DecisionType, Shiploader} from '@deckmans/domain';
import {useVessel, useVesselActivity} from 'hooks/useVessel';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {useTitle} from 'contexts/TitleContext';

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY,
});

interface HatchAwaitingShiploaderActivityType {
  startTime: string;
  endTime: string;
  comments: string;
  id: string;
  reason: DecisionAnswer;
}

export function HatchAwaitingShiploaderActivity() {
  const history = useHistory();
  useTitle('Awaiting shiploader');

  const {engine, eventCreator} = useEngineContext();

  const {hatchId, activityId} = useParams<{
    hatchId: string;
    activityId: string;
  }>();

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const vessel = useVessel();
  const reclaimStart = vessel?.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    reason: yup.string().required('This field is required'),
  });

  const parentActivity = useVesselActivity(hatchId);

  const shiploader = React.useMemo(() => {
    return parentActivity ? parentActivity.shiploader : Shiploader.UNRECOGNIZED;
  }, [parentActivity]);

  const activity = React.useMemo(() => {
    if (activityId) {
      return parentActivity?.vesselHatchActivityGroup?.activities[activityId];
    }
  }, [activityId, parentActivity]);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);

  const sd = pvEndDate !== '' ? pvEndDate : vessel?.reclaimStart ?? new Date();
  const {startDate, endDate} = useHistoryStateDateRange(sd);

  const initialValues = React.useMemo(() => {
    if (isEditing && activity) {
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments: activity?.hatchAwaitingShiploaderActivity?.comments ?? '',
        id: activity.id,
        shiploader,
        reason:
          activity.hatchAwaitingShiploaderActivity?.reason ?? defaultReason,
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        comments: '',
        id: '',
        shiploader,
        reason: defaultReason,
      };
    }
  }, [isEditing, activity, shiploader, startDate, endDate]);
  const hatchFinished = !!vessel.activities[hatchId].vesselHatchActivityGroup
    ?.completed;
  return (
    <BaseForm<HatchAwaitingShiploaderActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={initialValues}
      onCreateSubmit={async ({startTime, endTime, comments, reason}) => {
        const event = eventCreator.hatchAwaitingShiploaderActivityCreated({
          groupActivityId: hatchId,
          comments,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          vesselId: vessel.id,
        });

        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({startTime, endTime, comments, id, reason}) => {
        const event = eventCreator.hatchAwaitingShiploaderActivityEdited({
          id,
          groupActivityId: hatchId,
          comments,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          vesselId: vessel.id,
        });
        await engine.process(event);
        history.goBack();
      }}
    >
      {({values, submitForm}) => {
        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime />

                  <Grid item xs={6}>
                    <Box px={1}>
                      <Duration
                        startTime={values.startTime}
                        endTime={values.endTime}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <MultiLevelQuestions
                      name="reason"
                      decisionType={
                        DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box px={1}>
                      <Field
                        fullWidth
                        multiline
                        component={TextField}
                        name="comments"
                        label="Comments"
                        placeholder="Comments"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      saveText="save"
                      createText="create"
                      isEditing={isEditing}
                      handleSave={submitForm}
                      vessel={vessel}
                      activityId={activityId}
                      title="Delete Awaiting Shiploader Activity"
                      subtitle="Are you sure you want to delete this activity?"
                      hatchFinished={hatchFinished}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </BaseForm>
  );
}
