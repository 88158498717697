import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Cell} from 'components/Table';
import {makeStyles, Theme} from '@material-ui/core';

import ActivityButton from './ActivityButton';
import {Vessel} from '@deckmans/domain';

interface Props {
  vessel: Vessel;
}
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    position: 'sticky',
    //top: '10.5em',
    top: '168px',
    zIndex: 100,
  },
  stickyButton: {
    position: 'sticky',
    top: '5em',
    zIndex: 100,
  },
}));

export function TableHeader({vessel}: Props) {
  const classes = useStyles();

  return (
    <>
      <Cell className={classes.title}>
        <Typography variant="h5" color="primary">
          Shiploader 1
        </Typography>
        <ActivityButton shiploader={1} vessel={vessel} />
      </Cell>
      <Cell className={classes.title}>
        <Typography variant="h5" color="primary">
          Shiploader 2
        </Typography>
        <ActivityButton shiploader={2} vessel={vessel} />
      </Cell>
    </>
  );
}
