"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShiploader = exports.getSurveyType = exports.surveyTypes = exports.hatches = exports.getOreType = exports.OreTypes = exports.getClient = exports.clientsWithNon = exports.clients = exports.getUserRole = exports.userRoles = void 0;
const shared_1 = require("../proto/shared");
exports.userRoles = [
    { id: shared_1.UserRole.USER_ROLE_ADMIN, description: 'ADMIN' },
    { id: shared_1.UserRole.USER_ROLE_SUPERVISOR, description: 'SUPERVISOR' },
    { id: shared_1.UserRole.USER_ROLE_USER, description: 'USER' },
    { id: shared_1.UserRole.USER_ROLE_AGENT, description: 'READ_ONLY' },
];
function getUserRole(ur) {
    let userRole = {
        id: shared_1.UserRole.UNRECOGNIZED,
        description: 'unknown',
    };
    if (typeof ur === 'string') {
        const newRole = exports.userRoles.find(({ description }) => {
            return description === ur;
        });
        if (newRole) {
            userRole = newRole;
        }
    }
    else {
        const newRole = exports.userRoles.find(({ id }) => {
            return id === ur;
        });
        if (newRole) {
            userRole = newRole;
        }
    }
    if (userRole) {
        return userRole;
    }
    else {
        throw new Error(`UserRole: ${ur} not found`);
    }
}
exports.getUserRole = getUserRole;
exports.clients = [
    { id: shared_1.Client.CLIENT_ASSMANG, description: 'Assmang' },
    { id: shared_1.Client.CLIENT_KUMBA, description: 'Kumba' },
    { id: shared_1.Client.CLIENT_SEDIBENG, description: 'Sedibeng' },
];
exports.clientsWithNon = [
    { id: shared_1.Client.CLIENT_UNSPECIFIED, description: 'None' },
    ...exports.clients,
];
function getClient(clientId) {
    const client = exports.clients.find(({ id }) => {
        return id === clientId;
    });
    if (client) {
        return client;
    }
    else {
        throw new Error(`Client: ${clientId} not found`);
    }
}
exports.getClient = getClient;
exports.OreTypes = [
    {
        id: shared_1.OreType.ORE_TYPE_UNSPECIFIED,
        description: 'None',
        client: shared_1.Client.CLIENT_UNSPECIFIED,
    },
    { id: shared_1.OreType.ORE_TYPE_LB, description: 'LB', client: shared_1.Client.CLIENT_KUMBA },
    { id: shared_1.OreType.ORE_TYPE_FB, description: 'FB', client: shared_1.Client.CLIENT_KUMBA },
    { id: shared_1.OreType.ORE_TYPE_DA, description: 'DA', client: shared_1.Client.CLIENT_KUMBA },
    { id: shared_1.OreType.ORE_TYPE_LS, description: 'LS', client: shared_1.Client.CLIENT_KUMBA },
    { id: shared_1.OreType.ORE_TYPE_FA, description: 'FA', client: shared_1.Client.CLIENT_KUMBA },
    { id: shared_1.OreType.ORE_TYPE_AL, description: 'AL', client: shared_1.Client.CLIENT_ASSMANG },
    { id: shared_1.OreType.ORE_TYPE_AF, description: 'AF', client: shared_1.Client.CLIENT_ASSMANG },
    {
        id: shared_1.OreType.ORE_TYPE_SBL,
        description: 'SBL',
        client: shared_1.Client.CLIENT_SEDIBENG,
    },
    {
        id: shared_1.OreType.ORE_TYPE_SBF,
        description: 'SBF',
        client: shared_1.Client.CLIENT_SEDIBENG,
    },
];
function getOreType(oreTypeId) {
    const oreType = exports.OreTypes.find(({ id }) => {
        return id === oreTypeId;
    });
    if (oreType) {
        return oreType;
    }
    else {
        throw new Error(`OreType: ${oreType} not found`);
    }
}
exports.getOreType = getOreType;
exports.hatches = [
    { id: '1', description: '1' },
    { id: '2', description: '2' },
    { id: '3', description: '3' },
    { id: '4', description: '4' },
    { id: '5', description: '5' },
    { id: '6', description: '6' },
    { id: '7', description: '7' },
    { id: '8', description: '8' },
    { id: '9', description: '9' },
    { id: '10', description: '10' },
];
// export function getHatch(hatchId: Hatch) {
//     const hatch = hatches.find(({id}) => {
//      return id === hatchId;
//     });
//     if (hatch) {
//       return hatch;
//     } else {
//       return {id: Hatch.UNRECOGNIZED, description: 'Unknown'};
//     }
//   }
exports.surveyTypes = [
    { id: shared_1.SurveyType.SURVEY_TYPE_UNSPECIFIED, description: 'None' },
    { id: shared_1.SurveyType.SURVEY_TYPE_ADD_ON_SURVEY, description: 'Add on Survey' },
    { id: shared_1.SurveyType.SURVEY_TYPE_DRAFT_CHECK, description: 'Draft Check' },
    { id: shared_1.SurveyType.SURVEY_TYPE_DRAFT_SURVEY, description: 'Draft Survey' },
    {
        id: shared_1.SurveyType.SURVEY_TYPE_INTERMEDIATE_SURVEY,
        description: 'Intermediate Survey',
    },
];
function getSurveyType(surveyId) {
    const surveyType = exports.surveyTypes.find(({ id }) => {
        return id === surveyId;
    });
    if (surveyType) {
        return surveyType;
    }
    else {
        throw new Error(`Survey Type: ${surveyId} not found`);
    }
}
exports.getSurveyType = getSurveyType;
function getShiploader(shiploader) {
    switch (shiploader) {
        case 0:
            return shared_1.Shiploader.SHIPLOADER_BOTH;
        case 1:
            return shared_1.Shiploader.SHIPLOADER_ONE;
        case 2:
            return shared_1.Shiploader.SHIPLOADER_TWO;
        default:
            throw new Error(`Invalid Shiploader: ${shiploader}.`);
    }
}
exports.getShiploader = getShiploader;
