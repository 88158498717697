import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface AlertContextType {
  alert: (message: string, severity: AlertProps['severity']) => void;
}

const AlertContext = React.createContext<AlertContextType>({
  alert: () => {},
});

export const useAlertContext = () =>
  React.useContext<AlertContextType>(AlertContext);
interface Props {
  children: React.ReactNode;
}

export function AlertContextProvider({children}: Props) {
  const [state, setState] = React.useState<{
    open: boolean;
    severity: AlertProps['severity'];
    message: string;
  }>({open: false, severity: 'success', message: 'default message'});

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({open: false, message: '', severity: 'info'});
  };
  const alert = React.useCallback(
    (message: string, severity: AlertProps['severity']) => {
      setState({open: true, message, severity: severity});
    },
    []
  );
  const value = React.useMemo(() => ({alert}), [alert]);
  return (
    <AlertContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={state.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
}
