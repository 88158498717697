import React from 'react';
import {useField, FieldHookConfig} from 'formik';
import {GroupCircleSelect} from './GroupCircleSelect';
import {CO} from './types';
import {Typography} from '@material-ui/core';

interface Props<Val> {
  field: string | FieldHookConfig<Val>; ///aka field name
  data: CO<Val>[];
}

export function GroupCircleSelectField<Val = string>({
  field,
  data,
}: Props<Val>) {
  const [f, form, meta] = useField(field);
  return (
    <>
      <GroupCircleSelect<Val>
        data={data}
        value={f.value}
        handleChange={(_e: React.MouseEvent<HTMLElement>, newVal: Val) =>
          meta.setValue(newVal)
        }
      />
      {form.error && form.touched && (
        <Typography color="error">{form.error}</Typography>
      )}
    </>
  );
}
