import * as React from 'react';
import moment from 'moment';
import {generator} from '@deckmans/domain/lib/data/data';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import {useIndexedDbContext} from 'contexts/IndexedDbContext';
import BuildIcon from '@material-ui/icons/Build';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FastForward from '@material-ui/icons/FastForward';
import {ConfigService} from '@stackworx/react';
import {EngineContext} from 'contexts/EngineContext';
import DeleteDialog from 'components/DeleteDialog';
import {UserRole} from '@deckmans/domain';
import {useClearSiteData} from 'hooks/useClearSiteData';
// import {clear} from './clear';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const showDebugBar =
  process.env.NODE_ENV === 'development' ||
  ['development'].indexOf(ConfigService.stage) !== -1;

export function DebugBar() {
  const classes = useStyles();
  const [online, setOnline] = React.useState(true);
  const db = useIndexedDbContext();
  const [fabOpen, setFabOpen] = React.useState(true);
  const {clear} = useClearSiteData();
  const {engine, eventCreator, setEnableSync, sync} = React.useContext(
    EngineContext
  );

  if (showDebugBar) {
    return fabOpen ? (
      <Box position="fixed" bottom="16px" right="16px">
        <Fab color="secondary" onClick={() => setFabOpen(false)}>
          <BuildIcon />
        </Fab>
      </Box>
    ) : (
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Debug
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={online}
                onChange={(event) => setOnline(event.target.checked)}
                name="online"
              />
            }
            label={online ? 'Online' : 'Offline'}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={async () => {
              const offset = moment().subtract('hours', 3);
              const remainder = offset.minute() % 15;
              offset.subtract('minute', remainder);

              try {
                const generate = generator({
                  eventCreator,
                  offset: offset.toDate(),
                });

                setEnableSync(false);
                const events = generate({});
                await engine.processMultiple(events);
              } finally {
                setEnableSync(true);
                sync();
              }
            }}
            startIcon={<FastForward />}
          >
            Load Sample Vessel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            onClick={() => {}}
            className={classes.button}
            size="small"
            endIcon={
              <DeleteDialog
                title="Clear all site Data"
                subtitle="Are you sure you want to clear all site data, all un-synched data will be lost"
                handleAgree={clear}
                roleAllows={[
                  UserRole.USER_ROLE_USER,
                  UserRole.USER_ROLE_SUPERVISOR,
                  UserRole.USER_ROLE_ADMIN,
                ]}
              />
            }
          >
            Clear Site Data
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={async () => {
              await db.delete();
              window.location.reload();
            }}
            startIcon={<WarningIcon />}
          >
            Reset DB
          </Button>
          <IconButton onClick={() => setFabOpen(!fabOpen)} color="secondary">
            <ChevronRight />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }

  return null;
}
