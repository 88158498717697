import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch4SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7695 52.666V54.3887H6.34668L6.34668 58.8389H10.7695L10.7695 60.5684H0.816408L0.816408 58.8389H4.95899L4.95899 54.3887H0.816407L0.816407 52.666H10.7695Z"
        fill="white"
      />
      <path
        d="M8.45215 44.1709V48.0264L10.7695 48.833V50.6309L0.816407 46.8711L0.816407 45.3193L10.7695 41.5527V43.3574L8.45215 44.1709ZM7.05762 47.541V44.6562L2.92871 46.0986L7.05762 47.541Z"
        fill="white"
      />
      <path
        d="M2.21094 33.2969L2.21094 36.4004H10.7695V38.1162H2.21094L2.21094 41.1924H0.816407L0.816407 33.2969H2.21094Z"
        fill="white"
      />
      <path
        d="M7.5293 23.8311C8.59115 23.9313 9.42057 24.3232 10.0176 25.0068C10.61 25.6904 10.9062 26.5996 10.9062 27.7344C10.9062 28.5273 10.7194 29.2269 10.3457 29.833C9.96745 30.4346 9.43197 30.8994 8.73926 31.2275C8.04655 31.5557 7.24219 31.7266 6.32617 31.7402H5.39648C4.45768 31.7402 3.63053 31.5739 2.91504 31.2412C2.19954 30.9085 1.64811 30.4323 1.26074 29.8125C0.873372 29.1882 0.679688 28.4681 0.679688 27.6523C0.679687 26.554 0.97819 25.6699 1.5752 25C2.1722 24.3301 3.0153 23.9404 4.10449 23.8311L4.10449 25.5537C3.389 25.6357 2.87402 25.8454 2.55957 26.1826C2.24056 26.5153 2.08105 27.0052 2.08105 27.6523C2.08105 28.4043 2.35677 28.9831 2.9082 29.3887C3.45508 29.7897 4.25944 29.9948 5.32129 30.0039H6.20312C7.27865 30.0039 8.09896 29.8125 8.66406 29.4297C9.22917 29.0423 9.51172 28.4772 9.51172 27.7344C9.51172 27.0553 9.35905 26.5449 9.05371 26.2031C8.74837 25.8613 8.24023 25.6449 7.5293 25.5537V23.8311Z"
        fill="white"
      />
      <path
        d="M10.7695 13.627V15.3496H6.34668V19.7998H10.7695V21.5293H0.816406L0.816406 19.7998H4.95898V15.3496H0.816406L0.816406 13.627H10.7695Z"
        fill="white"
      />
      <path
        d="M7.22168 1.2373L7.22168 5.82676e-08L8.55469 0L8.55469 1.2373L10.7695 1.2373V2.89844H8.55469V7.19824L7.54297 7.24609L0.816405 2.95313L0.816405 1.23731L7.22168 1.2373ZM7.22168 5.50293V2.89844L3.06543 2.89844L3.28418 3.02148L7.22168 5.50293Z"
        fill="white"
      />
    </SvgIcon>
  );
}
