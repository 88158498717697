import React from 'react';
import {Chip, fade, makeStyles, useTheme} from '@material-ui/core';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';
import {SyncStatus, useSyncContext} from 'contexts/SyncContext';

const useBackgroundStyles = makeStyles((theme) => ({
  [SyncStatus.SUCCESS]: {
    color: theme.palette.success.main,
    backgroundColor: fade(theme.palette.success.main, 0.08),
  },
  [SyncStatus.OFFLINE]: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.08),
  },
  [SyncStatus.IN_PROGRESS]: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.warning.main, 0.08),
  },
  [SyncStatus.PENDING]: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.warning.main, 0.08),
  },
}));

export function HeaderSync() {
  const {palette} = useTheme();
  const {status, pendingEvents} = useSyncContext();
  const backgroundClasses = useBackgroundStyles();

  let label = '';
  let color: string;

  switch (status) {
    case SyncStatus.SUCCESS:
      label = 'Synced';
      color = palette.success.main;
      break;
    case SyncStatus.IN_PROGRESS:
      label = 'Synching...';
      color = palette.warning.main;
      break;
    case SyncStatus.OFFLINE:
    case SyncStatus.PENDING:
      label = `Sync pending ${pendingEvents}`;
      color = palette.error.main;
      break;
  }

  return (
    <Chip
      className={backgroundClasses[status]}
      icon={
        status !== SyncStatus.SUCCESS ? (
          <SyncProblemRoundedIcon
            style={{
              color,
            }}
          />
        ) : (
          <SyncRoundedIcon
            style={{
              color,
            }}
          />
        )
      }
      label={label}
    />
  );
}
