import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch2SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.543 52.502V54.2246H6.12012V58.6748H10.543V60.4043H0.589846L0.589846 58.6748H4.73242V54.2246H0.589846L0.589846 52.502H10.543Z"
        fill="white"
      />
      <path
        d="M8.22559 44.0068V47.8623L10.543 48.6689V50.4668L0.589846 46.707L0.589846 45.1553L10.543 41.3887V43.1934L8.22559 44.0068ZM6.83106 47.377V44.4922L2.70215 45.9346L6.83106 47.377Z"
        fill="white"
      />
      <path
        d="M1.98438 33.1328L1.98438 36.2363H10.543L10.543 37.9521H1.98438L1.98438 41.0283H0.589845L0.589845 33.1328H1.98438Z"
        fill="white"
      />
      <path
        d="M7.30274 23.667C8.36458 23.7673 9.19401 24.1592 9.79102 24.8428C10.3835 25.5264 10.6797 26.4355 10.6797 27.5703C10.6797 28.3633 10.4928 29.0628 10.1191 29.6689C9.74089 30.2705 9.2054 30.7354 8.5127 31.0635C7.81999 31.3916 7.01563 31.5625 6.09961 31.5762H5.16992C4.23112 31.5762 3.40397 31.4098 2.68848 31.0771C1.97298 30.7445 1.42155 30.2682 1.03418 29.6484C0.646811 29.0241 0.453126 28.304 0.453126 27.4883C0.453126 26.39 0.751629 25.5059 1.34863 24.8359C1.94564 24.166 2.78874 23.7764 3.87793 23.667V25.3896C3.16244 25.4717 2.64746 25.6813 2.33301 26.0186C2.014 26.3512 1.85449 26.8411 1.85449 27.4883C1.85449 28.2402 2.13021 28.819 2.68164 29.2246C3.22852 29.6257 4.03288 29.8307 5.09473 29.8398H5.97656C7.05208 29.8398 7.8724 29.6484 8.4375 29.2656C9.00261 28.8783 9.28516 28.3132 9.28516 27.5703C9.28516 26.8913 9.13249 26.3809 8.82715 26.0391C8.52181 25.6973 8.01367 25.4808 7.30274 25.3896V23.667Z"
        fill="white"
      />
      <path
        d="M10.543 13.4629L10.543 15.1855H6.12012V19.6357H10.543V21.3652H0.589845L0.589844 19.6357H4.73242V15.1855H0.589844L0.589844 13.4629H10.543Z"
        fill="white"
      />
      <path
        d="M10.543 0V6.69238H9.40137L5.78516 3.37695C5.25195 2.89844 4.80762 2.55892 4.45215 2.3584C4.09212 2.15332 3.7321 2.05078 3.37207 2.05078C2.89811 2.05078 2.5153 2.18522 2.22363 2.4541C1.93197 2.71843 1.78613 3.07617 1.78613 3.52734C1.78613 4.0651 1.9502 4.4821 2.27832 4.77832C2.60645 5.07454 3.05534 5.22266 3.625 5.22266L3.625 6.88379C3.01888 6.88379 2.47428 6.74707 1.99121 6.47363C1.50358 6.19564 1.12533 5.80143 0.856445 5.29102C0.587565 4.77604 0.453125 4.18359 0.453125 3.51367C0.453125 2.54753 0.69694 1.78646 1.18457 1.23047C1.66764 0.669922 2.33756 0.389649 3.19434 0.389649C3.69108 0.389649 4.21289 0.530925 4.75977 0.813477C5.30208 1.09147 5.9196 1.54948 6.6123 2.1875L9.2168 4.62109V5.79688e-08L10.543 0Z"
        fill="white"
      />
    </SvgIcon>
  );
}
