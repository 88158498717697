import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch7SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6758 52.4336V54.1562H6.25293L6.25293 58.6064H10.6758L10.6758 60.3359H0.722658L0.722658 58.6064H4.86524L4.86524 54.1562H0.722657L0.722657 52.4336H10.6758Z"
        fill="white"
      />
      <path
        d="M8.3584 43.9385V47.7939L10.6758 48.6006V50.3984L0.722657 46.6387L0.722657 45.0869L10.6758 41.3203V43.125L8.3584 43.9385ZM6.96387 47.3086V44.4238L2.83496 45.8662L6.96387 47.3086Z"
        fill="white"
      />
      <path
        d="M2.11719 33.0645L2.11719 36.168H10.6758V37.8838H2.11719L2.11719 40.96H0.722657L0.722657 33.0645H2.11719Z"
        fill="white"
      />
      <path
        d="M7.43555 23.5986C8.4974 23.6989 9.32682 24.0908 9.92383 24.7744C10.5163 25.458 10.8125 26.3672 10.8125 27.502C10.8125 28.2949 10.6257 28.9945 10.252 29.6006C9.8737 30.2021 9.33822 30.667 8.64551 30.9951C7.9528 31.3232 7.14844 31.4941 6.23242 31.5078H5.30273C4.36393 31.5078 3.53678 31.3415 2.82129 31.0088C2.10579 30.6761 1.55436 30.1999 1.16699 29.5801C0.779622 28.9557 0.585938 28.2357 0.585938 27.4199C0.585937 26.3216 0.88444 25.4375 1.48145 24.7676C2.07845 24.0977 2.92155 23.708 4.01074 23.5986L4.01074 25.3213C3.29525 25.4033 2.78027 25.613 2.46582 25.9502C2.14681 26.2829 1.9873 26.7728 1.9873 27.4199C1.9873 28.1719 2.26302 28.7507 2.81445 29.1562C3.36133 29.5573 4.16569 29.7624 5.22754 29.7715H6.10938C7.1849 29.7715 8.00521 29.5801 8.57031 29.1973C9.13542 28.8099 9.41797 28.2448 9.41797 27.502C9.41797 26.8229 9.2653 26.3125 8.95996 25.9707C8.65462 25.6289 8.14648 25.4124 7.43555 25.3213V23.5986Z"
        fill="white"
      />
      <path
        d="M10.6758 13.3945V15.1172H6.25293V19.5674H10.6758V21.2969H0.722656L0.722656 19.5674H4.86523V15.1172H0.722656L0.722656 13.3945H10.6758Z"
        fill="white"
      />
      <path
        d="M1.64551 0L10.6758 3.97852V5.72168L2.05566 1.75L2.05566 6.89746H0.722655L0.722655 4.03391e-08L1.64551 0Z"
        fill="white"
      />
    </SvgIcon>
  );
}
