import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch9SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0898 53.0977L10.0898 54.8203L5.66699 54.8203L5.66699 59.2705L10.0898 59.2705L10.0898 61L0.136719 61L0.136719 59.2705L4.2793 59.2705L4.2793 54.8203L0.136718 54.8203L0.136718 53.0977L10.0898 53.0977Z"
        fill="white"
      />
      <path
        d="M7.77246 44.6025L7.77246 48.458L10.0898 49.2646L10.0898 51.0625L0.136718 47.3027L0.136718 45.751L10.0898 41.9844L10.0898 43.7891L7.77246 44.6025ZM6.37793 47.9727L6.37793 45.0879L2.24902 46.5303L6.37793 47.9727Z"
        fill="white"
      />
      <path
        d="M1.53125 33.7285L1.53125 36.832L10.0898 36.832L10.0898 38.5479L1.53125 38.5479L1.53125 41.624L0.136718 41.624L0.136718 33.7285L1.53125 33.7285Z"
        fill="white"
      />
      <path
        d="M6.84961 24.2627C7.91146 24.363 8.74088 24.7549 9.33789 25.4385C9.93034 26.1221 10.2266 27.0312 10.2266 28.166C10.2266 28.959 10.0397 29.6585 9.66601 30.2646C9.28776 30.8662 8.75228 31.3311 8.05957 31.6592C7.36686 31.9873 6.5625 32.1582 5.64648 32.1719L4.7168 32.1719C3.77799 32.1719 2.95085 32.0055 2.23535 31.6729C1.51986 31.3402 0.968423 30.8639 0.581053 30.2441C0.193684 29.6198 -1.40315e-06 28.8997 -1.4388e-06 28.084C-1.48681e-06 26.9857 0.298501 26.1016 0.895506 25.4316C1.49251 24.7617 2.33561 24.3721 3.4248 24.2627L3.4248 25.9854C2.70931 26.0674 2.19433 26.277 1.87988 26.6143C1.56087 26.9469 1.40137 27.4368 1.40137 28.084C1.40137 28.8359 1.67708 29.4147 2.22851 29.8203C2.77539 30.2214 3.57975 30.4264 4.6416 30.4355L5.52344 30.4355C6.59896 30.4355 7.41927 30.2441 7.98437 29.8613C8.54948 29.474 8.83203 28.9089 8.83203 28.166C8.83203 27.487 8.67936 26.9766 8.37402 26.6348C8.06868 26.293 7.56055 26.0765 6.84961 25.9854L6.84961 24.2627Z"
        fill="white"
      />
      <path
        d="M10.0898 14.0586L10.0898 15.7812L5.66699 15.7812L5.66699 20.2314L10.0898 20.2314L10.0898 21.9609L0.136717 21.9609L0.136717 20.2314L4.2793 20.2314L4.27929 15.7812L0.136717 15.7812L0.136717 14.0586L10.0898 14.0586Z"
        fill="white"
      />
      <path
        d="M5.92676 2.57812C6.51465 3.13411 6.80859 3.78353 6.80859 4.52637C6.80859 5.40137 6.50325 6.09863 5.89258 6.61816C5.27734 7.1377 4.4707 7.39746 3.47265 7.39746C2.8164 7.39746 2.22168 7.2653 1.68847 7.00098C1.15527 6.73665 0.740558 6.36068 0.444334 5.87305C0.14811 5.38542 -2.45569e-06 4.82031 -2.48378e-06 4.17773C-2.52761e-06 3.17513 0.373695 2.37988 1.12109 1.79199C1.86849 1.2041 2.86881 0.910156 4.12207 0.910156L4.58691 0.910156C6.38248 0.910156 7.75195 1.31575 8.69531 2.12695C9.63867 2.93815 10.1172 4.14811 10.1309 5.75684L10.1309 5.95508L8.75683 5.95508L8.75683 5.71582C8.74316 4.736 8.50846 3.98405 8.05273 3.45996C7.59245 2.93587 6.88379 2.64193 5.92676 2.57812ZM5.50976 4.17773C5.50976 3.84505 5.41406 3.53288 5.22265 3.24121C5.03125 2.94954 4.76692 2.72396 4.42968 2.56445L3.78027 2.56445C3.04655 2.56445 2.45638 2.71484 2.00976 3.01562C1.56315 3.31641 1.33984 3.70605 1.33984 4.18457C1.33984 4.66309 1.53808 5.0459 1.93457 5.33301C2.32649 5.61556 2.82324 5.75684 3.4248 5.75684C4.04915 5.75684 4.55273 5.61328 4.93554 5.32617C5.31836 5.03906 5.50976 4.65625 5.50976 4.17773Z"
        fill="white"
      />
    </SvgIcon>
  );
}
