import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
const useStyles = makeStyles((theme: Theme) => ({
  oneChild: {
    //TODO set margin left and right according to if its uneven or even
    minHeight: 70,
    minWidth: 370,
    display: 'flex',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    //don't use margin here
    flexDirection: 'column',
    width: 'calc(100% - 16px)',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    //TODO future wrapping of shiploader table
    // [theme.breakpoints.down('xs')]: {
    //   gridTemplateColumns: '1fr',
    // },
    gridTemplateRows: 'auto',
    justifyContent: 'space-around',
    justifyItems: 'center',
    width: 'calc(100% + 18px)',
    left: '-8px',
    position: 'relative',
    '&>:first-child': {
      background: '#FFFFFF',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: 0,
    },
    '&>:nth-child(2)': {
      background: '#FFFFFF',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: 0,
    },
    '&>:not(:first-child):not(:nth-child(2)):not(:last-child):not(:nth-last-child(2))': {
      background: '#FFFFFF',
      borderTop: 0,
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: 0,
    },
    '&>:last-child': {
      background: '#FFFFFF',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTop: 0,
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&>:nth-last-child(2)': {
      background: '#FFFFFF',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTop: 0,
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
}));

export function Table(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const classes = useStyles();
  return <div className={classes.container} {...props} />;
}
export function Cell({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  const classes = useStyles();
  return <div {...props} className={clsx(classes.oneChild, className)} />;
}
