import React from 'react';
import {useHistory} from 'react-router';
import {useEngineContext} from 'contexts/EngineContext';
import {Cell, Table} from 'components/Table';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddActivityMenu from './components/AddActivityMenu';
import {TableHeader} from './components/TableHeader';
import {CellManager} from './components/CellManager';
import {FinishVesselModal} from './components/FinishVesselModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTitle} from 'contexts/TitleContext';
import {CheckIfHatchStopped} from './components/ActivityButton';
import {checkIfUnallocatedTime} from 'helpers/unAllocatedTimeHelper';
import {UserRole, VesselState} from '@deckmans/domain';
import {useVessel} from 'hooks/useVessel';
import {makeStyles, createStyles} from '@material-ui/core';
import {useAuthContext} from 'contexts/AuthContext';

const styles = makeStyles(() =>
  createStyles({
    stickyButton: {
      position: 'sticky',
      //top: '6.5em',
      top: '104px',
      zIndex: 100,
      backgroundColor: '#f4f6f8',
      width: 'calc(100% + 18px)',
    },
  })
);

export function VesselView() {
  const history = useHistory();
  const {engine, eventCreator} = useEngineContext();
  const vessel = useVessel();
  const classes = styles();
  const {hasRole} = useAuthContext();

  const resultsRef = React.useRef<HTMLDivElement>(null);

  const documentBody = document?.querySelector('body') as HTMLElement;
  React.useEffect(() => {
    if (resultsRef.current) {
      const node = resultsRef.current;
      documentBody.scrollTo({
        behavior: 'smooth',
        top: node?.offsetTop,
      });
    }
  }, [documentBody]);

  useTitle(vessel.name ?? 'Loading');
  const [modalOpen, setModalOpen] = React.useState(false);

  if (!vessel) {
    return (
      <>
        <CircularProgress />
        Loading
      </>
    );
  }

  const unallocatedTime = checkIfUnallocatedTime(vessel);

  const isFinishedLeft = CheckIfHatchStopped(vessel, 1);
  const isFinishedRight = CheckIfHatchStopped(vessel, 2);

  const vesselActivities = Object.values(vessel.activities).map((activity) => {
    return activity;
  });

  return (
    <>
      <Box pb={2} width="100%" className={classes.stickyButton}>
        <AddActivityMenu
          vessel={vessel}
          data={[
            {
              name: 'Stop Vessel Activity',
              onClick: () => {
                history.push(`/activity/stop-vessel-activity/${vessel.id}`);
              },
            },
            {
              name: 'Survey Activity',
              onClick: () =>
                history.push('/activity/surveyActivity/new/' + vessel.id),
            },
          ]}
        />
      </Box>
      <Table>
        <TableHeader vessel={vessel} />
        <CellManager vessel={vessel} />
        <Cell />
        <Cell />
      </Table>
      <Box py={2} margin=" auto" width="100%">
        <Button
          disabled={
            hasRole(UserRole.USER_ROLE_AGENT) ||
            vessel.state === VesselState.VESSEL_STATE_COMPLETE ||
            vesselActivities.length === 0 ||
            unallocatedTime ||
            (isFinishedLeft ? false : true) ||
            (isFinishedRight ? false : true)
          }
          color="secondary"
          fullWidth
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Submit Vessel
        </Button>
        <FinishVesselModal
          open={modalOpen}
          setOpen={setModalOpen}
          handleAccept={async () => {
            const event = eventCreator.stopVesselEvent({
              vesselId: vessel.id,
            });
            await engine.process(event);
            setModalOpen(false);
            history.push('/logbook');
          }}
        />
      </Box>
      <div ref={resultsRef}></div>
    </>
  );
}
