import * as React from 'react';
import {Box, Grid, Paper} from '@material-ui/core';
import {Formik} from 'formik';
import {useTitle} from 'contexts/TitleContext';
import {NonHatchShiftingActivity} from './NonHatchShifting';
import {SingleSelectField} from 'components/SingleSelectField';
import {NonHatchReclaimingActivity} from './NonHatchReclaiming';
import {NonHatchBreakdownActivity} from './NonHatchBreakdown';
import {NonHatchAwaitingShiploaderActivity} from './NonHatchAwaitingShiploader';
import {NonHatchTNPAActivity} from './NonHatchTNPA';
import {useHistory, useParams} from 'react-router';
import {useVesselActivity} from 'hooks/useVessel';

const options = [
  {
    id: 'None',
    description: 'None',
  },
  {
    id: 'Shifting',
    description: 'Shifting',
  },
  {
    id: 'Reclaiming',
    description: 'Reclaiming',
  },
  {
    id: 'Breakdown',
    description: 'Breakdown',
  },
  {
    id: 'Awaiting Shiploader',
    description: 'Awaiting Shiploader',
  },
  {
    id: 'TNPA',
    description: 'TNPA',
  },
];

export function NonHatchActivity() {
  useTitle('Non hatch activity');

  const {activityId} = useParams<{
    activityId: string;
  }>();
  const history = useHistory<{unallocatedTime?: boolean}>();
  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const selectedVesselActivity = useVesselActivity(activityId);

  return (
    <Formik
      initialValues={{
        selection: history.location?.state?.unallocatedTime
          ? 'Breakdown'
          : isEditing
          ? ''
          : options[0].description,
      }}
      onSubmit={() => {}}
    >
      {({values}) => {
        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container>
                  {!isEditing && (
                    <Grid item xs={12}>
                      <Box px={1}>
                        <SingleSelectField
                          fullWidth
                          data={options}
                          label="Activity"
                          field="selection"
                        />
                      </Box>
                    </Grid>
                  )}

                  {!isEditing && values.selection === 'Shifting' && (
                    <NonHatchShiftingActivity />
                  )}
                  {!isEditing && values.selection === 'Reclaiming' && (
                    <NonHatchReclaimingActivity />
                  )}
                  {!isEditing && values.selection === 'Breakdown' && (
                    <NonHatchBreakdownActivity />
                  )}
                  {!isEditing && values.selection === 'Awaiting Shiploader' && (
                    <NonHatchAwaitingShiploaderActivity />
                  )}
                  {!isEditing && values.selection === 'TNPA' && (
                    <NonHatchTNPAActivity />
                  )}
                  {isEditing &&
                    selectedVesselActivity &&
                    selectedVesselActivity.nonHatchShiftingActivity && (
                      <NonHatchShiftingActivity />
                    )}

                  {isEditing &&
                    selectedVesselActivity &&
                    selectedVesselActivity.nonHatchReclaimingActivity && (
                      <NonHatchReclaimingActivity />
                    )}

                  {isEditing &&
                    selectedVesselActivity &&
                    selectedVesselActivity.nonHatchBreakdownActivity && (
                      <NonHatchBreakdownActivity />
                    )}

                  {isEditing &&
                    selectedVesselActivity &&
                    selectedVesselActivity.nonHatchAwaitingShiploaderActivity && (
                      <NonHatchAwaitingShiploaderActivity />
                    )}

                  {isEditing &&
                    selectedVesselActivity &&
                    selectedVesselActivity.nonHatchTnpaActivity && (
                      <NonHatchTNPAActivity />
                    )}
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </Formik>
  );
}
