import React from 'react';
import Chip, {ChipProps} from '@material-ui/core/Chip';
import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles(({hexToRgba, palette}: Theme) => ({
  root: {
    width: 90,
  },
  colourPrimary: {
    background: hexToRgba(palette.success.main, 0.07),
    color: palette.success.main,
  },
  colourSecondary: {
    background: hexToRgba(palette.info.main, 0.07),
    color: palette.info.main,
  },
}));

export function LogbookChip(props: Omit<ChipProps, 'classes'>) {
  const classes = useStyles();
  return (
    <Chip
      classes={{
        root: classes.root,
        colorPrimary: classes.colourPrimary,
        colorSecondary: classes.colourSecondary,
      }}
      {...props}
    />
  );
}
