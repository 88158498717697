"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRgba = exports.hexToRgb = void 0;
function hexToRgb(hex) {
    try {
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        return `rgb(${r},${g},${b})`;
    }
    catch (error) {
        //eslint-disable-next-line no-console
        console.error(`Invalid Hex Value:${hex}, please make sure the value you supply is in the format #RRGGBB`, error);
        return hex;
    }
}
exports.hexToRgb = hexToRgb;
function hexToRgba(hex, opacity = 1) {
    try {
        if (!(opacity >= 0 && opacity <= 1)) {
            throw new Error('Opacity needs to be smaller or equal to 1 and larger or equal to 0 ');
        }
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        return `rgba(${r},${g},${b},${opacity})`;
    }
    catch (error) {
        //eslint-disable-next-line no-console
        console.error(`Invalid Hex Value:${hex}, please make sure the value you supply is in the format #RRGGBB`, error);
        return hex;
    }
}
exports.hexToRgba = hexToRgba;
