"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventCreator = void 0;
// import * as Events from '../events/events';
const Events = __importStar(require("../proto/event"));
const event_1 = require("../proto/event");
class EventCreator {
    constructor(userGetter, idGenerator) {
        this.userGetter = userGetter;
        this.idGenerator = idGenerator;
    }
    vesselCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: id,
            type: event_1.EventType.EVENT_TYPE_VESSEL_CREATED,
            vesselCreated: {
                id,
                ...payload,
            },
        });
    }
    vesselEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.id,
            type: event_1.EventType.EVENT_TYPE_VESSEL_EDITED,
            vesselEdited: payload,
        });
    }
    vesselDeleted(vesselId) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: vesselId,
            type: event_1.EventType.EVENT_TYPE_VESSEL_DELETED,
            vesselDeleted: {
                id: vesselId,
            },
        });
    }
    stopVesselEvent(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_STOP_VESSEL,
            stopVessel: payload,
        });
    }
    activityDeleted(vesselId, id) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: vesselId,
            type: event_1.EventType.EVENT_TYPE_ACTIVITY_DELETED,
            activityDeleted: {
                id,
                vesselId,
            },
        });
    }
    hatchActivityGroupedCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_CREATED,
            hatchActivityGroupCreated: {
                id,
                ...payload,
            },
        });
    }
    hatchActivityGroupEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_EDITED,
            hatchActivityGroupEdited: payload,
        });
    }
    hatchActivityGroupDeleted(vesselId, id) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_ACTIVITY_GROUP_DELETED,
            hatchActivityGroupDeleted: {
                id,
                vesselId,
            },
        });
    }
    hatchDirectLoadingActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_CREATED,
            hatchDirectLoadingActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    hatchDirectLoadingActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_DIRECT_LOADING_ACTIVITY_EDITED,
            hatchDirectLoadingActivityEdited: payload,
        });
    }
    hatchLoadingActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_CREATED,
            hatchLoadingActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    hatchLoadingActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_LOADING_ACTIVITY_EDITED,
            hatchLoadingActivityEdited: payload,
        });
    }
    hatchReclaimingActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_CREATED,
            hatchReclaimingActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    hatchReclaimingActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_RECLAIMING_ACTIVITY_EDITED,
            hatchReclaimingActivityEdited: payload,
        });
    }
    hatchBreakdownActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_CREATED,
            hatchBreakdownActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    hatchBreakdownActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_BREAKDOWN_ACTIVITY_EDITED,
            hatchBreakdownActivityEdited: payload,
        });
    }
    hatchAwaitingShiploaderActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED,
            hatchAwaitingShiploaderActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    hatchAwaitingShiploaderActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED,
            hatchAwaitingShiploaderActivityEdited: payload,
        });
    }
    surveyActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_SURVEY_ACTIVITY_CREATED,
            surveyActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    surveyActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_SURVEY_ACTIVITY_EDITED,
            surveyActivityEdited: payload,
        });
    }
    stopVesselActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_CREATED,
            stopVesselActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    stopVesselActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_STOP_VESSEL_ACTIVITY_EDITED,
            stopVesselActivityEdited: payload,
        });
    }
    nonHatchShiftingActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_CREATED,
            nonHatchShiftingActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    nonHatchShiftingActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_SHIFTING_ACTIVITY_EDITED,
            nonHatchShiftingActivityEdited: payload,
        });
    }
    nonHatchTnpaActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_CREATED,
            nonHatchTnpaActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    nonHatchTnpaActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_TNPA_ACTIVITY_EDITED,
            nonHatchTnpaActivityEdited: payload,
        });
    }
    nonHatchAwaitingShiploaderActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_CREATED,
            nonHatchAwaitingShiploaderActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    nonHatchAwaitingShiploaderActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY_EDITED,
            nonHatchAwaitingShiploaderActivityEdited: payload,
        });
    }
    nonHatchReclaimingActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_CREATED,
            nonHatchReclaimingActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    nonHatchReclaimingActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_RECLAIMING_ACTIVITY_EDITED,
            nonHatchReclaimingActivityEdited: payload,
        });
    }
    nonHatchBreakdownActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_CREATED,
            nonHatchBreakdownActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    nonHatchBreakdownActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_NON_HATCH_BREAKDOWN_ACTIVITY_EDITED,
            nonHatchBreakdownActivityEdited: payload,
        });
    }
    singleLoadingActivityCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_CREATED,
            singleLoadingActivityCreated: {
                id,
                ...payload,
            },
        });
    }
    singleLoadingActivityEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_SINGLE_LOADING_ACTIVITY_EDITED,
            singleLoadingActivityEdited: payload,
        });
    }
    stopHatchCreated(payload) {
        const id = this.idGenerator.getNextId();
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_STOP_HATCH_CREATED,
            stopHatchCreated: {
                id,
                ...payload,
            },
        });
    }
    stopHatchDeleted(vesselId, id, groupActivityId) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: vesselId,
            type: event_1.EventType.EVENT_TYPE_STOP_HATCH_DELETED,
            stopHatchDeleted: {
                id,
                vesselId,
                groupActivityId,
            },
        });
    }
    stopHatchEdited(payload) {
        return Events.Event.fromPartial({
            ...this.defaultEventValues(),
            aggregateId: payload.vesselId,
            type: event_1.EventType.EVENT_TYPE_STOP_HATCH_EDITED,
            stopHatchEdited: payload,
        });
    }
    defaultEventValues() {
        return {
            eventId: this.idGenerator.getNextId(),
            createdBy: this.userGetter.getCurrentUser(),
            createdAt: new Date(),
        };
    }
}
exports.EventCreator = EventCreator;
