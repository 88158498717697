import * as React from 'react';
import {Box, Grid, Paper, Divider} from '@material-ui/core';
import {useTitle} from 'contexts/TitleContext';
import {useParams, useHistory} from 'react-router';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {BaseForm} from 'components/BaseForm';
import moment from 'moment';
import {Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {ButtonBar} from 'components/ButtonBar';
import * as yup from 'yup';
import {StartEndDateTime} from '@deckmans/web-shared';
import {Duration} from 'components/Duration';
import {DecisionType, DecisionAnswer} from '@deckmans/domain';
import {useVessel, useVesselActivity} from 'hooks/useVessel';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {findAllVesselActivitiesEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_STOP_VESSEL_ACTIVITY,
});

interface StopVesselActivityType {
  id: string;
  startTime: string;
  endTime: string;
  reason: DecisionAnswer;
  comments: string;
  vslKmPerHour: number;
  ccrKmPerHour: number;
  portControlKmPerHour: number;
  swellMeters: number;
  vesselName: string;
}

export function StopVesselActivity() {
  const history = useHistory();
  const {engine, eventCreator} = useEngineContext();

  const {vesselId, activityId} = useParams<{
    vesselId: string;
    activityId: string;
  }>();

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);
  useTitle(isEditing ? 'Edit Stop Vessel Activity' : 'Stop Vessel Activity');

  const vessel = useVessel();
  const highWindSpeedTest = {
    is: function (reason: DecisionAnswer) {
      return (
        reason.decisionKeys.length > 1 &&
        reason.decisionKeys[1] === 'HIGH_WIND_SPEEDS'
      );
    },
    then: yup.number().required('This field is required'),
    otherwise: yup.number(),
  };
  const highSwellTest = {
    is: function (reason: DecisionAnswer) {
      return (
        reason.decisionKeys.length > 1 &&
        reason.decisionKeys[1] === 'HIGH_SWELL'
      );
    },
    then: yup.number().required('This field is required'),
    otherwise: yup.number(),
  };
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    reason: yup.object<DecisionAnswer>({
      type: yup
        .mixed<DecisionType>()
        .required(
          'This should be passed into the component so should never fail'
        ),
      decisionKeys: yup
        .array<string>()
        .min(1)
        .required('This field is required'),
    }),
    vesselName: yup.string().when('reason', {
      is: function (reason) {
        return (
          reason.decisionKeys.length > 0 &&
          reason.decisionKeys[0] === 'LOAD_OTHER_VESSEL'
        );
      },
      then: yup.string().required('This field is required'),
      otherwise: yup.string(),
    }),
    comments: yup.string(),
    vslKmPerHour: yup.number().when('reason', highWindSpeedTest),
    ccrKmPerHour: yup.number().when('reason', highWindSpeedTest),
    portControlKmPerHour: yup.number().when('reason', highWindSpeedTest),
    swellMeters: yup.number().when('reason', highSwellTest),
  });

  const activity = useVesselActivity(activityId);

  const pvEndDate = findAllVesselActivitiesEndDate(vessel);

  const sd = pvEndDate !== '' ? pvEndDate : reclaimStart;
  const {startDate, endDate} = useHistoryStateDateRange(sd);

  const initialValues = React.useMemo(() => {
    if (isEditing && vessel && activity && activity.stopVesselActivity) {
      const {
        vesselName,
        ccrKmPerHour,
        comments,
        portControlKmPerHour,
        swellMeters,
        vslKmPerHour,
        reason,
      } = activity.stopVesselActivity;
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments,
        id: activity.id,
        reason: reason ?? defaultReason,
        vslKmPerHour,
        ccrKmPerHour,
        portControlKmPerHour,
        swellMeters,
        vesselName: vesselName,
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        comments: '',
        id: '',
        reason: defaultReason,
        vslKmPerHour: 0,
        ccrKmPerHour: 0,
        portControlKmPerHour: 0,
        swellMeters: 0,
        vesselName: '',
      };
    }
  }, [activity, isEditing, vessel, startDate, endDate]);

  return (
    <BaseForm<StopVesselActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={initialValues}
      onCreateSubmit={async ({
        ccrKmPerHour,
        comments,
        endTime,
        portControlKmPerHour,
        reason,
        startTime,
        swellMeters,
        vslKmPerHour,
        vesselName,
      }) => {
        const loadVSL =
          reason.decisionKeys.length > 1 &&
          reason.decisionKeys[1] === 'HIGH_WIND_SPEEDS';
        const loadSwell =
          reason.decisionKeys.length > 1 &&
          reason.decisionKeys[1] === 'HIGH_SWELL';
        const loadVesselName =
          reason.decisionKeys.length > 0 &&
          reason.decisionKeys[0] === 'LOAD_OTHER_VESSEL';

        const event = eventCreator.stopVesselActivityCreated({
          vesselId,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          ccrKmPerHour: loadVSL ? ccrKmPerHour : 0,
          comments,
          portControlKmPerHour: loadVSL ? portControlKmPerHour : 0,
          reason,
          swellMeters: loadSwell ? swellMeters : 0,
          vslKmPerHour: loadVSL ? vslKmPerHour : 0,
          vesselName: loadVesselName ? vesselName : '',
        });

        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({
        ccrKmPerHour,
        comments,
        endTime,
        portControlKmPerHour,
        startTime,
        vslKmPerHour,
        reason,
        id,
        vesselName,
        swellMeters,
      }) => {
        const loadVSL =
          reason.decisionKeys.length > 1 &&
          reason.decisionKeys[1] === 'HIGH_WIND_SPEEDS';
        const loadSwell =
          reason.decisionKeys.length > 1 &&
          reason.decisionKeys[1] === 'HIGH_SWELL';
        const loadVesselName =
          reason.decisionKeys.length > 0 &&
          reason.decisionKeys[0] === 'LOAD_OTHER_VESSEL';
        const event = eventCreator.stopVesselActivityEdited({
          vesselId,
          id,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          ccrKmPerHour: loadVSL ? ccrKmPerHour : 0,
          comments,
          portControlKmPerHour: loadVSL ? portControlKmPerHour : 0,
          reason,
          swellMeters: loadSwell ? swellMeters : 0,
          vslKmPerHour: loadVSL ? vslKmPerHour : 0,
          vesselName: loadVesselName ? vesselName : '',
        });

        await engine.process(event);
        history.goBack();
      }}
    >
      {({values: {reason, startTime, endTime}, submitForm}) => {
        const showVSL =
          reason.decisionKeys.length > 1 &&
          reason.decisionKeys[1] === 'HIGH_WIND_SPEEDS';
        const showSwell =
          reason.decisionKeys.length > 1 &&
          reason.decisionKeys[1] === 'HIGH_SWELL';
        const showVesselName =
          reason.decisionKeys.length > 0 &&
          reason.decisionKeys[0] === 'LOAD_OTHER_VESSEL';
        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime />
                  <Grid item xs={6}>
                    <Box px={1}>
                      <Duration startTime={startTime} endTime={endTime} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MultiLevelQuestions
                      name="reason"
                      decisionType={
                        DecisionType.DECISION_TYPE_STOP_VESSEL_ACTIVITY
                      }
                    />
                  </Grid>
                  {showVesselName && (
                    <Grid item xs={12}>
                      <Box px={1}>
                        <Field
                          fullWidth
                          component={TextField}
                          label="Vessel Name"
                          name="vesselName"
                        />
                      </Box>
                    </Grid>
                  )}
                  {showVSL && (
                    <>
                      <Grid item xs={12}>
                        <Box px={1}>
                          <Field
                            fullWidth
                            component={TextField}
                            name="vslKmPerHour"
                            label="VSL (km/h)"
                            placeholder="VSL (km/h)"
                            validate={(val: number) => {
                              return showVSL && val === 0
                                ? 'You need to enter VSL (km/h)'
                                : '';
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box px={1}>
                          <Field
                            fullWidth
                            component={TextField}
                            name="ccrKmPerHour"
                            label="CCR (km/h)"
                            placeholder="CCR (km/h)"
                            validate={(val: number) => {
                              return showVSL && val === 0
                                ? 'You need to enter CCR (km/h)'
                                : '';
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box px={1}>
                          <Field
                            fullWidth
                            component={TextField}
                            name="portControlKmPerHour"
                            label="Port Control (km/h)"
                            placeholder="Port Control (km/h)"
                            validate={(val: number) => {
                              return showVSL && val === 0
                                ? 'You need to enter Port Control (km/h)'
                                : '';
                            }}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                  {showSwell && (
                    <Grid item xs={12}>
                      <Box px={1}>
                        <Field
                          fullWidth
                          component={TextField}
                          name="swellMeters"
                          label="Swell (m)"
                          placeholder="Swell (m)"
                          validate={(val: number) => {
                            return showSwell && val === 0
                              ? 'You need to enter swellMeters (m)'
                              : '';
                          }}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box px={1}>
                      {/* TODO logic to see if compulsory */}
                      <Field
                        fullWidth
                        multiline
                        component={TextField}
                        name="comments"
                        label="Comments"
                        placeholder="Comments"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      createText="create"
                      saveText="update"
                      isEditing={isEditing}
                      handleSave={() => {
                        submitForm();
                      }}
                      vessel={vessel}
                      activityId={activityId}
                      title="Delete Stop Vessel Activity"
                      subtitle="Are you sure you want to delete this activity?"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </BaseForm>
  );
}
