import {trackAiEvent} from 'appInsights';
import React from 'react';
export function useNavigatorOnline() {
  const [online, setOnline] = React.useState(navigator.onLine);
  React.useEffect(() => {
    function online() {
      trackAiEvent({
        name: 'App Online',
        properties: {timeStamp: new Date().toLocaleTimeString()},
      });
      setOnline(true);
    }

    function offline() {
      trackAiEvent({
        name: 'App Offline',
        properties: {timeStamp: new Date().toLocaleTimeString()},
      });
      setOnline(false);
    }
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    };
  }, [online]);

  return online;
}
