import React from 'react';
import {unregister} from 'serviceWorker';

export function useForceUpdate() {
  const forceUpdate = React.useCallback(() => {
    async function clearData() {
      unregister();
      const keys = await caches.keys();
      keys.forEach((name) => {
        caches.delete(name);
      });
      sessionStorage.clear();
      window.location.reload();
    }
    clearData();
  }, []);
  const value = React.useMemo(() => ({forceUpdate}), [forceUpdate]);
  return value;
}
