import * as React from 'react';
import {Redirect} from 'react-router';
import {Formik, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import Paper from '@material-ui/core/Paper';
import {useAuthContext} from 'contexts/AuthContext';
import BG from 'images/BG.jpg';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {AngloNamedLogo} from 'images/anglo-named-logo';
import {trackAiEvent} from 'appInsights';
import {Link} from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${BG})`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  paper: {
    width: 330,
    margin: '0 auto',
  },
}));

const schema = yup
  .object({
    username: yup.string().required('This field is required'),
    password: yup.string().required('This field is required'),
  })
  .required();

type Values = yup.InferType<typeof schema>;

export function LoginPage() {
  const classes = useStyles();
  const {handleLogin, auth} = useAuthContext();

  const [errorMessage, setErrorMessage] = React.useState('');
  if (auth.authenticated) {
    return <Redirect to="/logbook" />;
  }
  return (
    <div className={classes.container}>
      <Box
        position="absolute"
        top="24px"
        left="24px"
        display="flex"
        flexDirection="column"
        style={{color: 'white'}}
      >
        <Typography variant="h1">Deckmans</Typography>
        <Typography variant="h3">Logbook</Typography>
      </Box>
      <Formik<Values>
        validationSchema={schema}
        initialValues={{username: '', password: ''}}
        onSubmit={async ({username, password}) => {
          setErrorMessage('');
          try {
            await handleLogin(username, password);
            trackAiEvent({
              name: 'Login Succeeded',
              properties: {timeStamp: new Date().toLocaleTimeString()},
            });
          } catch (ex) {
            setErrorMessage('Your username or password is incorrect!');
            trackAiEvent({
              name: 'Login Failed',
              properties: {timeStamp: new Date().toLocaleTimeString()},
            });
          }
        }}
      >
        {({submitForm, isSubmitting}) => (
          <Paper className={classes.paper}>
            <Box m={2} mb={3} display="flex" justifyContent="center">
              <AngloNamedLogo />
            </Box>
            <Box m={2} mb={3}>
              <Field
                component={TextField}
                name="username"
                fullWidth
                label="Username"
              />
            </Box>
            <Box m={2} mb={3}>
              <Field
                component={TextField}
                name="password"
                type="password"
                fullWidth
                label="Password"
              />
            </Box>
            <Box m={2} display="flex" justifyContent="center">
              {errorMessage !== '' && (
                <Typography variant="caption" color="error">
                  {errorMessage}
                </Typography>
              )}
            </Box>
            <Box m={2} mb={3}>
              <Button
                onClick={submitForm}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={isSubmitting}
              >
                Login
              </Button>
            </Box>
            <Box
              m={2}
              mb={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Link href="/resetPassword">Reset Password</Link>
            </Box>
          </Paper>
        )}
      </Formik>
    </div>
  );
}
