import * as React from 'react';
import {Box, Button} from '@material-ui/core';
import DeleteDialog from 'components/DeleteDialog';
import {useEngineContext} from 'contexts/EngineContext';
import {useHistory} from 'react-router';
import {Shiploader, UserRole} from '@deckmans/domain';

interface Props {
  vesselId: string;
  hatchId: string;
  roleAllows: UserRole[];
  shiploader: Shiploader;
}

export function HatchActivityLogDelete({
  hatchId,
  vesselId,
  roleAllows,
  shiploader,
}: Props) {
  const {eventCreator, engine} = useEngineContext();
  const history = useHistory();

  return (
    <Box display="flex" alignItems="center">
      <div>
        <Button
          variant="text"
          onClick={() => {
            history.replace(`/hatch/edit/${vesselId}/${shiploader}/${hatchId}`);
          }}
        >
          edit
        </Button>
      </div>
      <div>
        <DeleteDialog
          title="Delete Hatch"
          subtitle="Are you sure you want to Delete the Hatch"
          handleAgree={async () => {
            const event = eventCreator.hatchActivityGroupDeleted(
              vesselId,
              hatchId
            );
            await engine.process(event);
            history.replace(`/vessel/view/${vesselId}`);
          }}
          roleAllows={roleAllows}
        />
      </div>
    </Box>
  );
}
