import React from 'react';
import {ConfigService} from '@stackworx/react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {Redirect} from 'react-router';
import {useTheme, Chip, fade} from '@material-ui/core';
import {PaletteColor} from '@material-ui/core/styles/createPalette';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Formik} from 'formik';
import {
  MultiLevelQuestions,
  printDecision,
} from 'components/MultiLevelQuestions';
import {useDataContext} from 'providers/DataContextProvider';
import {DecisionType} from '@deckmans/domain';

function ColourBlock({item: [key, value]}: {item: [string, string]}) {
  return (
    <>
      <Typography variant="body2">{key}</Typography>
      <div
        style={{
          backgroundColor: value,
          width: 100,
          height: 100,
          margin: 10,
        }}
      />
    </>
  );
}
function BlockMapper({
  block,
  title,
}: {
  block: PaletteColor;
  title: React.ReactNode;
}) {
  return (
    <Box display="flex" flexDirection="column" p={1}>
      {title}
      {Object.entries(block).map((item, index) => {
        return (
          <ColourBlock
            item={item}
            key={index + '_' + item[0] + '_' + item[1]}
          />
        );
      })}
    </Box>
  );
}
const showDebugBar =
  process.env.NODE_ENV === 'development' ||
  ['development'].indexOf(ConfigService.stage) !== -1;
export function ThemeSpecifics() {
  const theme = useTheme();
  const {decision: DecisionFind, master} = useDataContext();

  if (!showDebugBar) {
    return <Redirect to="*" />;
  }
  return (
    <>
      <Typography variant="overline">
        This page can only show in development
      </Typography>

      <Box display="flex" flexDirection="column">
        <Formik
          initialValues={{
            decision: [],
            ml: DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY,
          }}
          onSubmit={() => {
            //eslint-disable-next-line no-console
            console.log('Can you say Boynos Dias #Dora is cool');
          }}
        >
          {({values, setFieldValue, submitForm, validateForm}) => {
            const decision = DecisionFind(values.ml);
            return (
              <>
                <Box p={1}>
                  <TextField
                    select
                    SelectProps={{native: true}}
                    value={values.ml}
                    onChange={(e) => {
                      setFieldValue('ml', e.target.value);
                      setFieldValue('decision', []);
                    }}
                  >
                    {master.decisions.map(({type}) => (
                      <option key={type} value={type}>
                        {type}TODO convert key to string
                      </option>
                    ))}
                  </TextField>
                </Box>
                {decision && (
                  <MultiLevelQuestions
                    name="decision"
                    decisionType={values.ml}
                  />
                )}
                {decision && printDecision(decision, values.decision).join('/')}
                <button
                  onClick={() => {
                    validateForm().then(() => {
                      submitForm();
                    });
                  }}
                >
                  Submit
                </button>
              </>
            );
          }}
        </Formik>

        <Typography variant="h2">
          This section is just to show how the components look in the project
          relative to Figma and the DNA set up there
        </Typography>
        <Typography variant="h3">Typography</Typography>
        <Typography variant="h1">H1</Typography>
        <Typography variant="h2">H2</Typography>
        <Typography variant="h3">H3</Typography>
        <Typography variant="h4">H4</Typography>
        <Typography variant="h5">H5 </Typography>
        <Typography variant="h5" style={{fontFamily: 'Roboto Mono'}}>
          H5 ALT 01:00
        </Typography>
        <Typography variant="h6">H6</Typography>
        <Typography variant="subtitle1">Subtitle1 aka Subtitle</Typography>
        <Typography variant="subtitle2">
          Subtitle2 aka Subtitle small
        </Typography>
        <Typography variant="body1">Body1 aka Body</Typography>
        <Typography variant="body2">Body2 aka Body Small</Typography>
        <Typography variant="button">Button</Typography>
        <Typography variant="caption">caption aka Tab </Typography>
        <Typography variant="overline">overline aka Tab small </Typography>
        <Typography variant="h3">Colour Palette</Typography>
        <Box display="flex" flexWrap="wrap">
          <BlockMapper
            title={<Typography variant="h4">Colour Primary</Typography>}
            block={theme.palette.primary}
          />
          <BlockMapper
            title={<Typography variant="h4">Colour secondary</Typography>}
            block={theme.palette.secondary}
          />
          <BlockMapper
            title={<Typography variant="h4">Colour Error</Typography>}
            block={theme.palette.error}
          />
          <BlockMapper
            title={<Typography variant="h4">Colour info</Typography>}
            block={theme.palette.info}
          />
        </Box>
        <Typography variant="h3">TextField</Typography>
        <Box p={1}>
          <TextField label="TextField default" />
        </Box>
        <Box p={1}>
          <TextField label="TextField outlined" variant="outlined" />
        </Box>
        <Box p={1}>
          <TextField label="TextField filled" variant="filled" />
        </Box>
        <Box p={1}>
          <TextField label="TextField default" color="secondary" />
        </Box>
        <Box p={1}>
          <TextField
            label="TextField outlined"
            variant="outlined"
            color="secondary"
          />
        </Box>
        <Box p={1}>
          <TextField
            label="TextField filled"
            variant="filled"
            color="secondary"
          />
        </Box>
        <Typography variant="h3">Buttons</Typography>
        <Box p={1}>
          <Button>Button no props</Button>
        </Box>
        <Box p={1}>
          <Button variant="contained">Button contained</Button>
        </Box>
        <Box p={1}>
          <Button variant="contained" color="default">
            Button contained default
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="contained" color="inherit">
            Button contained inherit
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="contained" color="primary">
            Button contained primary
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="contained" color="secondary">
            Button contained secondary
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="outlined">Button outlined</Button>
        </Box>
        <Box p={1}>
          <Button variant="outlined" color="default">
            Button outlined default
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="outlined" color="inherit">
            Button outlined inherit
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="outlined" color="primary">
            Button outlined primary
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="outlined" color="secondary">
            Button outlined secondary
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="text">Button text</Button>
        </Box>
        <Box p={1}>
          <Button variant="text" color="default">
            Button text default
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="text" color="inherit">
            Button text inherit
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="text" color="primary">
            Button text primary
          </Button>
        </Box>
        <Box p={1}>
          <Button variant="text" color="secondary">
            Button text secondary
          </Button>
        </Box>
        <Box p={1}>
          <Chip
            label="Chip label"
            style={{
              color: theme.palette.success.main,
              backgroundColor: fade(theme.palette.success.main, 0.08),
            }}
          ></Chip>
        </Box>
        <Box p={1}>
          <Chip variant="outlined" label="Chip outlined"></Chip>
        </Box>
      </Box>
    </>
  );
}
