import {useEngineContext} from 'contexts/EngineContext';
import {useAuthContext} from 'contexts/AuthContext';
import {useObserver} from 'mobx-react';
import {useParams} from 'react-router';

export function useVessel() {
  let vesselId = '';
  const params = useParams<{vesselId?: string}>();
  if (params.vesselId && params.vesselId !== '') {
    vesselId = params.vesselId;
  }
  const {engine} = useEngineContext();
  const vessel = useObserver(() => engine.state.vessels[vesselId]);

  //vessel should exist if editing
  if (!vessel) {
    throw new Error(`Vessel ${vesselId} not found`);
  }
  return vessel;
}

export function useVessels(byUser = false) {
  const {engine} = useEngineContext();
  const stateVessels = useObserver(() => engine.state.vessels);
  const {auth} = useAuthContext();
  if (!stateVessels) {
    throw new Error('Vessels not found');
  }
  if (!auth.authenticated) {
    throw new Error('Not authenticated');
  }
  let vessels = Object.values(stateVessels);
  if (byUser) {
    vessels = vessels.filter((v) => {
      return v.createdBy?.id === auth.userId;
    });
  }
  return vessels;
}

export function useVesselActivity(activityId = '') {
  const vesselActivity = useVessel().activities[activityId];
  if (!activityId || activityId === '') {
    return undefined;
  }
  if (!vesselActivity) {
    // throw new Error(`Vessel activity ${activityId} not found`);
    return undefined;
  }
  return vesselActivity;
}
