import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {VesselOverviewDetails} from './VesselOverview';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useVessel} from 'hooks/useVessel';
import {mapReason} from '@deckmans/domain/lib/decision';
import {useDataContext} from 'providers/DataContextProvider';
import {TimeBetween} from '@deckmans/web-shared';
import {getSurveyType} from '@deckmans/domain/lib/util/enumData';
import moment from 'moment';
import {VesselHatchActivity} from '@deckmans/domain';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));

export function DurationTime(durationMinutes: number) {
  const minutes = durationMinutes;
  const h = Math.floor(minutes / 60);
  const mod = Math.ceil(minutes % 60).toString();
  const m = mod.length === 1 ? '0' + mod : mod;
  const duration = minutes < 0 ? '-- : --' : h + ':' + m;

  return duration;
}

export default function AgentSummary() {
  const classes = useStyles();
  const vessel = useVessel();
  const {master} = useDataContext();

  const vesselActivities = Object.values(vessel.activities).map((activity) => {
    return activity;
  });

  const stopVesselActivities = vesselActivities.filter(
    (a) => a.stopVesselActivity
  );

  const breakdownActivities = vesselActivities.filter(
    (a) => a.nonHatchBreakdownActivity
  );

  const surveyActivities = vesselActivities.filter((a) => a.surveyActivity);

  const hatchBreakdownActivities: VesselHatchActivity[] = [];
  vesselActivities.forEach((activity) => {
    if (activity.vesselHatchActivityGroup) {
      const hatchActivities = Object.values(
        activity.vesselHatchActivityGroup.activities
      ).map((activity) => {
        return activity;
      });
      return hatchActivities.forEach((a) => {
        if (a.hatchBreakdownActivity) {
          hatchBreakdownActivities.push(a);
        }
      });
    }
  });

  const stopVesselActivitiesTotalDuration = stopVesselActivities.reduce(
    (a, b) =>
      a +
      moment.duration(moment(b.endDate).diff(moment(b.startDate))).asMinutes(),
    0
  );

  const surveyActivitiesTotalDuration = surveyActivities.reduce(
    (a, b) =>
      a +
      moment.duration(moment(b.endDate).diff(moment(b.startDate))).asMinutes(),
    0
  );

  const breakdownActivitiesTotalDuration = breakdownActivities.reduce(
    (a, b) =>
      a +
      moment.duration(moment(b.endDate).diff(moment(b.startDate))).asMinutes(),
    0
  );
  const hatchBreakdownActivitiesTotalDuration = hatchBreakdownActivities.reduce(
    (a, b) =>
      a +
      moment.duration(moment(b.endDate).diff(moment(b.startDate))).asMinutes(),
    0
  );
  const displayActivityDates = (date?: Date) =>
    moment(date).format('Do MMMM YYYY, HH:mm');
  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table aria-label="spanning table" size="small">
        <TableHead></TableHead>
        <TableBody>
          <VesselOverviewDetails />
        </TableBody>
      </Table>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} align="left">
              <Typography variant="h4">Breakdown activities</Typography>
            </TableCell>
          </TableRow>
          <TableCell>Breakdown type</TableCell>
          <TableCell>Equipment type</TableCell>
          <TableCell>Equipment name</TableCell>
          <TableCell>Reason 1</TableCell>
          <TableCell>Comment</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>

          <TableCell>Breakdown duration</TableCell>
        </TableHead>
        <TableBody>
          {vesselActivities.map((activity) => {
            if (activity.nonHatchBreakdownActivity) {
              const breakdownActivity = activity.nonHatchBreakdownActivity;
              const reasons = mapReason(
                breakdownActivity.reason,
                master.decisions,
                'Breakdown'
              );

              return (
                <TableRow key={activity.id}>
                  <TableCell>{reasons.reason1}</TableCell>
                  <TableCell>{reasons.equipmentType}</TableCell>
                  <TableCell>{breakdownActivity.equipmentName}</TableCell>
                  <TableCell>{reasons.reason3}</TableCell>
                  <TableCell>{breakdownActivity.comments}</TableCell>
                  <TableCell>
                    {displayActivityDates(activity.startDate)}
                  </TableCell>
                  <TableCell>
                    {displayActivityDates(activity.endDate)}
                  </TableCell>

                  <TableCell>
                    <TimeBetween
                      startTime={activity.startDate}
                      endTime={activity.endDate}
                    />
                  </TableCell>
                </TableRow>
              );
            }
            if (activity.vesselHatchActivityGroup) {
              const hatchActivities = Object.values(
                activity.vesselHatchActivityGroup.activities
              ).map((activity) => {
                return activity;
              });

              return hatchActivities.map((a) => {
                if (a.hatchBreakdownActivity) {
                  const breakdownActivity = a.hatchBreakdownActivity;
                  const reasons = mapReason(
                    breakdownActivity.reason,
                    master.decisions,
                    'Breakdown'
                  );
                  return (
                    <TableRow key={a.id}>
                      <TableCell>{reasons.reason1}</TableCell>
                      <TableCell>{reasons.equipmentType}</TableCell>
                      <TableCell>{breakdownActivity.equipmentName}</TableCell>
                      <TableCell>{reasons.reason3}</TableCell>
                      <TableCell>{breakdownActivity.comments}</TableCell>
                      <TableCell>{displayActivityDates(a.startDate)}</TableCell>
                      <TableCell>{displayActivityDates(a.endDate)}</TableCell>
                      <TableCell>
                        <TimeBetween
                          startTime={a.startDate}
                          endTime={a.endDate}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              });
            }
            return null;
          })}
          <TableRow>
            <TableCell align="right" colSpan={7}>
              <Typography style={{fontWeight: 'bold'}}>
                Total duration:
              </Typography>
            </TableCell>
            <TableCell align="left">
              {' '}
              {DurationTime(
                hatchBreakdownActivitiesTotalDuration +
                  breakdownActivitiesTotalDuration
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} align="left">
              <Typography variant="h4">Stop vessel activities</Typography>
            </TableCell>
          </TableRow>
          <TableCell>Activity type</TableCell>
          <TableCell>Reason 1</TableCell>
          <TableCell>Comment</TableCell>
          <TableCell>Start date</TableCell>
          <TableCell>End date</TableCell>

          <TableCell>Activity duration</TableCell>
        </TableHead>
        <TableBody>
          {stopVesselActivities.map((activity) => {
            const stopVesselActivity = activity.stopVesselActivity;
            if (stopVesselActivity) {
              const reasons = mapReason(
                stopVesselActivity.reason,
                master.decisions
              );

              return (
                <TableRow key={activity.id}>
                  <TableCell>{reasons.activity}</TableCell>
                  <TableCell>{reasons.reason1}</TableCell>
                  <TableCell>{stopVesselActivity.comments}</TableCell>
                  <TableCell>
                    {displayActivityDates(activity.startDate)}
                  </TableCell>
                  <TableCell>
                    {displayActivityDates(activity.endDate)}
                  </TableCell>
                  <TableCell>
                    <TimeBetween
                      startTime={activity.startDate}
                      endTime={activity.endDate}
                    />
                  </TableCell>
                </TableRow>
              );
            }
            return null;
          })}

          <TableRow>
            <TableCell align="right" colSpan={5}>
              <Typography style={{fontWeight: 'bold'}}>
                Total duration:
              </Typography>
            </TableCell>
            <TableCell align="left">
              {DurationTime(stopVesselActivitiesTotalDuration)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} align="left">
              <Typography variant="h4">Survey activities</Typography>
            </TableCell>
          </TableRow>
          <TableCell>Survey type</TableCell>
          <TableCell>Start date</TableCell>
          <TableCell>End date</TableCell>
          <TableCell>Survey duration</TableCell>
        </TableHead>
        <TableBody>
          {surveyActivities.map((activity) => {
            const surveyActivity = activity.surveyActivity;
            if (surveyActivity) {
              const st =
                getSurveyType(surveyActivity.surveyType)?.description ??
                'No Type found';

              return (
                <TableRow key={activity.id}>
                  <TableCell>{st}</TableCell>
                  <TableCell>
                    {displayActivityDates(activity.startDate)}
                  </TableCell>
                  <TableCell>
                    {displayActivityDates(activity.endDate)}
                  </TableCell>

                  <TableCell>
                    <TimeBetween
                      startTime={activity.startDate}
                      endTime={activity.endDate}
                    />
                  </TableCell>
                </TableRow>
              );
            }
            return null;
          })}

          <TableRow>
            <TableCell align="right" colSpan={3}>
              <Typography style={{fontWeight: 'bold'}}>
                Total duration:
              </Typography>
            </TableCell>
            <TableCell align="left">
              {DurationTime(surveyActivitiesTotalDuration)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
