import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Formik, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import * as yup from 'yup';

interface Props {
  open: boolean;
  setOpen: (id: string) => void;
  handleAddShipmentNumber: (shipmentNumber: string) => void;
  shipmentNumbers: string[];
}
export function AddShipmentModal({
  open,
  setOpen,
  handleAddShipmentNumber,
  shipmentNumbers,
}: Props) {
  const validationSchema = yup.object().shape({
    shipmentNumber: yup
      .string()
      .required('This field is required')
      .test(
        'notIncludedTest',
        'The Shipment number you are trying to add has already been added',
        (value) => {
          if (shipmentNumbers.indexOf(value) !== -1) {
            return false;
          } else {
            return true;
          }
        }
      ),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{shipmentNumber: ''}}
      onSubmit={(values, {resetForm}) => {
        handleAddShipmentNumber(values.shipmentNumber);
        resetForm();
      }}
    >
      {({submitForm, resetForm}) => {
        const handleClose = () => {
          setOpen('');
          resetForm();
        };
        return (
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Task</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter the shipment number
                </DialogContentText>
                <Field
                  component={TextField}
                  margin="dense"
                  label="Shipment number"
                  fullWidth
                  name="shipmentNumber"
                />
              </DialogContent>
              <DialogActions>
                <Button size="small" onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button size="small" onClick={submitForm} color="primary">
                  Add Shipment Number
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }}
    </Formik>
  );
}
