import React from 'react';
//TODO JZ
// import {ErrorDisplay} from '../components/ErrorDisplay';
import {PageLoading} from 'components/PageLoading';
import {createMasterDataService} from 'services';
import {Master, Decision, DecisionType, DecisionAnswer} from '@deckmans/domain';
import {print} from '@deckmans/domain/lib/decision';
import {useNavigatorOnline} from 'hooks/useNavigatorOnline';
import {CO} from 'components/types';
import {FullPageError} from 'components/FullPageError';
import {useIndexedDbContext} from 'contexts/IndexedDbContext';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import {useAlertContext} from 'contexts/AlertContex';
import {useClearSiteData} from 'hooks/useClearSiteData';
import {notify} from 'appInsights';
import _ from 'lodash';

export interface DataContextType {
  master: Master;
  decision: (key: DecisionType) => Decision;
  optionCo: (key: DecisionType) => CO[];
  printDecision: (decisionAnswer?: DecisionAnswer) => string[];
  seedChanged: boolean;
  handleMasterClear: () => Promise<void>;
  fetchAndUpdateMasterData: () => Promise<void>;
}

export const DataContext = React.createContext<DataContextType>({
  master: {
    seed: '',
    decisions: [],
    routes: [],
    tipplers: [],
    equipmentTypes: [],
    stockpiles: [],
    trains: [],
    clients: [],
    oreTypes: [],
    clientNoOreType: [],
    vesselNames: [],
  },
  decision: () => {
    return Decision.fromPartial({});
  },
  optionCo: () => [],
  printDecision: () => ['dummy'],
  seedChanged: false,
  handleMasterClear: async () => {},
  fetchAndUpdateMasterData: async () => {},
});

export const useDataContext = () =>
  React.useContext<DataContextType>(DataContext);

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

export function DataContextProvider({children}: Props) {
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [error, setError] = React.useState<Error>();
  const [master, setMaster] = React.useState<Master | undefined>(undefined);
  const isOnline = useNavigatorOnline();
  const db = useIndexedDbContext();
  const [seedId, setSeedId] = useLocalStorage<string>('seedId', '');
  const [seedUpdateRequired, setSeedUpdateRequired] = React.useState(false);
  const {alert} = useAlertContext();
  const {clear} = useClearSiteData();

  const hasSeedIdChange = React.useCallback(
    (master: Master) => {
      if (master.seed === seedId) {
        setSeedUpdateRequired(false);
        return false;
      } else {
        setSeedUpdateRequired(true);
        return true;
      }
    },
    [setSeedUpdateRequired, seedId]
  );

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (seedUpdateRequired) {
        alert(
          'Please sync your data and update your Master Data as soon as possible',
          'warning'
        );
      }
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(intervalId);
    };
  }, [alert, seedUpdateRequired]);

  const masterDataService = React.useMemo(() => {
    return createMasterDataService({
      hooks: {
        beforeRetry: [
          ({retryCount}) => {
            // TODO: handle this
            // eslint-disable-next-line no-console
            console.log({retryCount});
          },
        ],
      },
    });
  }, []);

  const handleMasterClear = React.useCallback(async () => {
    //need to get new seed id;
    const masterData = await masterDataService.GetMaster({});
    if (masterData) {
      setSeedId(masterData.seed);
      // clear all site data even state to update db structure
      clear();
    } else {
      alert('Something went wrong, please try again in a little while', 'info');
    }
  }, [clear, setSeedId, alert, masterDataService]);

  const fetchAndUpdateMasterData = React.useCallback(async () => {
    const masterData = await masterDataService.GetMaster({});
    if (masterData) {
      setSeedId(masterData.seed);
      setMaster(masterData);
      db.saveMasterData(masterData);
    } else {
      alert('Something went wrong, please try again in a little while', 'info');
    }
  }, [alert, db, masterDataService, setSeedId]);

  const fetchData = React.useCallback(async () => {
    try {
      if (isOnline) {
        const masterData = await masterDataService.GetMaster({});
        let idChanged = false;
        if (seedId === '') {
          setSeedId(masterData.seed);
          idChanged = false;
        } else {
          idChanged = hasSeedIdChange(masterData);
        }

        if (!idChanged) {
          //Keep old master data until sync finished and user allows update
          setMaster(masterData);
          db.saveMasterData(masterData);
          setError(undefined);
        }
      } else {
        const dbMasterData = await db.getMasterData();
        if (dbMasterData) {
          setMaster(dbMasterData);
        } else {
          setError(new Error('No local Master Data'));
        }
      }
      setError(undefined);
      setFirstLoad(false);
    } catch (ex) {
      notify(ex);
      setError(new Error(`Failed to Retrieve Master Data: ${ex.message}`));
    }
  }, [isOnline, masterDataService, db, hasSeedIdChange, seedId, setSeedId]);

  //TODO update master data in indexdb once new vessels have been added offline?
  // const updateLocalMaster = React.useCallback(async () => {
  //   try {
  //     const pendingCount = await db.getPendingEventsCount();
  //     if (pendingCount > 0) {
  //       const master = await db.getMasterData();
  //       const events = await db.getPendingEvents();

  //       const vesselNames = master?.vesselNames ?? [];

  //       events.forEach((item) => {
  //         if (item.vesselCreated?.name) {
  //           vesselNames.push(item.vesselCreated.name);
  //         } else if (item.vesselEdited?.name) {
  //           vesselNames.push(item.vesselEdited.name);
  //         }
  //       });

  //       db.saveMasterData({
  //         ...master,
  //         vesselNames,
  //       });
  //     } else {
  //     }
  //   } catch {}
  // }, [isOnline]);

  React.useEffect(() => {
    if (!isOnline) {
      db.getMasterData()
        .then((masterData) => {
          if (masterData) {
            setMaster(masterData);
          }
          setFirstLoad(false);
        })
        .catch((ex) => {
          setError(ex);
        });
    } else {
      fetchData()
        .then(() => {
          setFirstLoad(false);
        })
        .catch((ex) => {
          setError(ex);
        });
    }
  }, [db, fetchData, isOnline]);

  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      fetchData();
    }, 1000 * 60 * 10);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData]);

  const decision = React.useCallback(
    (key: DecisionType) => {
      const dc = master?.decisions.find((decision) => {
        return decision.type === key;
      });
      if (dc) {
        return dc;
      } else {
        throw new Error('Decision does not exist' + key);
      }
    },
    [master]
  );

  const optionCo = React.useCallback(
    (key: DecisionType) => {
      const orderedOptions = _.orderBy(
        decision(key)?.options,
        ['sequence'],
        ['asc']
      );
      const dc =
        orderedOptions.map(({key, name}) => {
          return {id: key, description: name};
        }) ?? [];
      if (dc) {
        return dc;
      } else {
        throw new Error('Decision CO does not exist');
      }
    },
    [decision]
  );
  const printDecision = React.useCallback(
    (decisionAnswer?: DecisionAnswer) => {
      return print(decisionAnswer, master?.decisions ?? []);
    },
    [master]
  );

  const value = React.useMemo(
    () => ({
      master: master ?? {
        seed: '',
        decisions: [],
        equipmentTypes: [],
        routes: [],
        tipplers: [],
        trains: [],
        stockpiles: [],
        clients: [],
        oreTypes: [],
        clientNoOreType: [],
        vesselNames: [],
      },
      optionCo,
      decision,
      printDecision,
      seedChanged: seedUpdateRequired,
      handleMasterClear,
      fetchAndUpdateMasterData,
    }),
    [
      master,
      optionCo,
      decision,
      printDecision,
      handleMasterClear,
      seedUpdateRequired,
      fetchAndUpdateMasterData,
    ]
  );

  if (firstLoad) {
    return <PageLoading text="Loading Master Data" />;
  }
  if (error) {
    return <FullPageError error={error} />;
  }
  if (!master) {
    return <FullPageError error={new Error('Master Data not found')} />;
  }
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
