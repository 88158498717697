import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch10SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '69px', width: '11px'}}
      viewBox="0 0 11 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0898 61.0977L10.0898 62.8203L5.66699 62.8203L5.66699 67.2705L10.0898 67.2705L10.0898 69L0.136719 69L0.136719 67.2705L4.2793 67.2705L4.2793 62.8203L0.136718 62.8203L0.136718 61.0977L10.0898 61.0977Z"
        fill="white"
      />
      <path
        d="M7.77246 52.6025L7.77246 56.458L10.0898 57.2646L10.0898 59.0625L0.136718 55.3027L0.136718 53.751L10.0898 49.9844L10.0898 51.7891L7.77246 52.6025ZM6.37793 55.9727L6.37793 53.0879L2.24902 54.5303L6.37793 55.9727Z"
        fill="white"
      />
      <path
        d="M1.53125 41.7285L1.53125 44.832L10.0898 44.832L10.0898 46.5479L1.53125 46.5479L1.53125 49.624L0.136718 49.624L0.136718 41.7285L1.53125 41.7285Z"
        fill="white"
      />
      <path
        d="M6.84961 32.2627C7.91146 32.363 8.74088 32.7549 9.33789 33.4385C9.93034 34.1221 10.2266 35.0312 10.2266 36.166C10.2266 36.959 10.0397 37.6585 9.66601 38.2646C9.28776 38.8662 8.75228 39.3311 8.05957 39.6592C7.36686 39.9873 6.5625 40.1582 5.64648 40.1719L4.7168 40.1719C3.77799 40.1719 2.95085 40.0055 2.23535 39.6729C1.51986 39.3402 0.968423 38.8639 0.581053 38.2441C0.193684 37.6198 -1.40315e-06 36.8997 -1.4388e-06 36.084C-1.48681e-06 34.9857 0.298501 34.1016 0.895506 33.4316C1.49251 32.7617 2.33561 32.3721 3.4248 32.2627L3.4248 33.9854C2.70931 34.0674 2.19433 34.277 1.87988 34.6143C1.56087 34.9469 1.40137 35.4368 1.40137 36.084C1.40137 36.8359 1.67708 37.4147 2.22851 37.8203C2.77539 38.2214 3.57975 38.4264 4.6416 38.4355L5.52344 38.4355C6.59896 38.4355 7.41927 38.2441 7.98437 37.8613C8.54948 37.474 8.83203 36.9089 8.83203 36.166C8.83203 35.487 8.67936 34.9766 8.37402 34.6348C8.06868 34.293 7.56055 34.0765 6.84961 33.9854L6.84961 32.2627Z"
        fill="white"
      />
      <path
        d="M10.0898 22.0586L10.0898 23.7812L5.66699 23.7812L5.66699 28.2314L10.0898 28.2314L10.0898 29.9609L0.136717 29.9609L0.136717 28.2314L4.2793 28.2314L4.27929 23.7812L0.136717 23.7812L0.136717 22.0586L10.0898 22.0586Z"
        fill="white"
      />
      <path
        d="M10.0898 10.79L10.0898 12.4443L2.09863 12.4443L2.93261 14.8848L1.53125 14.8848L0.102537 11.002L0.102537 10.79L10.0898 10.79Z"
        fill="white"
      />
      <path
        d="M5.89941 0.0937497C7.3304 0.0937497 8.41048 0.36263 9.13965 0.90039C9.86425 1.43359 10.2266 2.24707 10.2266 3.34082C10.2266 4.41634 9.87337 5.22754 9.16699 5.77441C8.45605 6.31673 7.4056 6.59473 6.01562 6.6084L4.29297 6.6084C2.86198 6.6084 1.78873 6.3418 1.07324 5.80859C0.357745 5.27083 -2.82145e-06 4.4528 -2.86946e-06 3.35449C-2.91707e-06 2.2653 0.34863 1.4541 1.0459 0.920898C1.74316 0.383138 2.78678 0.107422 4.17675 0.0937498L5.89941 0.0937497ZM4.04004 1.75488C3.10579 1.75488 2.4222 1.88249 1.98925 2.1377C1.55175 2.3929 1.333 2.7985 1.333 3.35449C1.333 3.89681 1.54036 4.29557 1.95508 4.55078C2.36523 4.80599 3.00781 4.94043 3.88281 4.9541L6.13867 4.9541C7.06836 4.9541 7.76106 4.82422 8.21679 4.56445C8.67252 4.30469 8.90039 3.89681 8.90039 3.34082C8.90039 2.80762 8.69075 2.41341 8.27148 2.1582C7.84765 1.90299 7.18685 1.76855 6.28906 1.75488L4.04004 1.75488Z"
        fill="white"
      />
    </SvgIcon>
  );
}
