import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {HatchSummaryEditDialog} from './HatchSummaryEditDialog';
import {VesselHatchActivityGroup} from '@deckmans/domain';

const useStyles = makeStyles((theme: Theme) => ({
  statusBlockComplete: {
    backgroundColor: theme.palette.success.main,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },
  statusBlockNotComplete: {
    backgroundColor: theme.palette.info.main,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  vesselHatchGroup: VesselHatchActivityGroup;
  vesselId: string;
}
function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
export function HatchSummary({vesselHatchGroup, vesselId}: Props) {
  const {completed, oreType} = vesselHatchGroup;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  let oreId = '';

  if (oreType) {
    oreId = oreType;
  }
  const oreTypeText = oreId;

  const roundedRate = completed?.hatchRate
    ? Math.round(completed.hatchRate)
    : 0;

  return (
    <>
      <Typography variant="subtitle2">Summary</Typography>
      {completed ? (
        <ListItem
          className={classes.statusBlockComplete}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Typography variant="subtitle1" color="inherit">
            Tons
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {completed.hatchTons
              ? numberWithSpaces(completed.hatchTons) + 't'
              : completed.hatchTons + 't'}
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            Rate
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {numberWithSpaces(roundedRate) + ' tph'}
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            {oreTypeText}
          </Typography>
        </ListItem>
      ) : (
        <ListItem className={classes.statusBlockNotComplete}>
          <Typography variant="subtitle1" color="inherit">
            Not completed
          </Typography>
        </ListItem>
      )}
      <HatchSummaryEditDialog
        open={open}
        setOpen={() => {
          setOpen(!open);
        }}
        vesselHatchGroup={vesselHatchGroup}
        vesselId={vesselId}
      />
    </>
  );
}
