import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Fab,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {HatchActivityInfoBlock} from './HatchActivityInfoBlock';
import {useHistory, useParams} from 'react-router';
import {TextField} from 'formik-material-ui';
import {Field, Formik} from 'formik';
import * as yup from 'yup';
import {useEngineContext} from 'contexts/EngineContext';
import {useTitle} from 'contexts/TitleContext';
import {UnallocatedTimeBlock} from './UnallocatedTimeBlock';
import {HatchActivitiesMenu} from './HatchActivitiesMenu';
import {
  VesselHatchActivity,
  VesselHatchActivityGroup,
  DecisionType,
  VesselState,
} from '@deckmans/domain';
import {Shiploader, UserRole} from '@deckmans/domain/lib/proto/shared';
import {useVesselActivity, useVessel} from 'hooks/useVessel';
import {FullPageError} from 'components/FullPageError';
import {useAuthContext} from 'contexts/AuthContext';
import {useDataContext} from 'providers/DataContextProvider';
import {HatchActivityLogDelete} from './HatchActivityLogDelete';
import {
  sortVesselHatchActivities,
  convertToExtendedVesselHatchActivity,
  findVesselHatchGroupStartDate,
  findVesselHatchGroupEndDate,
  findVesselHatchGroupStockpileDuration,
  findVesselHatchGroupDirectLoadingDuration,
  findVesselHatchGroupDurations,
} from '@deckmans/domain/lib/util/sortVesselHatchActivities';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    boxShadow:
      '0px 4px 5px #00000021, 0px 3px 14px #0000000F, 0px 8px 10px #00000014',
    borderRadius: '4px',
  },
}));

function findType(activity: VesselHatchActivity) {
  let type = '';
  //find activity type
  if (activity.hatchBreakdownActivity) {
    type = 'hatchBreakdownActivity';
  }
  if (activity.hatchDirectLoadingActivity) {
    type = 'hatchDirectLoadingActivity';
  }
  if (activity.hatchLoadingActivity) {
    type = 'hatchLoadingActivity';
  }
  if (activity.hatchReclaimingActivity) {
    type = 'hatchReclaimingActivity';
  }
  if (activity.hatchAwaitingShiploaderActivity) {
    type = 'hatchAwaitingShiploaderActivity';
  }
  return type;
}

function findId(activity: VesselHatchActivity) {
  return activity.id;
}

function calculateRate(totalTons: number, duration: number) {
  return totalTons / duration;
}

export function HatchActivityLogWrapper() {
  const {hatchId} = useParams<{
    hatchId: string;
  }>();

  const activity = useVesselActivity(hatchId);
  const vesselHatchActivityGroup = activity?.vesselHatchActivityGroup;

  if (!vesselHatchActivityGroup) {
    return (
      <FullPageError
        error={new Error('Vessel Hatch Activity group not found')}
      />
    );
  }
  return (
    <HatchActivityLog
      vesselHatchActivityGroup={vesselHatchActivityGroup}
      hatchId={hatchId}
      shiploader={activity?.shiploader ?? Shiploader.UNRECOGNIZED}
      activityId={activity?.id ?? ''}
    />
  );
}

interface Props {
  vesselHatchActivityGroup: VesselHatchActivityGroup;
  hatchId: string;
  shiploader: Shiploader;
  activityId: string;
}

type localHatchDetailsType = {
  tons: number;
  directLoadingTons: number;
  hatchId: string;
};

export function HatchActivityLog({
  vesselHatchActivityGroup,
  hatchId,
  shiploader,
  activityId,
}: Props) {
  useTitle(`Deckmans`);
  const classes = useStyles();
  const {hasRole} = useAuthContext();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const {eventCreator, engine} = useEngineContext();
  const {printDecision} = useDataContext();
  const hatchNumber = vesselHatchActivityGroup?.hatchNumber ?? 0;
  const client = vesselHatchActivityGroup?.client ?? 'No Client specified';

  //use local storage in order to keep the hatch tons when un-finishing a hatch
  const [localHatchDetails, setLocalHatchDetails] = useLocalStorage<
    localHatchDetailsType
  >('localHatchDetails', {tons: 0, directLoadingTons: 0, hatchId: ''});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {decision} = useDataContext();

  const nonHatchReclaimDecision = decision(
    DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY
  );

  const hatchAwaitingShiploaderDecision = decision(
    DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY
  );

  const vessel = useVessel();
  const isVesselSubmitted = vessel.state === VesselState.VESSEL_STATE_COMPLETE;
  const roleAllowsAfterVesselSubmitted =
    hasRole(UserRole.USER_ROLE_ADMIN) || hasRole(UserRole.USER_ROLE_SUPERVISOR);
  const roleAllowsBeforeVesselSubmitted =
    hasRole(UserRole.USER_ROLE_ADMIN) ||
    hasRole(UserRole.USER_ROLE_SUPERVISOR) ||
    hasRole(UserRole.USER_ROLE_USER);

  const vesselActivities = sortVesselHatchActivities(vesselHatchActivityGroup);

  const activities = convertToExtendedVesselHatchActivity(vesselActivities);

  const hatchFinished = !!vesselHatchActivityGroup.completed;

  React.useEffect(() => {
    if (hatchFinished) {
      setLocalHatchDetails({
        tons: vesselHatchActivityGroup.completed?.hatchTons ?? 0,
        directLoadingTons:
          vesselHatchActivityGroup.completed?.directLoadingTons ?? 0,
        hatchId,
      });
    }
  }, [
    hatchFinished,
    setLocalHatchDetails,
    vesselHatchActivityGroup.completed,
    hatchId,
  ]);

  const containsLoadingOrDirectLoading = activities.some((act) => {
    return act.hatchDirectLoadingActivity || act.hatchLoadingActivity;
  });

  const containsLoading = activities.some((act) => {
    return act.hatchLoadingActivity;
  });

  const containsDirectLoading = activities.some((act) => {
    return act.hatchDirectLoadingActivity;
  });

  const duration = findVesselHatchGroupDurations(activities);

  const stockpileDuration = findVesselHatchGroupStockpileDuration(activities);

  const directLoadingDuration = findVesselHatchGroupDirectLoadingDuration(
    activities
  );

  let unallocated = false;
  activities.map((act) => {
    if (act.unAllocatedTime) {
      unallocated = true;
    }
    return unallocated;
  });

  const validationSchema = yup.object().shape({
    tons:
      containsDirectLoading && containsLoading
        ? yup
            .number()
            .typeError('Tons must be a number')
            .moreThan(0, 'Tons cannot be 0')
            .moreThan(
              yup.ref('directLoadingTons'),
              'Total tons must be more than direct loading tons'
            )
            .required('Please enter tons loaded')
        : yup
            .number()
            .typeError('Tons must be a number')
            .moreThan(0, 'Tons cannot be 0')
            .required('Please enter tons loaded'),
    directLoadingTons:
      containsDirectLoading && containsLoading
        ? yup
            .number()
            .typeError('Direct loading tons must be a number')
            .moreThan(0, 'Direct loading tons cannot be 0')
            .required('Please enter direct loading tons')
        : yup.number(),
  });

  return (
    <Formik
      initialValues={{
        tons:
          hatchId === localHatchDetails?.hatchId ? localHatchDetails?.tons : 0,
        directLoadingTons:
          localHatchDetails?.hatchId && containsDirectLoading
            ? localHatchDetails?.directLoadingTons
            : 0,
      }}
      onSubmit={async ({tons, directLoadingTons}) => {
        const hatchRate = calculateRate(tons, duration / 60);
        let directLoadingRate = 0;

        if (containsDirectLoading) {
          directLoadingRate = calculateRate(
            containsDirectLoading && containsLoading ? directLoadingTons : tons,
            directLoadingDuration / 60
          );
        }

        let stockpileRate = 0;
        if (containsLoading) {
          stockpileRate = calculateRate(
            containsDirectLoading && containsLoading
              ? tons - directLoadingTons
              : tons,
            stockpileDuration / 60
          );
        }

        const event = eventCreator.stopHatchCreated({
          groupActivityId: vesselHatchActivityGroup.id,
          hatchTons: tons,
          vesselId: vessel.id,
          hatchRate,
          oreType:
            vessel.activities[hatchId].vesselHatchActivityGroup?.oreType ?? '',
          directLoadingTons:
            containsDirectLoading && containsLoading
              ? directLoadingTons
              : containsLoading
              ? 0
              : tons,
          directLoadingRate,
          stockpileRate,
          stockpileTons:
            containsDirectLoading && containsLoading
              ? tons - directLoadingTons
              : containsDirectLoading
              ? 0
              : tons,
        });
        await engine.process(event);
        setOpen(!open);
        history.goBack();
      }}
      validationSchema={validationSchema}
    >
      {({values, submitForm, setFieldValue}) => {
        return (
          <Box>
            <Box pb={3}>
              <Fab
                variant="extended"
                color="primary"
                onClick={handleClick}
                disabled={
                  (isVesselSubmitted
                    ? roleAllowsAfterVesselSubmitted
                      ? false
                      : true
                    : false) ||
                  hatchFinished ||
                  hasRole(UserRole.USER_ROLE_AGENT)
                }
              >
                <AddRoundedIcon />
                Add new activity
              </Fab>

              <HatchActivitiesMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                hatchId={hatchId}
                vesselId={vessel.id}
                shiploader={shiploader}
              />
            </Box>
            <Paper>
              <Box className={classes.container}>
                <Box p={2} display="flex" justifyContent="space-between">
                  <div>
                    <Typography variant="h6" color="primary">
                      {client}
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {`Shiploader ${shiploader} / Hatch ${hatchNumber}`}
                    </Typography>
                  </div>

                  {isVesselSubmitted && roleAllowsAfterVesselSubmitted && (
                    <div>
                      <HatchActivityLogDelete
                        vesselId={vessel.id}
                        hatchId={hatchId}
                        shiploader={shiploader}
                        roleAllows={[
                          UserRole.USER_ROLE_ADMIN,
                          UserRole.USER_ROLE_SUPERVISOR,
                        ]}
                      />
                    </div>
                  )}
                  {!isVesselSubmitted && roleAllowsBeforeVesselSubmitted && (
                    <div>
                      <HatchActivityLogDelete
                        vesselId={vessel.id}
                        hatchId={hatchId}
                        shiploader={shiploader}
                        roleAllows={[
                          UserRole.USER_ROLE_ADMIN,
                          UserRole.USER_ROLE_SUPERVISOR,
                          UserRole.USER_ROLE_USER,
                        ]}
                      />
                    </div>
                  )}
                </Box>
                <Box>
                  {activities.map((activity) => {
                    let activityName = '';
                    let reason = '';
                    const type = findType(activity);
                    if (activity.hatchBreakdownActivity) {
                      const ba = activity.hatchBreakdownActivity;
                      activityName = 'Breakdown';
                      reason =
                        ba.equipmentName !== ''
                          ? ba.equipmentName
                          : printDecision(ba.reason).join(', ');
                    } else if (activity.hatchDirectLoadingActivity) {
                      activityName = 'Direct loading';
                      reason = `Train number: ${activity.hatchDirectLoadingActivity.train} `;
                    } else if (activity.hatchLoadingActivity) {
                      activityName = 'Loading';
                      reason = `Stockpile: ${activity.hatchLoadingActivity.stockpile}`;
                    } else if (activity.hatchReclaimingActivity) {
                      activityName = 'Reclaiming';

                      const reasonText = nonHatchReclaimDecision
                        ? printDecision(activity.hatchReclaimingActivity.reason)
                        : '';
                      reason = `Reason: ${reasonText}`;
                    } else if (activity.hatchAwaitingShiploaderActivity) {
                      activityName = 'Awaiting shiploader';

                      const reasonText = hatchAwaitingShiploaderDecision
                        ? printDecision(
                            activity.hatchAwaitingShiploaderActivity.reason
                          )
                        : '';
                      reason = `Reason: ${reasonText}`;
                    }
                    return (
                      <React.Fragment key={findId(activity)}>
                        {activity.hatchBreakdownActivity ||
                        activity.hatchDirectLoadingActivity ||
                        activity.hatchLoadingActivity ||
                        activity.hatchReclaimingActivity ||
                        activity.hatchAwaitingShiploaderActivity ? (
                          <HatchActivityInfoBlock
                            vesselState={vessel.state}
                            hatchFinished={hatchFinished}
                            startTime={findVesselHatchGroupStartDate(
                              activity
                            ).toDate()}
                            endTime={findVesselHatchGroupEndDate(
                              activity
                            ).toDate()}
                            activity={activityName}
                            reason={reason}
                            onDelete={async () => {
                              const event = eventCreator.activityDeleted(
                                vessel.id,
                                findId(activity)
                              );
                              await engine.process(event);
                            }}
                            onClick={() => {
                              history.push(
                                `/activity/${type}/edit/${
                                  vessel.id
                                }/${shiploader}/${hatchId}/${findId(activity)}`
                              );
                            }}
                          />
                        ) : (
                          <UnallocatedTimeBlock
                            startDate={activity.startDate}
                            endDate={activity.endDate}
                            activityId={activityId}
                            hatchGroupId={hatchId}
                            shiploader={shiploader}
                            vesselId={vessel.id}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}

                  <Divider />
                </Box>
                {!hatchFinished && (
                  <Box py={4} px={2}>
                    <Button
                      disabled={
                        hasRole(UserRole.USER_ROLE_AGENT) ||
                        hatchFinished ||
                        (activities.length > 0 ? false : true) ||
                        (unallocated ? true : false) ||
                        (containsLoadingOrDirectLoading ? false : true) ||
                        (isVesselSubmitted
                          ? roleAllowsAfterVesselSubmitted
                            ? false
                            : true
                          : false)
                      }
                      fullWidth
                      color="secondary"
                      onClick={() => {
                        setFieldValue(
                          'directLoadingTons',
                          containsDirectLoading &&
                            hatchId === localHatchDetails?.hatchId
                            ? localHatchDetails?.directLoadingTons
                            : 0
                        );
                        setOpen(!open);
                      }}
                    >
                      {hatchFinished
                        ? 'hatch finished'
                        : unallocated
                        ? 'there is unallocated time / time overlap'
                        : 'finish hatch'}
                    </Button>
                  </Box>
                )}
                {hatchFinished &&
                  isVesselSubmitted &&
                  roleAllowsAfterVesselSubmitted && (
                    <Box py={4} px={2}>
                      <Button
                        fullWidth
                        color="secondary"
                        onClick={async () => {
                          setFieldValue('tons', localHatchDetails?.tons);
                          setFieldValue(
                            'directLoadingTons',
                            localHatchDetails?.directLoadingTons
                          );

                          const event = eventCreator.stopHatchDeleted(
                            vessel.id,
                            vesselHatchActivityGroup.id,
                            vesselHatchActivityGroup.id
                          );
                          await engine.process(event);

                          const vesselEditEvent = eventCreator.vesselEdited({
                            id: vessel.id,
                            name: vessel.name.toUpperCase(),
                            comments: vessel.comments,
                            berth: vessel.berth,
                            reclaimStart: moment(vessel.reclaimStart).toDate(),
                            clients: vessel.clients,
                            state: VesselState.VESSEL_STATE_IN_PROGRESS,
                            delay: vessel.delay,
                            previousVesselId: vessel.previousVesselId,
                            delayReason2: vessel.delayReason2,
                            secondComments: vessel.secondComments,
                            timeBetweenVesselHours:
                              vessel.timeBetweenVesselHours,
                            timeBetweenVesselMinutes:
                              vessel.timeBetweenVesselMinutes,
                          });

                          await engine.process(vesselEditEvent);
                        }}
                      >
                        Undo finish hatch
                      </Button>
                    </Box>
                  )}
                {hatchFinished &&
                  !isVesselSubmitted &&
                  roleAllowsBeforeVesselSubmitted && (
                    <Box py={4} px={2}>
                      <Button
                        fullWidth
                        color="secondary"
                        onClick={async () => {
                          setFieldValue('tons', localHatchDetails?.tons);
                          setFieldValue(
                            'directLoadingTons',
                            localHatchDetails?.directLoadingTons
                          );

                          const event = eventCreator.stopHatchDeleted(
                            vessel.id,
                            vesselHatchActivityGroup.id,
                            vesselHatchActivityGroup.id
                          );
                          await engine.process(event);

                          const vesselEditEvent = eventCreator.vesselEdited({
                            id: vessel.id,
                            name: vessel.name.toUpperCase(),
                            comments: vessel.comments,
                            berth: vessel.berth,
                            reclaimStart: moment(vessel.reclaimStart).toDate(),
                            clients: vessel.clients,
                            state: VesselState.VESSEL_STATE_IN_PROGRESS,
                            delay: vessel.delay,
                            previousVesselId: vessel.previousVesselId,
                            delayReason2: vessel.delayReason2,
                            secondComments: vessel.secondComments,
                            timeBetweenVesselHours:
                              vessel.timeBetweenVesselHours,
                            timeBetweenVesselMinutes:
                              vessel.timeBetweenVesselMinutes,
                          });

                          await engine.process(vesselEditEvent);
                        }}
                      >
                        Undo finish hatch
                      </Button>
                    </Box>
                  )}
              </Box>
            </Paper>
            <Dialog
              open={open}
              onClose={() => {
                setOpen(!open);
              }}
              fullWidth
            >
              <DialogTitle disableTypography>
                <Typography variant="h6">Tons loaded (Tons)</Typography>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Field
                    component={TextField}
                    name="tons"
                    label="Total Tons"
                    placeholder="Total Tons"
                    fullWidth
                    onFocus={(event: React.FormEvent<HTMLInputElement>) => {
                      event.currentTarget.setSelectionRange(
                        0,
                        event.currentTarget.size
                      );
                    }}
                  />
                </Box>
                {containsDirectLoading && containsLoading && (
                  <Box pt={3}>
                    <Field
                      fullWidth
                      component={TextField}
                      name="directLoadingTons"
                      label="Direct loading tons"
                      placeholder="Direct loading tons"
                      onFocus={(event: React.FormEvent<HTMLInputElement>) => {
                        event.currentTarget.setSelectionRange(
                          0,
                          event.currentTarget.size
                        );
                      }}
                    />
                  </Box>
                )}

                {values.directLoadingTons > 0 && (
                  <Box display="flex" justifyContent="space-between" pt={3}>
                    <Typography variant="subtitle1">Direct loading</Typography>
                    <Typography variant="subtitle1">
                      {values.directLoadingTons}
                    </Typography>
                  </Box>
                )}
                {values.tons !== 0 && containsLoading && (
                  <Box display="flex" justifyContent="space-between" pt={3}>
                    <Typography variant="subtitle1">
                      Stockpile loading
                    </Typography>
                    <Typography variant="subtitle1">
                      {values.directLoadingTons > 0
                        ? values.tons - values.directLoadingTons
                        : values.tons}
                    </Typography>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button fullWidth color="primary" onClick={submitForm}>
                      save & finish
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="text"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      cancel
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Box>
        );
      }}
    </Formik>
  );
}
