import * as React from 'react';
import {Box, Divider, Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {Field} from 'formik';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {TextField} from 'formik-material-ui';
import {ButtonBar} from 'components/ButtonBar';
import {useHistory, useParams} from 'react-router';
import {BaseForm} from 'components/BaseForm';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {StartEndDateTime} from '@deckmans/web-shared';
import * as yup from 'yup';

import {useShiploader} from '../../hooks/useShiploader';
import {DecisionAnswer, DecisionType} from '@deckmans/domain';
import {useVessel, useVesselActivity} from 'hooks/useVessel';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY,
});

interface NonHatchAwaitingShiploaderActivityType {
  startTime: string;
  endTime: string;
  comments: string;
  id: string;
  reason: DecisionAnswer;
}

export function NonHatchAwaitingShiploaderActivity() {
  const history = useHistory();
  const {engine, eventCreator} = useEngineContext();

  const {activityId, shiploaderId} = useParams<{
    shiploaderId: string;
    activityId: string;
  }>();

  const shiploader = useShiploader(shiploaderId);

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const vessel = useVessel();
  const reclaimStart = vessel?.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    reason: yup.string().required('This field is required'),
  });

  const activity = useVesselActivity(activityId);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);

  const sd = pvEndDate !== '' ? pvEndDate : vessel?.reclaimStart ?? new Date();
  const {startDate, endDate} = useHistoryStateDateRange(sd);

  const initialValues = React.useMemo(() => {
    if (
      isEditing &&
      vessel &&
      activity &&
      activity.nonHatchAwaitingShiploaderActivity
    ) {
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments: activity.nonHatchAwaitingShiploaderActivity.comments,
        id: activity.id,
        shiploader,
        reason:
          activity.nonHatchAwaitingShiploaderActivity.reason ?? defaultReason,
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        comments: '',
        id: '',
        shiploader,
        reason: defaultReason,
      };
    }
  }, [isEditing, vessel, activity, shiploader, startDate, endDate]);

  return (
    <BaseForm<NonHatchAwaitingShiploaderActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={initialValues}
      onCreateSubmit={async ({startTime, endTime, comments, reason}) => {
        const event = eventCreator.nonHatchAwaitingShiploaderActivityCreated({
          comments,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          shiploader,
          vesselId: vessel.id,
        });

        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({startTime, endTime, comments, id, reason}) => {
        const event = eventCreator.nonHatchAwaitingShiploaderActivityEdited({
          id,
          comments,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          shiploader,
          vesselId: vessel.id,
        });
        await engine.process(event);
        history.goBack();
      }}
    >
      {({values, submitForm}) => {
        return (
          <Box py={2}>
            <Grid container spacing={3}>
              {isEditing && (
                <Grid item xs={12}>
                  <Box px={1}>
                    <Typography variant="h4" color="primary">
                      Awaiting shiploader
                    </Typography>
                  </Box>
                </Grid>
              )}
              <StartEndDateTime />

              <Grid item xs={6}>
                <Box px={1}>
                  <Duration
                    startTime={values.startTime}
                    endTime={values.endTime}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <MultiLevelQuestions
                  name="reason"
                  decisionType={
                    DecisionType.DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Box px={1}>
                  <Field
                    fullWidth
                    multiline
                    component={TextField}
                    name="comments"
                    label="Comments"
                    placeholder="Comments"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ButtonBar
                  saveText="save"
                  createText="create"
                  isEditing={isEditing}
                  handleSave={submitForm}
                  vessel={vessel}
                  activityId={activityId}
                  title="Delete Awaiting Shiploader Activity"
                  subtitle="Are you sure you want to delete this activity?"
                />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </BaseForm>
  );
}
