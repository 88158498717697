import * as React from 'react';
import {
  Route as PublicRoute,
  Switch,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router';
import {LoginPage} from './modules/LoginPage/LoginPage';
import {Logbook} from './modules/Logbook/Logbook';
import {VesselEditor} from './modules/VesselEditor/VesselEditor';
import {ThemeSpecifics} from './modules/ThemeSpecifics/ThemeSpecifics';
import {Test} from 'modules/Test/Test';
import {HatchActivityEditor} from 'modules/HatchActivityEditor/HatchActivityEditor';
import {HatchActivityLogWrapper} from 'modules/HatchActivityLog/HatchActivityLogScreen';
import {BreakdownActivity} from 'modules/HatchActivities/BreakdownActivity/BreakdownActivity';
import {VesselView} from 'modules/VesselView/VesselView';
import {DirectLoadingActivity} from 'modules/HatchActivities/DirectLoadingActivity/DirectLoadingActivity';
import {LoadingActivity} from 'modules/HatchActivities/LoadingActivity/LoadingActivity';
import {ReclaimingActivity} from 'modules/HatchActivities/ReclaimingActivity/ReclaimingActivity';
import {SingleLoadingActivity} from 'modules/SingleLoadingActivity/SingleLoadingActivity';
import {NonHatchActivity} from 'modules/NonHatchActivities/NonHatchActivity';
import {StopVesselActivity} from 'modules/StopVesselActivities/StopVesselActivity';
import {SurveyActivityEditor} from 'modules/SurveyActivityEditor/SurveyActivityEditor';
import {AuthContext} from 'contexts/AuthContext';
import {makeStyles} from '@material-ui/core';
import {VesselOverview} from 'modules/VesselOverview/VesselOverview';
import {Page404} from 'modules/404/Page404';
import {VesselSearch} from 'modules/VesselSearch/VesselSearch';
import {HatchAwaitingShiploaderActivity} from 'modules/HatchActivities/AwaitingShiploaderActivity/HatchAwaitingShiploader';
import {CreatePassword} from 'modules/CreatePassword/CreatePassword';
import {ResetPassword} from 'modules/ResetPassword/ResetPassword';
import {TokenExpired} from 'modules/TokenExpired/TokenExpired';

const useStyles = (isLogin: boolean) => {
  return makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: isLogin ? 0 : '0px 20px 20px 20px',
      maxWidth: 850,
      [theme.breakpoints.up('md')]: {
        margin: '0 auto',
      },
    },
  }));
};

function PrivateRoute(props: Omit<RouteProps, 'children' | ''>) {
  const {auth} = React.useContext(AuthContext);

  if (!auth.authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {from: props.location},
        }}
      />
    );
  }

  return <PublicRoute {...props} />;
}

export function Todo() {
  return <>TODO</>;
}

export function Routes() {
  const history = useHistory();
  const classes = useStyles(history.location.pathname === '/login')();
  return (
    <div className={classes.container}>
      <Switch>
        <PublicRoute exact path="/login" component={LoginPage} />
        <PublicRoute exact path="/createPassword" component={CreatePassword} />
        <PublicRoute
          exact
          path="/forgotPassword"
          component={() => <CreatePassword label="Forgot Password" />}
        />
        <PublicRoute
          exact
          path="/changePassword"
          component={() => (
            <CreatePassword
              message="You have not changed your password in the last 90 days. Please change your password below to proceed."
              label="Change Password"
            />
          )}
        />
        <PublicRoute exact path="/resetPassword" component={ResetPassword} />
        <PublicRoute exact path="/tokenExpired" component={TokenExpired} />
        <PrivateRoute
          exact
          path="/"
          component={() => <Redirect to="/logbook" />}
        />
        <PrivateRoute exact path="/logbook" component={Logbook} />
        <PrivateRoute exact path="/search" component={VesselSearch} />
        <PrivateRoute exact path="/vessel/new" component={VesselEditor} />
        <PrivateRoute
          exact
          path="/vessel/edit/:vesselId"
          component={VesselEditor}
        />
        <PrivateRoute
          exact
          path="/vessel/view/:vesselId"
          component={VesselView}
        />
        <PrivateRoute
          exact
          path={'/hatch/new/:shiploaderId/:vesselId'}
          component={HatchActivityEditor}
        />
        <PrivateRoute
          exact
          path={'/hatch/edit/:vesselId/:shiploaderId/:hatchId'}
          component={HatchActivityEditor}
        />
        <PrivateRoute
          exact
          path="/hatch/view/:vesselId/:hatchId/"
          component={HatchActivityLogWrapper}
        />

        <PrivateRoute
          exact
          path="/activity/surveyActivity/new/:vesselId"
          component={SurveyActivityEditor}
        />
        <PrivateRoute
          exact
          path="/activity/surveyActivity/edit/:vesselId/:activityId"
          component={SurveyActivityEditor}
        />

        <PrivateRoute
          exact
          path="/activity/hatchDirectLoadingActivity/new/:vesselId/:shiploaderId/:hatchId"
          component={DirectLoadingActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchDirectLoadingActivity/edit/:vesselId/:shiploaderId/:hatchId/:activityId"
          component={DirectLoadingActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchLoadingActivity/new/:vesselId/:shiploaderId/:hatchId"
          component={LoadingActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchLoadingActivity/edit/:vesselId/:shiploaderId/:hatchId/:activityId"
          component={LoadingActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchReclaimingActivity/new/:vesselId/:shiploaderId/:hatchId"
          component={ReclaimingActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchReclaimingActivity/edit/:vesselId/:shiploaderId/:hatchId/:activityId"
          component={ReclaimingActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchBreakdownActivity/new/:vesselId/:shiploaderId/:hatchId"
          component={BreakdownActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchBreakdownActivity/edit/:vesselId/:shiploaderId/:hatchId/:activityId"
          component={BreakdownActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchAwaitingShiploaderActivity/new/:vesselId/:shiploaderId/:hatchId"
          component={HatchAwaitingShiploaderActivity}
        />
        <PrivateRoute
          exact
          path="/activity/hatchAwaitingShiploaderActivity/edit/:vesselId/:shiploaderId/:hatchId/:activityId"
          component={HatchAwaitingShiploaderActivity}
        />
        <PrivateRoute
          path="/activity/single-loading/:shiploaderId/:vesselId"
          exact
          component={SingleLoadingActivity}
        />
        <PrivateRoute
          path="/activity/single-loading/edit/:shiploaderId/:vesselId/:activityId"
          exact
          component={SingleLoadingActivity}
        />

        <PrivateRoute
          path="/activity/non-hatch-activity/:shiploaderId/:vesselId"
          exact
          component={NonHatchActivity}
        />
        <PrivateRoute
          path="/activity/stop-vessel-activity/:vesselId"
          exact
          component={StopVesselActivity}
        />

        <PrivateRoute
          path="/activity/stop-vessel-activity/edit/:vesselId/:activityId"
          exact
          component={StopVesselActivity}
        />

        <PrivateRoute
          path="/activity/non-hatch-activity/edit/:shiploaderId/:vesselId/:activityId"
          exact
          component={NonHatchActivity}
        />
        <PrivateRoute
          path="/vesselOverview/:vesselId"
          exact
          component={VesselOverview}
        />
        <PrivateRoute
          path="/activity/hatch/:activityId/loading/new"
          exact
          component={Todo}
        />
        <PrivateRoute
          exact
          path="/activity/hatch/:activityId/direct-loading/:hatchActivityId"
          component={Todo}
        />
        <PrivateRoute exact path="/theme" component={ThemeSpecifics} />
        <PrivateRoute exact path="/test" component={Test} />
        <PrivateRoute path="*" component={Page404} />
      </Switch>
    </div>
  );
}
