import React from 'react';
import {SurveyActivity as SA} from '@deckmans/domain';
import {ActivityListItem} from './ActivityListItem';
import {useHistory} from 'react-router';
import {getSurveyType} from '@deckmans/domain/lib/util/enumData';

export interface StopVesselActivityProps {
  surveyActivity: SA;
  vesselId: string;
  id?: string;
  startDate?: Date;
  endDate?: Date;
}
export function SurveyActivityComp({
  vesselId,
  surveyActivity,
  startDate,
  endDate,
  id,
}: StopVesselActivityProps) {
  const history = useHistory();
  const {surveyType, oreGroup, comments} = surveyActivity;
  const tons = oreGroup.map((o) => o.oreTons).reduce((a, b) => a + b, 0);
  const st = getSurveyType(surveyType)?.description ?? 'No Type found';
  return (
    <ActivityListItem
      color="error"
      title={st}
      subtitle={surveyType > 0 ? `${tons}t` : comments}
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        history.push(`/activity/surveyActivity/edit/${vesselId}/${id}`);
      }}
    />
  );
}
