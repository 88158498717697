import React from 'react';

export function Test() {
  return (
    <>
      Test
      <br />
      <button onClick={async () => {}}>GetState</button>
    </>
  );
}
