import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch5SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '61px', width: '11px'}}
      viewBox="0 0 11 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3164 52.4609V54.1836H5.89356L5.89356 58.6338H10.3164L10.3164 60.3633H0.363283L0.363283 58.6338H4.50586L4.50586 54.1836H0.363282L0.363282 52.4609H10.3164Z"
        fill="white"
      />
      <path
        d="M7.99902 43.9658V47.8213L10.3164 48.6279V50.4258L0.363282 46.666L0.363282 45.1143L10.3164 41.3477V43.1523L7.99902 43.9658ZM6.60449 47.3359V44.4512L2.47559 45.8936L6.60449 47.3359Z"
        fill="white"
      />
      <path
        d="M1.75781 33.0918L1.75781 36.1953H10.3164V37.9111H1.75781L1.75781 40.9873H0.363282L0.363282 33.0918H1.75781Z"
        fill="white"
      />
      <path
        d="M7.07617 23.626C8.13802 23.7262 8.96745 24.1182 9.56445 24.8018C10.1569 25.4854 10.4531 26.3945 10.4531 27.5293C10.4531 28.3223 10.2663 29.0218 9.89258 29.6279C9.51432 30.2295 8.97884 30.6943 8.28613 31.0225C7.59342 31.3506 6.78906 31.5215 5.87305 31.5352H4.94336C4.00456 31.5352 3.17741 31.3688 2.46191 31.0361C1.74642 30.7035 1.19499 30.2272 0.807617 29.6074C0.420247 28.9831 0.226563 28.263 0.226562 27.4473C0.226562 26.349 0.525065 25.4648 1.12207 24.7949C1.71908 24.125 2.56217 23.7354 3.65137 23.626L3.65137 25.3486C2.93587 25.4307 2.4209 25.6403 2.10645 25.9775C1.78743 26.3102 1.62793 26.8001 1.62793 27.4473C1.62793 28.1992 1.90365 28.778 2.45508 29.1836C3.00195 29.5846 3.80631 29.7897 4.86816 29.7988H5.75C6.82552 29.7988 7.64583 29.6074 8.21094 29.2246C8.77604 28.8372 9.05859 28.2721 9.05859 27.5293C9.05859 26.8503 8.90592 26.3398 8.60059 25.998C8.29525 25.6562 7.78711 25.4398 7.07617 25.3486V23.626Z"
        fill="white"
      />
      <path
        d="M10.3164 13.4219V15.1445H5.89355V19.5947H10.3164V21.3242H0.363281L0.363281 19.5947H4.50586V15.1445H0.363281L0.363281 13.4219H10.3164Z"
        fill="white"
      />
      <path
        d="M5.38086 6.20703L0.36328 5.66699L0.36328 0.321289L1.79883 0.321289L1.79883 4.28613L4.17773 4.55957C3.91341 4.09928 3.78125 3.57975 3.78125 3.00098C3.78125 2.05306 4.08431 1.31706 4.69043 0.792969C5.29655 0.264323 6.1123 4.48213e-08 7.13769 0C8.14941 -4.42236e-08 8.95605 0.291667 9.55762 0.875C10.1546 1.45833 10.4531 2.25814 10.4531 3.27441C10.4531 4.18587 10.1956 4.94238 9.68066 5.54395C9.16113 6.14095 8.47754 6.46452 7.62988 6.51465V4.9082C8.11295 4.8444 8.48437 4.67122 8.74414 4.38867C8.99935 4.10612 9.12695 3.73698 9.12695 3.28125C9.12695 2.77083 8.94466 2.37435 8.58008 2.0918C8.21549 1.80469 7.71875 1.66113 7.08984 1.66113C6.48372 1.66113 6.00521 1.81836 5.6543 2.13281C5.29883 2.44727 5.12109 2.88021 5.12109 3.43164C5.12109 3.73242 5.16211 3.98763 5.24414 4.19727C5.32161 4.4069 5.47656 4.63477 5.70898 4.88086L5.38086 6.20703Z"
        fill="white"
      />
    </SvgIcon>
  );
}
