import {VesselActivity, Vessel, Shiploader} from '@deckmans/domain';
import moment from 'moment';
import {
  SortVesselActivities,
  findVesselActivityStartDate,
  findVesselActivityEndDate,
} from '@deckmans/domain/lib/util/sortVesselActivities';
import {populateCells} from 'modules/VesselView/components/CellManager';

export interface ExtendedVesselActivity extends VesselActivity {
  unAllocatedTime?: unknown;
}

export function calculateExtendedActivities(
  vesselActivities: VesselActivity[],
  vesselReclaimStart: Date,
  sameLength: boolean
) {
  const h: ExtendedVesselActivity[] = [];

  for (let i = 0; i < vesselActivities.length; i++) {
    // const one = vesselActivities[i];
    const one = vesselActivities[i];
    const two = vesselActivities[i + 1];
    if (one && two) {
      if (
        moment(one.startDate).isAfter(moment(vesselReclaimStart)) &&
        i === 0 &&
        sameLength
      ) {
        h.push({
          id: '',
          shiploader: one.shiploader,
          startDate: vesselReclaimStart,
          endDate: findVesselActivityStartDate(one).toDate(),
          unAllocatedTime: {},
        });
      }
      if (
        findVesselActivityEndDate(one).isSame(
          findVesselActivityStartDate(two),
          'minute'
        )
      ) {
        h.push({...one});
      } else {
        h.push({...one});
        h.push({
          id: '',
          shiploader: one.shiploader,
          startDate: findVesselActivityEndDate(one).toDate(),
          endDate: findVesselActivityStartDate(two).toDate(),
          unAllocatedTime: {},
        });
      }
    } else {
      if (
        moment(one.startDate).isAfter(moment(vesselReclaimStart)) &&
        i === 0 &&
        sameLength
      ) {
        h.push({
          id: '',
          shiploader: one.shiploader,
          startDate: vesselReclaimStart,
          endDate: findVesselActivityStartDate(one).toDate(),
          unAllocatedTime: {},
        });
        h.push({...one, unAllocatedTime: undefined});
      } else {
        h.push({...one, unAllocatedTime: undefined});
      }
    }
  }
  return h;
}

export function calculateExtendedActivitiesCrossVessel(
  vesselActivities: ExtendedVesselActivity[][]
) {
  let h: ExtendedVesselActivity[] = [];
  const extendedVesselActivities: ExtendedVesselActivity[][] = [];

  for (let i = 0; i < vesselActivities.length; i++) {
    const one = vesselActivities[i];
    const two = vesselActivities[i + 1];

    if (one && one.length > 0 && two && two.length > 0) {
      const oneLast = one[one.length - 1];
      const twoLast = two.length > 1 ? two[0] : two[two.length - 1];
      if (
        findVesselActivityEndDate(oneLast).isSame(
          findVesselActivityStartDate(twoLast),
          'minute'
        )
      ) {
        if (oneLast.unAllocatedTime) {
          if (one.length > 1) {
            for (let j = 0; j < one.length; j++) {
              h.push({...one[j], unAllocatedTime: {}});
            }
            extendedVesselActivities.push(h);
            h = [];
          } else {
            h.push({...oneLast, unAllocatedTime: {}});
            extendedVesselActivities.push(h);
            h = [];
          }
        } else {
          if (one.length > 1) {
            for (let j = 0; j < one.length; j++) {
              if (one[j].unAllocatedTime) {
                h.push({...one[j], unAllocatedTime: {}});
              } else {
                h.push({...one[j], unAllocatedTime: undefined});
              }
            }
            extendedVesselActivities.push(h);
            h = [];
          } else {
            h.push({...oneLast, unAllocatedTime: undefined});
            extendedVesselActivities.push(h);
            h = [];
          }
        }
      } else {
        if (oneLast.unAllocatedTime) {
          if (one.length > 1) {
            for (let j = 0; j < one.length; j++) {
              h.push({...one[i], unAllocatedTime: {}});
              h.push({
                id: '',
                shiploader: Shiploader.SHIPLOADER_BOTH,
                startDate: findVesselActivityEndDate(oneLast).toDate(),
                endDate: findVesselActivityStartDate(twoLast).toDate(),
                unAllocatedTime: {},
              });
            }
            extendedVesselActivities.push(h);
            h = [];
          } else {
            h.push({...oneLast, unAllocatedTime: {}});
            h.push({
              id: '',
              shiploader: Shiploader.SHIPLOADER_BOTH,
              startDate: findVesselActivityEndDate(oneLast).toDate(),
              endDate: findVesselActivityStartDate(twoLast).toDate(),
              unAllocatedTime: {},
            });
            extendedVesselActivities.push(h);
            h = [];
          }
        } else {
          if (one.length > 1) {
            for (let j = 0; j < one.length; j++) {
              h.push({...one[i], unAllocatedTime: undefined});
              h.push({
                id: '',
                shiploader: Shiploader.SHIPLOADER_BOTH,
                startDate: findVesselActivityEndDate(oneLast).toDate(),
                endDate: findVesselActivityStartDate(twoLast).toDate(),
                unAllocatedTime: {},
              });
            }
            extendedVesselActivities.push(h);
            h = [];
          } else {
            h.push({...oneLast, unAllocatedTime: undefined});
            extendedVesselActivities.push(h);
            h = [];
            h.push({
              id: '',
              shiploader: Shiploader.SHIPLOADER_BOTH,
              startDate: findVesselActivityEndDate(oneLast).toDate(),
              endDate: findVesselActivityStartDate(twoLast).toDate(),
              unAllocatedTime: {},
            });
            extendedVesselActivities.push(h);
            h = [];
          }
        }
      }
    } else {
      if (one && one.length > 0 && one[one.length - 1].unAllocatedTime) {
        for (let j = 0; j < one.length; j++) {
          if (one[j].unAllocatedTime) {
            h.push({...one[j], unAllocatedTime: {}});
          } else {
            h.push({...one[j], unAllocatedTime: undefined});
          }
        }
        extendedVesselActivities.push(h);
        h = [];
      } else {
        if (one.length !== 0) {
          if (one.length > 1) {
            for (let j = 0; j < one.length; j++) {
              if (one[j].unAllocatedTime) {
                h.push({...one[j], unAllocatedTime: {}});
              } else {
                h.push({...one[j], unAllocatedTime: undefined});
              }
            }
          } else {
            h.push({...one[one.length - 1], unAllocatedTime: undefined});
          }
          extendedVesselActivities.push(h);
          h = [];
        } else {
          extendedVesselActivities.push([]);
        }
      }
    }
  }
  return extendedVesselActivities;
}

export function addToExtendedActivities(
  vesselActivities: ExtendedVesselActivity[][],
  vesselActivitiesRight: ExtendedVesselActivity[][],
  vesselStartDate: Date
) {
  const slLeftLength = vesselActivities.length;
  const slLeftLast = vesselActivities[slLeftLength - 1];
  const slLeftSecondLast = vesselActivities[slLeftLength - 2];

  const slRightLength = vesselActivitiesRight.length;
  const slRightLast = vesselActivitiesRight[slRightLength - 1];
  const slRightSecondLast = vesselActivitiesRight[slRightLength - 2];

  if (
    (slLeftLength > 0 && slLeftLast.length > 0) ||
    (slRightLength > 0 && slRightLast.length > 0)
  ) {
    const lastLeft =
      slLeftLength === 1
        ? slLeftLast.length > 0
          ? slLeftLast
          : null
        : slLeftLast.length > 0
        ? slLeftLast
        : slLeftSecondLast;

    const lastRight =
      slRightLength === 1
        ? slRightLast.length > 0
          ? slRightLast
          : null
        : slRightLast.length > 0
        ? slRightLast
        : slRightSecondLast;

    const lastLeftEndDate = lastLeft
      ? checkLastActivityEndDate(lastLeft[lastLeft.length - 1])
      : null;
    const lastRightEndDate = lastRight
      ? checkLastActivityEndDate(lastRight[lastRight.length - 1])
      : null;

    const leftIsAfter = moment(lastLeftEndDate).isAfter(
      moment(lastRightEndDate)
    );
    const RightIsAfter = moment(lastRightEndDate).isAfter(
      moment(lastLeftEndDate)
    );

    if (RightIsAfter && lastRightEndDate && lastLeftEndDate) {
      vesselActivities[slLeftLength - 1].push({
        id: '',
        shiploader: Shiploader.SHIPLOADER_ONE,
        startDate: lastLeftEndDate,
        endDate: lastRightEndDate,
        unAllocatedTime: {},
      });
    } else if (leftIsAfter && lastRightEndDate && lastLeftEndDate) {
      vesselActivitiesRight[slRightLength - 1].push({
        id: '',
        shiploader: Shiploader.SHIPLOADER_TWO,
        startDate: lastRightEndDate,
        endDate: lastLeftEndDate,
        unAllocatedTime: {},
      });
    }
    if (
      vesselActivitiesRight[slRightLength - 1].length === 0 &&
      lastLeftEndDate
    ) {
      vesselActivitiesRight.pop();
      vesselActivitiesRight.push([
        {
          id: '',
          shiploader: Shiploader.SHIPLOADER_TWO,
          startDate: vesselStartDate,
          endDate: lastLeftEndDate,
          unAllocatedTime: {},
        },
      ]);
    }
    if (vesselActivities[slLeftLength - 1].length === 0 && lastRightEndDate) {
      vesselActivities.pop();
      vesselActivities.push([
        {
          id: '',
          shiploader: Shiploader.SHIPLOADER_ONE,
          startDate: vesselStartDate,
          endDate: lastRightEndDate,
          unAllocatedTime: {},
        },
      ]);
    }
  }
}

export function checkIfUnallocatedTime(vessel: Vessel) {
  const sortedVesselActivities = SortVesselActivities(vessel.activities);

  const sortedByShiploaderVesselActivities = populateCells(
    sortedVesselActivities,
    vessel.reclaimStart ?? new Date()
  );

  const cellsWithUnallocatedTime = getExtendedUnallocatedTime(
    sortedByShiploaderVesselActivities,
    vessel.reclaimStart ?? new Date()
  );

  const unallocatedCells: ExtendedVesselActivity[] = [];
  cellsWithUnallocatedTime.forEach((cells) => {
    cells.forEach((cell) => {
      if (cell.unAllocatedTime) {
        unallocatedCells.push(cell);
      }
    });
  });
  const unallocatedTime = unallocatedCells.some((cell) => {
    return cell.unAllocatedTime;
  });

  return unallocatedTime;
}

export function getExtendedUnallocatedTime(
  cells: ExtendedVesselActivity[][],
  vesselReclaimStart: Date
) {
  const left: ExtendedVesselActivity[][] = [];
  const right: ExtendedVesselActivity[][] = [];
  const cellsWithUnallocatedTime: ExtendedVesselActivity[][] = [];

  for (let i = 0; i < cells.length; i++) {
    const isEven = i % 2 === 0;

    if (isEven) {
      left.push(cells[i]);
    } else {
      right.push(cells[i]);
    }
  }

  //this function checks if the left and right endDates of activities are the same,
  //otherwise adds unallocated time to the side where the end date is earlier
  addToExtendedActivities(left, right, vesselReclaimStart ?? new Date());

  //here the unallocated time is added on the left side if the activity is a cross vessel activity
  const leftCellsWithUnallocatedTime = calculateExtendedActivitiesCrossVessel(
    left
  );

  //here the unallocated time is added on the right side if the activity is a cross vessel activity
  const rightCellsWithUnallocatedTime = calculateExtendedActivitiesCrossVessel(
    right
  );

  let shiploaderOne: ExtendedVesselActivity[] = [];
  let shiploaderTwo: ExtendedVesselActivity[] = [];
  const length =
    rightCellsWithUnallocatedTime.length > leftCellsWithUnallocatedTime.length
      ? rightCellsWithUnallocatedTime.length
      : leftCellsWithUnallocatedTime.length;

  //this adds the left and right activities into one array
  for (let i = 0; i < length; i++) {
    if (leftCellsWithUnallocatedTime[i]) {
      for (let j = 0; j < leftCellsWithUnallocatedTime[i].length; j++) {
        shiploaderOne.push(leftCellsWithUnallocatedTime[i][j]);
      }
    } else {
      shiploaderOne.push();
    }
    if (rightCellsWithUnallocatedTime[i]) {
      for (let k = 0; k < rightCellsWithUnallocatedTime[i].length; k++) {
        shiploaderTwo.push(rightCellsWithUnallocatedTime[i][k]);
      }
    } else {
      shiploaderTwo.push();
    }
    cellsWithUnallocatedTime.push(shiploaderOne, shiploaderTwo);
    shiploaderOne = [];
    shiploaderTwo = [];
  }
  return cellsWithUnallocatedTime;
}

function checkLastActivityEndDate(activity: ExtendedVesselActivity) {
  return activity.endDate;
}
