import React from 'react';
import {
  EventCreator,
  uuidV4,
  IdGenerator,
  Engine,
  User,
  State,
} from '@deckmans/domain';
import {useIndexedDbContext} from './IndexedDbContext';
import {IndexedDbEventPersistor} from 'services';
import {createEventService} from 'services';
import {useAuthContext} from 'contexts/AuthContext';
import {PageLoading} from 'components/PageLoading';
import {useNavigatorOnline} from 'hooks/useNavigatorOnline';
import {useAlertContext} from './AlertContex';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import {FullPageError} from '@deckmans/web-shared';
import {notify, trackAiEvent} from 'appInsights';
import {useSearchContext} from './SearchContext';

interface EngineContextType {
  engine: Engine;
  sync(): Promise<boolean>;
  synching: boolean;
  setEnableSync(sync: boolean): void;
  eventCreator: EventCreator;
  getState(toDate: Date, fromDate: Date): Promise<State>;
}

export const EngineContext = React.createContext<EngineContextType>(
  // @ts-expect-error @typescript-eslint/ban-ts-comment
  {}
);
interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

function createIdGenerator(): IdGenerator {
  return {
    getNextId: uuidV4,
  };
}

export const useEngineContext = () =>
  React.useContext<EngineContextType>(EngineContext);

export function EngineContextProvider({children}: Props) {
  const db = useIndexedDbContext();
  const {endDate, startDate} = useSearchContext();

  const online = useNavigatorOnline();
  const [error, setError] = React.useState<Error>();
  const {auth} = useAuthContext();
  const {alert} = useAlertContext();
  const [enableSync, setEnableSync] = React.useState(true);
  const [, setLastSyncDate] = useLocalStorage<Date>('lastSyncDate');

  const eventService = React.useMemo(() => {
    return createEventService(async () => {
      if (auth.authenticated) {
        return auth.token;
      } else {
        return '';
      }
    });
  }, [auth]);

  const [initialSync, setInitialSync] = React.useState(false);

  const eventPersistor = React.useMemo(() => {
    return new IndexedDbEventPersistor(db);
  }, [db]);

  const userGetter = React.useMemo(() => {
    return {
      getCurrentUser: function () {
        if (auth.authenticated) {
          const user: User = {
            id: auth.userId ?? -1,
            roles: auth.roles,
            username: auth.username,
          };
          return user;
        } else {
          const user: User = {
            id: -1,
            roles: [],
            username: '',
          };
          return user;
        }
      },
    };
  }, [auth]);

  const idGenerator = React.useMemo(() => {
    return createIdGenerator();
  }, []);

  const engine = React.useMemo(() => {
    return new Engine(eventPersistor);
  }, [eventPersistor]);

  const eventCreator = React.useMemo(() => {
    return new EventCreator(userGetter, idGenerator);
  }, [userGetter, idGenerator]);

  const syncInProgressRef = React.useRef<boolean>(false);
  const [synching, setSynching] = React.useState(false);

  const sync = React.useCallback(async () => {
    let synced = false;
    if (
      !syncInProgressRef.current &&
      auth.authenticated &&
      online &&
      initialSync &&
      enableSync
    ) {
      try {
        setSynching(true);
        syncInProgressRef.current = true;

        const pendingCount = await db.getPendingEventsCount();

        if (pendingCount > 0) {
          const events = await db.getPendingEvents();

          const resp = await eventService.UploadEvents({
            // TODO: transform events, this is likely json
            events,
            toDate: endDate,
            fromDate: startDate,
          });

          // Delete pending events
          await db.eventsUploaded(events.map(({eventId}) => eventId));

          if (resp.state) {
            const pending = await db.getPendingEvents(200);
            engine.setState(resp.state, pending);
          }

          db.saveState(engine.state);
          if (pendingCount > events.length) {
            // More events to sync
            // TODO: does finally run before this?
            sync();
          }
        } else {
          // Up to date - Check if server has new events
          const resp = await eventService.GetState({
            eventId: engine.state.eventId,
          });

          if (resp.state) {
            engine.setState(resp.state, await db.getPendingEvents(200));
          }
        }
        //this shows every interval as well so would leave it for now
        // alert('Synch Successful.', 'info');
        setLastSyncDate(new Date());
        synced = true;
        trackAiEvent({
          name: 'Sync Succeeded',
          properties: {timeStamp: new Date().toLocaleTimeString()},
        });
      } catch (ex) {
        alert('Synch failed', ex.message);
        trackAiEvent({
          name: 'Sync Failed',
          properties: {timeStamp: new Date().toLocaleTimeString()},
        });
        notify(ex);
        synced = false;
      } finally {
        setSynching(false);
        syncInProgressRef.current = false;
      }
    } else {
      synced = false;
    }
    return synced;
  }, [
    auth.authenticated,
    db,
    engine,
    eventService,
    online,
    initialSync,
    enableSync,
    alert,
    setLastSyncDate,
    endDate,
    startDate,
  ]);

  React.useEffect(() => {
    engine.setEventListener({
      onEventFailure: () => {
        // TODO
      },
      onEventSuccess: () => {
        // Trigger a sync immediately
        sync();
      },
    });
  }, [engine, sync]);

  const filterDates = React.useCallback(
    async (toDate: Date, fromDate: Date) => {
      const resp = await eventService.GetState({
        eventId: '',
        toDate,
        fromDate,
      });
      if (resp.state) {
        engine.setState(resp.state);
        db.saveState(resp.state);
        return resp.state;
      } else {
        return {eventId: '', vessels: {}};
      }
    },
    [db, engine, eventService]
  );

  const value = React.useMemo(() => {
    return {
      eventCreator,
      engine,
      sync,
      synching,
      setEnableSync,
      getState: filterDates,
    };
  }, [eventCreator, engine, sync, synching, setEnableSync, filterDates]);

  React.useEffect(() => {
    async function loadEvents() {
      const {engine} = value;

      // This is called once to load local values on startup
      if (!initialSync) {
        try {
          if (online) {
            const resp = await eventService.GetState({
              eventId: '',
              toDate: endDate,
              fromDate: startDate,
            });

            const pending = await db.getPendingEvents();
            if (resp.state) {
              engine.setState(resp.state, pending);
              db.saveState(resp.state);
            }
            //Do calls
            setInitialSync(true);
          } else {
            //offline
            const savedState = await db.getSavedState();
            if (!savedState) {
              throw new Error('Internet connection required');
            } else {
              engine.setState(savedState);
            }
          }
        } catch (ex) {
          notify(ex);
          setError(new Error(`Failed to do Initial Sync: ${ex.message}`));
        }
      }
    }

    loadEvents();
  }, [
    db,
    value,
    setInitialSync,
    initialSync,
    eventService,
    online,
    endDate,
    startDate,
  ]);

  if (error) {
    return <FullPageError error={error} />;
  }
  if (!initialSync) {
    return <PageLoading text="Engine Context Loading" />;
  }

  return (
    <EngineContext.Provider value={value}>{children}</EngineContext.Provider>
  );
}
