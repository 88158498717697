import React from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';

export function Hatch1SVG(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      style={{height: '59px', width: '11px'}}
      viewBox="0 0 11 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0898 50.666V52.3887H5.66699L5.66699 56.8389H10.0898L10.0898 58.5684H0.13672L0.13672 56.8389H4.2793L4.2793 52.3887H0.13672L0.13672 50.666H10.0898Z"
        fill="white"
      />
      <path
        d="M7.77246 42.1709V46.0264L10.0898 46.833V48.6309L0.13672 44.8711L0.13672 43.3193L10.0898 39.5527V41.3574L7.77246 42.1709ZM6.37793 45.541V42.6562L2.24902 44.0986L6.37793 45.541Z"
        fill="white"
      />
      <path
        d="M1.53125 31.2969L1.53125 34.4004H10.0898V36.1162H1.53125L1.53125 39.1924H0.136719L0.136719 31.2969H1.53125Z"
        fill="white"
      />
      <path
        d="M6.84961 21.8311C7.91146 21.9313 8.74089 22.3232 9.33789 23.0068C9.93034 23.6904 10.2266 24.5996 10.2266 25.7344C10.2266 26.5273 10.0397 27.2269 9.66602 27.833C9.28776 28.4346 8.75228 28.8994 8.05957 29.2275C7.36686 29.5557 6.5625 29.7266 5.64648 29.7402H4.7168C3.77799 29.7402 2.95085 29.5739 2.23535 29.2412C1.51986 28.9085 0.968425 28.4323 0.581055 27.8125C0.193685 27.1882 3.56578e-08 26.4681 0 25.6523C-4.80085e-08 24.554 0.298503 23.6699 0.895508 23C1.49251 22.3301 2.33561 21.9404 3.4248 21.8311L3.4248 23.5537C2.70931 23.6357 2.19434 23.8454 1.87988 24.1826C1.56087 24.5153 1.40137 25.0052 1.40137 25.6523C1.40137 26.4043 1.67708 26.9831 2.22852 27.3887C2.77539 27.7897 3.57975 27.9948 4.6416 28.0039H5.52344C6.59896 28.0039 7.41927 27.8125 7.98438 27.4297C8.54948 27.0423 8.83203 26.4772 8.83203 25.7344C8.83203 25.0553 8.67936 24.5449 8.37402 24.2031C8.06868 23.8613 7.56055 23.6449 6.84961 23.5537V21.8311Z"
        fill="white"
      />
      <path
        d="M10.0898 11.627V13.3496H5.66699V17.7998H10.0898V19.5293H0.136718L0.136718 17.7998H4.2793V13.3496H0.136718L0.136718 11.627H10.0898Z"
        fill="white"
      />
      <path
        d="M10.0898 0.358398V2.0127L2.09863 2.0127L2.93262 4.45313H1.53125L0.102538 0.570313L0.102538 0.358399L10.0898 0.358398Z"
        fill="white"
      />
    </SvgIcon>
  );
}
