import * as React from 'react';
import {Typography, Menu, MenuItem} from '@material-ui/core';
import {useHistory} from 'react-router';

interface Props {
  anchorEl: Element | null;
  handleClose: () => void;
  vesselId: string;
  shiploader: string | number;
  hatchId: string;
}
export function HatchActivitiesMenu({
  anchorEl,
  handleClose,
  vesselId,
  shiploader,
  hatchId,
}: Props) {
  const history = useHistory();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{horizontal: 'right', vertical: 'top'}}
      transformOrigin={{vertical: 'top', horizontal: 'center'}}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() =>
          history.push(
            `/activity/hatchLoadingActivity/new/${vesselId}/${shiploader}/${hatchId}`
          )
        }
      >
        <Typography variant="button">Loading</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push(
            `/activity/hatchDirectLoadingActivity/new/${vesselId}/${shiploader}/${hatchId}`
          );
        }}
      >
        <Typography variant="button">Direct Loading</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push(
            `/activity/hatchReclaimingActivity/new/${vesselId}/${shiploader}/${hatchId}`
          );
        }}
      >
        <Typography variant="button">Reclaiming</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push(
            `/activity/hatchBreakdownActivity/new/${vesselId}/${shiploader}/${hatchId}`
          );
        }}
      >
        <Typography variant="button">Breakdown</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push(
            `/activity/hatchAwaitingShiploaderActivity/new/${vesselId}/${shiploader}/${hatchId}`
          );
        }}
      >
        <Typography variant="button">Awaiting Shiploader</Typography>
      </MenuItem>
    </Menu>
  );
}
