import * as React from 'react';
import {Formik, FormikConfig, FormikProps, FormikValues} from 'formik';
interface Props<Values>
  extends Omit<FormikConfig<Values>, 'onSubmit' | 'children'> {
  isEditing: boolean;
  onEditSubmit: FormikConfig<Values>['onSubmit'];
  onCreateSubmit: FormikConfig<Values>['onSubmit'];
  children?: (props: FormikProps<Values>) => React.ReactNode;
}
export function BaseForm<Values extends FormikValues>({
  isEditing,
  children,
  onEditSubmit,
  onCreateSubmit,
  ...rest
}: Props<Values>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit: FormikConfig<Values>['onSubmit'] = React.useCallback(
    isEditing ? onEditSubmit : onCreateSubmit,
    [isEditing, onEditSubmit, onCreateSubmit]
  );
  return (
    <Formik<Values> onSubmit={onSubmit} {...rest}>
      {children}
    </Formik>
  );
}
