import React from 'react';
import moment from 'moment';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import {ActivityListItemWithBox} from './ActivityListItemWithBox';
import {useHistory} from 'react-router';
import {Shiploader} from '@deckmans/domain';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '2px solid ' + theme.palette.error.main,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    marginBottom: theme.spacing(1.5),
  },
}));
export interface StopVesselActivityProps {
  startDate?: Date;
  endDate?: Date;
  //might change to vessel
  vesselId: string;
  shiploader: Shiploader;
}
export function UnallocatedTimeComp({
  startDate,
  endDate,
  shiploader,
  vesselId,
}: StopVesselActivityProps) {
  const history = useHistory();
  const classes = useStyles();
  const isUnallocated = moment(startDate).isBefore(moment(endDate));
  return isUnallocated ? (
    <ActivityListItemWithBox
      color="error" //success/info/warning/error
      title={'Unallocated'}
      type="unallocatedTime"
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        // history.push(`/activity/single-loading/${shiploader}/${vesselId}`, {
        //   startDate,
        //   endDate,
        // });
        history.push(`/activity/non-hatch-activity/${shiploader}/${vesselId}`, {
          unallocatedTime: true,
          startDate,
          endDate,
        });
      }}
    />
  ) : (
    <Box display="flex" className={classes.container}>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        alignItems="center"
      >
        <Box display="flex">
          <Typography variant="h5">TIME OVERLAP </Typography>
        </Box>
      </Box>
    </Box>
  );
}
