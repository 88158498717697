import * as React from 'react';
import {Box, Divider, Grid, Paper} from '@material-ui/core';
import {Field, getIn, useFormikContext} from 'formik';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {useTitle} from 'contexts/TitleContext';
import {TextField as FTextField} from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import {ButtonBar} from 'components/ButtonBar';
import {BaseForm} from 'components/BaseForm';
import {useParams, useHistory} from 'react-router';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import * as yup from 'yup';
import {StartEndDateTime} from '@deckmans/web-shared';
import {DecisionType, DecisionAnswer} from '@deckmans/domain';
import {Shiploader} from '@deckmans/domain';
import {useDataContext} from 'providers/DataContextProvider';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {useVessel} from 'hooks/useVessel';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import Typography from '@material-ui/core/Typography';

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY,
  decisionKeys: [],
});
interface EquipmentNameProps {
  name: string;
  equipmentNames: {
    id: string;
    description: string;
  }[];
  isEditing: boolean;
  eqType: string;
  //only applicable if there is only 1 equipment name in the list
  equipmentNameFirst?: string;
}

function EquipmentNameField({
  name,
  equipmentNames,
  isEditing,
  eqType,
  equipmentNameFirst,
}: EquipmentNameProps) {
  const {
    values: {reason, equipmentName},
    touched,
    errors,
    setFieldValue,
  } = useFormikContext<BreakdownActivityType>();

  React.useEffect(() => {
    if (eqType !== '' && eqType !== reason.decisionKeys[1]) {
      setFieldValue(name, '');
    }
  }, [reason.decisionKeys, setFieldValue, name, eqType]);

  React.useEffect(() => {
    if (equipmentNameFirst) {
      setFieldValue(name, equipmentNameFirst);
    }
  }, [setFieldValue, name, equipmentNameFirst]);

  return (
    <Field
      validate={(value: string) => {
        let error = '';
        if (value === '') {
          error = 'This field is required';
        }
        return error;
      }}
      component={Autocomplete}
      disableClearable
      options={equipmentNames.map(({id}) => id)}
      defaultValue={isEditing ? equipmentName : undefined}
      renderOption={(option: string) => option}
      name={name}
      value={equipmentName}
      getOptionLabel={(option: string) => option}
      fullWidth
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <>
            <TextField
              placeholder="Equipment Name"
              label="Equipment Name"
              {...params}
            />
            {getIn(touched, name) && !!getIn(errors, name) && (
              <Box pl={2}>
                <Typography variant="caption" color="error">
                  {errors['equipmentName']}
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    />
  );
}

interface BreakdownActivityType {
  id: string;
  startTime: string;
  endTime: string;
  equipmentName: string;
  comments: string;
  reason: DecisionAnswer;
}

export function BreakdownActivity() {
  useTitle('Breakdown');
  const history = useHistory();
  const {master} = useDataContext();
  const {engine, eventCreator} = useEngineContext();
  const {hatchId, activityId} = useParams<{
    hatchId: string;
    activityId: string;
  }>();

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const vessel = useVessel();
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
  });

  const parentActivity = React.useMemo(() => {
    return vessel.activities[hatchId];
  }, [hatchId, vessel.activities]);

  const activity = React.useMemo(() => {
    if (activityId) {
      return parentActivity?.vesselHatchActivityGroup?.activities[activityId];
    }
  }, [activityId, parentActivity]);

  const shiploader = React.useMemo(() => {
    return parentActivity ? parentActivity.shiploader : Shiploader.UNRECOGNIZED;
  }, [parentActivity]);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);

  const sd = pvEndDate !== '' ? pvEndDate : reclaimStart;

  const {startDate, endDate} = useHistoryStateDateRange(sd);

  const initialValues = React.useMemo(() => {
    if (isEditing && vessel && activity && activity.hatchBreakdownActivity) {
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments: activity.hatchBreakdownActivity.comments,
        equipmentName: activity.hatchBreakdownActivity.equipmentName,
        id: activity.id,
        reason: activity.hatchBreakdownActivity.reason ?? defaultReason,
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        comments: '',
        equipmentName: '',
        id: '',
        reason: defaultReason,
      };
    }
  }, [activity, isEditing, startDate, vessel, endDate]);

  const hatchFinished = !!vessel.activities[hatchId].vesselHatchActivityGroup
    ?.completed;
  const [eqType, setEqType] = React.useState('');
  return (
    <BaseForm<BreakdownActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={initialValues}
      onCreateSubmit={async ({
        startTime,
        endTime,
        comments,
        equipmentName,
        reason,
      }) => {
        const equipmentNames = master.equipmentTypes
          .find((et) => et.type === reason.decisionKeys[1] ?? '')
          ?.names.map((en) => ({id: en, description: en}));
        const event = eventCreator.hatchBreakdownActivityCreated({
          vesselId: vessel.id,
          comments,
          equipmentName: equipmentNames ? equipmentName : '',
          groupActivityId: hatchId,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          reason,
        });

        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({
        comments,
        endTime,
        startTime,
        equipmentName,
        reason,
        id,
      }) => {
        const equipmentNames = master.equipmentTypes
          .find((et) => et.type === reason.decisionKeys[1] ?? '')
          ?.names.map((en) => ({id: en, description: en}));
        const event = eventCreator.hatchBreakdownActivityEdited({
          id,
          vesselId: vessel.id,
          comments,
          equipmentName: equipmentNames ? equipmentName : '',
          groupActivityId: hatchId,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          reason,
        });
        await engine.process(event);
        history.goBack();
      }}
    >
      {({submitForm, values}) => {
        const equipmentNames = master.equipmentTypes
          .find((et) => et.type === values.reason.decisionKeys[1] ?? '')
          ?.names.map((en) => ({id: en, description: en}));

        setEqType(values.reason.decisionKeys[1]);
        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime />

                  <Grid item xs={6}>
                    <Box px={1}>
                      <Duration
                        startTime={values.startTime}
                        endTime={values.endTime}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <MultiLevelQuestions
                      name="reason"
                      decisionType={
                        DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY
                      }
                      subLevels={[
                        <React.Fragment key="empty_1" />,
                        equipmentNames ? (
                          <Grid key="names_in_place" item xs={12}>
                            <Box px={1} py={1}>
                              {/* <SingleSelectField
                                addNone
                                fullWidth
                                label="Equipment Name"
                                data={equipmentNames}
                                field="equipmentName"
                              /> */}
                              <EquipmentNameField
                                name="equipmentName"
                                equipmentNames={equipmentNames}
                                isEditing={isEditing}
                                eqType={eqType}
                                equipmentNameFirst={
                                  equipmentNames?.length === 1
                                    ? equipmentNames[0].id
                                    : undefined
                                }
                              />
                            </Box>
                          </Grid>
                        ) : (
                          <></>
                        ),
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box px={1}>
                      <Field
                        fullWidth
                        multiline
                        component={FTextField}
                        name="comments"
                        label="Comments"
                        placeholder="Comments"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      createText="create"
                      saveText="update"
                      isEditing={isEditing}
                      handleSave={submitForm}
                      vessel={vessel}
                      activityId={activityId}
                      title="Delete Breakdown Activity"
                      subtitle="Are you sure you want to delete this breakdown activity?"
                      hatchFinished={hatchFinished}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </BaseForm>
  );
}
