import * as React from 'react';
import {useHistory, useParams} from 'react-router';
import {FastField, Field} from 'formik';
import {TextField as FieldTextField} from 'formik-material-ui';
import {
  Box,
  Button,
  Typography,
  Paper,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {useTitle} from 'contexts/TitleContext';
import {useEngineContext} from 'contexts/EngineContext';
import {SingleSelectField} from 'components/SingleSelectField';
import Grid from '@material-ui/core/Grid';
import {BaseForm} from 'components/BaseForm';
import moment from 'moment';
import * as yup from 'yup';
import {
  Vessel_Client,
  VesselState,
  DecisionType,
  UserRole,
  Vessel,
  State,
} from '@deckmans/domain';
import {VesselEditorClients} from 'modules/VesselEditor/VesselEditorClients';
import {ToggleButtonField} from 'components/ToggleButtonField';
import {FDateTimeField} from '@deckmans/web-shared';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import {useAuthContext} from 'contexts/AuthContext';
import {useDataContext} from 'providers/DataContextProvider';
import TextField from '@material-ui/core/TextField';

import {
  findLastVessel,
  findVesselEndDate,
  sortVessels,
} from '@deckmans/domain/lib/util/sortVessels';
import TimeBetweenLastVesselEdit, {
  TimeBetweenLastVesselView,
} from './TimeBetweenLastVessel';
import {useSearchContext} from 'contexts/SearchContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dottedBox: {
    border: '1px dashed ' + theme.palette.text.disabled,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    height: '100%',
    display: 'flex',
    padding: theme.spacing(0, 1),
    minWidth: 150,
  },
  betweenLine: {
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clientBox: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4, 0),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function getHoursAndMinutes(
  startTime?: string | Date,
  endTime?: string | Date
) {
  const dur = moment.duration(moment(endTime).diff(moment(startTime)));
  const minutes = startTime && endTime ? dur.asMinutes() : 0;
  const h = Math.floor(minutes / 60);
  const mod = Math.ceil(minutes % 60);
  const m = mod;

  return {hours: h, minutes: m};
}
export interface VesselEditorType {
  id: string;
  vesselName: string;
  date: string;
  berth: string;
  delay: string;
  comments: string;
  clients: Vessel_Client[];
  previousVessel: string;
  delayReason2: string;
  secondComments: string;
  timeBetweenVesselHours: number;
  timeBetweenVesselMinutes: number;
}

export function VesselEditor() {
  const classes = useStyles();
  const {engine, eventCreator, getState} = useEngineContext();
  const {
    optionCo,
    master: {clientNoOreType, vesselNames},
  } = useDataContext();
  const {endDate, startDate} = useSearchContext();
  const options = optionCo(DecisionType.DECISION_TYPE_VESSEL_DELAY_OPTIONS);
  const history = useHistory();
  const vesselId = useParams<{vesselId?: string}>().vesselId ?? '';
  const isEditing = React.useMemo(() => {
    return Boolean(vesselId);
  }, [vesselId]);
  const {auth, hasRole} = useAuthContext();

  const [state, setState] = React.useState<State>({
    eventId: '',
    vessels: engine.state.vessels,
  });

  useTitle(isEditing ? 'Edit your vessel' : 'Add new vessel');

  const setVesselState = React.useCallback(() => {
    const fetch = async () => {
      const state = await getState(
        endDate ?? new Date(),
        startDate ?? new Date()
      );
      if (state) {
        setState(state);
      }
    };
    fetch();
  }, [getState, endDate, startDate]);

  const lastVessel = findLastVessel(engine.state.vessels, vesselId);

  const lvEndDate = lastVessel
    ? findVesselEndDate(lastVessel).toDate()
    : undefined;
  //cant use useVessel because vessel can be undefined here
  const vessel: Vessel | undefined =
    engine.state.vessels[vesselId] ?? undefined;

  const sortedVessels = sortVessels(state.vessels);

  const previousVessels = sortedVessels
    .filter((vessels) => {
      return moment(vessels.reclaimStart).isBefore(vessel?.reclaimStart);
    })
    .map((vessel) => {
      return {id: vessel.id, description: vessel.name};
    });

  const previousTwoVessels =
    previousVessels.length > 2
      ? previousVessels.slice(previousVessels.length - 2)
      : previousVessels;

  const initialValues = React.useMemo(() => {
    if (isEditing && vessel) {
      const {
        name,
        berth,
        clients,
        comments,
        delay,
        reclaimStart,
        previousVesselId,
        delayReason2,
        secondComments,
        timeBetweenVesselHours,
        timeBetweenVesselMinutes,
      } = vessel;
      return {
        id: vesselId,
        vesselName: name,
        date: moment(reclaimStart).format('YYYY-MM-DDTHH:mm'),
        berth,
        delay,
        comments,
        clients,
        previousVessel: previousVesselId,
        delayReason2,
        secondComments,
        timeBetweenVesselHours,
        timeBetweenVesselMinutes,
      };
    } else {
      return {
        id: '',
        vesselName: '',
        date: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
        berth: '',
        delay: '',
        comments: '',
        clients: [],
        previousVessel: '',
        delayReason2: '',
        secondComments: '',
        timeBetweenVesselHours: 0,
        timeBetweenVesselMinutes: 0,
      };
    }
  }, [isEditing, vesselId, vessel]);
  if (!auth.authenticated) {
    return <>You are not authenticated</>;
  }
  // if (
  //   !(
  //     hasRole(UserRole.USER_ROLE_SUPERVISOR) ||
  //     hasRole(UserRole.USER_ROLE_ADMIN)
  //   ) &&
  //   isEditing
  // ) {
  //   return <>You do not have the required access to view this page</>;
  // }

  const validationsSchema = yup.object().shape({
    vesselName: yup.string().required('This field is required').nullable(),
    date: isEditing
      ? yup.date().required('This field is required')
      : lvEndDate
      ? yup
          .date()
          .required('This field is required')
          .min(
            lvEndDate,
            `Reclaim start time cannot be before previous vessel last activity end date (${lvEndDate?.toDateString()} ${lvEndDate?.toLocaleTimeString()})`
          )
          .max(new Date(), 'Reclaim start cannot be after current date')
      : yup
          .date()
          .required('This field is required')
          .max(new Date(), 'Reclaim start cannot be after current date'),
    berth: yup.string().required('Berth is required'),
    timeBetweenVessels: yup.string(),
    delay: yup.string().required('You need to select a vessel delay reason'),
    comments: yup.string(),
    clients: yup
      .array<Vessel_Client>()
      .min(1, 'You need to select at least one Client')
      .max(2, 'You need to select no more than two Clients')
      .required('This field is required'),
    previousVessel:
      previousVessels.length > 0
        ? yup.string().required('Select a previous vessel')
        : yup.string(),
    timeBetweenVesselHours: yup
      .number()
      .min(0, 'Invalid time')
      .required('Required'),
    timeBetweenVesselMinutes: yup
      .number()
      .min(0, 'Invalid time')
      .max(59, 'Invalid time')
      .required('Required'),
  });

  return (
    <BaseForm<VesselEditorType>
      initialValues={initialValues}
      validationSchema={validationsSchema}
      isEditing={isEditing}
      onCreateSubmit={async ({
        berth,
        comments,
        clients,
        date,
        delay,
        vesselName,
        previousVessel,
        delayReason2,
        secondComments,
        timeBetweenVesselHours,
        timeBetweenVesselMinutes,
      }) => {
        const event = eventCreator.vesselCreated({
          delay,
          name: vesselName.toUpperCase(),
          comments,
          berth,
          reclaimStart: moment(date).toDate(),
          clients,
          state: VesselState.VESSEL_STATE_IN_PROGRESS,
          previousVesselId: previousVessel,
          delayReason2,
          secondComments,
          timeBetweenVesselHours,
          timeBetweenVesselMinutes,
        });
        // await engine.process(event);
        // history.replace('/logbook');

        setVesselState();
        setTimeout(async () => {
          await engine.process(event);
          history.replace('/logbook');
        }, 1 * 1000);
      }}
      onEditSubmit={async ({
        id,
        berth,
        comments,
        clients,
        date,
        vesselName,
        delay,
        previousVessel,
        delayReason2,
        secondComments,
        timeBetweenVesselHours,
        timeBetweenVesselMinutes,
      }) => {
        const event = eventCreator.vesselEdited({
          id,
          name: vesselName.toUpperCase(),
          comments,
          berth,
          reclaimStart: moment(date).toDate(),
          clients,
          state: VesselState.VESSEL_STATE_IN_PROGRESS,
          delay,
          previousVesselId: previousVessel,
          delayReason2,
          secondComments,
          timeBetweenVesselHours,
          timeBetweenVesselMinutes,
        });

        await engine.process(event);
        history.replace('/logbook');
      }}
    >
      {({submitForm, values, touched, errors, setFieldValue}) => {
        const lVessel = state.vessels[values.previousVessel];
        const lastVesselEndDate = lVessel
          ? findVesselEndDate(lVessel).toDate()
          : undefined;
        return (
          <div className={classes.container}>
            <Paper elevation={8} className={classes.paper}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FastField
                      disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                      component={FDateTimeField}
                      name="date"
                      label="Date"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      width="100%"
                      height="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ToggleButtonField
                        disable={hasRole(UserRole.USER_ROLE_AGENT)}
                        label="Select Berth"
                        field="berth"
                        data={[
                          {id: 'OBL', description: 'OBL'},
                          {id: 'OBS', description: 'OBS'},
                        ]}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                      component={Autocomplete}
                      defaultValue={values.vesselName}
                      freeSolo
                      disableClearable
                      name="vesselName"
                      options={vesselNames}
                      renderInput={(params: AutocompleteRenderInputParams) => {
                        return (
                          <TextField
                            placeholder="Vessel name"
                            label="Vessel name"
                            {...params}
                            error={
                              touched['vesselName'] && !!errors['vesselName']
                            }
                            helperText={
                              touched['vesselName'] && !!errors['vesselName']
                                ? errors['vesselName']
                                : ''
                            }
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.betweenLine}>
                      {previousVessels.length > 0 && (
                        <SingleSelectField
                          disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                          label="Previous Vessel"
                          addNone
                          fullWidth
                          field="previousVessel"
                          data={previousTwoVessels}
                          onChangeAdditional={(e) => {
                            const lVessel = state.vessels[e.target.value];

                            const lastVesselEndDate = lVessel
                              ? findVesselEndDate(lVessel).toDate()
                              : undefined;

                            const hoursAndMinutes = getHoursAndMinutes(
                              lastVesselEndDate?.toString(),
                              values.date
                            );

                            setFieldValue(
                              'timeBetweenVesselHours',
                              hoursAndMinutes.hours
                            );
                            setFieldValue(
                              'timeBetweenVesselMinutes',
                              hoursAndMinutes.minutes
                            );
                          }}
                        />
                      )}
                      {previousVessels.length === 0 && (
                        <>
                          <Typography variant="subtitle1">
                            Previous Vessel:
                          </Typography>
                          <div className={classes.dottedBox}>
                            <Typography variant="button">
                              {lastVessel?.name ?? 'N/A'}
                            </Typography>
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.betweenLine}>
                      <Typography variant="subtitle1">
                        Time between vessels:
                      </Typography>

                      {hasRole(UserRole.USER_ROLE_ADMIN) ? (
                        <TimeBetweenLastVesselEdit isEditing={isEditing} />
                      ) : (
                        <Typography variant="button">
                          <TimeBetweenLastVesselView
                            startTime={lastVesselEndDate?.toString()}
                          />
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <SingleSelectField
                      disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                      fullWidth
                      addNone
                      field="delay"
                      label="Was there a vessel delay?"
                      placeholder="Was there a vessel delay?"
                      data={options}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField
                      disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                      fullWidth
                      multiline
                      component={FieldTextField}
                      name="comments"
                      label="Comments"
                      placeholder="Comments"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleSelectField
                      disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                      fullWidth
                      addNone
                      field="delayReason2"
                      label="Was there a second vessel delay?"
                      placeholder="Was there a second vessel delay?"
                      data={options}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FastField
                      disabled={hasRole(UserRole.USER_ROLE_AGENT)}
                      fullWidth
                      multiline
                      component={FieldTextField}
                      name="secondComments"
                      label="Comments"
                      placeholder="Comments"
                    />
                  </Grid>
                </Grid>
                <Box className={classes.clientBox}>
                  <Typography variant="subtitle1">Select Clients</Typography>
                  <Box display="flex" flexDirection="column">
                    <VesselEditorClients
                      field="clients"
                      isEditing={isEditing}
                      data={clientNoOreType}
                      vessel={vessel}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Button
                    disabled={
                      hasRole(UserRole.USER_ROLE_AGENT) ||
                      (isEditing && hasRole(UserRole.USER_ROLE_USER))
                    }
                    fullWidth
                    color="secondary"
                    onClick={submitForm}
                  >
                    {isEditing ? 'Update' : 'Create'}
                  </Button>
                  <Button
                    fullWidth
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      setVesselState();
                      setTimeout(() => {
                        history.goBack();
                      }, 1 * 500);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Paper>
          </div>
        );
      }}
    </BaseForm>
  );
}
