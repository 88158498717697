import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {register} from '../serviceWorker';
import {AuthContext} from 'contexts/AuthContext';

interface Props {
  children: React.ReactNode;
}

// https://github.com/facebook/create-react-app/issues/5316#issuecomment-591075209
function updateHandler(
  registration: ServiceWorkerRegistration,
  waiting: ServiceWorker
) {
  const handler = (event: Event) => {
    //eslint-disable-next-line
    // @ts-ignore
    if (event?.target?.state === 'activated') {
      window.location.reload();
    }
  };

  registration
    .unregister()
    .then(() => {
      waiting.addEventListener('statechange', handler);
      waiting.postMessage({type: 'SKIP_WAITING'});
    })
    .catch((ex) => {
      window.alert(`Failed to Update: ${ex.message}`);
    });

  return () => {
    waiting.removeEventListener('statechange', handler);
  };
}

type ServiceWorkerContextType = (() => void) | undefined;

export const ServiceWorkerContext = React.createContext<
  ServiceWorkerContextType
>(undefined);

export const ServiceWorkerProvider = React.memo(function ServiceWorkerProvider({
  children,
}: Props) {
  const {auth} = React.useContext(AuthContext);

  const [updateAvailable, setUpdateAvailable] = React.useState<() => void>();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    register({
      onUpdate(registration) {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
          // Immediately reload if user not logged in
          if (!auth.authenticated && waitingServiceWorker) {
            updateHandler(registration, waitingServiceWorker);
          } else {
            setOpen(true);
            setUpdateAvailable(() => {
              updateHandler(registration, waitingServiceWorker);
              setOpen(false);
            });
          }
        }
      },
    });
  }, [setOpen, auth.authenticated]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleUpdate = React.useCallback(() => {
    if (updateAvailable) {
      updateAvailable();
    }
    handleClose();
  }, [handleClose, updateAvailable]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Application Update Available'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A new version of app is available, This will reload the application
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
          <Button onClick={handleClose} color="primary">
            Later
          </Button>
        </DialogActions>
      </Dialog>
      <ServiceWorkerContext.Provider value={updateAvailable}>
        {children}
      </ServiceWorkerContext.Provider>
    </>
  );
});
