import * as React from 'react';

interface TitleContextState {
  title: string;
  setTitle: (title: string) => void;
}

export const TitleContext = React.createContext<TitleContextState>({
  title: 'Deckmans',
  setTitle: () => {},
});

export const useTitleContext = () =>
  React.useContext<TitleContextState>(TitleContext);

export function useTitle(newTitle: string) {
  const {setTitle} = useTitleContext();

  React.useEffect(() => {
    setTitle(newTitle);
  }, [newTitle, setTitle]);
}

interface Props {
  children: React.ReactNode;
}

export function TitleContextController({children}: Props) {
  const [title, setTitle] = React.useState('Deckmans');

  const value = React.useMemo(
    () => ({
      title,
      setTitle,
    }),
    [title, setTitle]
  );

  return (
    <TitleContext.Provider value={value}>{children}</TitleContext.Provider>
  );
}
