import * as React from 'react';
import {Formik} from 'formik';
import {Box, Typography, Paper, makeStyles, Button} from '@material-ui/core';
import {useTitle} from 'contexts/TitleContext';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {State, UserRole, Vessel} from '@deckmans/domain';
import {VesselSearchTable} from './VesselSearchTable';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useSearchContext} from 'contexts/SearchContext';
import {useEngineContext} from 'contexts/EngineContext';
import {useNavigatorOnline} from 'hooks/useNavigatorOnline';
import {useHistory} from 'react-router';
import {useAuthContext} from 'contexts/AuthContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface AutocompleteOptions {
  groupName: string;
  id: string;
  name: string;
}

function filterVessels(
  vessels: Vessel[],
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) {
  const v = vessels.filter((vessel) => {
    if (searchTerm !== '' && startDate !== null && endDate !== null) {
      return (
        (vessel.name === searchTerm ||
          vessel.berth === searchTerm ||
          vessel.createdBy?.username === searchTerm) &&
        moment(vessel.reclaimStart).isBetween(startDate, endDate, 'date', '[]')
      );
    } else {
      if (startDate === null && endDate === null && searchTerm !== '') {
        return (
          vessel.name === searchTerm ||
          vessel.berth === searchTerm ||
          vessel.createdBy?.username === searchTerm
        );
      } else if (startDate !== null && endDate !== null && searchTerm === '') {
        return moment(vessel.reclaimStart).isBetween(
          startDate,
          endDate,
          'date',
          '[]'
        );
      } else {
        return vessel;
      }
    }
  });

  return v;
}

export function VesselSearch() {
  useTitle('Logbook search');
  const online = useNavigatorOnline();
  const history = useHistory();

  const {hasRole, auth} = useAuthContext();

  const classes = useStyles();
  const [state, setState] = React.useState<State>({eventId: '', vessels: {}});
  const [errorMessage, setErrorMessage] = React.useState('');
  const {getState} = useEngineContext();
  const clientNames = auth.authenticated ? auth.clients : [];
  const vessels = Object.values(state.vessels).filter((item) =>
    item.clients.every((element) => {
      return hasRole(UserRole.USER_ROLE_AGENT)
        ? clientNames.indexOf(element.name) !== -1
        : item;
    })
  );

  const {
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    searchTerm,
    setSearchTerm,
  } = useSearchContext();

  React.useEffect(() => {
    const fetch = async () => {
      const state = await getState(
        endDate ?? new Date(),
        startDate ?? new Date()
      );
      if (state) {
        setState(state);
      }
    };
    fetch();
  }, [endDate, startDate, getState]);

  const filteredVessel = filterVessels(vessels, searchTerm, startDate, endDate);

  const vesselGrouped: AutocompleteOptions[] = [];
  vessels.forEach((vessel) => {
    const types = ['Vessel Name', 'Berth', 'User'];
    if (startDate === null && endDate === null) {
      types.forEach((type) => {
        if (type === 'Vessel Name') {
          vesselGrouped.push({
            groupName: type,
            id: vessel.id,
            name: vessel.name,
          });
        } else if (type === 'Berth') {
          if (!vesselGrouped.some((v) => v.name === vessel.berth)) {
            vesselGrouped.push({
              groupName: type,
              id: vessel.id,
              name: vessel.berth,
            });
          }
        } else if (type === 'User') {
          if (
            !vesselGrouped.some((v) => v.name === vessel.createdBy?.username)
          ) {
            vesselGrouped.push({
              groupName: type,
              id: vessel.id,
              name: vessel.createdBy?.username ?? '',
            });
          }
        }
      });
    } else {
      if (
        moment(vessel.reclaimStart).isBetween(startDate, endDate, 'date', '[]')
      ) {
        types.forEach((type) => {
          if (type === 'Vessel Name') {
            vesselGrouped.push({
              groupName: type,
              id: vessel.id,
              name: vessel.name,
            });
          } else if (type === 'Berth') {
            if (!vesselGrouped.some((v) => v.name === vessel.berth)) {
              vesselGrouped.push({
                groupName: type,
                id: vessel.id,
                name: vessel.berth,
              });
            }
          } else if (type === 'User') {
            if (
              !vesselGrouped.some((v) => v.name === vessel.createdBy?.username)
            ) {
              vesselGrouped.push({
                groupName: type,
                id: vessel.id,
                name: vessel.createdBy?.username ?? '',
              });
            }
          }
        });
      }
    }
  });

  return (
    <Formik
      initialValues={{
        startDate: startDate,
        endDate: endDate,
        search: searchTerm,
        groupName: '',
        id: '',
      }}
      onSubmit={() => {}}
    >
      {({values, setFieldValue}) => {
        return online ? (
          <>
            <Box marginBottom={2}>
              <Paper elevation={3}>
                <Box
                  py={2}
                  px={1.5}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="100%">
                    <Autocomplete
                      value={
                        {
                          name: values.search,
                          groupName: values.groupName,
                          id: values.id,
                        } ?? {}
                      }
                      size="small"
                      options={vesselGrouped.sort(
                        (a, b) => -b.groupName.localeCompare(a.groupName)
                      )}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      groupBy={(option) => option.groupName}
                      getOptionLabel={(option) => option.name ?? ''}
                      onChange={(e, val) => {
                        if (val) {
                          setFieldValue('search', val.name);
                          setFieldValue('groupName', val.groupName);
                          setFieldValue('id', val.id);
                          setSearchTerm(val.name ?? '');
                        } else {
                          setFieldValue('search', '');
                          setFieldValue('groupName', '');
                          setFieldValue('id', '');
                          setSearchTerm('');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="search"
                          placeholder="Search by Vessel Name, Berth or users"
                          label="Search by Vessel Name, Berth or users "
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>

                  <Box display="flex" alignItems="center" width="100%">
                    <Box px={1} width="100%" textAlign="right">
                      <Typography variant="caption" color="textSecondary">
                        Search by date
                      </Typography>
                    </Box>
                    <Box width="100%">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableFuture
                          variant="inline"
                          size="small"
                          value={values.startDate}
                          onChange={(date) => {
                            setFieldValue('startDate', date);
                            setStartDate(moment(date).startOf('day').toDate());
                          }}
                          format="dd/MM/yyyy"
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box pl={1} width="100%">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          variant="inline"
                          size="small"
                          value={values.endDate}
                          onChange={(date) => {
                            setFieldValue('endDate', date);

                            if (moment(date).isBefore(values.startDate)) {
                              setErrorMessage(
                                'End date cannot be before start date'
                              );
                            } else {
                              if (moment(date).isSame(new Date(), 'date')) {
                                setEndDate(moment(date).endOf('day').toDate());
                              } else {
                                setEndDate(moment(date).toDate());
                              }

                              setErrorMessage('');
                            }
                          }}
                          format="dd/MM/yyyy"
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" pr={2}>
                  {errorMessage !== '' && (
                    <Typography variant="caption" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Box>
            <Box>
              <VesselSearchTable vessels={filteredVessel} />
            </Box>
          </>
        ) : (
          <div className={classes.container}>
            <Paper elevation={8} className={classes.paper}>
              <Typography color="primary" variant="h3">
                You are offline
              </Typography>
              <Typography color="primary" variant="h6">
                Logbook search is only available online
              </Typography>
              <Box m={2} mb={3}>
                <Button
                  onClick={() => history.push('/logbook')}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  Take me home
                </Button>
              </Box>
            </Paper>
          </div>
        );
      }}
    </Formik>
  );
}
