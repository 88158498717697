import * as React from 'react';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {Field, getIn, useFormikContext} from 'formik';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {TextField as FTextField} from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import {ButtonBar} from 'components/ButtonBar';
import {useHistory, useParams} from 'react-router';
import {BaseForm} from 'components/BaseForm';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {StartEndDateTime} from '@deckmans/web-shared';
import * as yup from 'yup';
import {DecisionType, DecisionAnswer} from '@deckmans/domain';
import {useShiploader} from '../../hooks/useShiploader';
import {Shiploader} from '@deckmans/domain';
import {useDataContext} from 'providers/DataContextProvider';
import {useVessel} from 'hooks/useVessel';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import {SingleSelectField} from 'components/SingleSelectField';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY,
});

interface EquipmentNameProps {
  name: string;
  equipmentNames: {
    id: string;
    description: string;
  }[];
  isEditing: boolean;
  eqType: string;
  //only applicable if there is only 1 equipment name in the list
  equipmentNameFirst?: string;
}

function EquipmentNameField({
  name,
  equipmentNames,
  isEditing,
  eqType,
  equipmentNameFirst: eq,
}: EquipmentNameProps) {
  const {
    values: {reason, equipmentName},
    touched,
    errors,
    setFieldValue,
  } = useFormikContext<NonHatchBreakdownActivityType>();

  React.useEffect(() => {
    if (eqType !== '' && eqType !== reason.decisionKeys[1]) {
      setFieldValue(name, '');
    }
  }, [reason, setFieldValue, name, eqType]);

  React.useEffect(() => {
    if (eq) {
      setFieldValue(name, eq);
    }
  }, [setFieldValue, name, eq]);

  return (
    <Field
      validate={(value: string) => {
        let error = '';
        if (value === '') {
          error = 'This field is required';
        }
        return error;
      }}
      component={Autocomplete}
      disableClearable
      options={equipmentNames.map(({id}) => id)}
      defaultValue={isEditing ? equipmentName : undefined}
      renderOption={(option: string) => option}
      name={name}
      value={equipmentName}
      getOptionLabel={(option: string) => option}
      fullWidth
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <>
            <TextField
              placeholder="Equipment Name"
              label="Equipment Name"
              {...params}
            />
            {getIn(touched, name) && !!getIn(errors, name) && (
              <Box pl={2}>
                <Typography variant="caption" color="error">
                  {errors['equipmentName']}
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    />
  );
}

interface NonHatchBreakdownActivityType {
  id: string;
  startTime: string;
  endTime: string;
  equipmentName: string;
  comments: string;
  shiploader: Shiploader;
  reason: DecisionAnswer;
  client: string; //Native select workaround
  oreType: string; //Native select workaround
}

export function NonHatchBreakdownActivity() {
  const history = useHistory();
  const {engine, eventCreator} = useEngineContext();
  const {master} = useDataContext();
  const {vesselId, activityId, shiploaderId} = useParams<{
    shiploaderId: string;
    vesselId: string;
    activityId: string;
  }>();

  const shiploader = useShiploader(shiploaderId);

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const vessel = useVessel();
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    oreType: yup.mixed<string>().required('This field is required'),
    client: yup.mixed<string>().required('This field is required'),
  });

  const activity = React.useMemo(() => {
    if (activityId) {
      return vessel.activities[activityId];
    }
  }, [activityId, vessel.activities]);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);

  const sd = pvEndDate !== '' ? pvEndDate : vessel.reclaimStart ?? new Date();
  const {startDate, endDate} = useHistoryStateDateRange(sd);

  const initialValues = React.useMemo(() => {
    if (isEditing && vessel && activity && activity.nonHatchBreakdownActivity) {
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments: activity.nonHatchBreakdownActivity.comments,
        equipmentName: activity.nonHatchBreakdownActivity.equipmentName,
        id: activity.id,
        shiploader,
        reason: activity.nonHatchBreakdownActivity.reason ?? defaultReason,
        client: activity?.nonHatchBreakdownActivity.client.toString() ?? '',
        oreType: activity?.nonHatchBreakdownActivity.oreType.toString() ?? '',
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        comments: '',
        equipmentName: '',
        id: '',
        shiploader,
        reason: defaultReason,
        client: '',
        oreType: '',
      };
    }
  }, [activity, isEditing, shiploader, startDate, endDate, vessel]);

  const [eqType, setEqType] = React.useState('');
  return (
    <BaseForm<NonHatchBreakdownActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={initialValues}
      onCreateSubmit={async ({
        startTime,
        endTime,
        comments,
        shiploader,
        equipmentName,
        reason,
        client,
        oreType,
      }) => {
        const equipmentNames = master.equipmentTypes
          .find((et) => et.type === reason.decisionKeys[1] ?? '')
          ?.names.map((en) => ({id: en, description: en}));
        const event = eventCreator.nonHatchBreakdownActivityCreated({
          vesselId,
          comments,
          equipmentName: equipmentNames ? equipmentName : '',
          shiploader,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          reason,
          oreType: oreType,
          client: client,
        });
        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({
        comments,
        endTime,
        startTime,
        shiploader,
        equipmentName,
        reason,
        id,
        client,
        oreType,
      }) => {
        const equipmentNames = master.equipmentTypes
          .find((et) => et.type === reason.decisionKeys[1] ?? '')
          ?.names.map((en) => ({id: en, description: en}));
        const event = eventCreator.nonHatchBreakdownActivityEdited({
          id,
          vesselId,
          comments,
          equipmentName: equipmentNames ? equipmentName : '',
          shiploader,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          reason: reason,
          oreType: oreType,
          client: client,
        });
        await engine.process(event);
        history.goBack();
      }}
    >
      {({values, submitForm, setFieldValue}) => {
        const equipmentNames = master.equipmentTypes
          .find((et) => et.type === values.reason.decisionKeys[1] ?? '')
          ?.names.map((en) => ({id: en, description: en}));
        setEqType(values.reason.decisionKeys[1]);

        return (
          <Box py={2}>
            <Grid container spacing={3}>
              {isEditing && (
                <Grid item xs={12}>
                  <Box px={1}>
                    <Typography variant="h4" color="primary">
                      Breakdown activity
                    </Typography>
                  </Box>
                </Grid>
              )}
              <StartEndDateTime />
              <Grid item xs={6}>
                <Box px={1}>
                  <Duration
                    startTime={values.startTime}
                    endTime={values.endTime}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Box px={1}>
                  <SingleSelectField
                    fullWidth
                    addNone
                    field="client"
                    label="Client"
                    onChangeCapture={() => {
                      setFieldValue('oreType', '');
                    }}
                    placeholder="Client name"
                    data={vessel.clients.map(({name}) => {
                      const client = name;
                      if (!client) {
                        return {
                          id: '',
                          description: '',
                        };
                      } else {
                        return {
                          id: client,
                          description: client,
                        };
                      }
                    })}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box px={1}>
                  <SingleSelectField
                    fullWidth
                    field="oreType"
                    label="Ore type"
                    placeholder="Ore type"
                    addNone
                    data={master.clients
                      .filter(({type}) => {
                        return `${type}` === values.client;
                      })
                      .map((b) => {
                        return b.oreTypes;
                      })[0]
                      ?.map(({id, description}) => ({
                        id: id.toString(),
                        description,
                      }))}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <MultiLevelQuestions
                  name="reason"
                  decisionType={DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY}
                  subLevels={[
                    <React.Fragment key="empty_1" />,
                    equipmentNames ? (
                      <Grid key="names_in_place" item xs={12}>
                        <Box px={1} py={1}>
                          {/* <SingleSelectField
                            addNone
                            fullWidth
                            label="Equipment Name"
                            data={equipmentNames}
                            field="equipmentName"
                          /> */}
                          <EquipmentNameField
                            name="equipmentName"
                            equipmentNames={equipmentNames}
                            isEditing={isEditing}
                            eqType={eqType}
                            equipmentNameFirst={
                              equipmentNames?.length === 1
                                ? equipmentNames[0].id
                                : undefined
                            }
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    ),
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Box px={1}>
                  <Field
                    fullWidth
                    multiline
                    component={FTextField}
                    name="comments"
                    label="Comments"
                    placeholder="Comments"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ButtonBar
                  saveText="save"
                  createText="create"
                  isEditing={isEditing}
                  handleSave={submitForm}
                  vessel={vessel}
                  activityId={activityId}
                  title="Delete Breakdown Activity"
                  subtitle="Are you sure you want to delete this activity?"
                />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </BaseForm>
  );
}
