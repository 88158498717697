import React from 'react';
import {TextField, Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {useField, FieldMetaProps} from 'formik';
import {Option, Decision, DecisionAnswer, DecisionType} from '@deckmans/domain';
import {useDataContext} from 'providers/DataContextProvider';
import _ from 'lodash';

function optionDig(options: Option[], keys: string[], ps: string[]) {
  let opt: Option | null = null;
  for (const itm of options) {
    if (keys.indexOf(itm.key) !== -1) {
      opt = itm;
    }
  }
  if (opt && opt?.options) {
    //dig deeper,
    ps.push(opt.name);
    optionDig(opt.options, keys, ps);
  } else if (opt) {
    ps.push(opt.name);
  }
}
export function printDecision(option: Decision, ids: string[]) {
  const printString: string[] = [];
  optionDig(option?.options ?? [], ids, printString);
  return printString;
}

interface DropdownProps {
  value: DecisionAnswer;
  options: Option[];
  label: string;
  setValue(newValue: string, level: number): void;
  level?: number;
  meta: FieldMetaProps<DecisionAnswer>;
  subLevels: JSX.Element[];
}
function Dropdown({
  options,
  value,
  setValue,
  label,
  level = 0,
  meta,
  subLevels,
}: DropdownProps) {
  const levelValue = React.useMemo(() => {
    if (value.decisionKeys.length + 1 >= level) {
      return value.decisionKeys[level];
    }
    return '';
  }, [value, level]);

  const orderedOptions = _.orderBy(options, ['sequence'], ['asc']);

  const childOption = React.useMemo(() => {
    return orderedOptions?.find((option) => {
      return option.key === levelValue;
    });
  }, [levelValue, orderedOptions]);

  const freeText = orderedOptions[0].freeText;

  if (
    options.length === 1 &&
    options[0].label === 'Equipment Type' &&
    value.decisionKeys.length === 1
  ) {
    setValue(options[0].key, level);
  }
  return (
    <>
      <Box width="100%" p={1} style={{boxSizing: 'border-box'}}>
        {!freeText && (
          <TextField
            select
            label={label}
            value={levelValue}
            fullWidth
            SelectProps={{native: true}}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setValue(e.target.value, level);
            }}
          >
            {[
              <option key="null" aria-label="None" value="">
                None
              </option>,
              ...orderedOptions.map(({name, key}) => {
                return (
                  <option key={key} value={key}>
                    {name}
                  </option>
                );
              }),
            ]}
          </TextField>
        )}

        {freeText && orderedOptions.length > 0 && (
          <TextField
            fullWidth
            value={levelValue}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setValue(e.target.value, level);
            }}
            label={orderedOptions[0].label}
            placeholder={orderedOptions[0].label}
          />
        )}
      </Box>
      {subLevels.length >= level && subLevels[level]}
      {childOption?.options && childOption.options.length > 0 && (
        <>
          <Dropdown
            meta={meta}
            options={childOption.options}
            level={level + 1}
            value={value}
            setValue={setValue}
            label={childOption.options[0].label}
            subLevels={subLevels}
          />
        </>
      )}
    </>
  );
}
interface Props {
  name: string;
  decisionType: DecisionType;
  subLevels?: JSX.Element[];
}

function getChildOption(key: string, options: Option[]): Option[] {
  for (const opt of options) {
    if (key === opt.key) {
      return opt.options;
    }
  }

  throw new Error(`Unknown Key: ${key}`);
}

function validate(decision: Decision, newAnswer: DecisionAnswer) {
  if (newAnswer.decisionKeys.length === 0) {
    return false;
  }

  let options = decision.options;

  for (const key of newAnswer.decisionKeys) {
    options = getChildOption(key, options);
  }

  return !options || options.length === 0;
}

export function MultiLevelQuestions({
  name,
  decisionType,
  subLevels = [],
}: Props) {
  const [field, meta, helper] = useField<DecisionAnswer>({
    name,
    validate: (newAnswer: DecisionAnswer) => {
      const isValid = validate(decision, newAnswer);

      if (isValid) {
        return '';
      } else {
        return 'Please make sure you select all the values.';
      }
    },
  });
  const {decision: DecisionFind} = useDataContext();
  const {value} = field;

  const decision = DecisionFind(decisionType);

  const setValue = React.useCallback(
    // do NOT reset when value does not change
    (val: string, level: number) => {
      if (value.decisionKeys[level] !== val) {
        // Reset
        if (val === '') {
          // shorten values if user switches choice higher in the tree
          const newValue = [...value.decisionKeys].slice(0, level);
          helper.setValue({type: decisionType, decisionKeys: newValue});
        } else {
          // shorten values if user switches choice higher in the tree
          const newValue = [...value.decisionKeys].slice(0, level);
          newValue.push(val);
          helper.setValue({type: decisionType, decisionKeys: newValue});
        }
      }
    },

    [decisionType, helper, value.decisionKeys]
  );

  return (
    <>
      {decision?.options && decision.options.length > 0 && (
        <Dropdown
          subLevels={subLevels}
          meta={meta}
          value={value}
          label={decision.options[0].label}
          options={decision.options}
          setValue={setValue}
        />
      )}
      {meta.error && meta.touched && (
        <Box pl={2}>
          <Typography variant="caption" color="error">
            This field is required
          </Typography>
        </Box>
      )}
    </>
  );
}
