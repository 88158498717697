import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import {createLoginService} from 'services';
import moment from 'moment';
import {useAlert} from '@deckmans/web-shared';
import {useIndexedDbContext} from './IndexedDbContext';
import {setUser, clearUser} from 'appInsights';
import * as domain from '@deckmans/domain';
import {useHistory} from 'react-router';

export type AuthContextType =
  | {
      token: string;
      authenticated: true;
      roles: domain.UserRole[];
      clients: string[];
      username: string;
      userId: number;
      expiry: Date;
    }
  | {
      authenticated: false;
    };

export interface AuthContextStateType {
  auth: AuthContextType;
  handleLogin: (username: string, password: string) => Promise<void>;
  handleLogout: () => void;
  handleCreatePassword: (token: string, password: string) => Promise<void>;
  handleRequestResetPassword: (req: {
    username: string;
  }) => Promise<domain.ResetPasswordResponse>;
  hasRole(role: domain.UserRole): boolean;
}

export const AuthContext = createContext<AuthContextStateType>({
  auth: {authenticated: false},
  handleLogin: async () => new Promise(() => {}),
  handleLogout: () => {},
  handleCreatePassword: async () => new Promise(() => {}),
  handleRequestResetPassword: async () => new Promise(() => {}),
  hasRole: () => true,
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function AuthContextProvider({children}: Props) {
  const loginService = createLoginService();

  const loggingOut = useRef(false);
  const authTokenLoaded = React.useRef<boolean>(false);
  const db = useIndexedDbContext();
  const alert = useAlert();
  const {push: navigate} = useHistory();
  const [localAuth, setLocalAuth] = useLocalStorage<AuthContextType>('auth', {
    authenticated: false,
  });
  const [auth, setAuth] = useState<AuthContextType>(() => {
    authTokenLoaded.current = true;
    if (localAuth) {
      return localAuth;
    } else {
      return {authenticated: false};
    }
  });
  const handleLogin = React.useCallback(
    async function (username: string, password: string) {
      loggingOut.current = false;

      const result = await loginService.Login(
        domain.LoginRequest.fromPartial({
          username,
          password,
        })
      );
      if (
        result.changePasswordToken != null &&
        result.changePasswordToken !== ''
      ) {
        navigate(`/changePassword?token=${result.changePasswordToken}`);

        return;
      }

      const newAuth: AuthContextType = {
        authenticated: true,
        ...result,
        username,
        expiry: moment().add(30, 'days').toDate(),
      };

      setAuth(newAuth);
      setLocalAuth(newAuth);
      setUser(result.userId.toString());
      navigate('/');
    },
    [loginService, setLocalAuth, navigate]
  );
  const handleCreatePassword = React.useCallback(
    async function (token: string, password: string) {
      loggingOut.current = false;

      const result = await loginService.CreatePassword(
        domain.CreatePasswordRequest.fromPartial({
          password,
          token,
        })
      );
      const newAuth: AuthContextType = {
        authenticated: true,
        ...result,
        username: result.username,
        expiry: moment().add(30, 'days').toDate(),
      };

      setAuth(newAuth);
      setLocalAuth(newAuth);
      setUser(result.userId.toString());
      alert('Successfully created password', 'success');
    },
    [setLocalAuth, alert, loginService]
  );
  const handleRequestResetPassword = React.useCallback(
    ({username}: {username: string}) => {
      return loginService.ResetPassword(
        domain.ResetPasswordRequest.fromPartial({username})
      );
    },
    [loginService]
  );

  const handleLogout = useCallback(() => {
    if (auth.authenticated === true) {
      setAuth({authenticated: false});
      setLocalAuth({authenticated: false});
      clearUser();
    }
  }, [auth.authenticated, setLocalAuth]);

  useEffect(() => {
    if (loggingOut.current) {
      handleLogout();
      loggingOut.current = false;
    }
  }, [loggingOut, handleLogout]);

  useEffect(() => {
    let innerAuth: AuthContextType = {authenticated: false};

    // TODO: Localize expiry
    if (localAuth != null) {
      innerAuth = localAuth;

      setAuth(innerAuth);
    }
  }, [localAuth, auth]);

  const expiry = auth.authenticated ? auth.expiry : null;

  useEffect(() => {
    const interval = setInterval(async () => {
      if (auth.authenticated && moment().isSameOrAfter(expiry)) {
        if ((await db.getPendingEventsCount()) > 0) {
          alert('Your session is about to expire. Please sync data', 'warning');
        } else {
          alert('Your session has expired. Please log in again', 'error');
        }
      }
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, [auth.authenticated, expiry, alert, db]);

  const hasRole = React.useCallback(
    (role: domain.UserRole) => {
      if (!auth.authenticated) {
        return false;
      }

      if (auth.roles?.indexOf(role) !== -1) {
        return true;
      }

      return false;
    },
    [auth]
  );

  const value = React.useMemo(
    () => ({
      auth,
      handleLogin,
      handleLogout,
      hasRole,
      handleCreatePassword,
      handleRequestResetPassword,
    }),
    [
      auth,
      handleLogin,
      handleLogout,
      hasRole,
      handleCreatePassword,
      handleRequestResetPassword,
    ]
  );

  // Prevent double render before auth token loaded
  if (!authTokenLoaded.current) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
