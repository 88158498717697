import React from 'react';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Add from '@material-ui/icons/Add';
import {useParams, useHistory} from 'react-router';
import Typography from '@material-ui/core/Typography';
import {
  Vessel,
  VesselActivity,
  Shiploader,
  VesselState,
  UserRole,
} from '@deckmans/domain';
import {useAuthContext} from 'contexts/AuthContext';
import {SortVesselActivities} from '@deckmans/domain/lib/util/sortVesselActivities';
interface Props {
  shiploader: Shiploader;
  vessel: Vessel;
}

function VesselViewCellManager(vessel: Vessel, shiploader: Shiploader) {
  const vesselActivities = SortVesselActivities(vessel.activities);
  const cellItemLeft: VesselActivity[] = [];
  const cellItemRight: VesselActivity[] = [];

  vesselActivities.forEach((vesselActivity) => {
    const {vesselHatchActivityGroup, shiploader} = vesselActivity;
    if (vesselHatchActivityGroup) {
      // Shiploader specific
      const Item = vesselActivity;
      if (shiploader === Shiploader.SHIPLOADER_ONE) {
        cellItemLeft.push(Item);
      }
      if (shiploader === Shiploader.SHIPLOADER_TWO) {
        cellItemRight.push(Item);
      }
    }
  });

  if (shiploader === 1) {
    return cellItemLeft;
  } else {
    return cellItemRight;
  }
}

export function CheckIfHatchStopped(vessel: Vessel, shiploader: Shiploader) {
  const sortedActivities = VesselViewCellManager(vessel, shiploader);
  let isStopped = true;

  if (sortedActivities.length > 0) {
    const completedActivities = sortedActivities.filter(
      (activity) => activity.vesselHatchActivityGroup?.completed
    );
    isStopped =
      completedActivities.length === sortedActivities.length ? true : false;
  }
  return isStopped;
}

export default function ActivityButton({shiploader, vessel}: Props) {
  //Check done in parent
  const params = useParams<{vesselId: string}>();
  const history = useHistory();

  const {hasRole} = useAuthContext();
  const isVesselSubmitted = vessel.state === VesselState.VESSEL_STATE_COMPLETE;
  const roleAllowsAfterVesselSubmitted =
    hasRole(UserRole.USER_ROLE_ADMIN) || hasRole(UserRole.USER_ROLE_SUPERVISOR);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isStop = CheckIfHatchStopped(vessel, shiploader);

  return (
    <div>
      <Fab
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="extended"
        size="small"
        color="primary"
        onClick={handleClick}
        disabled={
          hasRole(UserRole.USER_ROLE_AGENT) || isVesselSubmitted
            ? roleAllowsAfterVesselSubmitted
              ? false
              : true
            : false
        }
      >
        <Add fontSize="small" /> Activity
      </Fab>
      {isStop ? (
        <Menu
          id="create-hatch-activity-menu"
          anchorEl={anchorEl}
          anchorOrigin={{horizontal: 'right', vertical: 'top'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            disabled={isStop ? false : true}
            onClick={() => {
              history.push(`/hatch/new/${shiploader}/${params.vesselId}`);
              handleClose();
            }}
          >
            <Typography variant="button">Hatch activity</Typography>
          </MenuItem>
          <MenuItem
            disabled={isStop ? false : true}
            onClick={() => {
              //alert('TODO');
              history.push(
                `/activity/non-hatch-activity/${shiploader}/${params.vesselId}`
              );
              handleClose();
            }}
          >
            <Typography variant="button">Non Hatch activity</Typography>
          </MenuItem>
          {/* <MenuItem
            disabled={isStop ? false : true}
            onClick={() => {
              //alert('TODO');
              history.push(
                `/activity/single-loading/${shiploader}/${params.vesselId}`
              );
              handleClose();
            }}
          >
            <Typography variant="button">Single Loading Activity</Typography>
          </MenuItem> */}
        </Menu>
      ) : (
        <Menu
          id="create-hatch-activity-menu"
          anchorEl={anchorEl}
          anchorOrigin={{horizontal: 'right', vertical: 'top'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            disabled={isStop ? false : true}
            onClick={() => {
              //alert('TODO');
              history.push(
                `/activity/single-loading/${shiploader}/${params.vesselId}`
              );
              handleClose();
            }}
          >
            <Typography variant="button">Please finish hatch</Typography>
          </MenuItem>
        </Menu>
      )}
      {/* </Menu> */}
    </div>
  );
}
