import * as React from 'react';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {Field} from 'formik';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {TextField} from 'formik-material-ui';
import {ButtonBar} from 'components/ButtonBar';
import {useHistory, useParams} from 'react-router';
import {BaseForm} from 'components/BaseForm';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {StartEndDateTime} from '@deckmans/web-shared';
import * as yup from 'yup';
import {DecisionType, DecisionAnswer} from '@deckmans/domain';
import {FullPageError} from 'components/FullPageError';
import {Client} from '@deckmans/domain';
import {useShiploader} from 'hooks/useShiploader';
import {SingleSelectField} from 'components/SingleSelectField';
// import {clients} from '@deckmans/domain/lib/util/enumData';
import {useVessel} from 'hooks/useVessel';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {useDataContext} from 'providers/DataContextProvider';

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY,
});

interface NonHatchReclaimingActivityType {
  id: string;
  startTime: string;
  endTime: string;
  comments: string;
  reason: DecisionAnswer;
  client: string;
}

export function NonHatchReclaimingActivity() {
  const history = useHistory();
  const {engine, eventCreator} = useEngineContext();
  const {
    master: {clientNoOreType},
  } = useDataContext();

  const {activityId, shiploaderId} = useParams<{
    shiploaderId: string;
    activityId: string;
  }>();

  const shiploader = useShiploader(shiploaderId);
  const vessel = useVessel();

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    reason: yup.object<DecisionAnswer>({
      type: yup
        .mixed<DecisionType>()
        .required(
          'This should be passed into the component so should never fail'
        ),
      decisionKeys: yup
        .array<string>()
        .min(1)
        .required('This field is required'),
    }),
    client: yup.mixed<Client>().when('reason', {
      is: (reason: DecisionAnswer) => {
        return (
          reason.decisionKeys.length > 0 &&
          reason.decisionKeys[0] === 'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );
      },
      then: yup
        .mixed<Client>()
        .notOneOf(
          [Client.CLIENT_UNSPECIFIED, Client.UNRECOGNIZED, ''],
          'This is required field'
        )
        .required('This is a required field'),
      otherwise: yup.string(),
    }),
  });

  const activity = React.useMemo(() => {
    if (activityId) {
      return vessel.activities[activityId];
    }
  }, [activityId, vessel.activities]);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);

  const sd = pvEndDate !== '' ? pvEndDate : vessel?.reclaimStart ?? new Date();
  const {startDate, endDate} = useHistoryStateDateRange(sd);

  if (!vessel) {
    return <FullPageError error={new Error('No Vessel could be found')} />;
  }
  return (
    <BaseForm<NonHatchReclaimingActivityType>
      isEditing={isEditing}
      validationSchema={validationSchema}
      initialValues={
        isEditing
          ? {
              startTime: moment(activity?.startDate).format('YYYY-MM-DDTHH:mm'),
              endTime: moment(activity?.endDate).format('YYYY-MM-DDTHH:mm'),
              comments: activity?.nonHatchReclaimingActivity?.comments ?? '',
              id: activityId ?? '',
              client: activity?.nonHatchReclaimingActivity?.client ?? '',
              reason:
                activity?.nonHatchReclaimingActivity?.reason ?? defaultReason,
            }
          : {
              startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
              endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
              comments: '',
              id: '',
              client: '',
              reason: defaultReason,
            }
      }
      onCreateSubmit={async ({
        startTime,
        endTime,
        comments,
        reason,
        client,
      }) => {
        const loadClient = reason.decisionKeys.includes(
          'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );

        const event = eventCreator.nonHatchReclaimingActivityCreated({
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          reason,
          shiploader,
          comments,
          client: loadClient ? client : '',
          vesselId: vessel.id,
        });

        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({
        startTime,
        endTime,
        comments,
        id,
        reason,
        client,
      }) => {
        const loadClient = reason.decisionKeys.includes(
          'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );

        const event = eventCreator.nonHatchReclaimingActivityEdited({
          id,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          reason,
          comments,
          shiploader,
          client: loadClient ? client : '',
          vesselId: vessel.id,
        });

        await engine.process(event);
        history.goBack();
      }}
    >
      {({values: {startTime, endTime, reason}, submitForm}) => {
        const showClient = reason.decisionKeys.includes(
          'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );
        return (
          <Box py={2}>
            <Grid container spacing={3}>
              {isEditing && (
                <Grid item xs={12}>
                  <Box px={1}>
                    <Typography variant="h4" color="primary">
                      Reclaiming activity
                    </Typography>
                  </Box>
                </Grid>
              )}
              <StartEndDateTime />

              <Grid item xs={6}>
                <Box px={1}>
                  <Duration startTime={startTime} endTime={endTime} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <MultiLevelQuestions
                  name="reason"
                  decisionType={DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY}
                />
              </Grid>

              {showClient && (
                <Grid item xs={12}>
                  <Box px={1}>
                    <SingleSelectField
                      fullWidth
                      field="client"
                      label="Client"
                      placeholder="Client"
                      addNone
                      data={clientNoOreType}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box px={1}>
                  <Field
                    fullWidth
                    multiline
                    component={TextField}
                    name="comments"
                    label="Comments"
                    placeholder="Comments"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ButtonBar
                  saveText="save"
                  createText="create"
                  isEditing={isEditing}
                  handleSave={submitForm}
                  vessel={vessel}
                  activityId={activityId}
                  title="Delete Reclaiming Activity"
                  subtitle="Are you sure you want to delete this activity?"
                />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </BaseForm>
  );
}
