import * as React from 'react';
import {useHistory, useParams} from 'react-router';
import {FastField, FastFieldProps, Field, FieldProps} from 'formik';
import {TextField as FieldTextField} from 'formik-material-ui';
import {Box, Paper, makeStyles, Theme, Typography} from '@material-ui/core';
import {useTitle} from 'contexts/TitleContext';
import {useEngineContext} from 'contexts/EngineContext';
import {SingleSelectField} from 'components/SingleSelectField';
import Grid from '@material-ui/core/Grid';
import {BaseForm} from 'components/BaseForm';
import moment from 'moment';
import * as yup from 'yup';
import {Duration} from 'components/Duration';
import {StartEndDateTime} from '@deckmans/web-shared';
import {OreGroup, SurveyType} from '@deckmans/domain/lib/proto/shared';
import {surveyTypes} from '@deckmans/domain/lib/util/enumData';
import {findAllVesselActivitiesEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {ButtonBar} from 'components/ButtonBar';
import {Vessel, VesselActivity} from '@deckmans/domain';
import {useVessel} from 'hooks/useVessel';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {isBefore} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));
interface SurveyActivityType {
  id?: string;
  startDate: string;
  endDate: string;
  oreGroup?: OreGroup[];
  surveyType: SurveyType;
  comments: string;
}

function getOreTypesLoaded(
  isEditing: boolean,
  activityStartDate: Date | undefined,
  vesselActivities: Vessel['activities'],
  startDate?: Date
) {
  return Object.values(vesselActivities)
    .filter((previousActivities) => {
      // If we are editing a existing survey only include ore types from before the survey was done
      if (isEditing && previousActivities?.startDate && activityStartDate) {
        return isBefore(previousActivities.startDate, activityStartDate);
      } else if (!isEditing && startDate && previousActivities?.startDate) {
        return isBefore(previousActivities.startDate, startDate);
      }
      // Use all activities for creating
      return true;
    })
    .filter((previousActivities) => {
      return Boolean(previousActivities.vesselHatchActivityGroup != null);
    })
    .map((previousActivities) => previousActivities.vesselHatchActivityGroup)
    .map((vhag) => {
      return vhag?.oreType ?? '';
    })
    .filter((oreType, index, self) => {
      return self.indexOf(oreType) === index;
    });
}

export function SurveyActivityEditor() {
  const classes = useStyles();
  const {engine, eventCreator} = useEngineContext();
  const history = useHistory();
  const params = useParams<{vesselId?: string; activityId?: string}>();
  const isEditing = React.useMemo(() => {
    return Boolean(params.vesselId && params.activityId);
  }, [params]);

  useTitle(isEditing ? 'Edit Survey Activity' : 'Survey Activity');

  const vessel: Vessel = useVessel();

  const activity: VesselActivity = vessel.activities[params.activityId ?? ''];

  const oreTypesLoaded = getOreTypesLoaded(
    isEditing,
    activity?.startDate,
    vessel.activities
  );

  const validationsSchema = yup.object().shape({
    comments: yup.string(),
    surveyType: yup
      .number()
      .required('This field is required')
      .min(1, 'This is a required field'),
    startDate: yup
      .date()
      .required('This field is required')
      .min(
        vessel.reclaimStart ?? new Date(),
        ` Start time cannot be before vessel start time (${vessel.reclaimStart?.toDateString()} ${vessel.reclaimStart?.toLocaleTimeString()})`
      ),
    endDate: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startDate} = this.parent;
          return moment(value).isAfter(moment(startDate));
        }
      ),
    oreGroup: yup.array().when('surveyType', {
      is: function (value: SurveyType) {
        if (
          value === SurveyType.SURVEY_TYPE_DRAFT_CHECK ||
          value === SurveyType.UNRECOGNIZED ||
          value === SurveyType.SURVEY_TYPE_UNSPECIFIED
        ) {
          return false;
        } else {
          return true;
        }
      },
      then: yup
        .array()
        .of(
          yup.object().shape({
            oreType: yup.string().required('This field is required'),
            oreTons: yup
              .number()
              .required('This field is required')
              .typeError('Tons must be a number')
              .positive('You can only enter numbers')
              .min(1, 'Your ore tons cannot be less than one ton'),
          })
        )
        .required('This field is required'),
    }),
  });

  const pvEndDate = findAllVesselActivitiesEndDate(vessel);

  const sd = pvEndDate !== '' ? pvEndDate : vessel.reclaimStart ?? new Date();
  const {startDate, endDate} = useHistoryStateDateRange(sd);
  const initialValues: SurveyActivityType = React.useMemo(() => {
    const oreGroupsVisible = oreTypesLoaded.map((ot) => {
      const og: OreGroup = {
        oreType: ot,
        oreTons: 0,
      };
      return og;
    });
    const addNewGroups = (previousOreGroup: OreGroup[]) => {
      //allowedOreGroups
      const oldIdsSaved = previousOreGroup.map((og) => og.oreType);
      //exclude ids that has been assigned
      const ogv = oreGroupsVisible.filter(({oreType}) => {
        return oldIdsSaved.indexOf(oreType) === -1;
      });
      previousOreGroup.forEach((og) => ogv.push(og));
      return ogv;
    };
    if (isEditing && activity && activity.surveyActivity) {
      const {
        id,
        endDate,
        startDate,
        surveyActivity: {comments, surveyType, oreGroup},
      } = activity;
      return {
        id,
        startDate: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        surveyType,
        //TODO check logic
        oreGroup: addNewGroups(oreGroup),
        comments,
      };
    } else {
      return {
        id: '',
        startDate: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        oreGroup: oreGroupsVisible,
        surveyType: SurveyType.SURVEY_TYPE_UNSPECIFIED,
        comments: '',
      };
    }
  }, [isEditing, oreTypesLoaded, activity, startDate, endDate]);

  return (
    <BaseForm<SurveyActivityType>
      initialValues={initialValues}
      validationSchema={validationsSchema}
      isEditing={isEditing}
      onCreateSubmit={async ({
        endDate,
        surveyType,
        startDate,
        oreGroup,
        comments,
      }) => {
        let st = surveyType;
        if (typeof surveyType === 'string') {
          st = parseInt(surveyType, 10) as SurveyType;
        }
        const event = eventCreator.surveyActivityCreated({
          surveyType: st,
          vesselId: params.vesselId ?? '',
          endDate: moment(endDate).toDate(),
          startDate: moment(startDate).toDate(),
          oreGroup: surveyType > 0 && oreGroup ? oreGroup : [],
          comments,
        });
        await engine.process(event);
        history.replace('/vessel/view/' + vessel.id);
      }}
      onEditSubmit={async ({
        endDate,
        surveyType,
        startDate,
        id,
        oreGroup,
        comments,
      }) => {
        let st = surveyType;
        if (typeof surveyType === 'string') {
          st = parseInt(surveyType, 10) as SurveyType;
        }
        const event = eventCreator.surveyActivityEdited({
          id: id ?? '',
          surveyType: st,
          vesselId: params.vesselId ?? '',
          endDate: moment(endDate).toDate(),
          startDate: moment(startDate).toDate(),
          oreGroup: surveyType > 0 && oreGroup ? oreGroup : [],
          comments,
        });
        await engine.process(event);
        history.replace('/vessel/view/' + vessel.id);
      }}
    >
      {({submitForm, values: {surveyType, ...values}, setFieldValue}) => {
        const st =
          typeof surveyType === 'string'
            ? (parseInt(surveyType, 10) as SurveyType)
            : surveyType;

        return (
          <div className={classes.container}>
            <Paper elevation={8} className={classes.paper}>
              <Box py={4} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime
                    startDateField="startDate"
                    endDateField="endDate"
                    onChangeStart={(startDate) => {
                      setFieldValue(
                        'oreGroup',
                        getOreTypesLoaded(
                          isEditing,
                          activity?.startDate,
                          vessel.activities,
                          new Date(startDate)
                        ).map((ot) => {
                          const og: OreGroup = {
                            oreType: ot,
                            oreTons: 0,
                          };
                          return og;
                        })
                      );
                    }}
                  />
                  <Grid item xs={6}>
                    <Box px={1}>
                      <Duration
                        startTime={values.startDate}
                        endTime={values.endDate}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box px={1}>
                      <SingleSelectField
                        fullWidth
                        field="surveyType"
                        label="Activity"
                        placeholder="Select Survey Type"
                        data={surveyTypes.map(({id, description}) => {
                          return {id: id.toString(), description};
                        })}
                      />
                    </Box>
                  </Grid>

                  {Boolean(
                    st === SurveyType.SURVEY_TYPE_ADD_ON_SURVEY ||
                      st === SurveyType.SURVEY_TYPE_DRAFT_SURVEY ||
                      st === SurveyType.SURVEY_TYPE_INTERMEDIATE_SURVEY
                  ) && (
                    <FastField name="oreGroup">
                      {({field}: FastFieldProps) => {
                        return field.value.length > 0 ? (
                          field.value.map((oreType: OreGroup, idx: number) => {
                            return (
                              <React.Fragment
                                key={idx + '_' + oreType.oreType.toString()}
                              >
                                <Grid item xs={6}>
                                  <Box
                                    px={1}
                                    display="flex"
                                    alignItems="center"
                                    height="100%"
                                    justifyContent="space-between"
                                  >
                                    <Typography variant="subtitle1">
                                      Ore Type:
                                    </Typography>
                                    <Field
                                      name={`${field.name}[${idx}].oreType`}
                                    >
                                      {(fp: FieldProps) => {
                                        return (
                                          <Typography variant="subtitle1">
                                            {fp.field.value}
                                          </Typography>
                                        );
                                      }}
                                    </Field>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <Box px={1}>
                                    <Field
                                      fullWidth
                                      name={`${field.name}[${idx}].oreTons`}
                                      component={FieldTextField}
                                      onFocus={(
                                        event: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        event.currentTarget.setSelectionRange(
                                          0,
                                          event.currentTarget.size
                                        );
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <Box px={2}>
                            <Typography color="error">
                              No Ore has been loaded to survey
                            </Typography>
                          </Box>
                        );
                      }}
                    </FastField>
                  )}
                  <Grid item xs={12}>
                    <Box px={1}>
                      <Field
                        multiline
                        name="comments"
                        component={FieldTextField}
                        label="Comments"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      createText="create"
                      saveText="update"
                      isEditing={isEditing}
                      handleSave={() => {
                        submitForm();
                      }}
                      vessel={vessel}
                      activityId={params.activityId ?? ''}
                      title="Delete Survey Activity"
                      subtitle="Are you sure you want to delete this activity?"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </div>
        );
      }}
    </BaseForm>
  );
}
