import React from 'react';
import {SingleLoadingActivity as SLA, Shiploader} from '@deckmans/domain';
// import {useHistory} from 'react-router';
import {ActivityListItem} from './ActivityListItem';
import {useHistory} from 'react-router';
import {useDataContext} from 'providers/DataContextProvider';

export interface SingleLoadingActivityProps {
  singleLoadingActivity: SLA;
  vesselId: string;
  id?: string;
  startDate?: Date;
  shiploader: Shiploader;
  endDate?: Date;
}
export function SingleLoadingActivityComp({
  singleLoadingActivity,
  vesselId,
  startDate,
  endDate,
  shiploader,
  id,
}: SingleLoadingActivityProps) {
  const {reason} = singleLoadingActivity;
  const history = useHistory();
  const {printDecision} = useDataContext();

  const subtitle = printDecision(reason).join('/ ');

  return (
    <ActivityListItem
      color="success"
      title="Single Loading"
      subtitle={subtitle}
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        history.push(
          `/activity/single-loading/edit/${shiploader}/${vesselId}/${id}`
        );
      }}
    />
  );
}
