import React from 'react';
import {StopVesselActivity} from '@deckmans/domain';
import {useHistory} from 'react-router';
import {ActivityListItemWithBox} from './ActivityListItemWithBox';
import {useDataContext} from 'providers/DataContextProvider';
// import {useDataContext} from 'providers/DataContextProvider';

export interface StopVesselActivityProps {
  stopVesselActivity: StopVesselActivity;
  vesselId: string;
  id?: string;
  startDate?: Date;
  endDate?: Date;
}
export function StopVesselActivityComp({
  vesselId,
  startDate,
  endDate,
  id,
  stopVesselActivity: {reason},
}: StopVesselActivityProps) {
  const history = useHistory();
  const {printDecision} = useDataContext();

  const title = printDecision(reason);
  return (
    <ActivityListItemWithBox
      color="warning"
      title={title[0]}
      type="stopVessel"
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        history.push(`/activity/stop-vessel-activity/edit/${vesselId}/${id}`);
      }}
    />
  );
}
