import * as React from 'react';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  createStyles,
  Paper,
} from '@material-ui/core';
import {Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {Duration} from 'components/Duration';
import moment from 'moment';
import * as yup from 'yup';
import {useTitle} from 'contexts/TitleContext';
import {ButtonBar} from 'components/ButtonBar';
import {BaseForm} from 'components/BaseForm';
import {useParams, useHistory} from 'react-router';
import {useEngineContext} from 'contexts/EngineContext';
import {MultiLevelQuestions} from 'components/MultiLevelQuestions';
import {SingleSelectField} from 'components/SingleSelectField';
import {StartEndDateTime} from '@deckmans/web-shared';
import {DecisionType, DecisionAnswer, Shiploader} from '@deckmans/domain';
import {Client} from '@deckmans/domain';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {useVessel, useVesselActivity} from 'hooks/useVessel';
import {FullPageError} from 'components/FullPageError';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';
import {useDataContext} from 'providers/DataContextProvider';

const styles = makeStyles(() =>
  createStyles({
    centerDisplay: {
      display: 'grid',
      alignItems: 'center',
    },
  })
);

const defaultReason = DecisionAnswer.fromPartial({
  type: DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY,
});

interface ReclaimingActivityType {
  id: string;
  startTime: string;
  endTime: string;
  comments: string;
  reason: DecisionAnswer;
  client: string;
}

export function ReclaimingActivity() {
  useTitle('Reclaiming');
  const {
    master: {clientNoOreType},
  } = useDataContext();

  const history = useHistory();
  const classes = styles();
  const {engine, eventCreator} = useEngineContext();

  const {hatchId, activityId} = useParams<{
    hatchId: string;
    activityId: string;
  }>();

  const vessel = useVessel();
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    reason: yup.object<DecisionAnswer>({
      type: yup
        .mixed<DecisionType>()
        .required(
          'This should be passed into the component so should never fail'
        ),
      decisionKeys: yup
        .array<string>()
        .min(1)
        .required('This field is required'),
    }),
    client: yup.mixed<Client>().when('reason', {
      is: (reason: DecisionAnswer) => {
        return (
          reason.decisionKeys.length > 0 &&
          reason.decisionKeys[0] === 'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );
      },
      then: yup
        .mixed<Client>()
        .notOneOf(
          [Client.CLIENT_UNSPECIFIED, Client.UNRECOGNIZED, ''],
          'This field is required'
        )
        .required('This field is required'),
      otherwise: yup.string(),
    }),
  });

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const parentActivity = useVesselActivity(hatchId);

  const shiploader = parentActivity?.shiploader ?? Shiploader.UNRECOGNIZED;

  const activity = React.useMemo(() => {
    if (activityId) {
      return parentActivity?.vesselHatchActivityGroup?.activities[activityId];
    }
  }, [activityId, parentActivity]);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);
  const sd = pvEndDate !== '' ? pvEndDate : reclaimStart;
  const {startDate, endDate} = useHistoryStateDateRange(sd);
  const initialValues = React.useMemo(() => {
    if (isEditing && vessel && activity && activity.hatchReclaimingActivity) {
      return {
        startTime: moment(activity.startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(activity.endDate).format('YYYY-MM-DDTHH:mm'),
        comments: activity.hatchReclaimingActivity.comments,
        shiploader,
        reason: activity.hatchReclaimingActivity.reason ?? defaultReason,
        id: activity.id,
        client: activity.hatchReclaimingActivity.client,
      };
    } else {
      return {
        startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        comments: '',
        shiploader,
        reason: defaultReason,
        id: '',
        client: '',
      };
    }
  }, [activity, isEditing, shiploader, startDate, vessel, endDate]);
  if (!vessel) {
    return <FullPageError error={new Error('Vessel not found')} />;
  }

  const hatchFinished = !!vessel.activities[hatchId].vesselHatchActivityGroup
    ?.completed;

  return (
    <BaseForm<ReclaimingActivityType>
      isEditing={isEditing}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onCreateSubmit={async ({
        startTime,
        endTime,
        comments,
        reason,
        client,
      }) => {
        const loadClient = reason.decisionKeys.includes(
          'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );

        const event = eventCreator.hatchReclaimingActivityCreated({
          vesselId: vessel.id,
          comments,
          groupActivityId: hatchId,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          client: loadClient ? client : '',
        });
        await engine.process(event);
        history.goBack();
      }}
      onEditSubmit={async ({
        startTime,
        endTime,
        comments,
        reason,
        client,
        id,
      }) => {
        const loadClient = reason.decisionKeys.includes(
          'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );

        const event = eventCreator.hatchReclaimingActivityEdited({
          id,
          vesselId: vessel.id,
          comments,
          groupActivityId: hatchId,
          endDate: moment(endTime).toDate(),
          startDate: moment(startTime).toDate(),
          reason,
          client: loadClient ? client : '',
        });
        await engine.process(event);
        history.goBack();
      }}
    >
      {({values: {startTime, endTime, reason}, submitForm}) => {
        const showClient = reason.decisionKeys.includes(
          'OFFLOADING_OF_TRAINS_3RD_PARTY'
        );

        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime />
                  <Grid item xs={6} className={classes.centerDisplay}>
                    <Box px={1}>
                      <Duration startTime={startTime} endTime={endTime} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MultiLevelQuestions
                      name="reason"
                      decisionType={DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY}
                    />
                  </Grid>

                  {showClient && (
                    <Grid item xs={12}>
                      <Box px={1}>
                        <SingleSelectField
                          fullWidth
                          field="client"
                          label="Client"
                          placeholder="Client"
                          addNone
                          data={clientNoOreType}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box px={1}>
                      <Field
                        fullWidth
                        multiline
                        component={TextField}
                        name="comments"
                        label="Comments"
                        placeholder="Comments"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      createText="save"
                      saveText="update"
                      isEditing={isEditing}
                      handleSave={submitForm}
                      vessel={vessel}
                      activityId={activityId}
                      title="Delete Reclaiming Activity"
                      subtitle="Are you sure you want to delete this reclaiming activity?"
                      hatchFinished={hatchFinished}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </BaseForm>
  );
}
