"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const styles_1 = require("@material-ui/core/styles");
const hexToRgb_1 = require("./hexToRgb");
const baseTheme = styles_1.createMuiTheme({
    hexToRgba: hexToRgb_1.hexToRgba,
    hexToRgb: styles_1.hexToRgb,
    props: {
        MuiButton: { variant: 'contained', color: 'primary', size: 'large' },
        MuiTextField: {
            variant: 'outlined',
            InputLabelProps: {
                shrink: true,
            },
        },
    },
    palette: {
        background: { paper: '#fff' },
        primary: { main: '#002478' },
        secondary: { main: '#C60C30' },
        action: { selected: 'rgba(0, 0, 0, 0.08)' },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
    },
    overrides: {
        MuiMenu: {
            paper: {
                backgroundColor: '#fff',
                color: '#002478',
                borderRadius: '10px',
            },
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: '#002478',
                    color: '#fff',
                    '& .MuiListItemText-primary': {
                        color: '#002478',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderWidth: 1,
                },
            },
        },
        MuiFab: {
            root: {
                boxShadow: '0',
            },
        },
        MuiAppBar: {
            root: {
                borderRadius: '4px',
            },
        },
        MuiPaper: {
            elevation4: {
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.13);',
            },
            elevation8: {
                boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            },
        },
    },
    typography: {
        fontFamily: 'din-next-medium',
        h1: { letterSpacing: '-1.5px', fontSize: '96px' },
        h2: { letterSpacing: '-0.5px', fontSize: '60px' },
        h3: { fontSize: '48px' },
        h4: { letterSpacing: '0.25px', fontSize: '34px' },
        h5: { fontSize: '24px' },
        h6: { letterSpacing: '0.15px', fontSize: '20px' },
        subtitle1: { letterSpacing: '0.5px', fontSize: '18px' },
        subtitle2: { letterSpacing: '0.25px', fontSize: '14px' },
        body1: {
            fontFamily: 'Roboto',
            letterSpacing: '0.15px',
            fontSize: '16px',
        },
        body2: { fontFamily: 'Roboto', lineHeight: '20px', fontSize: '14px' },
        button: {
            fontFamily: 'Roboto',
            letterSpacing: '0.75px',
            fontSize: '14px',
        },
        caption: {
            fontFamily: 'Roboto',
            letterSpacing: '0.4px',
            fontSize: '12px',
        },
        overline: {
            fontFamily: 'Roboto',
            letterSpacing: '0.75px',
            fontSize: '10px',
        },
    },
});
exports.theme = styles_1.responsiveFontSizes(baseTheme, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    factor: 6,
    disableAlign: true,
});
