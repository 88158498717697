import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router';
import DeleteDialog from './DeleteDialog';
import {UserRole} from '@deckmans/domain';
import {useClearSiteData} from 'hooks/useClearSiteData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: 0,
      left: 0,
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  error: Error;
  routeTo?: string;
}

export function FullPageError({error, routeTo = '/'}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const {clear} = useClearSiteData();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={classes.paper}>
          <Typography variant="h2" id="simple-modal-title">
            Something went wrong!
          </Typography>
          <Typography id="simple-modal-description">
            The following error has been detected:
          </Typography>
          <Typography component="pre" color="error">
            {error.message}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            onClick={() => {}}
            className={classes.button}
            size="small"
            endIcon={
              <DeleteDialog
                title="Clear all site Data"
                subtitle="Are you sure you want to clear all site data, all un-synched data will be lost"
                handleAgree={clear}
                roleAllows={[
                  UserRole.USER_ROLE_USER,
                  UserRole.USER_ROLE_SUPERVISOR,
                  UserRole.USER_ROLE_ADMIN,
                ]}
              />
            }
          >
            Clear Site Data
          </Button>
          <br />
          <Button
            className={classes.button}
            size="small"
            variant="outlined"
            onClick={() => {
              if (history.location.pathname === routeTo) {
                setOpen(false);
              } else {
                history.push(routeTo);
              }
            }}
          >
            Take me home
          </Button>
        </Paper>
      </Modal>
    </div>
  );
}
