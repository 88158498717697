import React from 'react';
import {ActivityListItem} from './ActivityListItem';
import {Shiploader, VesselActivity} from '@deckmans/domain';
import moment from 'moment';
import {Box, Typography, makeStyles, Theme} from '@material-ui/core';
import {
  convertToExtendedVesselHatchActivity,
  ExtendedVesselHatchActivity,
  sortVesselHatchActivities,
} from '@deckmans/domain/lib/util/sortVesselHatchActivities';
import {useDataContext} from 'providers/DataContextProvider';
import {useHistory} from 'react-router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '2px solid ' + theme.palette.error.main,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    marginBottom: theme.spacing(1.5),
  },
}));

interface Props {
  vesselActivity: VesselActivity;
  vesselId: string;
}

export function HatchActivityList({vesselActivity, vesselId}: Props) {
  const {shiploader, vesselHatchActivityGroup} = vesselActivity;
  if (!vesselHatchActivityGroup) {
    return <></>;
  }
  const vesselActivities = sortVesselHatchActivities(vesselHatchActivityGroup);

  const activities = convertToExtendedVesselHatchActivity(vesselActivities);

  if (activities.length > 0) {
    const activityList: JSX.Element[] = [];
    for (let i = 0; i < activities.length; i++) {
      const vesselHatchActivity = activities[i];

      activityList.push(
        <ActivityItem
          key={'activity_list_item_' + i}
          vesselHatchActivity={vesselHatchActivity}
          shiploader={shiploader}
          vesselId={vesselId}
          hatchGroupId={vesselHatchActivityGroup.id}
        />
      );
    }
    return <>{activityList}</>;
  } else {
    return <ActivityListItem title="Hatch Empty" subtitle="" color="error" />;
  }
}

interface ActivityItemProps {
  vesselId: string;
  shiploader: Shiploader;
  hatchGroupId: string;
  vesselHatchActivity: ExtendedVesselHatchActivity;
}
function ActivityItem({
  vesselId,
  shiploader,
  hatchGroupId,
  vesselHatchActivity: {
    id,
    startDate,
    endDate,
    hatchBreakdownActivity,
    hatchDirectLoadingActivity,
    hatchLoadingActivity,
    hatchReclaimingActivity,
    hatchAwaitingShiploaderActivity,
    unAllocatedTime,
  },
}: ActivityItemProps) {
  const classes = useStyles();
  const {printDecision} = useDataContext();

  if (hatchDirectLoadingActivity) {
    const dla = hatchDirectLoadingActivity;
    return (
      <ActivityListItem
        color="info"
        endTime={endDate}
        startTime={startDate}
        subtitle={`${dla.train}` ?? 'No train specified'}
        title="Direct Loading"
      />
    );
  }
  if (hatchLoadingActivity) {
    const la = hatchLoadingActivity;
    return (
      <ActivityListItem
        color="info"
        endTime={endDate}
        startTime={startDate}
        subtitle={la.stockpile ?? 'No Stockpile identified'}
        title="Loading"
      />
    );
  }
  if (hatchReclaimingActivity) {
    return (
      <ActivityListItem
        color="info"
        endTime={endDate}
        startTime={startDate}
        subtitle={printDecision(hatchReclaimingActivity.reason).join('/ ')}
        title="Reclaim"
      />
    );
  }

  if (hatchBreakdownActivity) {
    const ba = hatchBreakdownActivity;
    return (
      <ActivityListItem
        color="info"
        endTime={endDate}
        startTime={startDate}
        subtitle={
          ba.equipmentName !== ''
            ? ba.equipmentName
            : printDecision(ba.reason).join('/ ')
        }
        title="Breakdown"
      />
    );
  }
  if (hatchAwaitingShiploaderActivity) {
    return (
      <ActivityListItem
        color="info"
        endTime={endDate}
        startTime={startDate}
        subtitle={printDecision(hatchAwaitingShiploaderActivity.reason).join(
          '/ '
        )}
        title="Awaiting shiploader"
      />
    );
  }

  if (unAllocatedTime) {
    const isUnallocated = moment(startDate).isBefore(moment(endDate));

    return isUnallocated ? (
      <UnAllocatedTimeMenu
        shiploader={shiploader}
        hatchGroupId={hatchGroupId}
        vesselId={vesselId}
        startDate={startDate}
        endDate={endDate}
        activityId={id}
      />
    ) : (
      <Box display="flex" className={classes.container}>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="center"
        >
          <Box display="flex">
            <Typography variant="h5">TIME OVERLAP </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return <>No Hatch Activity matches this type</>;
}

interface UnallocatedTimeMenuProps {
  startDate?: Date;
  endDate?: Date;
  vesselId: string;
  shiploader: Shiploader;
  hatchGroupId: string;
  activityId: string;
}

function UnAllocatedTimeMenu({
  startDate,
  endDate,
  shiploader,
  vesselId,
  hatchGroupId,
}: UnallocatedTimeMenuProps) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ActivityListItem
        color="error"
        startTime={startDate}
        endTime={endDate}
        subtitle=""
        title="Unallocated Time"
        editItem={handleClick}
      />
      <Menu
        id="create-hatch-activity-menu"
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(
              `/activity/hatchLoadingActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            )
          }
        >
          <Typography variant="button">Loading</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              `/activity/hatchDirectLoadingActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            );
          }}
        >
          <Typography variant="button">Direct Loading</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              `/activity/hatchReclaimingActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            );
          }}
        >
          <Typography variant="button">Reclaiming</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(
              `/activity/hatchBreakdownActivity/new/${vesselId}/${shiploader}/${hatchGroupId}`,
              {startDate, endDate}
            );
          }}
        >
          <Typography variant="button">Breakdown</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
