import * as React from 'react';
import moment from 'moment';

interface SearchContextState {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  startDate: Date;
  setStartDate: (startDate: Date) => void;
  endDate: Date;
  setEndDate: (startDate: Date) => void;
}

const searchStartDate = moment(new Date()).subtract(7, 'day').toDate();

export const SearchContext = React.createContext<SearchContextState>({
  searchTerm: '',
  setSearchTerm: () => {},
  startDate: searchStartDate,
  setStartDate: () => {},
  endDate: moment().add(2, 'weeks').toDate(),
  setEndDate: () => {},
});

export const useSearchContext = () =>
  React.useContext<SearchContextState>(SearchContext);

interface Props {
  children: React.ReactNode;
}

export function SearchContextController({children}: Props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date>(searchStartDate);
  const [endDate, setEndDate] = React.useState<Date>(
    moment().add(2, 'weeks').toDate()
  );

  const value = React.useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }),
    [searchTerm, setSearchTerm, startDate, setStartDate, endDate, setEndDate]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
