import * as React from 'react';
import {Box, Divider, Grid, Paper} from '@material-ui/core';
import {Duration} from 'components/Duration';
import moment from 'moment';
import {useTitle} from 'contexts/TitleContext';
import {ToggleButtonField} from 'components/ToggleButtonField';
import {ButtonBar} from 'components/ButtonBar';
import {BaseForm} from 'components/BaseForm';
import {useParams, useHistory} from 'react-router';
import {useEngineContext} from 'contexts/EngineContext';
import * as yup from 'yup';
import {StartEndDateTime} from '@deckmans/web-shared';
import {FullPageError} from 'components/FullPageError';
import {Field} from 'formik';
import TextField from '@material-ui/core/TextField';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import {useDataContext} from 'providers/DataContextProvider';
import {useHistoryStateDateRange} from 'hooks/useHistoryStateDateRange';
import {useVessel, useVesselActivity} from 'hooks/useVessel';
import {Shiploader} from '@deckmans/domain';
import {getLastVesselActivityEndDate} from '@deckmans/domain/lib/util/sortVesselActivities';

interface LoadingActivityType {
  id: string;
  startTime: string;
  endTime: string;
  comments: string;
  route: string;
  stockpile: string;
  selectedStockpile: string;
}

export function LoadingActivity() {
  useTitle('Loading');
  const {
    master: {routes, stockpiles},
  } = useDataContext();
  const history = useHistory();

  const {hatchId, activityId} = useParams<{
    hatchId: string;
    activityId: string;
  }>();
  const {engine, eventCreator} = useEngineContext();
  const vessel = useVessel();
  const reclaimStart = vessel.reclaimStart ?? new Date();
  const validationsSchema = yup.object().shape({
    startTime: yup
      .date()
      .required('This field is required')
      .min(
        reclaimStart,
        ` Start time cannot be before vessel start time (${reclaimStart.toDateString()} ${reclaimStart.toLocaleTimeString()})`
      ),
    endTime: yup
      .string()
      .required('This field is required')
      .test(
        'is-greater',
        'End time should be greater than start time',
        function (value) {
          const {startTime} = this.parent;
          return moment(value).isAfter(moment(startTime));
        }
      ),
    shiploader: yup.string(),
    stockpile: yup.string().required('This field is required'),
    comments: yup.string(),
    route: yup.string().required('Select a route'),
  });

  const isEditing = React.useMemo(() => {
    return Boolean(activityId);
  }, [activityId]);

  const parentActivity = useVesselActivity(hatchId);

  const shiploader = parentActivity?.shiploader ?? Shiploader.UNRECOGNIZED;

  const activity = React.useMemo(() => {
    if (activityId) {
      return parentActivity?.vesselHatchActivityGroup?.activities[activityId];
    }
  }, [activityId, parentActivity]);

  const pvEndDate = getLastVesselActivityEndDate(vessel.activities, shiploader);

  const sd = pvEndDate !== '' ? pvEndDate : reclaimStart;
  const {startDate, endDate} = useHistoryStateDateRange(sd);

  if (!vessel) {
    return <FullPageError error={new Error('No Vessel could be found')} />;
  }
  if (isEditing && !activity) {
    return (
      <FullPageError
        error={new Error('No activities could be found on the vessel')}
      />
    );
  }

  const hatchFinished = !!vessel.activities[hatchId].vesselHatchActivityGroup
    ?.completed;

  return (
    <BaseForm<LoadingActivityType>
      isEditing={isEditing}
      validationSchema={validationsSchema}
      initialValues={
        isEditing
          ? {
              id: activity?.id ?? '',
              startTime: moment(activity?.startDate).format('YYYY-MM-DDTHH:mm'),
              endTime: moment(activity?.endDate).format('YYYY-MM-DDTHH:mm'),
              stockpile: activity?.hatchLoadingActivity?.stockpile ?? '',
              comments: '',
              route: activity?.hatchLoadingActivity?.route ?? '',
              selectedStockpile:
                activity?.hatchLoadingActivity?.stockpile ?? '',
            }
          : {
              id: '',
              startTime: moment(startDate).format('YYYY-MM-DDTHH:mm'),
              endTime: moment(endDate).format('YYYY-MM-DDTHH:mm'),
              stockpile: '',
              comments: '',
              route: '',
              selectedStockpile: '',
            }
      }
      onCreateSubmit={async ({
        endTime,
        startTime,
        comments,
        route,
        stockpile,
      }) => {
        const event = eventCreator.hatchLoadingActivityCreated({
          comments,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          groupActivityId: hatchId,
          vesselId: vessel.id,
          route,
          stockpile,
        });

        await engine.process(event);
        //history.replace(`/hatch/view/${vesselId}/${hatchId}`);
        history.goBack();
      }}
      onEditSubmit={async ({
        id,
        endTime,
        startTime,
        comments,
        route,
        stockpile,
      }) => {
        const event = eventCreator.hatchLoadingActivityEdited({
          id,
          comments,
          startDate: moment(startTime).toDate(),
          endDate: moment(endTime).toDate(),
          groupActivityId: hatchId,
          vesselId: vessel.id,
          route,
          stockpile,
        });

        await engine.process(event);
        history.goBack();
      }}
    >
      {({values, submitForm, errors, touched}) => {
        return (
          <Box>
            <Paper elevation={8}>
              <Box py={4} px={2} mt={3}>
                <Grid container spacing={3}>
                  <StartEndDateTime />

                  <Grid item xs={6}>
                    <Box px={1}>
                      <ToggleButtonField
                        label="Route"
                        field="route"
                        data={routes.map((route) => ({
                          id: route,
                          description: route,
                        }))}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box px={1}>
                      <Duration
                        startTime={values.startTime}
                        endTime={values.endTime}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box px={1}>
                      <Field
                        component={Autocomplete}
                        options={stockpiles}
                        defaultValue={
                          isEditing ? values.selectedStockpile : undefined
                        }
                        renderOption={(option: string) => option}
                        getOptionLabel={(option: string) => option}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => {
                          return (
                            <TextField
                              placeholder="Stockpile"
                              label="Stockpile"
                              {...params}
                              error={
                                touched['stockpile'] && !!errors['stockpile']
                              }
                              helperText={
                                touched['stockpile'] && !!errors['stockpile']
                                  ? errors['stockpile']
                                  : ''
                              }
                            />
                          );
                        }}
                        name="stockpile"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonBar
                      createText="save"
                      saveText="update"
                      isEditing={isEditing}
                      handleSave={submitForm}
                      vessel={vessel}
                      activityId={activityId}
                      title="Delete Loading Activity"
                      subtitle="Are you sure you want to delete this loading activity?"
                      hatchFinished={hatchFinished}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      }}
    </BaseForm>
  );
}
