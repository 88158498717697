import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {Hatch1SVG} from './Hatch1SVG';
import {Hatch2SVG} from './Hatch2SVG';
import {Hatch3SVG} from './Hatch3SVG';
import {Hatch4SVG} from './Hatch4SVG';
import {Hatch5SVG} from './Hatch5SVG';
import {Hatch6SVG} from './Hatch6SVG';
import {Hatch7SVG} from './Hatch7SVG';
import {Hatch8SVG} from './Hatch8SVG';
import {Hatch9SVG} from './Hatch9SVG';
import {Hatch10SVG} from './Hatch10SVG';

const useStyles = makeStyles((theme: Theme) => ({
  hb: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1, 0, 0, 1),
    padding: 2,
    color: '#fff',
    flexDirection: 'column-reverse',
    width: '100%',
    justifyContent: 'space-between',
  },
  hbIcon: {
    transform: 'rotate(270deg)',
    // this messes with alignment
    // paddingTop: theme.spacing(1),
  },
  svg: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));
interface Props {
  onClick?: () => void;
  hatch: number;
}
function HatchText({hatchNumber}: {hatchNumber: number}) {
  const classes = useStyles();
  switch (hatchNumber) {
    case 1:
      return <Hatch1SVG className={classes.svg} />;
    case 2:
      return <Hatch2SVG className={classes.svg} />;
    case 3:
      return <Hatch3SVG className={classes.svg} />;
    case 4:
      return <Hatch4SVG className={classes.svg} />;
    case 5:
      return <Hatch5SVG className={classes.svg} />;
    case 6:
      return <Hatch6SVG className={classes.svg} />;
    case 7:
      return <Hatch7SVG className={classes.svg} />;
    case 8:
      return <Hatch8SVG className={classes.svg} />;
    case 9:
      return <Hatch9SVG className={classes.svg} />;
    case 10:
      return <Hatch10SVG className={classes.svg} />;
    default:
      return <>No Hatch SVG found</>;
  }
}
export function HatchButton({onClick, hatch}: Props) {
  const classes = useStyles();
  return (
    <ButtonBase className={classes.hb} onClick={onClick}>
      <HatchText hatchNumber={hatch} />
      <ChevronRight className={classes.hbIcon} />
    </ButtonBase>
  );
}
