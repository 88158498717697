import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {DebugBar} from 'modules/DebugBar/DebugBar';
import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
interface Props {
  text?: string;
}
export function PageLoading({text}: Props) {
  const classes = useStyles();
  return (
    <>
      <Backdrop open={true} className={classes.backdrop}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5" color="primary">
            {text}
          </Typography>
          <CircularProgress size={50} />
        </Box>
        <DebugBar />
      </Backdrop>
    </>
  );
}
