import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  ButtonBase,
  makeStyles,
  Fab,
  Avatar,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {useTitleContext} from 'contexts/TitleContext';
import logo from '../../images/anglo-logo@1x.png';
import {useHistory} from 'react-router';
import {useAuthContext} from 'contexts/AuthContext';
import {HeaderConnected} from './components/HeaderConnected';
import {HeaderSync} from './components/HeaderSync';
import {HeaderUpdate} from './components/HeaderUpdate';
import {useEngineContext} from 'contexts/EngineContext';
import {Switch, Route} from 'react-router';
import {ServiceWorkerContext} from 'contexts/ServiceWorkerContext';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {ConfigService} from '@stackworx/react';
import {UserRole} from '@deckmans/domain';
import {getUserRole} from '@deckmans/domain/lib/util/enumData';
import {useAlertContext} from 'contexts/AlertContex';
import {useIndexedDbContext} from 'contexts/IndexedDbContext';
import {useDataContext} from 'providers/DataContextProvider';
import UpdateIcon from '@material-ui/icons/Update';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {useForceUpdate} from 'hooks/useForceUpdate';
import {useClearSiteData} from 'hooks/useClearSiteData';
import {useSearchContext} from 'contexts/SearchContext';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#f4f6f8',
    margin: '0px 20px 0px 20px',
    maxWidth: 850,
    width: 'calc(100% - 50px)',
    [theme.breakpoints.up('md')]: {
      //margin: '20px auto',
      margin: '0px auto',
    },
  },
  toolBar: {
    borderRadius: '4px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerBox: {
    top: 0,
    position: 'sticky',
    zIndex: 1,
    backgroundColor: '#f4f6f8',
    padding: '20px 0px 20px 0px',
    [theme.breakpoints.up('md')]: {
      padding: '20px auto',
    },
  },
  version: {
    color: '#808080',
  },
}));

function Header() {
  const classes = useStyles();
  const {title} = useTitleContext();
  const {clear} = useClearSiteData();
  const {forceUpdate} = useForceUpdate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openHardReset, setOpenHardReset] = React.useState(false);
  const [openForceUpdate, setOpenForceUpdate] = React.useState(false);
  const history = useHistory();
  const {auth, handleLogout, hasRole} = useAuthContext();
  const {sync, getState} = useEngineContext();
  const {endDate, startDate} = useSearchContext();

  const handleUpdate = React.useContext(ServiceWorkerContext);
  const {alert} = useAlertContext();
  const db = useIndexedDbContext();
  const {
    seedChanged,
    handleMasterClear,
    fetchAndUpdateMasterData,
  } = useDataContext();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const setVesselState = React.useCallback(() => {
    const fetch = async () => {
      await getState(endDate ?? new Date(), startDate ?? new Date());
    };
    fetch();
  }, [getState, endDate, startDate]);
  return (
    <Box className={classes.headerBox}>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Box display="flex" alignItems="center">
            <Box>
              <Switch>
                <Route
                  exact
                  path={['/', '/logbook']}
                  component={() => (
                    <Avatar
                      variant="square"
                      src={logo}
                      imgProps={{style: {objectFit: 'contain'}}}
                    />
                  )}
                />
                <Route
                  exact
                  path="/*"
                  component={() => (
                    <Fab
                      size="small"
                      color="primary"
                      onClick={() => {
                        if (history.location.pathname === '/vessel/new') {
                          setVesselState();
                          setTimeout(() => {
                            history.goBack();
                          }, 1 * 500);
                        } else {
                          history.goBack();
                        }
                      }}
                    >
                      <ArrowBackRoundedIcon />
                    </Fab>
                  )}
                />
              </Switch>
            </Box>
            <Box p={1}>
              <Typography color="primary" variant="h5">
                {title}
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box pr={1.5}>
              <HeaderConnected />
            </Box>
            <Box pr={1.5}>
              <HeaderSync />
            </Box>
            {handleUpdate && (
              <Box pr={1.5}>
                <HeaderUpdate handleUpdate={handleUpdate} />
              </Box>
            )}
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              pr={1.5}
            >
              <Typography variant="body2" color="textPrimary">
                {auth.authenticated ? auth.username : 'Display name'}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {auth.authenticated
                  ? auth.roles.map((role) => {
                      return getUserRole(role).description;
                    })
                  : 'Role'}
              </Typography>
            </Box>
            <Fab
              size="small"
              color="primary"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            >
              <MenuRoundedIcon />
            </Fab>
          </Box>
        </Toolbar>
        <Drawer
          open={openMenu}
          onClose={() => {
            setOpenMenu(!openMenu);
          }}
          anchor="right"
        >
          <Box display="flex" flexDirection="column" width="250px">
            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  const synced = await sync();
                  if (!synced) {
                    alert(
                      'Sync failed, please make sure you have an active internet connection before syncing',
                      'warning'
                    );
                  } else {
                    alert('Sync Successful', 'success');
                  }

                  setOpenMenu(false);
                }}
              >
                <SyncRoundedIcon style={{paddingRight: '36px'}} />
                <Typography variant="body1">Sync</Typography>
              </ButtonBase>
            </Box>

            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  history.push('/search');
                }}
              >
                <SearchRoundedIcon style={{paddingRight: '36px'}} />
                <Typography variant="body1">Search</Typography>
              </ButtonBase>
            </Box>

            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  const pendingCount = await db.getPendingEventsCount();
                  if (pendingCount > 0) {
                    alert(
                      'You cant log out until you synced your data',
                      'error'
                    );
                  } else {
                    handleLogout();
                  }
                }}
              >
                <ExitToAppRoundedIcon style={{paddingRight: '36px'}} />
                <Typography variant="body1">Logout</Typography>
              </ButtonBase>
            </Box>
            {hasRole(UserRole.USER_ROLE_ADMIN) && (
              <Box p={2}>
                <ButtonBase href={ConfigService.serverUri + '/export'}>
                  <SystemUpdateAltIcon style={{paddingRight: '36px'}} />
                  <Typography variant="body1">Export Projections</Typography>
                </ButtonBase>
              </Box>
            )}
            {seedChanged && (
              <Box p={2}>
                <ButtonBase
                  onClick={async () => {
                    const synced = await sync();
                    if (synced) {
                      handleMasterClear();
                    } else {
                      alert('Could not sync and update', 'warning');
                    }
                  }}
                >
                  <UpdateIcon style={{paddingRight: '36px'}} />
                  <Typography variant="body1">
                    Sync + Update Master Data
                  </Typography>
                </ButtonBase>
              </Box>
            )}
            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  const synced = await sync();
                  //sync not required, but good to sync when you are online
                  if (synced) {
                    fetchAndUpdateMasterData();
                  } else {
                    alert('Something went wrong please try again', 'warning');
                  }
                }}
              >
                <UpdateIcon style={{paddingRight: '36px'}} />
                <Typography variant="body1">Update Master Data</Typography>
              </ButtonBase>
            </Box>
          </Box>
          <Box
            height="100%"
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button variant="text" onClick={handleMenuClick}>
              <Typography className={classes.version} variant="body1">
                {process.env.REACT_APP_CI_VERSION}
              </Typography>
            </Button>
            <Menu
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{horizontal: 'center', vertical: 'center'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              getContentAnchorEl={null}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  setOpenForceUpdate(true);
                }}
              >
                <Typography color="inherit" variant="button">
                  Force update
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  setOpenHardReset(true);
                }}
              >
                <Typography color="inherit" variant="button">
                  Hard reset
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <ConfirmationDialog
            title="Force update"
            subtitle="Are you sure you want to force update?"
            open={openForceUpdate}
            setOpen={() => {
              setOpenForceUpdate(false);
            }}
            handleAgree={forceUpdate}
          />
          <ConfirmationDialog
            title="Hard reset"
            subtitle="Unsaved data will be lost and you will be logged out. Are you sure?"
            open={openHardReset}
            setOpen={() => {
              setOpenHardReset(false);
            }}
            handleAgree={clear}
          />
        </Drawer>
      </AppBar>
    </Box>
  );
}

export function HeaderRouter() {
  return (
    <Switch>
      <Route exact path="/login" />
      <Route exact path="/createPassword" />
      <Route exact path="/resetPassword" />
      <Route exact path="/tokenExpired" />
      <Route>
        <Header />
      </Route>
    </Switch>
  );
}
