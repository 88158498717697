import {
  LoginServiceClientImpl,
  EventServiceClientImpl,
  MasterServiceClientImpl,
} from '@deckmans/domain';
import {requestFactory, RequestOptions} from '@deckmans/web-shared';
import {notify} from 'appInsights';

export function createLoginService() {
  return new LoginServiceClientImpl({
    request: requestFactory(async () => '', notify),
  });
}
export function createMasterDataService(opts: RequestOptions) {
  return new MasterServiceClientImpl({
    request: requestFactory(async () => '', notify, opts),
  });
}

export function createEventService(getToken: () => Promise<string>) {
  return new EventServiceClientImpl({
    request: requestFactory(getToken, notify),
  });
}

export function createInitialEventService(
  opts: RequestOptions,
  getToken: () => Promise<string>
) {
  return new EventServiceClientImpl({
    request: requestFactory(getToken, notify, opts),
  });
}
