import * as React from 'react';
import Dexie from 'dexie';
import * as domain from '@deckmans/domain';
import {useAlertContext} from './AlertContex';
import {FullPageError} from '@deckmans/web-shared';
import {notify} from 'appInsights';

interface Props {
  children: React.ReactNode;
}
interface MasterTable {
  data: Uint8Array;
}
interface StateTable {
  eventId: string;
  data: Uint8Array;
}
export class MyDatabase extends Dexie {
  private pendingEvents: Dexie.Table<domain.Event, string>;
  private master: Dexie.Table<MasterTable, string>;
  private state: Dexie.Table<StateTable, string>;

  constructor(databaseName: string) {
    super(databaseName);
    this.version(1).stores({
      pendingEvents: `++sequence, eventId, type, aggregateId`,
      master: `++id`,
      state: `++id, eventId`,
    });
    this.pendingEvents = this.table('pendingEvents');
    this.master = this.table('master');
    this.state = this.table('state');
  }

  public async reset() {
    return this.delete();
  }

  public async persistEvent(event: domain.Event): Promise<void> {
    await this.pendingEvents.add(event, event.eventId);
  }

  public async getPendingEvents(limit = 50): Promise<domain.Event[]> {
    // TODO: confirm this sorts in the correct direction
    return this.pendingEvents.orderBy('sequence').limit(limit).toArray();
  }

  public async getPendingEventsCount(): Promise<number> {
    return this.pendingEvents.count();
  }

  public async eventsUploaded(eventIds: string[]) {
    return this.transaction('rw', this.pendingEvents, async () => {
      await this.pendingEvents
        .where('eventId')
        .anyOf(...eventIds)
        .delete();
    });
  }
  public async getMasterData() {
    const master = await this.master.toArray();
    return master.length > 0 ? domain.Master.decode(master[0].data) : null;
  }
  public async saveMasterData(master: domain.Master) {
    return this.transaction('rw', this.master, async () => {
      await this.master.clear();
      await this.master.add({data: domain.Master.encode(master).finish()});
    });
  }

  public async getSavedState() {
    const state = await this.state.toArray();
    return state.length > 0 ? domain.State.decode(state[0].data) : null;
  }
  public async saveState(state: domain.State) {
    //TODO dont update if event id didnt change
    return this.transaction('rw', this.state, async () => {
      await this.state.clear();
      await this.state.add({
        eventId: state.eventId,
        data: domain.State.encode(state).finish(),
      });
    });
  }
}

const IndexedDbContext = React.createContext<MyDatabase>(
  new MyDatabase('test')
);

export const useIndexedDbContext = () => React.useContext(IndexedDbContext);

export function IndexedDbProvider({children}: Props) {
  const {alert} = useAlertContext();
  const db = React.useMemo(() => new MyDatabase('deckmans'), []);
  const [error, setError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    async function open() {
      try {
        await db.open();
      } catch (ex) {
        alert('Attempting to resolve issue', 'info');
        //TODO
        alert(
          'If this error persists Please reset the db and try again',
          'info'
        );
        notify(ex);
        setError(ex);
      }
    }

    open();

    return () => {
      if (db.isOpen()) {
        db.close();
      }
    };
  }, [db, alert]);

  if (db.hasFailed()) {
    return <FullPageError error={new Error('DB has failed')} />;
  }
  if (error) {
    return <FullPageError error={error} />;
  }
  return (
    <IndexedDbContext.Provider value={db}>{children}</IndexedDbContext.Provider>
  );
}
