import * as React from 'react';
import {getShiploader} from '@deckmans/domain/lib/util/enumData';

export function useShiploader(shiploaderId: string) {
  const shiploader = React.useMemo(() => {
    return getShiploader(parseInt(shiploaderId, 10));
  }, [shiploaderId]);

  return shiploader;
}
