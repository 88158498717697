import * as React from 'react';
import {useHistory} from 'react-router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import BG from 'images/BG.jpg';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {AngloNamedLogo} from 'images/anglo-named-logo';

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${BG})`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  paper: {
    width: 330,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function Page404() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <Box
        position="absolute"
        top="24px"
        left="24px"
        display="flex"
        flexDirection="column"
        style={{color: 'white'}}
      >
        <Typography variant="h1">Deckmans</Typography>
        <Typography variant="h3">Logbook</Typography>
      </Box>

      <Paper className={classes.paper}>
        <Box m={2} mb={3} display="flex" justifyContent="center">
          <AngloNamedLogo />
        </Box>
        <Typography color="primary" variant="h1">
          404
        </Typography>
        <Typography color="primary" variant="h6">
          This page seems to be lost at see
        </Typography>
        <Box m={2} mb={3}>
          <Button
            onClick={() => history.push('/logbook')}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            Take me home
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
