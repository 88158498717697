import * as React from 'react';
import {useNavigatorOnline} from 'hooks/useNavigatorOnline';
import {useEngineContext} from './EngineContext';
import {useIndexedDbContext} from './IndexedDbContext';
import moment from 'moment';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import {useAlertContext} from './AlertContex';

export enum SyncStatus {
  OFFLINE,
  PENDING,
  SUCCESS,
  IN_PROGRESS,
}

interface SyncContextState {
  status: SyncStatus;
  pendingEvents: number;
}

export const SyncContext = React.createContext<SyncContextState>({
  status: SyncStatus.PENDING,
  pendingEvents: 0,
});

export const useSyncContext = () =>
  React.useContext<SyncContextState>(SyncContext);

interface Props {
  children: React.ReactNode;
}
export function SyncContextController({children}: Props) {
  const {sync, synching} = useEngineContext();

  const [pendingEvents, setPendingEvents] = React.useState<SyncStatus>(
    SyncStatus.OFFLINE
  );

  const online = useNavigatorOnline();

  const db = useIndexedDbContext();

  const {alert} = useAlertContext();

  const [lastSyncedDate] = useLocalStorage<Date>('lastSyncDate');

  React.useEffect(() => {
    async function checkEvents() {
      setPendingEvents(await db.getPendingEventsCount());
    }

    setInterval(() => {
      checkEvents();
    }, 10 * 1000);
  });

  // TODO: only allow sync on specific screens

  // Sync every 60 seconds
  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      if (online) {
        await sync();
      }
    }, 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [online, pendingEvents, sync]);

  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      //check days since last sync, if more than 2 alert
      if (
        moment().isAfter(moment(lastSyncedDate).add(2, 'days')) &&
        (await db.getPendingEventsCount()) > 0
      ) {
        alert('You need to sync your data', 'warning');
      }
    }, 60 * 1000 * 5);
    return () => {
      clearInterval(intervalId);
    };
  }, [online, pendingEvents, sync, lastSyncedDate, alert, db]);

  const value = React.useMemo(() => {
    return {
      status: synching
        ? SyncStatus.IN_PROGRESS
        : pendingEvents > 0
        ? SyncStatus.PENDING
        : SyncStatus.SUCCESS,
      pendingEvents,
    };
  }, [pendingEvents, synching]);

  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>;
}
