import React from 'react';
import {useHistory} from 'react-router';
import moment from 'moment';
export function useHistoryStateDateRange(defaultStartDate: Date | string) {
  let startDate = moment(defaultStartDate).toDate();
  let endDate = moment(startDate).isBefore(moment())
    ? moment().toDate()
    : moment(startDate).add(1, 'minute').toDate();
  const history = useHistory<{startDate?: Date; endDate?: Date}>();
  const historyState = history.location.state;
  if (historyState) {
    if (historyState.startDate) {
      startDate = historyState.startDate;
    }
    if (historyState.endDate) {
      endDate = historyState.endDate;
    }
  }
  return React.useMemo(() => ({startDate, endDate}), [startDate, endDate]);
}
