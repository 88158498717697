"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Engine = void 0;
const mobx_1 = require("mobx");
const model_1 = require("../proto/model");
const state_1 = require("../state/state");
const debugging = false;
/*
 * https://mobx.js.org/best/actions.html
 */
class Engine {
    constructor(persistor, eventListener = {
        onEventSuccess: (e) => {
            if (debugging) {
                console.log('Event Processed', e);
            }
        },
        onEventFailure: (e) => {
            if (debugging) {
                console.log('Event Failed', e);
            }
        },
    }) {
        this.persistor = persistor;
        this.eventListener = eventListener;
        this.state = model_1.State.fromPartial({});
    }
    setEventListener(eventListener) {
        this.eventListener = eventListener;
    }
    /**
     * Update the state and process the given events, if any
     * Useful for reconciling remote state with local state
     * @param state
     * @param events
     */
    setState(state, events = []) {
        for (const event of events) {
            // Process events on the *given* state and then return, used to keep
            // changes in a single mobx transaction
            state_1.process(state, event);
        }
        this.state = state;
    }
    async process(event, persist = true) {
        try {
            state_1.process(this.state, event);
            if (persist) {
                // Only persist the event if processing succeeded
                await this.persistor.persist(event);
            }
            this.eventListener.onEventSuccess(event);
        }
        catch (ex) {
            // TODO: persist failed events
            this.eventListener.onEventFailure(event);
            throw ex;
        }
    }
    async processMultiple(events, persist = true) {
        // TODO: this should not be called from the front-end
        for (const event of events) {
            await this.process(event, persist);
        }
    }
}
__decorate([
    mobx_1.action.bound
], Engine.prototype, "setState", null);
__decorate([
    mobx_1.action.bound
], Engine.prototype, "process", null);
__decorate([
    mobx_1.action.bound
], Engine.prototype, "processMultiple", null);
exports.Engine = Engine;
