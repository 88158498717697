import * as React from 'react';
import {Formik, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import Paper from '@material-ui/core/Paper';
import BG from 'images/BG.jpg';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {AngloNamedLogo} from 'images/anglo-named-logo';
import {useAlertContext} from '@deckmans/web-shared';
import {useAuthContext} from 'contexts/AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `url(${BG})`,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  textBG: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup
  .object({
    username: yup.string().required('Required'),
  })
  .required();

type Values = yup.InferType<typeof validationSchema>;

export function ResetPassword() {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const [errorMessage, setErrorMessage] = React.useState('');
  const {handleRequestResetPassword} = useAuthContext();

  return (
    <Box height="100vh" display="flex" alignItems="center">
      <Formik<Values>
        validationSchema={validationSchema}
        initialValues={{username: ''}}
        onSubmit={async ({username}) => {
          setErrorMessage('');
          try {
            await handleRequestResetPassword({username});
            // TODO reset password mutation
            alert('Password Reset email sent successfully.', 'success');
          } catch (ex) {
            // TODO handle error messages better
            alert(ex.message, 'error');
          }
        }}
      >
        {({submitForm, submitCount, isSubmitting}) => (
          <Box className={classes.background}>
            <Box display="flex" width="50%" justifyContent="center">
              <Paper className={classes.paper}>
                <Box display="flex" flexDirection="column" pt={1.5}>
                  <Typography
                    color="primary"
                    variant="h1"
                    className={classes.textBG}
                  >
                    Deckmans
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h3"
                    className={classes.textBG}
                  >
                    Password Reset
                  </Typography>
                </Box>

                <Box>
                  <Box m={2} mb={3} display="flex" justifyContent="center">
                    <AngloNamedLogo />
                  </Box>

                  <Box m={2} mb={3}>
                    <Field
                      component={TextField}
                      name="username"
                      fullWidth
                      label="Username"
                    />
                  </Box>
                  <Box m={2} mb={3}>
                    {submitCount > 0 && (
                      <Typography variant="body2" style={{maxWidth: 320}}>
                        Please check your email for instruction on how to reset
                        your password
                      </Typography>
                    )}
                  </Box>
                  <Box display="flex" justifyContent="center">
                    {errorMessage !== '' && (
                      <Typography variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                  <Box m={2} mb={3}>
                    <Button
                      onClick={submitForm}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Send Reset Password Link
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
