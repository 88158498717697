import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  Chip,
  ListItem,
  List,
  ListItemText,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import DeleteDialog from 'components/DeleteDialog';
import {VesselState, UserRole} from '@deckmans/domain';
import {TimeBetween} from 'components/Duration';
import {useAuthContext} from 'contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  outerBox: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  h5: {
    fontFamily: 'Roboto Mono',
  },
  listPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

interface Props {
  startTime: Date;
  endTime: Date;
  activity: string;
  reason: string;
  onClick: () => void;
  onDelete: () => void;
  vesselState: number;
  hatchFinished: boolean;
}

export function HatchActivityInfoBlock({
  activity,
  reason,
  endTime,
  startTime,
  onClick,
  onDelete,
  vesselState,
  hatchFinished,
}: Props) {
  const classes = useStyles();
  const isVesselSubmitted = vesselState === VesselState.VESSEL_STATE_COMPLETE;
  const {hasRole} = useAuthContext();
  return (
    <List classes={{padding: classes.listPadding}}>
      <Divider />
      <ListItem>
        <ListItemText
          disableTypography
          primary={
            <Box display="flex">
              <Box>
                <Typography variant="subtitle1" color="textSecondary">
                  {moment(startTime).format('HH:mm')}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {moment(endTime).format('HH:mm')}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                pl={2}
              >
                <Box display="flex">
                  <Typography>{activity}</Typography>
                  <Box pl={3}>
                    <Chip variant="outlined" label={reason} />
                  </Box>
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography variant="overline">Total Time</Typography>
                      <Typography variant="h5" className={classes.h5}>
                        <TimeBetween
                          startTime={startTime.toString()}
                          endTime={endTime.toString()}
                        />
                      </Typography>
                    </Box>
                    <Box pl={3}>
                      <Button variant="text" onClick={onClick}>
                        {hatchFinished || hasRole(UserRole.USER_ROLE_AGENT)
                          ? 'View'
                          : 'Edit'}
                      </Button>

                      {!hatchFinished && isVesselSubmitted && (
                        <DeleteDialog
                          title={activity}
                          subtitle="Are you sure you want to delete this activity?"
                          handleAgree={() => {
                            onDelete();
                          }}
                          roleAllows={[
                            UserRole.USER_ROLE_ADMIN,
                            UserRole.USER_ROLE_SUPERVISOR,
                          ]}
                        />
                      )}
                      {!hatchFinished && !isVesselSubmitted && (
                        <DeleteDialog
                          title={activity}
                          subtitle="Are you sure you want to delete this activity?"
                          handleAgree={() => {
                            onDelete();
                          }}
                          roleAllows={[
                            UserRole.USER_ROLE_ADMIN,
                            UserRole.USER_ROLE_SUPERVISOR,
                            UserRole.USER_ROLE_USER,
                          ]}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </List>
  );
}
