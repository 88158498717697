import React from 'react';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  DialogContentText,
} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useEngineContext} from 'contexts/EngineContext';
import {useAuthContext} from 'contexts/AuthContext';
import {TransitionProps} from '@material-ui/core/transitions';
import {Vessel, VesselState, UserRole} from '@deckmans/domain';

interface Props {
  saveText?: string;
  createText?: string;
  handleSave?: () => void;
  isEditing: boolean;
  title: string;
  subtitle: string;
  vessel: Vessel;
  activityId: string;
  hatchFinished?: boolean;
}
const Transition = React.forwardRef(function Transition(
  //eslint-disable-next-line
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export function ButtonBar({
  saveText,
  createText,
  handleSave,
  isEditing,
  title,
  subtitle,
  vessel,
  activityId,
  hatchFinished,
}: Props) {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(
    false
  );
  const history = useHistory();
  const {eventCreator, engine} = useEngineContext();
  const {hasRole} = useAuthContext();

  const roleAllows =
    hasRole(UserRole.USER_ROLE_ADMIN) ||
    hasRole(UserRole.USER_ROLE_SUPERVISOR) ||
    hasRole(UserRole.USER_ROLE_USER);
  const isVesselSubmitted = vessel.state === VesselState.VESSEL_STATE_COMPLETE;

  const roleAllowsAfterVesselSubmitted =
    hasRole(UserRole.USER_ROLE_ADMIN) || hasRole(UserRole.USER_ROLE_SUPERVISOR);

  hatchFinished = hatchFinished ?? false;

  return (
    <Box>
      {isEditing ? (
        !hatchFinished ? (
          <Grid container spacing={3}>
            {isVesselSubmitted && roleAllowsAfterVesselSubmitted && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={() => {
                    return new Promise((resolve) => {
                      if (handleSave) {
                        handleSave();
                      }
                      resolve();
                    });
                  }}
                >
                  {saveText}
                </Button>
              </Grid>
            )}
            {!isVesselSubmitted && roleAllows && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={() => {
                    return new Promise((resolve) => {
                      if (handleSave) {
                        handleSave();
                      }
                      resolve();
                    });
                  }}
                >
                  {saveText}
                </Button>
              </Grid>
            )}
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="text"
                color="secondary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Grid>
            {roleAllows === true && !isVesselSubmitted && (
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    setOpenDeleteConfirmation(!openDeleteConfirmation);
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
            {hasRole(
              UserRole.USER_ROLE_ADMIN || hasRole(UserRole.USER_ROLE_SUPERVISOR)
            ) &&
              isVesselSubmitted === true && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={() => {
                      setOpenDeleteConfirmation(!openDeleteConfirmation);
                    }}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="text"
                color="secondary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                return new Promise((resolve) => {
                  if (handleSave) {
                    handleSave();
                  }
                  resolve();
                });
              }}
            >
              {createText}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="text"
              color="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}

      <Dialog
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(!openDeleteConfirmation);
        }}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                onClick={async () => {
                  const event = eventCreator.activityDeleted(
                    vessel.id,
                    activityId
                  );
                  await engine.process(event);
                  history.goBack();
                }}
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="text"
                fullWidth
                color="primary"
                onClick={() => {
                  setOpenDeleteConfirmation(!openDeleteConfirmation);
                }}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
