import React from 'react';
import {NonHatchBreakdownActivity as NHA, Shiploader} from '@deckmans/domain';
import {ActivityListItem} from './ActivityListItem';
import {useHistory} from 'react-router';
import {useDataContext} from 'providers/DataContextProvider';

export interface NonHatchActivityProps {
  nonHatchBreakdownActivity: NHA;
  vesselId: string;
  id?: string;
  startDate?: Date;
  endDate?: Date;
  shiploader: Shiploader;
}
export function NonHatchBreakdownActivityComp({
  nonHatchBreakdownActivity,
  vesselId,
  startDate,
  endDate,
  shiploader,
  id,
}: NonHatchActivityProps) {
  const {reason, equipmentName} = nonHatchBreakdownActivity;
  const history = useHistory();
  const {printDecision} = useDataContext();

  const subtitle = printDecision(reason).join('/ ');
  const breakdownType = printDecision(reason)[0];

  return (
    <ActivityListItem
      color="warning"
      title={breakdownType === 'Disruption' ? 'Disruption' : 'Breakdown'}
      subtitle={
        breakdownType === 'Disruption'
          ? printDecision(reason)[2]
          : equipmentName !== ''
          ? equipmentName
          : subtitle
      }
      startTime={startDate}
      endTime={endDate}
      editItem={() => {
        history.push(
          `/activity/non-hatch-activity/edit/${shiploader}/${vesselId}/${id}`
        );
      }}
    />
  );
}
