import * as React from 'react';
import {Chip, Paper, Typography} from '@material-ui/core';
import {Vessel} from '@deckmans/domain';
import {theme} from '@deckmans/web-shared';
import {useHistory} from 'react-router';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import MaterialTable from 'material-table';
import {useWindowSize} from 'react-use';

interface Props {
  vessels: Vessel[];
}

export function VesselSearchTable({vessels}: Props) {
  const history = useHistory();
  const {height} = useWindowSize();

  const tableData = React.useMemo(() => {
    const tableData: {
      vesselName: string;
      berth: string;
      dateCreated: string;
      by: string;
      id: string;
    }[] = [];

    vessels.forEach((vessel) => {
      if (vessel) {
        tableData.push({
          vesselName: vessel.name,
          berth: vessel.berth,
          dateCreated:
            vessel.reclaimStart?.toLocaleDateString() ??
            new Date().toLocaleDateString(),
          by: vessel.createdBy?.username ?? '',
          id: vessel.id,
        });
      }
    });
    return tableData;
  }, [vessels]);

  return (
    <MaterialTable
      icons={{
        SortArrow: React.forwardRef(function addArrow(props, ref) {
          return <ArrowUpward {...props} ref={ref} />;
        }),
      }}
      data={tableData}
      options={{
        search: false,
        paging: false,
        actionsColumnIndex: -1,
        maxBodyHeight: height - 300,
        headerStyle: {
          color: theme.palette.text.secondary,
          textTransform: 'uppercase',
          position: 'sticky',
          top: 0,
        },
      }}
      title={
        <Typography variant="h4" color="primary">
          Vessel Logbook
        </Typography>
      }
      columns={[
        {
          title: 'Vessel Name',
          field: 'vesselName',

          render: function headerName(props) {
            return (
              <Typography variant="subtitle1">{props.vesselName}</Typography>
            );
          },
        },
        {
          title: 'Berth',
          field: 'berth',

          render: function headerName(props) {
            return (
              <Chip
                label={<Typography variant="body2">{props.berth}</Typography>}
                variant="outlined"
              />
            );
          },
        },
        {
          title: 'Date Created',
          field: 'dateCreated',

          render: function headerName(props) {
            return (
              <Typography variant="subtitle2" color="textSecondary">
                {props.dateCreated}
              </Typography>
            );
          },
        },
        {
          title: 'By',
          field: 'by',

          render: function headerName(props) {
            return (
              <Typography variant="subtitle2" color="textSecondary">
                {props.by}
              </Typography>
            );
          },
        },
      ]}
      actions={[
        {
          icon: function iconComp() {
            return <EditRoundedIcon color="primary" />;
          },
          tooltip: 'Edit Vessel',
          onClick: (_event, rowData) => {
            if (!Array.isArray(rowData)) {
              history.push(`/vessel/edit/${rowData.id}`);
            }
          },
        },
        {
          icon: function iconComp() {
            return <ViewColumnRoundedIcon color="primary" />;
          },
          tooltip: 'Add/Edit activities',
          onClick: (_event, rowData) => {
            if (!Array.isArray(rowData)) {
              history.push('/vessel/view/' + rowData.id);
            }
          },
        },
      ]}
      components={{
        Container: function containerComp(props) {
          return <Paper {...props} elevation={8} />;
        },
      }}
    />
  );
}
