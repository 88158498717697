import React from 'react';
import {useIndexedDbContext} from 'contexts/IndexedDbContext';
import {unregister} from 'serviceWorker';

export function useClearSiteData() {
  const db = useIndexedDbContext();
  const clear = React.useCallback(() => {
    async function clearData() {
      localStorage.clear();
      db.reset();
      unregister();
      const keys = await caches.keys();
      keys.forEach((name) => {
        caches.delete(name);
      });
      sessionStorage.clear();
      window.location.reload();
    }
    clearData();
  }, [db]);
  const value = React.useMemo(() => ({clear}), [clear]);
  return value;
}
