"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsLatestEventResponse = exports.IsLatestEventRequest = exports.GetStateResponse = exports.GetStateRequest = exports.UploadEventsResponse = exports.UploadEventsRequest = exports.EventServiceClientImpl = void 0;
/* eslint-disable */
const event_1 = require("../proto/event");
const model_1 = require("../proto/model");
const minimal_1 = require("protobufjs/minimal");
const timestamp_1 = require("../google/protobuf/timestamp");
const baseUploadEventsRequest = {};
const baseUploadEventsResponse = {};
const baseGetStateRequest = {
    eventId: "",
};
const baseGetStateResponse = {};
const baseIsLatestEventRequest = {
    eventId: "",
};
const baseIsLatestEventResponse = {
    isLatest: false,
};
class EventServiceClientImpl {
    constructor(rpc) {
        this.rpc = rpc;
    }
    UploadEvents(request) {
        const data = exports.UploadEventsRequest.encode(request).finish();
        const promise = this.rpc.request("service.EventService", "UploadEvents", data);
        return promise.then(data => exports.UploadEventsResponse.decode(new minimal_1.Reader(data)));
    }
    GetState(request) {
        const data = exports.GetStateRequest.encode(request).finish();
        const promise = this.rpc.request("service.EventService", "GetState", data);
        return promise.then(data => exports.GetStateResponse.decode(new minimal_1.Reader(data)));
    }
    IsLatestEvent(request) {
        const data = exports.IsLatestEventRequest.encode(request).finish();
        const promise = this.rpc.request("service.EventService", "IsLatestEvent", data);
        return promise.then(data => exports.IsLatestEventResponse.decode(new minimal_1.Reader(data)));
    }
}
exports.EventServiceClientImpl = EventServiceClientImpl;
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function toTimestamp(date) {
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
exports.UploadEventsRequest = {
    encode(message, writer = minimal_1.Writer.create()) {
        for (const v of message.events) {
            event_1.Event.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.toDate !== undefined && message.toDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.toDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.fromDate !== undefined && message.fromDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.fromDate), writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseUploadEventsRequest };
        message.events = [];
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.events.push(event_1.Event.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.toDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.fromDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseUploadEventsRequest };
        message.events = [];
        if (object.events !== undefined && object.events !== null) {
            for (const e of object.events) {
                message.events.push(event_1.Event.fromJSON(e));
            }
        }
        if (object.toDate !== undefined && object.toDate !== null) {
            message.toDate = fromJsonTimestamp(object.toDate);
        }
        else {
            message.toDate = undefined;
        }
        if (object.fromDate !== undefined && object.fromDate !== null) {
            message.fromDate = fromJsonTimestamp(object.fromDate);
        }
        else {
            message.fromDate = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseUploadEventsRequest };
        message.events = [];
        if (object.events !== undefined && object.events !== null) {
            for (const e of object.events) {
                message.events.push(event_1.Event.fromPartial(e));
            }
        }
        if (object.toDate !== undefined && object.toDate !== null) {
            message.toDate = object.toDate;
        }
        else {
            message.toDate = undefined;
        }
        if (object.fromDate !== undefined && object.fromDate !== null) {
            message.fromDate = object.fromDate;
        }
        else {
            message.fromDate = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        if (message.events) {
            obj.events = message.events.map(e => e ? event_1.Event.toJSON(e) : undefined);
        }
        else {
            obj.events = [];
        }
        obj.toDate = message.toDate !== undefined ? message.toDate.toISOString() : null;
        obj.fromDate = message.fromDate !== undefined ? message.fromDate.toISOString() : null;
        return obj;
    },
};
exports.UploadEventsResponse = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.state !== undefined && message.state !== undefined) {
            model_1.State.encode(message.state, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseUploadEventsResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = model_1.State.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseUploadEventsResponse };
        if (object.state !== undefined && object.state !== null) {
            message.state = model_1.State.fromJSON(object.state);
        }
        else {
            message.state = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseUploadEventsResponse };
        if (object.state !== undefined && object.state !== null) {
            message.state = model_1.State.fromPartial(object.state);
        }
        else {
            message.state = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.state = message.state ? model_1.State.toJSON(message.state) : undefined;
        return obj;
    },
};
exports.GetStateRequest = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.eventId);
        if (message.toDate !== undefined && message.toDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.toDate), writer.uint32(18).fork()).ldelim();
        }
        if (message.fromDate !== undefined && message.fromDate !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.fromDate), writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseGetStateRequest };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventId = reader.string();
                    break;
                case 2:
                    message.toDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.fromDate = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseGetStateRequest };
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = String(object.eventId);
        }
        else {
            message.eventId = "";
        }
        if (object.toDate !== undefined && object.toDate !== null) {
            message.toDate = fromJsonTimestamp(object.toDate);
        }
        else {
            message.toDate = undefined;
        }
        if (object.fromDate !== undefined && object.fromDate !== null) {
            message.fromDate = fromJsonTimestamp(object.fromDate);
        }
        else {
            message.fromDate = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseGetStateRequest };
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = object.eventId;
        }
        else {
            message.eventId = "";
        }
        if (object.toDate !== undefined && object.toDate !== null) {
            message.toDate = object.toDate;
        }
        else {
            message.toDate = undefined;
        }
        if (object.fromDate !== undefined && object.fromDate !== null) {
            message.fromDate = object.fromDate;
        }
        else {
            message.fromDate = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.eventId = message.eventId || "";
        obj.toDate = message.toDate !== undefined ? message.toDate.toISOString() : null;
        obj.fromDate = message.fromDate !== undefined ? message.fromDate.toISOString() : null;
        return obj;
    },
};
exports.GetStateResponse = {
    encode(message, writer = minimal_1.Writer.create()) {
        if (message.state !== undefined && message.state !== undefined) {
            model_1.State.encode(message.state, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseGetStateResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.state = model_1.State.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseGetStateResponse };
        if (object.state !== undefined && object.state !== null) {
            message.state = model_1.State.fromJSON(object.state);
        }
        else {
            message.state = undefined;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseGetStateResponse };
        if (object.state !== undefined && object.state !== null) {
            message.state = model_1.State.fromPartial(object.state);
        }
        else {
            message.state = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.state = message.state ? model_1.State.toJSON(message.state) : undefined;
        return obj;
    },
};
exports.IsLatestEventRequest = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(10).string(message.eventId);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseIsLatestEventRequest };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.eventId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseIsLatestEventRequest };
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = String(object.eventId);
        }
        else {
            message.eventId = "";
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseIsLatestEventRequest };
        if (object.eventId !== undefined && object.eventId !== null) {
            message.eventId = object.eventId;
        }
        else {
            message.eventId = "";
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.eventId = message.eventId || "";
        return obj;
    },
};
exports.IsLatestEventResponse = {
    encode(message, writer = minimal_1.Writer.create()) {
        writer.uint32(8).bool(message.isLatest);
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new minimal_1.Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseIsLatestEventResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isLatest = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseIsLatestEventResponse };
        if (object.isLatest !== undefined && object.isLatest !== null) {
            message.isLatest = Boolean(object.isLatest);
        }
        else {
            message.isLatest = false;
        }
        return message;
    },
    fromPartial(object) {
        const message = { ...baseIsLatestEventResponse };
        if (object.isLatest !== undefined && object.isLatest !== null) {
            message.isLatest = object.isLatest;
        }
        else {
            message.isLatest = false;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        obj.isLatest = message.isLatest || false;
        return obj;
    },
};
