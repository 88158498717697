"use strict";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
Object.defineProperty(exports, "__esModule", { value: true });
exports.generator = void 0;
const model_1 = require("../proto/model");
const shared_1 = require("../proto/shared");
const master_1 = require("../proto/master");
function createDecision(type, ...keys) {
    return master_1.DecisionAnswer.fromPartial({
        type,
        decisionKeys: keys,
    });
}
function generator({ eventCreator, offset, }) {
    function d(minutes) {
        var _a;
        const millis = (_a = offset === null || offset === void 0 ? void 0 : offset.getTime()) !== null && _a !== void 0 ? _a : 0;
        return new Date(millis + minutes * 60 * 1000);
    }
    function createShiploader1Hatch1(vesselId) {
        const events = [];
        const hatchActivityGroupEvent = eventCreator.hatchActivityGroupedCreated({
            vesselId,
            hatchNumber: 1,
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            client: 'Assmang',
            oreType: 'AF',
        });
        events.push(hatchActivityGroupEvent);
        const groupActivityId = hatchActivityGroupEvent.hatchActivityGroupCreated
            .id;
        events.push(eventCreator.hatchDirectLoadingActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(0),
            endDate: d(60),
            train: '1003/2',
            tippler: 'tippler1',
            route: 'Route1',
        }));
        events.push(eventCreator.hatchBreakdownActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(60),
            endDate: d(75),
            equipmentName: 'Conveyers',
            comments: '',
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY, 'SAMPLING_PLANT', 'CLEANING_WORK'),
        }));
        events.push(eventCreator.hatchLoadingActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(75),
            endDate: d(105),
            stockpile: 'S1',
            route: 'Route1',
            comments: '',
        }));
        events.push(eventCreator.hatchReclaimingActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(105),
            endDate: d(150),
            comments: '',
            client: 'Assmang',
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY, 'STOCKPILE_DEPLETED'),
        }));
        events.push(eventCreator.stopHatchCreated({
            vesselId,
            groupActivityId,
            hatchTons: 5,
            oreType: 'AF',
            hatchRate: 100,
            directLoadingTons: 50,
            directLoadingRate: 10,
            stockpileRate: 12,
            stockpileTons: 13,
        }));
        return events;
    }
    function createShiploader2Hatch2(vesselId) {
        const events = [];
        const hatchActivityGroupEvent = eventCreator.hatchActivityGroupedCreated({
            vesselId,
            hatchNumber: 2,
            shiploader: shared_1.Shiploader.SHIPLOADER_TWO,
            client: 'Assmang',
            oreType: 'AF',
        });
        events.push(hatchActivityGroupEvent);
        const groupActivityId = hatchActivityGroupEvent.hatchActivityGroupCreated
            .id;
        events.push(eventCreator.hatchDirectLoadingActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(0),
            endDate: d(45),
            train: '1003/2',
            tippler: 'tippler1',
            route: 'Route1',
        }));
        events.push(eventCreator.stopHatchCreated({
            vesselId,
            groupActivityId,
            hatchTons: 5,
            oreType: 'AF',
            hatchRate: 100,
            directLoadingTons: 50,
            directLoadingRate: 10,
            stockpileRate: 12,
            stockpileTons: 13,
        }));
        return events;
    }
    function createShiploader2Hatch4(vesselId) {
        const events = [];
        const hatchActivityGroupEvent = eventCreator.hatchActivityGroupedCreated({
            vesselId,
            hatchNumber: 4,
            shiploader: shared_1.Shiploader.SHIPLOADER_TWO,
            client: 'Assmang',
            oreType: 'AF',
        });
        events.push(hatchActivityGroupEvent);
        const groupActivityId = hatchActivityGroupEvent.hatchActivityGroupCreated
            .id;
        events.push(eventCreator.hatchBreakdownActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(45),
            endDate: d(90),
            equipmentName: 'Tippler1',
            comments: '',
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY, 'PLANT', 'TIPPLERS'),
        }));
        events.push(eventCreator.hatchDirectLoadingActivityCreated({
            vesselId,
            groupActivityId,
            startDate: d(90),
            endDate: d(150),
            train: '1003/2',
            tippler: 'tippler1',
            route: 'Route1',
        }));
        events.push(eventCreator.stopHatchCreated({
            vesselId,
            groupActivityId,
            hatchTons: 5,
            oreType: 'AF',
            hatchRate: 100,
            directLoadingTons: 50,
            directLoadingRate: 10,
            stockpileRate: 12,
            stockpileTons: 13,
        }));
        return events;
    }
    function createOthers(vesselId) {
        const events = [];
        events.push(eventCreator.surveyActivityCreated({
            vesselId,
            startDate: d(105),
            endDate: d(135),
            surveyType: shared_1.SurveyType.SURVEY_TYPE_ADD_ON_SURVEY,
            oreGroup: [],
            comments: '',
        }));
        events.push(eventCreator.stopVesselActivityCreated({
            vesselId,
            startDate: d(135),
            endDate: d(200),
            vslKmPerHour: 10,
            ccrKmPerHour: 10,
            portControlKmPerHour: 35,
            swellMeters: 100,
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_STOP_VESSEL_ACTIVITY, 'VESSEL_DE_BALLAST', 'NOT_ENOUGH_UPFRONT_DE_BALLAST_TIME'),
            vesselName: 'Gariep',
            comments: '',
        }));
        events.push(eventCreator.nonHatchShiftingActivityCreated({
            vesselId,
            startDate: d(200),
            endDate: d(215),
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            reason: 'Shifting!',
            comments: '',
        }));
        events.push(eventCreator.nonHatchReclaimingActivityCreated({
            vesselId,
            startDate: d(215),
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            endDate: d(230),
            client: 'Assmang',
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_RECLAIM_ACTIVITY, 'BREAKDOWN'),
            comments: '',
        }));
        events.push(eventCreator.nonHatchBreakdownActivityCreated({
            vesselId,
            startDate: d(230),
            endDate: d(245),
            equipmentName: '',
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY, 'BLOCKED_CHUTES', 'CONVEYOR_ON_CONVEYOR'),
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            comments: '',
            client: 'Assmang',
            oreType: 'AF',
        }));
        events.push(eventCreator.nonHatchAwaitingShiploaderActivityCreated({
            vesselId,
            startDate: d(245),
            endDate: d(260),
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            reason: createDecision(
            // TODO: correct type
            master_1.DecisionType.DECISION_TYPE_BREAKDOWN_ACTIVITY, 'BLOCKED_CHUTES', 'CONVEYOR_ON_CONVEYOR'),
            comments: 'string',
        }));
        events.push(eventCreator.nonHatchTnpaActivityCreated({
            vesselId,
            startDate: d(265),
            endDate: d(280),
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            reason: 'TNPA!',
            comments: '',
        }));
        events.push(eventCreator.singleLoadingActivityCreated({
            vesselId,
            startDate: d(280),
            endDate: d(300),
            shiploader: shared_1.Shiploader.SHIPLOADER_ONE,
            reason: createDecision(master_1.DecisionType.DECISION_TYPE_SINGLE_LOADING_ACTIVITY, 'ANNUAL_SHUTDOWN'),
            comments: '',
        }));
        return events;
    }
    return function generate({ vesselName = 'José', berth = 'OBS', }) {
        const events = [];
        const vesselCreatedEvent = eventCreator.vesselCreated({
            name: vesselName,
            berth,
            comments: '',
            state: model_1.VesselState.VESSEL_STATE_IN_PROGRESS,
            reclaimStart: d(0),
            clients: [{ name: 'Assmang', shipmentNumbers: [] }],
            delay: '',
            previousVesselId: '',
            delayReason2: '',
            secondComments: '',
            timeBetweenVesselHours: 0,
            timeBetweenVesselMinutes: 0,
        });
        events.push(vesselCreatedEvent);
        const vesselId = vesselCreatedEvent.vesselCreated.id;
        events.push(...createShiploader1Hatch1(vesselId));
        events.push(...createShiploader2Hatch2(vesselId));
        events.push(...createShiploader2Hatch4(vesselId));
        events.push(...createOthers(vesselId));
        return events;
    };
}
exports.generator = generator;
