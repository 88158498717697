import React from 'react';
import DeleteDialog from 'components/DeleteDialog';
import {useEngineContext} from 'contexts/EngineContext';
import {Vessel, UserRole} from '@deckmans/domain';

interface Props {
  vessel: Vessel;
}

export function DeleteVesselButton({vessel}: Props) {
  const {engine, eventCreator} = useEngineContext();
  return (
    <DeleteDialog
      handleAgree={async () => {
        const event = eventCreator.vesselDeleted(vessel.id ?? '');
        await engine.process(event);
      }}
      title={`Delete Vessel ${vessel.name}`}
      subtitle={`Are you sure you want to delete vessel ${vessel.name}?`}
      roleAllows={[UserRole.USER_ROLE_ADMIN, UserRole.USER_ROLE_SUPERVISOR]}
    />
  );
}
