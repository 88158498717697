import React from 'react';
import {useVessel} from 'hooks/useVessel';
import Typography from '@material-ui/core/Typography';
import {makeStyles, Theme} from '@material-ui/core/styles';
import moment from 'moment';
import {
  findVesselEndDate,
  getShoreScaleTons,
  getIntermediateSurveyTotalOreGroup,
  getDraftSurveyTotalOreGroup,
  getAddOnSurveyTotalOreGroup,
  getShoreScaleTonsPerHatchGroup,
} from '@deckmans/domain/lib/util/sortVessels';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import {TimeBetween} from 'components/Duration';
import {useTitle} from 'contexts/TitleContext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import AgentSummary from './AgentSummary';
import VesselOverviewSummary from './VesselOverviewSummary';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
  tabsAppBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    paddingBottom: 0,
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  tabPanel: {
    overflowY: 'auto',
    height: '75vh',
  },
}));
export function Row({
  child1,
  child2,
}: {
  child1: React.ReactNode;
  child2: React.ReactNode;
}) {
  return (
    <TableRow>
      <TableCell align="left" colSpan={1}>
        <Typography variant="subtitle1">{child1}</Typography>
      </TableCell>
      <TableCell align="left" colSpan={1}>
        <Typography variant="subtitle2">{child2}</Typography>
      </TableCell>
    </TableRow>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function VesselOverview() {
  useTitle('Overview');
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  return (
    <>
      <AppBar position="static" className={classes.tabsAppBar}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Vessel Overview" {...a11yProps(0)} />
          <Tab label="Agent Summary" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <VesselOverviewSummary />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AgentSummary />
      </TabPanel>
    </>
  );
}

export function VesselOverviewDetails() {
  const vessel = useVessel();

  const intermediateOreGroup = getIntermediateSurveyTotalOreGroup(vessel);

  const draftSurveyOreGroup = getDraftSurveyTotalOreGroup(vessel);
  const draftSurveyOreGroupTotal = draftSurveyOreGroup
    .map((og) => og.oreTons)
    .reduce(function (a, b) {
      return a + b;
    }, 0);

  const addOnSurveyOreGroup = getAddOnSurveyTotalOreGroup(vessel);

  const addOnSurveyOreGroupTotal = addOnSurveyOreGroup
    .map((og) => og.oreTons)
    .reduce(function (a, b) {
      return a + b;
    }, 0);

  const shoreScaleTons = getShoreScaleTons(vessel);
  const shoreScaleTonsPerHatch = getShoreScaleTonsPerHatchGroup(vessel);

  const scaleDiff =
    shoreScaleTons -
    (draftSurveyOreGroup.length > 0
      ? draftSurveyOreGroupTotal
      : addOnSurveyOreGroupTotal);

  const scaleDiffPercentage =
    (scaleDiff && draftSurveyOreGroupTotal) || addOnSurveyOreGroupTotal
      ? (
          (scaleDiff /
            (draftSurveyOreGroup.length > 0
              ? draftSurveyOreGroupTotal
              : addOnSurveyOreGroupTotal)) *
          100
        ).toFixed(2)
      : null;
  return (
    <>
      <Row child1="Vessel" child2={vessel.name} />
      <Row
        child1="Client"
        child2={vessel.clients.map(
          (c, idx) =>
            `${c.name} ${vessel.clients.length - 1 !== idx ? ' / ' : ''}`
        )}
      />
      <Row
        child1="Reclaim start"
        child2={moment(vessel.reclaimStart).format('Do MMMM YYYY HH:mm')}
      />
      <Row
        child1="Last activity end time"
        child2={moment(findVesselEndDate(vessel)).format('Do MMMM YYYY HH:mm')}
      />
      <Row
        child1="Duration"
        child2={
          <TimeBetween
            startTime={vessel.reclaimStart}
            endTime={findVesselEndDate(vessel).toDate()}
          />
        }
      />
      <Row
        child1="Shore Scale tons"
        child2={Math.round(shoreScaleTons).toLocaleString()}
      />
      {shoreScaleTonsPerHatch.length > 0 && (
        <Row
          child1={`Shore Scale Tons: ${shoreScaleTons} T`}
          child2={
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography>Hatch Nr</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography>Tons</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shoreScaleTonsPerHatch.map((hatch) => (
                  <TableRow
                    key={`Shore_Scale_Hatch_${hatch.hatchNumber}_${hatch.hatchTons}`}
                  >
                    <TableCell colSpan={1}>
                      <Typography>{hatch.hatchNumber}</Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography>{hatch.hatchTons}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        />
      )}

      {intermediateOreGroup.length > 0 &&
        intermediateOreGroup.map((intermediateSurvey) => {
          const intermediateOreGroupTotal = intermediateSurvey.oreGroup
            .map((og) => og.oreTons)
            .reduce(function (a, b) {
              return a + b;
            }, 0);

          return (
            <Row
              key={`TR_Intermediate_${intermediateSurvey.startDate.toLocaleTimeString()}`}
              child1={`Intermediate Survey - ${intermediateSurvey.startDate.toLocaleTimeString()} `}
              child2={
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1}>
                        <Typography>Ore Type</Typography>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Typography>Tons</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {intermediateSurvey.oreGroup.map((oreGroup) => (
                      <TableRow
                        key={`TR_Intermediate_${oreGroup.oreType}_${oreGroup.oreTons}`}
                      >
                        <TableCell colSpan={1}>
                          <Typography>{oreGroup.oreType}</Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography>{oreGroup.oreTons}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={1}>
                        <Typography>Total</Typography>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Typography>{intermediateOreGroupTotal}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
            />
          );
        })}

      {draftSurveyOreGroup.length > 0 && (
        <Row
          child1={`Draft Survey Tons`}
          child2={
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography>Ore Type</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography>Tons</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {draftSurveyOreGroup.map((oreGroup) => (
                  <TableRow
                    key={`TR_Survey_${oreGroup.oreType}_${oreGroup.oreTons}`}
                  >
                    <TableCell colSpan={1}>
                      <Typography>{oreGroup.oreType}</Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography>{oreGroup.oreTons}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography>Total</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography>{draftSurveyOreGroupTotal}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          }
        />
      )}

      {addOnSurveyOreGroup.length > 0 && (
        <Row
          child1={`Add On Survey Tons`}
          child2={
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography>Ore Type</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography>Tons</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addOnSurveyOreGroup.map((oreGroup) => (
                  <TableRow
                    key={`TR_Survey_${oreGroup.oreType}_${oreGroup.oreTons}`}
                  >
                    <TableCell colSpan={1}>
                      <Typography>{oreGroup.oreType}</Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography>{oreGroup.oreTons}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography>Total</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography>{addOnSurveyOreGroupTotal}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          }
        />
      )}

      <Row
        child1="Shore scale diff"
        child2={Math.round(scaleDiff).toLocaleString()}
      />
      <Row
        child1="Shore scale diff%"
        child2={
          scaleDiffPercentage !== null ? `${scaleDiffPercentage}%` : 'N/A'
        }
      />
    </>
  );
}
